import { EapOrganizationRole } from "../enums/EapOrganizationRole";
import { EapProjectRole } from "../enums/EapProjectRole";
import { EapPermission } from "../enums/Permission";
import { CategoryPermissions } from "../generated/permissions/Category.permissions";
import type { GlobalPermissions } from "../generated/permissions/Global.permissions";
import { OrganizationPermissions } from "../generated/permissions/Organization.permissions";
import { ProjectPermissions } from "../generated/permissions/Project.permissions";
import { TemplatePermissions } from "../generated/permissions/Template.permissions";
import { TodoListPermissions } from "../generated/permissions/TodoList.permissions";
import { UserPermissions } from "../generated/permissions/User.permissions";
import { VersionPermissions } from "../generated/permissions/Version.permissions";
import { WorkpackagePermissions } from "../generated/permissions/Workpackage.permissions";

export type AllPermissions =
  | CategoryPermissions
  | GlobalPermissions
  | ProjectPermissions
  | VersionPermissions
  | TemplatePermissions
  | WorkpackagePermissions
  | UserPermissions
  | TodoListPermissions
  | OrganizationPermissions
  | EapPermission;

export const AdminPermissions: AllPermissions[] = [
  ProjectPermissions.Create,
  ProjectPermissions.Read,
  ProjectPermissions.Update,
  OrganizationPermissions.Create,
  OrganizationPermissions.Read,
  OrganizationPermissions.Update,
];

export const GuestPermissions: AllPermissions[] = [
  CategoryPermissions.Read,
  ProjectPermissions.Read,
  ProjectPermissions.ReadInfo,
  VersionPermissions.Read,
  TemplatePermissions.Read,
  WorkpackagePermissions.Read,
  UserPermissions.Read,
  TodoListPermissions.Read,
];

export const ProjectPermissionsByRole: Record<
  EapProjectRole,
  AllPermissions[]
> = {
  [EapProjectRole.Guest]: [...GuestPermissions],
  [EapProjectRole.Responsible]: [
    ...GuestPermissions,
    WorkpackagePermissions.Update,
    TodoListPermissions.Create,
    WorkpackagePermissions.Update,
    WorkpackagePermissions.UpdateInfo,
    EapPermission.UpdateWorkpackagesControlinfo,
    EapPermission.UpdateActivitySortIfResponsible,
  ],
  [EapProjectRole.Commissioner]: [
    ...GuestPermissions,
    WorkpackagePermissions.Update,
    TodoListPermissions.Create,
    WorkpackagePermissions.Update,
    WorkpackagePermissions.UpdateInfo,
    EapPermission.UpdateWorkpackagesControlinfo,
    EapPermission.UpdateActivitySortIfResponsible,
    EapPermission.UpdateActivitySortIfInvolved,
    EapPermission.UpdateWorkpackagesInvolved,
  ],
  [EapProjectRole.ProjectManager]: [
    ...GuestPermissions,
    CategoryPermissions.Create,
    WorkpackagePermissions.Create,
    VersionPermissions.Create,
    ProjectPermissions.AddToDraft,
    TemplatePermissions.Create,
    TodoListPermissions.Create,
    WorkpackagePermissions.Delete,
    TodoListPermissions.Delete,
    CategoryPermissions.Delete,
    TemplatePermissions.Delete,
    CategoryPermissions.Update,
    WorkpackagePermissions.Update,
    WorkpackagePermissions.UpdateInfo,
    TodoListPermissions.Update,
    TemplatePermissions.Update,
    EapPermission.FreezeWorkpackage,
    EapPermission.DeleteActivities,
    EapPermission.CreateActivities,
    EapPermission.UpdateWorkpackagesControlinfo,
    EapPermission.UpdateWorkpackages,
    EapPermission.UpdateWorkpackagesCommissioner,
    EapPermission.UpdateWorkpackagesMetaInfo,
    EapPermission.UpdateWorkpackagesResponsible,
    EapPermission.UpdateActivitySort,
    EapPermission.UpdateActivitySortIfResponsible,

    EapPermission.UpdateWorkpackagesInvolved,
    EapPermission.UpdateWorkpackagesDone,
    EapPermission.UpdateWorkpackagesDecision,
    EapPermission.UpdateWorkpackagesPlannedStart,
    EapPermission.UpdateWorkpackagesPlannedFinish,
    EapPermission.UpdateTodoSortIfResponsible,
    EapPermission.UpdateTodoSort,

    EapPermission.DownloadXlsx,
    EapPermission.AdminRestorebackup,
  ],
  [EapProjectRole.UserManager]: [],
} as const;

export const OrganizationPermissionsByRole: Record<
  EapOrganizationRole,
  AllPermissions[]
> = {
  [EapOrganizationRole.Guest]: [OrganizationPermissions.Read],
  [EapOrganizationRole.Manager]: [
    OrganizationPermissions.Read,
    OrganizationPermissions.Update,
    ProjectPermissions.Create,
    ProjectPermissions.Read,
    ProjectPermissions.Update,
  ],
} as const;
