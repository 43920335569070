import { __useUsername } from "@atoms/atom-client";
import type { UserPublicID } from "@atoms/atom-types";
import { memo } from "react";

interface UserNameProps {
  publicId: UserPublicID;
  fallback?: string;
}

export const UserName = memo(({ publicId, fallback }: UserNameProps) => {
  const displayName = __useUsername(publicId, fallback);
  return <>{displayName || "unbekannt"}</>;
});
