import { useMyUserId } from "@atoms/atom-client";
import { DefaultIdentifiers } from "@atoms/atom-types";
import { useAtom } from "@project/api";
import {
  type AllPermissions,
  Atoms,
  Identifiers,
  derivePermissionsByContext,
} from "@project/shared";
import { useCallback } from "react";
import { useEapContext } from "./useEapContext";

export function useHasPermission() {
  const context = useEapContext();
  const myUserId = useMyUserId();
  const roles = useAtom(Atoms.UserRoles, (roles) => roles, {
    [DefaultIdentifiers.UserId]: myUserId,
  });

  const orgId = context[Identifiers.OrganizationId];
  const projectId = context[Identifiers.ProjectId] as string;
  const permissions = derivePermissionsByContext(roles, orgId, projectId);

  return useCallback(
    (perm: AllPermissions | AllPermissions[]) => {
      return true;
      // if (Array.isArray(perm)) {
      //   return intersection(perm, permissions).length > 0;
      // } else {
      //   return permissions.includes(perm);
      // }
    },
    [permissions],
  );
}
