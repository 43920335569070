import { Tooltip, type TooltipProps } from "antd";
import type { ReactNode } from "react";

type ConditionalTooltipProps = TooltipProps & {
  disabled: boolean;
  children: ReactNode;
};
export const ConditionalTooltip = ({
  disabled,
  children,
  ...props
}: ConditionalTooltipProps) => {
  return disabled ? <>{children}</> : <Tooltip {...props}>{children}</Tooltip>;
};
