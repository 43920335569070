import { DefaultIdentifiers } from "@atoms/atom-types";
import { faBookmark, faCogs, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom, useMyUserId } from "@project/api";
import {
  Atoms,
  type EapProjectRole,
  EapProjectRoleLabel,
  Identifiers,
} from "@project/shared";
import { App, Button, Card, Skeleton, Tag } from "antd";
import { useAppStore } from "../state/app";
import { useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { ProjectRoutePaths } from "./ProjectRoutes";
import clsx from "clsx";
import {
  ProjectSettingsModal,
  type ProjectSettingsModalRef,
} from "./ProjectSettingsModal";
import { useScopeAwareNavigate } from "../hooks/useScopeAwareNavigate";

export interface ProjectPreviewProps {
  projectId: string;
  organizationId: string;
}

export const ProjectPreview = ({
  projectId,
  organizationId,
}: ProjectPreviewProps) => {
  const { notification } = App.useApp();
  const myUserId = useMyUserId();
  const isDefaultProject = useAppStore(
    (state) =>
      state.defaultProject?.orgId === organizationId &&
      state.defaultProject?.projectId === projectId,
  );
  const setAppState = useAppStore((state) => state.set);
  const navigate = useScopeAwareNavigate();
  const myRoles = useAtom(
    Atoms.UserRoles,
    (roles) => roles.orgs[organizationId]?.projects[projectId] ?? [],
    {
      [DefaultIdentifiers.UserId]: myUserId,
    },
  );
  const project = useAtom(Atoms.ProjectInfo, (info) => info, {
    [Identifiers.ProjectId]: projectId,
    [Identifiers.OrganizationId]: organizationId,
  });
  const organization = useAtom(Atoms.OrganizationInfo, (info) => info, {
    [Identifiers.OrganizationId]: organizationId,
  });
  const projectSettingsModalRef = useRef<ProjectSettingsModalRef>(null);

  const setDefaultProject = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setAppState("defaultProject", {
        orgId: organizationId,
        projectId: projectId,
      });
      notification.success({
        placement: "top",
        message: "Standardprojekt festgelegt",
        description: (
          <>
            <i>{project?.name}</i> wurde als Ihr Standardprojekt festgelegt.
          </>
        ),
      });
    },
    [setAppState, organizationId, projectId, notification, project?.name],
  );

  const clearDefaultProject = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setAppState("defaultProject", undefined);
      notification.success({
        placement: "top",
        message: "Standardprojekt entfernt",
        description: (
          <>
            <i>{project?.name}</i> ist nicht mehr Ihr Standardprojekt.
          </>
        ),
      });
    },
    [setAppState, notification, project?.name],
  );

  const isMemberOfProject = !myRoles || myRoles.length === 0;

  if (!project) {
    return (
      <Card style={{ height: 200 }}>
        <Skeleton active />
      </Card>
    );
  }

  const CardContent = (
    <Card
      title={
        <>
          {project.name}
          <div className="text-xs text-gray-500">
            Organisation "{organization?.name}"
          </div>
        </>
      }
      actions={[
        <Button
          key="default"
          type="text"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            isDefaultProject ? clearDefaultProject(e) : setDefaultProject(e);
          }}
          className={clsx(
            isDefaultProject
              ? "!text-yellow-400 hover:!text-yellow-800"
              : "!text-gray-400 hover:!text-gray-800",
          )}
          title={
            isDefaultProject
              ? "Als Standardprojekt entfernen"
              : "Als Standardprojekt festlegen"
          }
        >
          <FontAwesomeIcon icon={faBookmark} />
        </Button>,
        <Button
          key="users"
          aria-label="Benutzer"
          className="text-gray-400"
          type="text"
          title="Benutzer verwalten"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(`/-/${ProjectRoutePaths.Users}`);
          }}
        >
          <FontAwesomeIcon icon={faUsers} />
        </Button>,
        <Button
          key="settings"
          aria-label="Verwalten"
          className="text-gray-400"
          type="text"
          title="Einstellungen"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            projectSettingsModalRef.current?.show();
          }}
        >
          <FontAwesomeIcon icon={faCogs} />
        </Button>,
      ]}
      hoverable={!isMemberOfProject}
    >
      <p className="whitespace-pre-line">{project.description}</p>
      {isMemberOfProject ? (
        <Tag color="red">Sie sind kein Projektmitglied</Tag>
      ) : (
        <p>
          {myRoles.map((role: EapProjectRole) => (
            <Tag key={role} color="blue" style={{ marginRight: 4 }}>
              {EapProjectRoleLabel[role]}
            </Tag>
          ))}
        </p>
      )}
    </Card>
  );

  return (
    <>
      <ProjectSettingsModal ref={projectSettingsModalRef} />
      {isMemberOfProject ? (
        CardContent
      ) : (
        <Link to={`/${organizationId}/${projectId}/${ProjectRoutePaths.Home}`}>
          {CardContent}
        </Link>
      )}
    </>
  );
};
