import { ColorPicker, Row } from "antd";
import {
  generic_labels,
  getLabelProperties,
} from "../../util/getLabelProperties";
import { AsyncButton } from "./AsyncButton";
import { FloatLabel } from "./FloatLabel";
import "./TagPicker.scss";

interface TagPickerProps {
  value: string | null;
  onPick: (id: string) => void;
  onReset: () => void;
}

export const TagPicker = ({ value, onPick, onReset }: TagPickerProps) => {
  const labelProps = value ? getLabelProperties(value) : { color: "#ffffff" };

  return (
    <FloatLabel label="Label" value="asdf">
      <ColorPicker
        size="small"
        rootClassName="tag-picker"
        presets={[
          {
            label: "Label",
            colors: generic_labels.map((label) => label.color),
          },
        ]}
        value={labelProps?.color}
        onChange={(color) => {
          const label = generic_labels.find(
            (label) => label.color === color.toHexString(),
          );
          if (label) {
            onPick(label.id);
          }
        }}
        panelRender={(_, { components: { Presets } }) => (
          <>
            <Row justify="space-between">
              <h3>Label</h3>
              <AsyncButton
                type="primary"
                danger
                ghost
                style={{ margin: "5px" }}
                onClick={onReset}
                size="small"
              >
                Zurücksetzen
              </AsyncButton>
            </Row>
            <Row justify={"start"}>
              <Presets />
            </Row>
          </>
        )}
      />
    </FloatLabel>
  );
};
