import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import type { Event, EventListener } from "graphql-ws";
import { createClient } from "graphql-ws";

export const makeSubscriptionsLink = (
  url: string,
  getAuthToken: () => Promise<string | null>,
  retryAttempts = 10,
  maxRetryWaitTime = 5_000,
  callbacks?: Partial<
    {
      [event in Event]: EventListener<event>;
    } & {
      /**
       * will be called if the connection progress takes longer than 5 seconds
       */
      onDelayedConnection: () => void;
      onFatalError: (errorOrCloseEvent: unknown) => void;
    }
  >,
) => {
  const graphqlDebug = localStorage.getItem("graphqlDebug");
  let timedOut: undefined | ReturnType<typeof setTimeout>;
  let delayedConnectionTimeout: undefined | ReturnType<typeof setTimeout>;

  const client = createClient({
    url,
    connectionAckWaitTimeout: 30_000,
    keepAlive: 10_000,
    lazy: false,
    connectionParams: async () => {
      try {
        const token = await getAuthToken();

        if (token) {
          return {
            token,
          };
        } else {
          return {};
        }
      } catch (e) {
        return {};
      }
    },
    generateID: (payload) => {
      const uuid = crypto.randomUUID();

      if (graphqlDebug) {
        return `${payload.variables?.type}_${uuid}`;
      } else {
        return uuid;
      }
    },
    shouldRetry: () => true,
    retryAttempts,
    retryWait: async (retryNr) => {
      const wait =
        Math.min(maxRetryWaitTime, 100 * 2 ** retryNr) + Math.random() * 200;

      return new Promise((resolve) => setTimeout(resolve, wait));
    },
    onNonLazyError: (errorOrCloseEvent) => {
      callbacks?.onFatalError?.(errorOrCloseEvent);

      if (delayedConnectionTimeout) {
        clearTimeout(delayedConnectionTimeout);
        delayedConnectionTimeout = undefined;
      }
    },
    on: {
      connecting: (isRetry) => {
        callbacks?.connecting?.(isRetry);

        if (!delayedConnectionTimeout) {
          delayedConnectionTimeout = setTimeout(() => {
            callbacks?.onDelayedConnection?.();
          }, maxRetryWaitTime + 1_000);
        }
      },
      connected: (socket, payload, wasRetry) => {
        callbacks?.connected?.(socket, payload, wasRetry);

        if (delayedConnectionTimeout) {
          clearTimeout(delayedConnectionTimeout);
          delayedConnectionTimeout = undefined;
        }
      },
      closed: (evt) => {
        callbacks?.closed?.(evt);
      },
      error: (error) => {
        callbacks?.error?.(error);
      },
      opened: (socket) => {
        callbacks?.opened?.(socket);
      },
      ping: (received, payload) => {
        callbacks?.ping?.(received, payload);
        if (!received /* sent */) {
          timedOut = setTimeout(() => {
            // a close event `4499: Terminated` is issued to the current WebSocket and an
            // artificial `{ code: 4499, reason: 'Terminated', wasClean: false }` close-event-like
            // object is immediately emitted without waiting for the one coming from `WebSocket.onclose`
            //
            // calling terminate is not considered fatal and a connection retry will occur as expected
            //
            // see: https://github.com/enisdenjo/graphql-ws/discussions/290
            client.terminate();
          }, 5_000); // wait 5 seconds for the pong and then close the connection
        }
      },
      pong: (received, payload) => {
        callbacks?.pong?.(received, payload);
        if (received && timedOut) {
          clearTimeout(timedOut); // pong is received, clear connection close timeout
        }
      },
    },
  });

  return new GraphQLWsLink(client);
};
