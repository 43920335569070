export enum EapPermission {
  AddToDraft = "add_todraft",
  AddToOrganizationMembers = "add_to_organizationmembers",
  AddToProjectMembers = "add_to_projectmembers",
  AddToVersionList = "add_to_versionlist",
  AdminRestorebackup = "admin_restorebackup",
  CreateActivities = "create_activities",
  CreateAgentSession = "create_agentsession",
  CreateCategory = "create_category",
  CreateDraft = "create_draft",
  CreateOrganization = "create_organization",
  CreateProject = "create_project",
  CreateTemplate = "create_template",
  CreateTodoList = "create_todolist",
  CreateTodos = "create_todos",
  CreateUser = "create_user",
  CreateVersion = "create_version",
  CreateWorkpackage = "create_workpackage",
  DeleteActivities = "delete_activities",
  DeleteAgentSession = "delete_agentsession",
  DeleteCategory = "delete_categories",
  DeleteDraft = "delete_draft",
  DeleteOrganization = "delete_organization",
  DeleteProject = "delete_project",
  DeleteTemplate = "delete_template",
  DeleteTodoList = "delete_todolist",
  DeleteTodos = "delete_todos",
  DeleteUser = "delete_user",
  DeleteWorkpackage = "delete_workpackages",
  DownloadXlsx = "download_xlsx",
  FreezeWorkpackage = "freeze_workpackages",
  ReadActivities = "read_activities",
  ReadAgentSession = "read_agentsession",
  ReadCategory = "read_categories",
  ReadDrafts = "read_drafts",
  ReadOrganization = "read_organization",
  ReadProject = "read_project",
  ReadTemplate = "read_template",
  ReadTemplateList = "read_templatelist",
  ReadTodoList = "read_todolist",
  ReadUser = "read_users",
  ReadVersion = "read_version",
  ReadWorkpackage = "read_workpackages",
  RemoveFromDraft = "remove_from_draft",
  RemoveFromOrganizationMembers = "remove_from_organizationmembers",
  RemoveFromProjectMembers = "remove_from_projectmembers",
  RemoveFromTodoList = "remove_from_todolist",
  RemoveFromVersionList = "remove_from_versionlist",
  RoleCommissioner = "role_commissioner",
  RoleProjectManager = "role_projectmanager",
  RoleResponsible = "role_responsible",
  RoleUserManager = "role_usermanager",
  UpdateActivities = "update_activities",
  UpdateActivitySort = "update_activitysort",
  UpdateActivitySortIfInvolved = "update_activitysort-ifinvolved",
  UpdateActivitySortIfResponsible = "update_activitysort-ifresponsible",
  UpdateAgentSessionInfo = "update_agentsession_info",
  UpdateBookedLicenses = "update_bookedlicenses",
  UpdateCategory = "update_categories",
  UpdateCategoryInfo = "update_category_info",
  UpdateDraftEntry = "update_draft_entry",
  UpdateOrganization = "update_organization",
  UpdateOrganizationMembersEntry = "update_organizationmembers_entry",
  UpdateOrganizationInfo = "update_organization_info",
  UpdatePersonalUserData = "update_personaluserdata",
  UpdateProject = "update_project",
  UpdateProjectMembersEntry = "update_projectmembers_entry",
  UpdateProjectInfo = "update_project_info",
  UpdateTemplate = "update_template",
  UpdateTemplateInfo = "update_template_info",
  UpdateTodoList = "update_todolist",
  UpdateTodoListInfo = "update_todolist_info",
  UpdateTodos = "update_todos",
  UpdateTodoSort = "update_todosort",
  UpdateTodoSortIfResponsible = "update_todosort-ifresponsible",
  UpdateWorkpackages = "update_workpackages",
  UpdateWorkpackageInfo = "update_workpackage_info",
  UpdateWorkpackagesCommissioner = "update_workpackages-commissioner",
  UpdateWorkpackagesControlinfo = "update_workpackages-controlinfo",
  UpdateWorkpackagesDecision = "update_workpackages-decision",
  UpdateWorkpackagesDone = "update_workpackages-done",
  UpdateWorkpackagesInvolved = "update_workpackages-involved",
  UpdateWorkpackagesMetaInfo = "update_workpackages-metainfo",
  UpdateWorkpackagesPlannedFinish = "update_workpackages-plannedfinish",
  UpdateWorkpackagesPlannedStart = "update_workpackages-plannedstart",
  UpdateWorkpackagesResponsible = "update_workpackages-responsible",
  UpdateVersionListEntry = "update_versionlist_entry",
}
