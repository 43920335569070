import { MagicFormContext, useLabel } from "@atoms/atom-components";
import { GenericPermission } from "@atoms/atom-types";
import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Signal } from "@project/shared";
import { Radio, Space } from "antd";
import SkeletonInput from "antd/es/skeleton/Input";
import { useContext } from "react";

export const MagicSignal = () => {
  const field = "signal";
  const { updateDraft, currentValue, loading, checkFieldPermission } =
    useContext(MagicFormContext)!;
  const mayUpdate = checkFieldPermission(field, GenericPermission.Update);
  const label = useLabel(field);

  return loading ? (
    <SkeletonInput active style={{ height: 112, width: 246 }} block />
  ) : (
    <div className="float-label">
      <div className="label label-float">Ampel</div>
      <div className="radio-buttons">
        <Radio.Group
          key={`signal-input`}
          size="middle"
          value={currentValue[field] ?? undefined}
          onChange={(evt) => {
            updateDraft({
              signal: evt.target.value,
            });
          }}
        >
          <Space direction="vertical">
            <Radio value={Signal.RED}>
              <FontAwesomeIcon className="red" icon={faTimesCircle} /> Ablauf
              gestört
            </Radio>
            <Radio value={Signal.YELLOW}>
              <FontAwesomeIcon className="yellow" icon={faExclamationCircle} />{" "}
              Störungen wahrscheinlich
            </Radio>
            <Radio value={Signal.GREEN}>
              <FontAwesomeIcon className="green" icon={faCheckCircle} /> Alles
              im Plan
            </Radio>
          </Space>
        </Radio.Group>
      </div>
    </div>
  );
};
