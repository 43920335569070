import { useIdentifier, useMyUserId } from "@atoms/atom-client";
import { DefaultIdentifiers } from "@atoms/atom-types";
import { useAtom } from "@project/api";
import {
  Atoms,
  Identifiers,
  derivePermissionsByContext,
} from "@project/shared";

export const useEapPermissions = () => {
  const myUserId = useMyUserId();

  const roles = useAtom(Atoms.UserRoles, {
    [DefaultIdentifiers.UserId]: myUserId,
  });
  const orgId = useIdentifier(Identifiers.OrganizationId);
  const projectId = useIdentifier(Identifiers.ProjectId);

  return derivePermissionsByContext(
    roles,
    orgId?.toString(),
    projectId?.toString(),
  );
};
