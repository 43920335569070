/*
 * This file has been generated by permissions
 * Hash: 8d61cbb704a6f8a799d0949d9cc8cafbc1e8f2bdae832b60b2831e5f23d09217
 */

export enum VersionPermissions {
  Create = "CreateVersion",
  Delete = "DeleteVersion",
  Read = "ReadVersion",
  Update = "UpdateVersion",
}
