import { makeForm } from "@atoms/atom-components";
import { Atoms, type IProjectInfo, type ProjectPermissions } from "@project/shared";
import { useHasPermission } from "../../hooks/useHasPermission";

export const ProjectInfoForm = makeForm<
  Atoms.ProjectInfo,
  IProjectInfo
>({
  atom: Atoms.ProjectInfo,
  // permissions: projectInfoPermissions,
});
