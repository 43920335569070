import {
  faCogs,
  faProjectDiagram,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom, useIdentifier } from "@project/api";
import { Atoms, Identifiers } from "@project/shared";
import { Button, Card, Space, Typography } from "antd";
import type React from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { LicenseIndicator } from "../Admin/LicenseIndicator";
import {
  buildOrganizationRoutePath,
  OrganizationRoutePaths,
} from "../Routes/OrganizationRoutes";
import {
  OrganizationSettingsModal,
  type OrganizationSettingsModalRef,
} from "./OrganizationSettingsModal";

const { Text } = Typography;

export const OrganizationPreviewCard: React.FC = () => {
  const organizationId = useIdentifier(Identifiers.OrganizationId)?.toString();

  const organizationSettingsRef = useRef<OrganizationSettingsModalRef>(null);
  const organization = useAtom(Atoms.OrganizationInfo, (info) => info);
  const projects = useAtom(Atoms.ProjectList, (info) => info.entries) ?? {};

  const members = useAtom(
    Atoms.OrganizationMembers,
    (members) => Object.keys(members.entries).length,
  );

  if (!organizationId || !organization) {
    return null;
  }

  return (
    <>
      <OrganizationSettingsModal ref={organizationSettingsRef} />
      <Card
        title={organization.name}
        actions={[
          <Link
            to={buildOrganizationRoutePath(
              organizationId,
              OrganizationRoutePaths.Users,
            )}
            key="users"
          >
            <Button
              aria-label="Benutzer"
              className="text-gray-400"
              type="text"
              title="Benutzer verwalten"
            >
              <FontAwesomeIcon icon={faUsers} />
            </Button>
          </Link>,
          <Link
            to={buildOrganizationRoutePath(
              organizationId,
              OrganizationRoutePaths.Projects,
            )}
            key="organization-projects"
          >
            <Button
              aria-label="Projekte"
              className="text-gray-400"
              type="text"
              title="Projekte verwalten"
            >
              <FontAwesomeIcon icon={faProjectDiagram} />
            </Button>
          </Link>,
          <Button
            key="settings"
            aria-label="Verwalten"
            className="text-gray-400"
            type="text"
            onClick={() => organizationSettingsRef.current?.show()}
          >
            <FontAwesomeIcon icon={faCogs} />
          </Button>,
        ]}
        className="mb-4"
      >
        <p className="whitespace-pre-line">{organization.description}</p>
        <Space direction="vertical">
          <LicenseIndicator />
          <Text>
            <FontAwesomeIcon icon={faUsers} className="mr-1 text-gray-600" />{" "}
            Mitglieder: {members}
          </Text>
          <Text>
            <FontAwesomeIcon
              icon={faProjectDiagram}
              className="mr-1 text-gray-600"
            />{" "}
            Projekte: {Object.keys(projects).length}
          </Text>
        </Space>
      </Card>
    </>
  );
};
