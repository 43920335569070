/*
 * This file has been generated by atomInfos
 * Hash: 43d6b0e0896779945da1c463af5a2977a434b3b4451b5356163562c7e3d315a1
 */

import { AtomInfos, DefaultRelation, DefaultIdentifiers, DefaultAtoms } from "@atoms/atom-types";
import { Atoms, Entities, Identifiers } from "../..";
export const atomInfos: AtomInfos<Atoms, Entities, Identifiers> = {
  [Atoms.BookedLicenses]: {
    validContexts: [[Identifiers.OrganizationId]],
    entityType: Entities.Organization,
  },
  [Atoms.CategoryLatest]: {
    validContexts: [
      [Identifiers.OrganizationId, Identifiers.ProjectId, Identifiers.Code],
    ],
    entityType: Entities.Category,
  },
  [Atoms.CategoryInfo]: {
    validContexts: [
      [Identifiers.OrganizationId, Identifiers.ProjectId, Identifiers.Code],
    ],
    entityType: Entities.Category,
  },
  [Atoms.Draft]: {
    validContexts: [[Identifiers.OrganizationId, Identifiers.ProjectId]],
    entityType: Entities.Project,
  },
  [Atoms.Licenses]: {
    validContexts: [[Identifiers.OrganizationId]],
    entityType: Entities.Organization,
  },
  [Atoms.OrganizationInfo]: {
    validContexts: [[Identifiers.OrganizationId]],
    entityType: Entities.Organization,
  },
  [Atoms.OrganizationList]: {
    validContexts: [[]],
    entityType: DefaultRelation.Global,
  },
  [Atoms.OrganizationMembers]: {
    validContexts: [[Identifiers.OrganizationId]],
    entityType: Entities.Organization,
  },
  [Atoms.ProjectInfo]: {
    validContexts: [[Identifiers.OrganizationId, Identifiers.ProjectId]],
    entityType: Entities.Project,
  },
  [Atoms.ProjectActivity]: {
    validContexts: [[Identifiers.OrganizationId, Identifiers.ProjectId]],
    entityType: Entities.Project,
  },
  [Atoms.ProjectList]: {
    validContexts: [[Identifiers.OrganizationId]],
    entityType: Entities.Organization,
  },
  [Atoms.ProjectMembers]: {
    validContexts: [[Identifiers.OrganizationId, Identifiers.ProjectId]],
    entityType: Entities.Project,
  },
  [Atoms.ProjectTree]: {
    validContexts: [[Identifiers.OrganizationId, Identifiers.ProjectId]],
    entityType: Entities.Project,
  },
  [Atoms.ProjectTreeSnapshot]: {
    validContexts: [
      [
        Identifiers.OrganizationId,
        Identifiers.ProjectId,
        DefaultIdentifiers.Version,
      ],
    ],
    entityType: Entities.Version,
  },
  [Atoms.TemplateLatest]: {
    validContexts: [[Identifiers.TemplateName]],
    entityType: Entities.Template,
  },
  [Atoms.TemplateInfo]: {
    validContexts: [[Identifiers.TemplateName]],
    entityType: Entities.Template,
  },
  [Atoms.TemplateList]: {
    validContexts: [[]],
    entityType: DefaultRelation.Global,
  },
  [Atoms.TodoListLatest]: {
    validContexts: [
      [
        Identifiers.OrganizationId,
        Identifiers.ProjectId,
        Identifiers.TodoListId,
      ],
    ],
    entityType: Entities.TodoList,
  },
  [Atoms.TodoListInfo]: {
    validContexts: [
      [
        Identifiers.OrganizationId,
        Identifiers.ProjectId,
        Identifiers.TodoListId,
      ],
    ],
    entityType: Entities.TodoList,
  },
  [Atoms.TodoListList]: {
    validContexts: [[Identifiers.OrganizationId, Identifiers.ProjectId]],
    entityType: Entities.Project,
  },
  [Atoms.UserRoles]: {
    validContexts: [[DefaultIdentifiers.UserId]],
    entityType: DefaultRelation.User,
  },
  [Atoms.VersionList]: {
    validContexts: [[Identifiers.OrganizationId, Identifiers.ProjectId]],
    entityType: Entities.Project,
  },
  [Atoms.WorkpackageLatest]: {
    validContexts: [
      [Identifiers.OrganizationId, Identifiers.ProjectId, Identifiers.Code],
    ],
    entityType: Entities.Workpackage,
  },
  [Atoms.WorkpackageInfo]: {
    validContexts: [
      [Identifiers.OrganizationId, Identifiers.ProjectId, Identifiers.Code],
    ],
    entityType: Entities.Workpackage,
  },
  [DefaultAtoms.PersonalUserData]: {
    validContexts: [[DefaultIdentifiers.UserId]],
    entityType: DefaultRelation.User,
  },
  [DefaultAtoms.AgentSessionInfo]: {
    validContexts: [[DefaultIdentifiers.AgentSessionId]],
    entityType: DefaultRelation.AgentSession,
  },
};
