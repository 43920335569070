import { MagicSaveButton } from "@atoms/atom-components";
import { projectSettingsLabels, useApiClient } from "@project/api";
import { Col, Modal, Row, Typography } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";
import { ProjectInfoForm } from "../generated/forms/ProjectInfo.form";

const { Paragraph } = Typography;

export interface ProjectSettingsModalRef {
  show: () => void;
}

export const ProjectSettingsModal = forwardRef<ProjectSettingsModalRef>(
  (_, ref) => {
    const api = useApiClient();
    const [isVisible, setIsVisible] = useState(false);

    useImperativeHandle(ref, () => ({
      show: () => {
        setIsVisible(true);
      },
    }));

    return (
      <Modal
        title="Einstellungen"
        open={isVisible}
        onCancel={() => setIsVisible(false)}
        footer={null}
      >
        <Paragraph>
          Hier können Sie die Einstellungen für das Projekt bearbeiten.
        </Paragraph>
        <ProjectInfoForm
          onSubmit={async (draft) => {
            await api.Project.update(draft);
            setIsVisible(false);
          }}
          labels={projectSettingsLabels}
        >
          <Row gutter={[16, 16]} className="py-4">
            {/* <Col span={24} className="flex justify-center">
              <MagicImageUpload field="logoBase64" />
            </Col> */}
            <Col span={24}>
              <ProjectInfoForm.TextareaOrDiv field="name" />
            </Col>
            <Col span={24}>
              <ProjectInfoForm.TextareaOrDiv field="description" />
            </Col>
            {/* <Col span={24}>
              <MagicCodeSchema />
            </Col> */}
            <Col span={24}>
              <MagicSaveButton />
            </Col>
          </Row>
        </ProjectInfoForm>
      </Modal>
    );
  },
);
