import { AsyncButton } from "@atoms/atom-components";
import { useApiClient, useAtom } from "@project/api";
import { Atoms, Identifiers } from "@project/shared";
import { Form, Input, InputNumber, Modal, message } from "antd";
import { forwardRef, useImperativeHandle, useState, useEffect } from "react";

export interface CreateOrganizationModalRef {
  show: () => void;
}

export const CreateOrganizationModal = forwardRef<CreateOrganizationModalRef>(
  (_, ref) => {
    const organizationList = useAtom(Atoms.OrganizationList);
    const api = useApiClient();
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOrganizationIdModified, setIsOrganizationIdModified] =
      useState(false);

    useImperativeHandle(ref, () => ({
      show: () => {
        setIsModalOpen(true);
      },
    }));

    const handleCreateOrganization = async (values: {
      organizationId: string;
      name: string;
      description: string;
      licenses: number;
    }) => {
      if (organizationList?.entries[values.organizationId]) {
        message.error("URL-Slug existiert bereits!");
        return;
      }
      try {
        await api.Global.createOrganization(
          {
            licenses: values.licenses,
            deleted: false,
          },
          {
            description: values.description,
            name: values.name,
          },
          {
            [Identifiers.OrganizationId]: values.organizationId,
          },
        );
        setIsModalOpen(false);
        form.resetFields();
        message.success("Organisation erfolgreich erstellt!");
      } catch (error) {
        message.error(
          "Fehler beim Erstellen der Organisation. Bitte versuchen Sie es erneut.",
        );
      }
    };

    // Function to sanitize the organizationId
    const sanitizeOrganizationId = (name: string): string => {
      return name
        .trim()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with dashes
        .replace(/[^a-z0-9\-]/g, ""); // Remove invalid characters
    };

    // Handle form value changes
    const handleValuesChange = (changedValues: any, allValues: any) => {
      if ("name" in changedValues && !isOrganizationIdModified) {
        const sanitizedId = sanitizeOrganizationId(allValues.name);
        form.setFieldsValue({ organizationId: sanitizedId });
      }

      if ("organizationId" in changedValues) {
        setIsOrganizationIdModified(true);
      }
    };

    // Reset the manual modification flag when modal is opened
    useEffect(() => {
      if (isModalOpen) {
        setIsOrganizationIdModified(false);
      }
    }, [isModalOpen]);

    return (
      <Modal
        title="Organisation erstellen"
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleCreateOrganization}
          layout="vertical"
          onValuesChange={handleValuesChange}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Bitte geben Sie den Namen der Organisation ein!",
              },
            ]}
            extra="Der Anzeigename der Organisation"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="organizationId"
            label="URL-Slug"
            rules={[
              {
                required: true,
                message: "Bitte geben Sie die URL-Slug ein!",
              },
              {
                validator: (_, value) =>
                  organizationList?.entries[value]
                    ? Promise.reject(new Error("URL-Slug existiert bereits!"))
                    : Promise.resolve(),
              },
            ]}
            extra="Der URL-Slug wird Teil der URL zur Organisation und muss eindeutig sein. Er kann im Nachhinein nicht mehr geändert werden."
          >
            <Input
              addonBefore={`${window.location.origin}/`}
              placeholder="url"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Beschreibung"
            rules={[
              {
                required: true,
                message:
                  "Bitte geben Sie eine Beschreibung der Organisation ein!",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="licenses"
            label="Anzahl Lizenzen"
            rules={[
              {
                required: true,
                message: "Bitte geben Sie die Anzahl der Lizenzen ein!",
              },
            ]}
            initialValue={10}
            extra="Die Anzahl der Lizenzen bestimmt die Anzahl der Projekte, die in der Organisation erstellt werden können. Die Anzahl der Lizenzen kann später nach Bedarf erhöht werden."
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item>
            <AsyncButton type="primary" htmlType="submit">
              Organisation erstellen
            </AsyncButton>
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);
