export type EntityWithWBSCode = { code: string };

export function sortByWBSCode(
  entityA: EntityWithWBSCode,
  entityB: EntityWithWBSCode,
): number {
  return sortByCode(entityA.code, entityB.code);
}

export const sortByCode = (
  codeA: string | undefined,
  codeB: string | undefined,
): number => {
  if (!codeA && !codeB) {
    // If both codes are undefined, they are equal
    return 0;
  } else if (!codeA) {
    // If only A is undefined, it is less than B
    return -1;
  } else if (!codeB) {
    // If only B is undefined, it is greater than A
    return 1;
  }

  const partsA = codeA.split(".");
  const partsB = codeB.split(".");

  for (let i = 0; i < partsA.length; i++) {
    if (partsB[i] === undefined) {
      // If we get to the end of B, it is less than A
      return 1;
    }

    if (partsA[i] === partsB[i]) {
      // If the parts are the same, go to the next part
      continue;
    }

    if (i < 2) {
      // If we are in the first two parts, compare them as strings
      if (!partsB[i]) {
        // If B is undefined, it is less than A
        return 1;
      }
      return partsA[i]!.localeCompare(partsB[i]!);
    } else {
      // Otherwise, compare them as numbers
      return Number(partsA[i]) - Number(partsB[i]);
    }
  }

  // If we get to the end of the loop, the codes are equal
  return 0;
};
