import type { ITransaction, UserPublicID } from "@atoms/atom-types";
import { useFrontendProvision } from "../FrontendProvisionProvider";
import { __useDefaultUsername } from "./useDefaultUsername";
import { useTransaction } from "./useTransaction";
import { useCallback } from "react";

/**
 * Get the username for a user.
 * @returns The username for the user, undefined if loading or null if the user does not exist
 */
export type GetUserNameFromIdHook = {
  (userId: UserPublicID, fallback: string): string | undefined;
  (userId: UserPublicID, fallback?: string): string | undefined | null;
};

export type GetUserNameWithTransaction = {
  (
    transaction: ITransaction,
    userId: UserPublicID,
    fallback: string,
  ): Promise<string | undefined>;
  (
    transaction: ITransaction,
    userId: UserPublicID,
    fallback?: string,
  ): Promise<string | undefined | null>;
};

export const __useUsername = (userId: UserPublicID, fallback?: string) => {
  if (!userId) throw new Error("userId is required");
  const provision = useFrontendProvision();
  return (provision.getUsernameFromIdHook ?? __useDefaultUsername)(
    userId,
    fallback,
  );
};

export const __useGetUserNameHook = (): GetUserNameFromIdHook => {
  const provision = useFrontendProvision();
  return provision.getUsernameFromIdHook ?? __useDefaultUsername;
};

export const __getUsernameResolver = (): ((
  userId: UserPublicID,
  fallback: string,
) => Promise<string | undefined>) => {
  const { getUsernameWithTransaction } = useFrontendProvision();
  if (!getUsernameWithTransaction) {
    throw new Error("getUsernameWithTransaction is not defined");
  }
  const transaction = useTransaction();
  return useCallback(
    (userId: UserPublicID, fallback: string) =>
      getUsernameWithTransaction(transaction, userId, fallback),
    [transaction, getUsernameWithTransaction],
  );
};
