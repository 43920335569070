import { useApiClient } from "@project/api";
import { type ITodoList, createTodoListDraft } from "@project/shared";
import Form from "antd/es/form";
import FormItem from "antd/es/form/FormItem";
import Input, { type InputRef } from "antd/es/input";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { UIElement } from "../../UIElements";
import { ModalsApi } from "../../state/modals";
import { useThunkDispatch } from "../../useThunkDispatch";
import { FloatLabel } from "../reusable/FloatLabel";
import { ReduxModal } from "./ReduxModal";

export const CreateTodoListModalId = "createTodoList";

export interface CreateTodoListModalRef {
  create: () => Promise<ITodoList>;
}

export const CreateTodoListModal = forwardRef<CreateTodoListModalRef>(
  (_, ref) => {
    const api = useApiClient();
    const dispatch = useThunkDispatch();

    const [draft, setDraft] = useState<Partial<ITodoList> | null>(null);

    const [resolve, setResolve] = useState<null | (() => void) | null>(null);
    const [reject, setReject] = useState<
      null | ((reason?: any) => void) | null
    >(null);

    const firstInputRef = useRef<InputRef>(null);

    useImperativeHandle(ref, () => ({
      create: async () => {
        setDraft(createTodoListDraft());
        dispatch(ModalsApi.show(CreateTodoListModalId));
        return new Promise<ITodoList>((_resolve, _reject) => {
          setResolve(() => _resolve);
          setReject(() => _reject);
        });
      },
    }));

    const updateDraft = (updatedFields: Partial<ITodoList>) => {
      setDraft((prevDraft) => ({ ...prevDraft, ...updatedFields }));
    };

    const handleOk = () => {
      if (draft && resolve) {
        resolve();
        setDraft(null);
        setResolve(() => null);
        setReject(() => null);
      }
    };

    const handleCancel = () => {
      if (reject) {
        reject();
        setDraft(null);
        setResolve(() => null);
        setReject(() => null);
      }
    };

    return (
      <ReduxModal
        id={CreateTodoListModalId}
        title="Neue ToDo-Liste anlegen"
        onOk={async () => {
          if (!draft) {
            return;
          }
          await api.TodoList.create(draft);
          handleOk();
        }}
        onCancel={handleCancel}
        okButtonProps={{ disabled: !draft?.label }}
        firstInputRef={firstInputRef}
      >
        <Form>
          <FormItem>
            <FloatLabel label="Titel" value={draft?.label}>
              <Input
                ref={firstInputRef}
                className={UIElement.Todo_CreateTodoListTitleInput}
                value={draft?.label}
                onChange={(evt) => updateDraft({ label: evt.target.value })}
              />
            </FloatLabel>
          </FormItem>
        </Form>
      </ReduxModal>
    );
  },
);
