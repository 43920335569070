import { createPersistedStore } from "@atoms/atom-client";
import type { StateCreator } from "zustand";

interface PersonalUserData {
  name: string;
  email: string;
  id: string;
}

export interface DefaultProject {
  orgId: string;
  projectId: string;
}

export const VERSION_DRAFT = "entwurf";

interface AppState {
  // user specific
  defaultProject?: DefaultProject;
  personalUserData?: PersonalUserData;

  // UI
  siderIsDragging: boolean;
  siderHidden: boolean;
  siderWidth: number;
  doNotShowChangelog?: boolean;
  lastKnownVersion: string;
}

interface AppActions {
  setSiderDragging: (isDragging: boolean) => void;
  setSiderHidden: (isHidden: boolean) => void;
  setSiderWidth: (width: number) => void;
  setDefaultProject: (project: DefaultProject) => void;
  setPersonalUserData: (data: PersonalUserData) => void;
  setLastKnownVersion: (version: string) => void;
  setDoNotShowChangelog: (doNotShow: boolean) => void;
}

type App = AppState & AppActions;

const defaultAppState: AppState = {
  // UI
  siderIsDragging: false,
  siderHidden: true,
  siderWidth: 500,
  lastKnownVersion: "0.0.0",
};

const appStoreInitializer: StateCreator<App> = (set) => ({
  ...defaultAppState,
  setSiderDragging: (isDragging) =>
    set((state) => ({ ...state, siderIsDragging: isDragging })),
  setSiderHidden: (isHidden) =>
    set((state) => ({ ...state, siderHidden: isHidden })),
  setSiderWidth: (width) => set((state) => ({ ...state, siderWidth: width })),
  setDefaultProject: (project) =>
    set((state) => ({ ...state, defaultProject: project })),
  setPersonalUserData: (data) =>
    set((state) => ({ ...state, personalUserData: data })),
  setLastKnownVersion: (version) =>
    set((state) => ({ ...state, lastKnownVersion: version })),
  setDoNotShowChangelog: (doNotShow) =>
    set((state) => ({ ...state, doNotShowChangelog: doNotShow })),
});

export const useAppStore = createPersistedStore(
  appStoreInitializer,
  "app",
  undefined,
  {
    partialize: (state) => ({
      siderWidth: state.siderWidth,
      lastKnownVersion: state.lastKnownVersion,
      doNotShowChangelog: state.doNotShowChangelog,
      defaultProject: state.defaultProject,
    }),
  },
);
