import type { TableProps } from "antd";
import Table from "antd/es/table";
import type { ColumnType, SortOrder } from "antd/es/table/interface";
import { useDispatch, useSelector } from "react-redux";
import type { State } from "../state";
import {
  type TableSettings,
  TableSettingsApi,
  type TableState,
  TableStateApi,
} from "../state/table";
interface ReduxTableProps<T> extends TableProps<T> {
  tableId: string;
  loading?: boolean;
}

export const ReduxTable = <T extends object = any>({
  loading,
  ...props
}: ReduxTableProps<T>) => {
  const dispatch = useDispatch();
  const sortField = useSelector(
    (state: State) => state.table[props.tableId as keyof TableState]?.sortField,
  );
  const sortOrder = useSelector(
    (state: State) => state.table[props.tableId as keyof TableState]?.sortOrder,
  );
  const paginationSize = useSelector(
    (state: State) =>
      state.tableSettings[props.tableId as keyof TableSettings]?.paginationSize,
  );
  const paginationIndex = useSelector(
    (state: State) =>
      state.table[props.tableId as keyof TableState]?.paginationIndex,
  );

  return (
    <Table<T>
      {...props}
      loading={loading}
      pagination={
        props.pagination === false
          ? false
          : {
              current: paginationIndex,
              pageSize: paginationSize ?? 50,
              ...props.pagination,
            }
      }
      onChange={(pagination, _filters, sorter, { action }) => {
        if (action === "sort") {
          let col: string | undefined;
          let order: SortOrder | undefined;
          if (Array.isArray(sorter)) {
            col = sorter[0]?.field?.toString();
            order = sorter[0]?.order;
          } else {
            col = sorter.field?.toString();
            order = sorter.order;
          }
          dispatch(TableStateApi.changeTableSort(props.tableId, col, order));
        } else if (action === "paginate") {
          dispatch(
            TableStateApi.changeTablePagination(
              props.tableId,
              pagination.current,
            ),
          );
          dispatch(
            TableSettingsApi.changeTablePageSize(
              props.tableId,
              pagination.pageSize,
            ),
          );
        }
      }}
      columns={props.columns?.map((col: ColumnType<T>) => {
        return {
          ...col,
          sortOrder: col.dataIndex === sortField ? sortOrder : undefined,
          sorter: loading ? undefined : col.sorter, // the data could be anything during loading, so it is not possible to sort
        };
      })}
    />
  );
};
