import type { DataNode } from "antd/es/tree";
import { useMemo } from "react";
import { seperator } from "../components/WorkPackageTable";
import { useCategories } from "../hooks/useCategories";
import { useWorkPackages } from "../hooks/useWorkPackage";
import { sortByCode } from "./sortByWBS";

export const buildCategoryTree = (
  parentCode = "",
): DataNode[] | null | undefined => {
  // const api = useApClient();
  // const dispatch = useThunkDispatch();

  const categories = useCategories();
  const packages = useWorkPackages();
  // const hasPermission = useHasPermission();
  // const getFreeCode = useFreeCode();

  return useMemo(() => {
    // const makeItems = (c: IProjectTreeElement): MenuProps["items"] =>
    //     [
    //         hasPermission(Permission.CreateCategories)
    //             ? {
    //                   label: "neues WBS-Element anlegen",
    //                   key: "1",
    //                   onClick: ({ domEvent }) => {
    //                       dispatch(AppApi.updateCategoryDraft({ code: getFreeCode(c.code) }));
    //                       dispatch(ModalsApi.show(CreateCategoryModalId));
    //                       domEvent.stopPropagation();
    //                   },
    //                   icon: <FontAwesomeIcon icon={faProjectDiagram} />,
    //               }
    //             : null,
    //         hasPermission(Permission.CreateWorkpackages)
    //             ? {
    //                   label: "neues Arbeitspaket anlegen",
    //                   key: "2",
    //                   onClick: ({ domEvent }) => {
    //                       dispatch(AppApi.set("packageDraft", createWorkpackageDraft(`${c.code}.`)));
    //                       dispatch(ModalsApi.show(CreateWorkPackageModalId));
    //                       domEvent.stopPropagation();
    //                   },
    //                   icon: <FontAwesomeIcon icon={faFileMedical} />,
    //               }
    //             : null,
    //         hasPermission(Permission.DeleteCategories)
    //             ? {
    //                   label: "WBS-Element löschen",
    //                   key: "3",
    //                   onClick: ({ domEvent }) => {
    //                       api.Category.delete(c.code);
    //                       domEvent.stopPropagation();
    //                   },
    //                   icon: <FontAwesomeIcon icon={faTrashAlt} />,
    //               }
    //             : null,
    //     ].filter(Boolean);

    function hasParent(code: string, path: string): boolean {
      const parts = code.split(seperator);
      parts.pop();
      return parts.join(seperator) === path;
    }

    const filteredCategories = (parentCode = "") => {
      return categories
        ?.filter(({ code }) => hasParent(code, parentCode))
        .map(
          (c): DataNode => ({
            key: c.code,
            title: (
              <div className="dataNode">
                {/* <Dropdown menu={{ items: makeItems(c) }} trigger={["contextMenu"]}> */}
                <span style={{ flexGrow: 10 }} className={"wbsElement"}>
                  {c.code}: {c.label}
                </span>
                {/* </Dropdown> */}
              </div>
            ),
            children: filteredCategories(c.code),
          }),
        )
        .concat(
          (packages ?? [])
            .filter((a) => hasParent(a.code, parentCode))
            .map(
              (a): DataNode => ({
                key: a.code,
                children: [],
                title: (
                  <span className={"package"}>{`${a.code}: ${a.label}`}</span>
                ),
              }),
            ),
        )
        .sort((a: any, b: any) => sortByCode(a.key, b.key));
    };

    if (categories === undefined || packages === undefined) {
      return undefined;
    }

    return filteredCategories(parentCode);
  }, [categories, packages]);
};
