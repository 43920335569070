/*
 * This file has been generated by permissions
 * Hash: 4daecebbcf6599b9f4956831d3af7156c827f05854b8176600189bd4e7b96df8
 */

export enum ProjectPermissions {
  Create = "CreateProject",
  Delete = "DeleteProject",
  Read = "ReadProject",
  Update = "UpdateProject",
  AddToDraft = "AddToDraft",
  RemoveFromDraft = "RemoveFromDraft",
  UpdateDraftEntry = "UpdateDraftEntry",
  ReadInfo = "ReadInfo",
  UpdateInfo = "UpdateInfo",
  AddToMembers = "AddToMembers",
  RemoveFromMembers = "RemoveFromMembers",
  UpdateMembersEntry = "UpdateMembersEntry",
  AddToVersionList = "AddToVersionList",
  RemoveFromVersionList = "RemoveFromVersionList",
  UpdateVersionListEntry = "UpdateVersionListEntry",
}
