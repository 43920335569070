import type { ITodoList } from "../interfaces/ITodoList";

export const createTodoListDraft = (): ITodoList => ({
  label: "",
  responsibleUserId: null,
  involvedUserIds: [],
  description: "",
  todos: {},
  deleted: false,
  done: false,
  dependentWbsElements: [],
});
