import TextArea, { type TextAreaProps } from "antd/es/input/TextArea";
import { debounce } from "lodash";
import { type ChangeEvent, useCallback, useEffect, useState } from "react";

export const TextAreaOrDiv = (props: TextAreaProps) => {
  const [value, setValue] = useState(props.value);
  // debounced onChange
  const debouncedOnChange = useCallback(
    debounce((e: ChangeEvent<HTMLTextAreaElement>) => {
      props.onChange?.(e);
    }, 500),
    [props.onChange],
  );

  useEffect(() => {
    setValue(props.value);
    debouncedOnChange.cancel();
  }, [props.value, debouncedOnChange]);

  return props.disabled ? (
    <span
      className={"ant-input ant-input-disabled " + props.className}
      style={{ ...props.style, whiteSpace: "pre-wrap" }}
    >
      {String(props.value)}
    </span>
  ) : (
    <TextArea
      {...props}
      onChange={(e) => {
        setValue(e.target.value);
        debouncedOnChange(e);
      }}
      value={value}
      onBlur={(e) => {
        props.onBlur?.(e);
        props.onChange?.(e);
      }}
    />
  );
};
