import { Modal, Tabs } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";
import { LicenseManager } from "../Admin/LicenseManager";
import { OrganizationInfoEditor } from "../Admin/OrganizationInfoEditor";

export interface OrganizationSettingsModalRef {
  show: () => void;
}

export const OrganizationSettingsModal =
  forwardRef<OrganizationSettingsModalRef>((_, ref) => {
    const [isVisible, setIsVisible] = useState(false);

    useImperativeHandle(ref, () => ({
      show: () => {
        setIsVisible(true);
      },
    }));

    const items = [
      {
        key: "1",
        label: "Organisationsinfo",
        children: <OrganizationInfoEditor />,
      },
      {
        key: "2",
        label: "Lizenzverwaltung",
        children: <LicenseManager />,
      },
    ];

    return (
      <Modal
        title="Einstellungen"
        open={isVisible}
        onCancel={() => setIsVisible(false)}
        footer={null}
      >
        <Tabs defaultActiveKey="1" items={items} />
      </Modal>
    );
  });
