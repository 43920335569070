import DiffMatchPatch from "diff-match-patch";
const dmp = new DiffMatchPatch();

export function diffWords(text1: string, text2: string) {
  const diffs = dmp.diff_main(text1, text2);
  dmp.diff_cleanupSemantic(diffs);

  return diffs.map((diff) => {
    const [operation, text] = diff;
    let type;

    switch (operation) {
      case -1:
        type = "removed";
        break;
      case 1:
        type = "added";
        break;
      default:
        type = "unchanged";
    }

    return { value: text, [type]: true };
  });
}
