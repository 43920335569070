import {
  __useAtom,
  useCheckFieldPermission,
  useMyUserId,
} from "@atoms/atom-client";

import type { AtomData, ContextValues } from "@atoms/atom-types";

import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { omit } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { MagicAutoComplete } from "./MagicAutoComplete";
import { MagicDatePicker } from "./MagicDatePicker";
import {
  MagicFormProvider,
  MagicInput,
  type SuggestedChange,
} from "./MagicForm";
import { MagicMultiUserSelector } from "./MagicMultiUserSelect";
import { MagicSwitch } from "./MagicSwitch";
import { MagicTextareaOrDiv } from "./MagicTextareaOrDiv";
import { MagicUserSelector } from "./MagicUserSelector";
import { type LabelType, RevisionConflictModal } from "./RevisionConflictModal";
import { useDrafting } from "./useDrafting";

export interface MagicFormComponent<AtomDataType extends AtomData = AtomData>
  extends React.FC<MagicFormProps<AtomDataType>> {
  Input: typeof MagicInput<AtomDataType>;
  UserSelector: typeof MagicUserSelector<AtomDataType>;
  MultiUserSelector: typeof MagicMultiUserSelector<AtomDataType>;
  DatePicker: typeof MagicDatePicker<AtomDataType>;
  TextareaOrDiv: typeof MagicTextareaOrDiv<AtomDataType>;
  AutoComplete: typeof MagicAutoComplete<AtomDataType>;
  Switch: typeof MagicSwitch<AtomDataType>;
}

export type MagicFormProps<AtomDataType extends AtomData> = {
  loading?: boolean;
  children: React.ReactNode;
  extraContext?: ContextValues;
  labels?: LabelType<AtomDataType>;
  onSubmit: (
    draft: Partial<AtomDataType>,
    current: AtomDataType,
  ) => Promise<void>;
};

export const makeForm = <
  AtomType extends string = string,
  AtomDataType extends AtomData = AtomData,
>({
  atom,
}: {
  atom: AtomType;
}): MagicFormComponent<AtomDataType> => {
  const MagicFormComponent = ({
    children,
    onSubmit,
    loading: loadingProp = false,
    labels,
    extraContext,
  }: MagicFormProps<AtomDataType>) => {
    const [form] = useForm();
    const dataFromBackend = __useAtom<AtomDataType>(atom, extraContext);
    if (dataFromBackend === null) {
      throw new Error("Data from backend is null");
    }
    const loading = dataFromBackend === undefined || loadingProp;
    const checkFieldPermission = useCheckFieldPermission(extraContext);
    const myUserId = useMyUserId();
    const [suggestedChanges, setSuggestedChanges] =
      useState<SuggestedChange<AtomDataType>>();

    const { draft, currentValue, updateDraft, resetDraft, conflictingValues } =
      useDrafting<AtomDataType>(dataFromBackend);

    // if this was the last suggested change, clear the suggested changes
    useEffect(() => {
      if (
        suggestedChanges &&
        Object.keys(suggestedChanges.changeSet).length === 0
      ) {
        setSuggestedChanges(undefined);
      }
    }, [
      suggestedChanges !== undefined,
      Object.keys(suggestedChanges?.changeSet ?? {}).length,
    ]);
    const resolveSuggestedChange = useCallback(
      (field: keyof AtomDataType, value: AtomDataType[keyof AtomDataType]) => {
        updateDraft({
          ...draft,
          [field]: value,
        } as Partial<AtomDataType>);
        setSuggestedChanges({
          ...suggestedChanges,
          changeSet: omit(
            suggestedChanges?.changeSet,
            field,
          ) as Partial<AtomDataType>,
        });
      },
      [setSuggestedChanges, suggestedChanges],
    );
    return (
      <MagicFormProvider
        value={{
          currentValue,
          updateDraft: updateDraft as (draft: Partial<AtomData>) => void,
          checkFieldPermission: (field, permission) => true,
          // checkFieldPermission(atom, field) === permission,
          submit: async (ignoreSuggestedChanges = false) => {
            if (!draft) {
              throw new Error("No draft provided");
            }

            if (!ignoreSuggestedChanges) {
              if (suggestedChanges) {
                throw new Error("Suggested changes");
              }

              if (conflictingValues) {
                setSuggestedChanges({
                  source: "conflicting",
                  changeSet: conflictingValues,
                });
                console.log("conflicting values", conflictingValues);
                throw new Error("Conflicting values");
              }
            }
            await onSubmit(draft, currentValue);
          },
          loading,
          draft,
          valueFromBackend: dataFromBackend,
          resetDraft,
          myUserId,
          suggestedChanges,
          atomType: atom,
          resolveSuggestedChange,
          discardSuggestedChanges: () => setSuggestedChanges(undefined),
          onCancel: () => {},
          labels: labels as LabelType<AtomData>,
        }}
      >
        <RevisionConflictModal />
        <Form
          form={form}
          initialValues={draft ?? undefined}
          className={`mf-atom-${atom.replace(/\s/g, "-")}`}
        >
          {children}
        </Form>
      </MagicFormProvider>
    );
  };

  MagicFormComponent.Input = MagicInput;
  MagicFormComponent.UserSelector = MagicUserSelector;
  MagicFormComponent.MultiUserSelector = MagicMultiUserSelector;
  MagicFormComponent.DatePicker = MagicDatePicker;
  MagicFormComponent.TextareaOrDiv = MagicTextareaOrDiv;
  MagicFormComponent.AutoComplete = MagicAutoComplete;
  MagicFormComponent.Switch = MagicSwitch;
  return MagicFormComponent as MagicFormComponent<AtomDataType>;
};
