import type { ANY_FIELD, ContextValues } from "@atoms/atom-types";
import { useCallback } from "react";
import { useCombinedContext } from "../useCombinedContext";
import { useAtomStore } from "./useAtomStore";

export const useCheckFieldPermission = (extraContext?: ContextValues) => {
  const { getPermissionForField, getPermissionsInContext } = useAtomStore()!;
  const combinedContext = useCombinedContext(extraContext);
  const projectSpecificPermissions = getPermissionsInContext?.(combinedContext);
  const fn = useCallback(
    (atomType: string, field: string | typeof ANY_FIELD) => {
      return true;
      // return getPermissionForField?.(
      //   projectSpecificPermissions ?? [],
      //   atomType,
      //   field,
      // );
    },
    [getPermissionForField, getPermissionsInContext],
  );
  return fn;
};
