import { AsyncButton } from "@atoms/atom-components";
import {
  Atoms,
  Identifiers,
  useApiClient,
  useAtom,
  useIdentifier,
} from "@project/api";
import { Form, Input, Modal, message } from "antd";
import { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { CodeSchemaInput } from "./CodeSchemaInput";

export interface CreateProjectModalRef {
  show: () => void;
}

export const CreateProjectModal = forwardRef<CreateProjectModalRef>(
  (_, ref) => {
    const api = useApiClient();
    const [isVisible, setIsVisible] = useState(false);
    const [form] = Form.useForm();
    const [isProjectIdModified, setIsProjectIdModified] = useState(false);

    useImperativeHandle(ref, () => ({
      show: () => {
        setIsVisible(true);
      },
    }));

    const projects = useAtom(Atoms.ProjectList, (info) => info.entries) ?? {};

    const handleCreateProject = async (values: {
      projectId: string;
      name: string;
      description: string;
      codeSchema: string;
    }) => {
      if (projects[values.projectId]) {
        message.error("Projekt-ID existiert bereits!");
        return;
      }
      try {
        await api.Organization.createProject(values, {
          [Identifiers.ProjectId]: values.projectId,
        });
        setIsVisible(false);
        form.resetFields();
        message.success("Projekt erfolgreich erstellt!");
      } catch (error) {
        message.error(
          "Fehler beim Erstellen des Projekts. Bitte versuchen Sie es erneut.",
        );
      }
    };

    const organizationId = useIdentifier(Identifiers.OrganizationId);

    // Function to sanitize the projectId
    const sanitizeProjectId = (name: string): string => {
      return name
        .trim()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with dashes
        .replace(/[^a-z0-9\-]/g, ""); // Remove invalid characters
    };

    // Handle form value changes
    const handleValuesChange = (changedValues: any, allValues: any) => {
      if ("name" in changedValues && !isProjectIdModified) {
        const sanitizedId = sanitizeProjectId(allValues.name);
        form.setFieldsValue({ projectId: sanitizedId });
      }

      if ("projectId" in changedValues) {
        setIsProjectIdModified(true);
      }
    };

    // Reset the manual modification flag when modal is opened
    useEffect(() => {
      if (isVisible) {
        setIsProjectIdModified(false);
      }
    }, [isVisible]);

    return (
      <Modal
        title="Projekt erstellen"
        open={isVisible}
        onCancel={() => {
          setIsVisible(false);
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleCreateProject}
          layout="vertical"
          onValuesChange={handleValuesChange}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              { required: true, message: "Projektname ist erforderlich" },
            ]}
            extra="Der Anzeigename des Projekts"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="projectId"
            label="URL-Slug"
            rules={[{ required: true, message: "URL-Slug ist erforderlich" }]}
            extra="Der URL-Slug wird Teil der URL zum Projekt und muss eindeutig sein. Er kann im Nachhinein nicht mehr geändert werden."
          >
            <Input
              addonBefore={`${window.location.origin}/${organizationId}/`}
              placeholder="url"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Beschreibung"
            rules={[
              {
                required: true,
                message: "Beschreibung ist erforderlich",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="codeSchema"
            label="Code-Schema"
            rules={[
              { required: true, message: "Code-Schema ist erforderlich" },
            ]}
            extra={`Das Code-Schema definiert die Struktur der Daten im Projekt. Es besteht aus Segmenten, die durch einen Punkt getrennt sind. Durch Klick auf "+ Segment hinzufügen" können weitere Segmente hinzugefügt werden. Durch einen Klick auf das X-Symbol können Segmente wieder entfernt werden. Bei einem normalen Klick auf ein Segment kann zwischen verschiedenen Segment-Typen (A-Z, 1-9, 10-90) gewechselt werden.`}
          >
            <CodeSchemaInput
              value={form.getFieldValue("codeSchema") ?? ""}
              onChange={(value) => form.setFieldValue("codeSchema", value)}
            />
          </Form.Item>
          <Form.Item>
            <AsyncButton type="primary" htmlType="submit">
              Projekt erstellen
            </AsyncButton>
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);
