import type {
  AtomInfos,
  AtomTypeFromInfoType,
  EntityTypeFromInfoType,
} from "../AtomInfos";
import {
  GenericPermission,
  GenericPermissionsString,
  GenericPermissionsStringMap,
} from "./PermissionsByRole";

export const ANY_FIELD = Symbol("ANY_FIELD");

export type GetPermissionForField<AtomInfoType extends AtomInfos> = (
  permissions: Set<ValidProjectSpecificPermissions<AtomInfoType>>,
  atomType: AtomTypeFromInfoType<AtomInfoType>,
  field: string,
) => GenericPermission | undefined;

export type ValidProjectSpecificPermissions<AtomInfoType extends AtomInfos> =
  | `${EntityTypeFromInfoType<AtomInfoType>}:${GenericPermissionsString}`
  | `${EntityTypeFromInfoType<AtomInfoType>}:${AtomTypeFromInfoType<AtomInfoType>}:${GenericPermissionsString}`
  | `${EntityTypeFromInfoType<AtomInfoType>}:${AtomTypeFromInfoType<AtomInfoType>}:${string}:${GenericPermissionsString}`;

export function makeGetPermissionForField<AtomInfoType extends AtomInfos>(
  atomInfos: AtomInfoType,
): GetPermissionForField<AtomInfoType> {
  return (
    permissions: Set<ValidProjectSpecificPermissions<AtomInfoType>>,
    atomType: AtomTypeFromInfoType<AtomInfoType>,
    field: string,
  ): GenericPermission | undefined => {
    if (!atomInfos[atomType]) {
      throw new Error(`Atom type ${atomType} not found`);
    }

    const entityType = atomInfos[atomType]!
      .entityType as EntityTypeFromInfoType<AtomInfoType>;

    let permission: GenericPermission = GenericPermission.None;

    // check entity-level permissions
    for (const permissionString of Object.values(GenericPermissionsString)) {
      if (
        permissions.has(
          `${entityType}:${permissionString as GenericPermissionsString}`,
        )
      ) {
        permission |=
          GenericPermissionsStringMap[
            permissionString as GenericPermissionsString
          ];
      }
    }

    // check atom-level permissions
    for (const permissionString of Object.values(GenericPermissionsString)) {
      if (
        permissions.has(
          `${entityType}:${atomType}:${permissionString as GenericPermissionsString}`,
        )
      ) {
        permission |=
          GenericPermissionsStringMap[
            permissionString as GenericPermissionsString
          ];
      }
    }

    // check field-level permissions
    for (const permissionString of Object.values(GenericPermissionsString)) {
      if (
        permissions.has(
          `${entityType}:${atomType}:${field}:${permissionString as GenericPermissionsString}`,
        )
      ) {
        permission |=
          GenericPermissionsStringMap[
            permissionString as GenericPermissionsString
          ];
      }
    }

    return permission || undefined;
  };
}
