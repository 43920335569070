/*
 * This file has been generated by permissions
 * Hash: e99d4fb9415e5736a3c9f1d070610282a040a05dab150a29e4a39c759c4e4c58
 */

export enum TemplatePermissions {
  Create = "CreateTemplate",
  Delete = "DeleteTemplate",
  Read = "ReadTemplate",
  Update = "UpdateTemplate",
  ReadInfo = "ReadInfo",
  UpdateInfo = "UpdateInfo",
}
