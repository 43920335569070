import { Identifiers } from "@project/shared";
import { useCallback } from "react";
import { type NavigateOptions, useNavigate } from "react-router-dom";
import { useIdentifier } from "@atoms/atom-client";
import { useAppStore } from "../state/app";

export const useActiveProject = () => {
  const defaultProject = useAppStore((state) => state.defaultProject);

  const projectId = useIdentifier(Identifiers.ProjectId)?.toString();
  const organizationId = useIdentifier(Identifiers.OrganizationId)?.toString();

  const isDefaultProject =
    defaultProject &&
    projectId === defaultProject.projectId &&
    organizationId === defaultProject.orgId;
  return {
    projectId,
    organizationId,
    isDefaultProject,
  };
};

export const useScopedPath = () => {
  const { projectId, organizationId } = useActiveProject();

  return useCallback(
    (path: string) => {
      if (organizationId && projectId) {
        return `/${organizationId}/${projectId}${path}`;
      }
      return path;
    },
    [organizationId, projectId],
  );
};

export const useScopeAwareNavigate = () => {
  const navigate = useNavigate();
  const getScopedPath = useScopedPath();

  const awareNavigate = useCallback(
    (pathOrDelta: string | number, options?: NavigateOptions) => {
      if (typeof pathOrDelta === "number") {
        navigate(pathOrDelta);
      } else {
        navigate(getScopedPath(pathOrDelta), options);
      }
    },
    [navigate, getScopedPath],
  );
  return awareNavigate;
};
