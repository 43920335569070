import { EapProjectRole, EapProjectRoleLabel } from "@project/shared";
import { Checkbox } from "antd";
import { useState } from "react";

export interface ProjectRoleEditorProps {
  roles: EapProjectRole[];
  onChange?: (roles: EapProjectRole[]) => void;
  disabled?: boolean;
}

export const ProjectRoleEditor = ({
  roles,
  onChange,
  disabled,
}: ProjectRoleEditorProps) => {
  const [selectedRoles, setSelectedRoles] = useState<EapProjectRole[]>(roles);

  const handleRoleChange = (role: EapProjectRole) => {
    setSelectedRoles((prevRoles) => {
      const newRoles = [...prevRoles];
      if (newRoles.includes(role)) {
        newRoles.splice(newRoles.indexOf(role), 1);
      } else {
        newRoles.push(role);
      }
      onChange?.(newRoles);
      return newRoles;
    });
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
      {Object.values(EapProjectRole).map((role) => (
        <Checkbox
          key={role}
          checked={selectedRoles.includes(role)}
          onChange={() => handleRoleChange(role)}
          disabled={disabled || onChange === undefined}
        >
          {EapProjectRoleLabel[role]}
        </Checkbox>
      ))}
    </div>
  );
};
