export enum DraftItemType {
  AddCategory = "AddCategory",
  MoveCategory = "MoveCategory",
  DeleteCategory = "DeleteCategory",
  AddWorkpackage = "AddWorkpackage",
  MoveWorkpackage = "MoveWorkpackage",
  DeleteWorkpackage = "DeleteWorkpackage",
  UpdateCategoryTitle = "UpdateCategoryTitle",
  UpdateWorkpackageTitle = "UpdateWorkpackageTitle",
  RestoreWorkpackage = "RestoreWorkpackage",
  RestoreCategory = "RestoreCategory",
}
