import { RetryLink } from "@apollo/client/link/retry/retryLink";

export const makeRetryLink = () => {
  return new RetryLink({
    delay: {
      initial: 500,
      max: 30000,
      jitter: true,
    },
    attempts: {
      max: 5,
      // do not retry Server Errors (e.g. HTTP 400 bad request)
      retryIf: (error, _operation) => !!error && error.name !== "ServerError",
    },
  });
};
