import { ApolloLink, type RequestHandler, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities/graphql/getFromAST";

export const makeSplitLink = (
  httpLinks: (ApolloLink | RequestHandler)[],
  wsLinks: (ApolloLink | RequestHandler)[],
) => {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },

    ApolloLink.from(wsLinks),
    ApolloLink.from(httpLinks),
  );
};
