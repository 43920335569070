import { MagicFormContext } from "@atoms/atom-components";
import { GenericPermission } from "@atoms/atom-types";
import { InputNumber, Progress, Row } from "antd";
import SkeletonInput from "antd/es/skeleton/Input";
import { useContext } from "react";

export const EstimatedProgress = () => {
  const { updateDraft, currentValue, loading, checkFieldPermission } =
    useContext(MagicFormContext)!;
  const mayUpdateEstimatedProgress = checkFieldPermission(
    "estimatedProgress",
    GenericPermission.Update,
  );

  return loading ? (
    <SkeletonInput active style={{ height: 56, width: 300 }} />
  ) : (
    <div className="float-label">
      <div className="label label-float">
        geschätzter Fortschritt (Expertenschätzung)
      </div>
      <Row
        wrap={false}
        align="middle"
        className="progress"
        style={{ width: "300px" }}
      >
        <Progress
          key={`progress_estimated-display`}
          percent={currentValue.progress}
          showInfo={false}
        />
        <InputNumber
          key={`progress_estimated-input`}
          style={{ marginLeft: "10px" }}
          value={currentValue.progress}
          disabled={!mayUpdateEstimatedProgress}
          variant={mayUpdateEstimatedProgress ? "outlined" : undefined}
          min={0}
          max={100}
          step={10}
          formatter={(value) => `${value}%`}
          parser={(value) =>
            Number.parseInt(value?.replace("%", "") ?? "") || 0
          }
          onChange={(value) => {
            updateDraft({
              progress: value!,
            });
          }}
        />
      </Row>
    </div>
  );
};
