/*
 * This file has been generated by permissions
 * Hash: bec4ba92f88d77ba7a1102b406d77e39604ae799f15c35f072cf2ec131b1cc6e
 */

export enum OrganizationPermissions {
  Create = "CreateOrganization",
  Delete = "DeleteOrganization",
  Read = "ReadOrganization",
  Update = "UpdateOrganization",
  ReadBookedLicenses = "ReadBookedLicenses",
  UpdateBookedLicenses = "UpdateBookedLicenses",
  ReadInfo = "ReadInfo",
  UpdateInfo = "UpdateInfo",
  AddToMembers = "AddToMembers",
  RemoveFromMembers = "RemoveFromMembers",
  UpdateMembersEntry = "UpdateMembersEntry",
}
