import * as Sentry from "@sentry/react";
import { Button } from "antd";
import { Content } from "antd/es/layout/layout";
import Result from "antd/es/result";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { useSetTitle } from "../hooks/useSetTitle";

export const RouterErrorView = () => {
  const error = useRouteError();
  const eventId =
    // see https://github.com/remix-run/react-router/releases/tag/react-router%406.16.0 why "error" is any
    isRouteErrorResponse(error) && (error as any).error
      ? Sentry.captureException((error as any).error)
      : undefined;
  console.error(error);

  useSetTitle("Fehler");

  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Result
        status="500"
        title="Ups, das ist schief gegangen."
        subTitle={
          <>
            Bitte versuchen Sie die Seite neu zu laden.
            <br />
            Falls der Fehler weiterhin auftritt, kontaktieren Sie bitte den{" "}
            <a href={`mailto:info@program51.de`}>Support</a>.
            {eventId ? (
              <>
                <br />
                <i>Event-ID</i>: <code className="text-xs">{eventId}</code>
              </>
            ) : null}
            {isRouteErrorResponse(error) && error.statusText ? (
              <>
                <br />
                <span style={{ maxWidth: "50vw" }}>
                  <i>Fehler:</i>{" "}
                  <code className="text-xs">{error.statusText}</code>
                </span>
              </>
            ) : null}
          </>
        }
        extra={
          <>
            <Button type="primary" onClick={() => location.reload()}>
              Neu laden
            </Button>
            {/* {eventId ? (
                            <Button
                                type="link"
                                onClick={() =>
                                    showSentryReportDialog({
                                        eventId: eventId,
                                    })
                                }
                            >
                                Weitere Informationen hinzufügen
                            </Button>
                        ) : null} */}
          </>
        }
      />
    </Content>
  );
};
