import type { UserPublicID } from "@atoms/atom-types";
import type { IProjectTreeElement, IWorkpackageCompact } from "@project/shared";
import moment from "moment";
import { useWorkpackageFiltersStore } from "../state/project/workpackageFilters";
import { useWorkpackageListStore } from "../state/project/workpackageList";
import { useWorkPackages } from "./useWorkPackage";

export type WithFavorite<ElemType = any> = ElemType & { favorite?: boolean };
export type FilteredPackages = WithFavorite<
  IProjectTreeElement<IWorkpackageCompact>
>[];

export function useFilteredPackages(
  version?: string,
): FilteredPackages | undefined {
  const packages = useWorkPackages(version);
  const checked = useWorkpackageFiltersStore((state) => state.checked);
  const label = useWorkpackageFiltersStore((state) => state.label);
  const attentionRequiredOnly = useWorkpackageFiltersStore(
    (state) => state.attentionRequiredOnly,
  );
  const active = useWorkpackageFiltersStore((state) => state.active);
  const dueWithin40DaysOnly = useWorkpackageFiltersStore(
    (state) => state.dueWithin40DaysOnly,
  );
  const favoritesOnly = useWorkpackageFiltersStore(
    (state) => state.favoritesOnly,
  );
  const responsibleUserIds = useWorkpackageFiltersStore(
    (state) => state.responsibleUserIds,
  );
  const commissionerUserIds = useWorkpackageFiltersStore(
    (state) => state.commissionerUserIds,
  );
  const involvedUserIds = useWorkpackageFiltersStore(
    (state) => state.involvedUserIds,
  );
  const favorites = useWorkpackageListStore((state) => state.favorites);
  const plannedStart = useWorkpackageFiltersStore((state) => state.startDate);
  const plannedEnd = useWorkpackageFiltersStore((state) => state.endDate);

  const filteredPackages = filterPackages({
    packages,
    favorites,
    label,
    attentionRequiredOnly,
    active,
    responsibleUserIds,
    commissionerUserIds,
    involvedUserIds,
    favoritesOnly,
    dueWithin40DaysOnly,
    plannedStart,
    plannedEnd,
    checked,
  });
  return filteredPackages;
}
interface PackageFilters {
  packages: IProjectTreeElement<IWorkpackageCompact>[] | null | undefined;
  favorites: {
    [code: string]: true;
  };
  label: string;
  attentionRequiredOnly: boolean;
  active: boolean;
  responsibleUserIds: UserPublicID[] | null;
  commissionerUserIds: UserPublicID[] | null;
  involvedUserIds: UserPublicID[] | null;
  favoritesOnly: boolean;
  dueWithin40DaysOnly: boolean;
  plannedStart: {
    beginning: Date | null;
    end: Date | null;
  } | null;
  plannedEnd: Date | null;
  checked: string[];
}

function filterPackages({
  packages,
  favorites,
  label,
  attentionRequiredOnly,
  active,
  responsibleUserIds,
  involvedUserIds,
  commissionerUserIds,
  favoritesOnly,
  dueWithin40DaysOnly,
  plannedStart,
  plannedEnd,
  checked,
}: PackageFilters) {
  return packages
    ?.map((p) => ({ ...p, favorite: favorites[p.code] }))
    .filter((p) => {
      // Filter packages based on checked codes
      // If any codes are checked, only keep packages whose code starts with one of the checked codes
      if (checked.length > 0) {
        let isChecked = false;
        for (const c of checked) {
          if (p.code.indexOf(c) === 0) {
            isChecked = true;
            break;
          }
        }
        if (!isChecked) {
          return false;
        }
      }

      if (label.length > 0) {
        const searchText = `${p.goals} ${p.label} ${p.objective} ${p.description} ${p.code}`;
        if (
          !new RegExp(label.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i").test(
            searchText,
          )
        ) {
          return false;
        }
      }
      if (attentionRequiredOnly && !p.decisionRequired) {
        return false;
      }

      if (active && p.done) {
        return false;
      }
      const noUserFilterSet =
        !responsibleUserIds && !commissionerUserIds && !involvedUserIds;
      const anyUserFilterFullfilled = [
        responsibleUserIds === null
          ? undefined
          : isUserIncluded(p.responsibleUserId, responsibleUserIds),
        commissionerUserIds === null
          ? undefined
          : isUserIncluded(p.commissionerUserId, commissionerUserIds),
        involvedUserIds === null
          ? undefined
          : [
              ...(p.involvedUserIds ?? []),
              p.commissionerUserId,
              p.responsibleUserId,
            ]?.some((user) => isUserIncluded(user, involvedUserIds)) ?? false,
      ]
        .filter(Boolean)
        .some((e) => e);

      if (!noUserFilterSet && !anyUserFilterFullfilled) {
        return false;
      }

      if (favoritesOnly) {
        return p.favorite;
      }

      const deltaT = Number(p.plannedFinish) - Number(new Date());
      if (
        dueWithin40DaysOnly &&
        ((p.plannedFinish && deltaT > 40 * 24 * 60 * 60 * 1000) ||
          p.plannedFinish === null ||
          deltaT < 0)
      ) {
        return false;
      }
      if (plannedStart) {
        const { beginning, end } = plannedStart;
        if (
          (beginning !== null &&
            (!p.plannedStart ||
              moment(beginning)
                .startOf("day")
                .isAfter(moment(p.plannedStart).startOf("day")))) ||
          (end !== null &&
            moment(end)
              .startOf("day")
              .isBefore(moment(p.plannedStart).startOf("day")))
        ) {
          return false;
        }
      }

      if (
        plannedEnd &&
        (!p.plannedFinish ||
          plannedEnd.setHours(0, 0, 0, 0) !==
            // TODO somehow plannedStart is not a date object, even if types say sth else
            new Date(p.plannedFinish as unknown as string).setHours(0, 0, 0, 0))
      ) {
        return false;
      }

      return true;
    });
}

const isUserIncluded = (
  userId: UserPublicID | null,
  allUserIDs: UserPublicID[],
) => {
  if (allUserIDs.includes("null" as UserPublicID) && userId === null) {
    return true;
  } else if (userId && allUserIDs.includes(userId)) {
    return true;
  }

  return false;
};
