import {
  type InputHTMLAttributes,
  type KeyboardEvent,
  useEffect,
  useState,
} from "react";
import { useWbsStore } from "../../state/project/wbs";

interface WBSInputProps extends InputHTMLAttributes<HTMLInputElement> {
  wwidth: number;
  x?: number;
  onlyLastPart?: boolean;
  onTab?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onEnter?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export const WBSInput = ({
  onTab,
  onEnter,
  onlyLastPart,
  x,
  wwidth,
  ...props
}: WBSInputProps) => {
  const displaySettings = useWbsStore((state) => state.displaySettings);
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(props.value as string);
  }, [props.value]);
  return (
    <foreignObject x={x ?? -1} y={-1} width={wwidth} height={100}>
      <input
        {...props}
        onKeyDown={(e: any) => {
          switch (e.key) {
            case "Tab":
              e.preventDefault();
              break;
            case "Enter":
              e.preventDefault();
              e.stopPropagation();
              onEnter?.(e);
              break;
            case "Escape":
              break;
            default:
              // e.preventDefault();
              e.stopPropagation();
              break;
          }
        }}
        style={{
          borderRadius: 0,
          ...props.style,
          // border: "none",
          background: "none",
          paddingTop: -5 + displaySettings.padding,
          width: wwidth,
          fontSize: displaySettings.fontSize,
        }}
        value={value}
        onChange={(e) => {
          // make sure only the last part of the value has been changed
          if (
            onlyLastPart &&
            !e.target.value.startsWith(
              value.substring(0, value.lastIndexOf(".") + 1),
            )
          ) {
            return;
          }

          setValue(e.target.value);
          props.onChange?.(e);
        }}
      />
    </foreignObject>
  );
};
