import type { ReactNode } from "react";

import { createContext, useContext } from "react";
import type { FrontendProvision } from "./FrontendProvision";

const FrontendProvisionContext = createContext<FrontendProvision | null>(null);

export const useFrontendProvision = () => {
  const provision = useContext(FrontendProvisionContext);
  if (!provision) throw new Error("FrontendProvisionProvider not found");
  return provision;
};

export const FrontendProvisionProvider = ({
  children,
  provision,
}: {
  children: ReactNode;
  provision: FrontendProvision;
}) => {
  return (
    <FrontendProvisionContext.Provider value={provision}>
      {children}
    </FrontendProvisionContext.Provider>
  );
};
