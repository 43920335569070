import { ArrowLeftOutlined } from "@ant-design/icons";
import { AsyncButton, UserName, makeAtomTable } from "@atoms/atom-components";
import {
  EapOrganizationRole,
  type atomDataIndex,
  useApiClient,
  useAtom,
  useMyUserId,
} from "@project/api";
import { Atoms } from "@project/shared";
import { Button, Input, Typography, message } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { useSetTitle } from "../../hooks/useSetTitle";

const { Title } = Typography;

const EapTable = makeAtomTable<atomDataIndex>();

export const OrganizationUserManager = () => {
  useSetTitle("Nutzerverwaltung");

  const api = useApiClient();
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserName, setNewUserName] = useState("");
  const [newUserRoles, setNewUserRoles] = useState<EapOrganizationRole[]>([]);

  const myUserId = useMyUserId();

  const organization = useAtom(Atoms.OrganizationInfo);

  const handleAddUser = async () => {
    if (!newUserEmail || !newUserName) {
      message.error(
        "Bitte geben Sie eine gültige E-Mail-Adresse und einen Namen ein",
      );
      return;
    }
    try {
      await api.Organization.addUserToByEmail(newUserEmail, newUserName, [
        EapOrganizationRole.Manager,
      ]);
      message.success("Benutzer erfolgreich eingeladen");
      setNewUserEmail("");
      setNewUserName("");
      setNewUserRoles([]);
    } catch (error) {
      message.error("Fehler beim Einladen des Benutzers");
    }
  };

  return (
    <Content>
      <Title level={3}>
        {organization?.name ? `${organization.name} - ` : ""}Nutzerverwaltung
        <Button
          type="link"
          onClick={() => window.history.back()}
          style={{ marginBottom: 16 }}
        >
          <ArrowLeftOutlined /> Zurück zur Übersicht
        </Button>
      </Title>

      <div className="flex flex-row mb-5">
        <Input
          placeholder="Vorname Nachname"
          value={newUserName}
          onChange={(e) => setNewUserName(e.target.value)}
          style={{ width: "300px", marginRight: "10px" }}
        />
        <Input
          placeholder="E-Mail"
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}
          style={{
            width: "300px",
            marginRight: "10px",
            verticalAlign: "middle",
          }}
        />
        {/* <Select
          mode="multiple"
          placeholder="Rolle(n)"
          value={newUserRoles}
          onChange={setNewUserRoles}
          style={{
            width: "300px",
            marginRight: "10px",
            verticalAlign: "middle",
          }}
          options={Object.values(EapOrganizationRole).map((role) => ({
            label: EapOrganizationRoleLabel[role],
            value: role,
          }))}
        /> */}
        <AsyncButton onClick={handleAddUser}>
          Organisationsverwalter einladen
        </AsyncButton>
      </div>

      <h3>Organisationsverwalter</h3>
      <EapTable
        atomType={Atoms.OrganizationMembers}
        actions={(key, record) => (
          <>
            <AsyncButton
              onClick={async () => {
                return await api.Organization.updateMembersEntry(key, {
                  ...record,
                  disabled: !record.disabled,
                });
              }}
              disabled={key === myUserId}
              title={
                key === myUserId
                  ? "Sie können sich selbst nicht deaktivieren"
                  : ""
              }
            >
              {record.disabled ? "Aktivieren" : "Deaktivieren"}
            </AsyncButton>
          </>
        )}
        actionColProps={{ width: "200px" }}
        columns={[
          {
            title: "Benutzer",
            dataIndex: "key",
            render: (userId) => <UserName publicId={userId} />,
          },
          // {
          //   title: "Rollen",
          //   dataIndex: "roles",
          //   render: (roles, record) => (
          //     <OrganizationRoleEditor
          //       roles={roles as EapOrganizationRole[]}
          //       disabled={record.key === myUserId}
          //       onChange={(newRoles) =>
          //         api.Organization.updateMembersEntry(record.key, {
          //           ...record,
          //           roles: newRoles,
          //         })
          //       }
          //     />
          //   ),
          // },
          {
            title: "Status",
            dataIndex: "disabled",
            render: (isDisabled) => (
              <span>{(isDisabled as boolean) ? "Deaktiviert" : "Aktiv"}</span>
            ),
            width: "100px",
          },
        ]}
      />
    </Content>
  );
};
