import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsyncButton } from "./AsyncButton";
import { useMagicForm } from "./MagicForm";

export const MagicSaveButton = () => {
  const { submit, draft, resetDraft } = useMagicForm();

  return (
    <AsyncButton
      type="primary"
      disabled={draft === null}
      onClick={async () => {
        await submit();
        resetDraft();
      }}
      icon={<FontAwesomeIcon icon={faSave} />}
    >
      Speichern
    </AsyncButton>
  );
};
