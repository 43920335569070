import { useLayoutEffect } from "react";
import { ENVIRONMENTS } from "../util/constants";

export const useSetTitle = (title?: string) => {
  const environment = import.meta.env.HOSTING_ENVIRONMENT;

  useLayoutEffect(() => {
    let prefix = "";
    if (environment === ENVIRONMENTS.dev) {
      prefix = "[DEV] ";
    } else if (environment === ENVIRONMENTS.stg) {
      prefix = "[STG] ";
    }

    document.title = "AP51" + (title ? ` - ${title}` : "");
  }, [title]);
};
