import { User } from "oidc-client-ts";

export function makeGetAuthToken(authority: string, clientId: string) {
  function getUser() {
    const oidcStorage = localStorage.getItem(
      `oidc.user:${authority}:${clientId}`,
    );
    if (!oidcStorage) {
      return null;
    }

    return User.fromStorageString(oidcStorage);
  }

  return (): Promise<string | null> => {
    const user = getUser();
    const token = user?.access_token;

    if (!token) {
      return Promise.resolve(null);
    }

    return Promise.resolve(token);
  };
}
