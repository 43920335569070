import { useLayoutEffect, useState, useTransition } from "react";

export const useReactTransition = () => {
  const [transition] = useTransition();
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    if (transition) {
      return;
    }

    setTimeout(() => {
      setLoading(false);
    });
  }, [transition]);

  return localStorage.getItem("noTransition") ? false : loading;
};
