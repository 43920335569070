import {
  StoreRegistryProvider,
  AtomNotFoundBoundary,
} from "@atoms/atom-client";
import { Suspense, lazy } from "react";
import { Outlet, Route } from "react-router-dom";
import {} from "../CoreApp";
import { EapErrorBoundary } from "./EapErrorBoundary";
import { NotFoundView } from "./NotFoundView";
import { ProjectTreeLoader } from "./ProjectTreeLoader";
import { ProjectUserManager } from "./ProjectUserManager";
import { SplashScreen } from "./reusable/SplashScreen";

// lazily loaded components
const TodoListTableView = lazy(() => import("./TodoListTableView"));
const TodoListView = lazy(() => import("./TodoListView"));
const WBSView = lazy(() => import("./WBS/WBSView"));
const WBSTableView = lazy(() => import("./WBS/WBSTableView"));
const ProjectActivityView = lazy(() => import("./ProjectActivityView"));
const TemplateEditor = lazy(() => import("./TemplateEditor"));
const GanttView = lazy(() => import("./GanttView"));

// preloaded components
const WorkpackageListViewImport = import("./WorkpackageListView");
const WorkpackageListView = lazy(() => WorkpackageListViewImport);
const WorkpackageViewImport = import("./WorkpackageView");
const WorkpackageView = lazy(() => WorkpackageViewImport);

export enum ProjectRoutePaths {
  Template = "template",
  Home = "",
  WBS = "wbs",
  WBS_Table = "wbs/table",
  Activity = "activity",
  Todos = "todos",
  Workpackage = "p",
  Gantt = "gantt",
  Users = "users",
  Info = "info",
}

export const ProjectRoutes = (
  <Route
    path="/:organizationId/:projectId/*"
    element={
      <EapErrorBoundary>
        <AtomNotFoundBoundary
          title="Unbekanntes Projekt"
          subTitle="Dieses Projekt existiert nicht oder Sie haben keinen Zugriff auf dieses Projekt."
        >
          <ProjectTreeLoader>
            <StoreRegistryProvider>
              <Suspense fallback={<SplashScreen />}>
                <Outlet />
              </Suspense>
            </StoreRegistryProvider>
          </ProjectTreeLoader>
        </AtomNotFoundBoundary>
      </EapErrorBoundary>
    }
  >
    <Route path="-/">
      <Route path={ProjectRoutePaths.Template} element={<TemplateEditor />} />
      <Route
        path={`${ProjectRoutePaths.Template}/:templateName`}
        element={<TemplateEditor />}
      />
      <Route path={`${ProjectRoutePaths.WBS}/:subCode`} element={<WBSView />} />
      <Route path={ProjectRoutePaths.WBS} element={<WBSView />} />
      <Route path={ProjectRoutePaths.WBS_Table} element={<WBSTableView />} />
      <Route
        path={ProjectRoutePaths.Activity}
        element={<ProjectActivityView />}
      />
      <Route path={ProjectRoutePaths.Todos} element={<TodoListTableView />} />
      <Route
        path={`${ProjectRoutePaths.Todos}/:todoListId`}
        element={<TodoListView />}
      />
      <Route
        path={`${ProjectRoutePaths.Workpackage}/:code`}
        element={<WorkpackageView />}
      />
      <Route path={ProjectRoutePaths.Gantt} element={<GanttView />} />
      <Route path={ProjectRoutePaths.Users} element={<ProjectUserManager />} />
    </Route>
    <Route path="" element={<WorkpackageListView />} />
    <Route path="*" element={<NotFoundView />} />
  </Route>
);

export const buildProjectRoutePath = (
  projectId: string,
  organizationId: string,
  projectRoute: ProjectRoutePaths,
) => {
  if (projectRoute === ProjectRoutePaths.Home) {
    return `/${organizationId}/${projectId}/`;
  }
  return `/${organizationId}/${projectId}/-/${projectRoute}`;
};
