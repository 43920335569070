import "@ant-design/pro-layout/es/components/PageHeader/style/index";
import {
  type AiBackendApi,
  AiContextProvider,
} from "@atoms/ai-module/frontend";
import { useApiClient } from "@project/api";
import { Layout } from "antd";
import "dayjs/locale/de";
import { Suspense } from "react";
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
} from "react-router-dom";
import { EapErrorBoundary } from "./components/EapErrorBoundary";
import { Header } from "./components/Header";
import { MyOrganizations } from "./components/MyOrganizations";
import { MyProjects } from "./components/MyProjects";
import { UnsavedChangesHandler } from "./components/UnsavedChangesWarning";
import { SplashScreen } from "./components/reusable/SplashScreen";
import "./style/antd-styles";
import { NotFoundView } from "./components/NotFoundView";
import { RouterErrorView } from "./components/RouterErrorView";
import {
  buildProjectRoutePath,
  ProjectRoutePaths,
} from "./components/ProjectRoutes";
import { OrganizationRoutes } from "./components/Routes/OrganizationRoutes";
import { ProjectRoutes } from "./components/ProjectRoutes";
import TemplateEditor from "./components/TemplateEditor";
import { useAppStore } from "./state/app";

// lazily loaded components

// preloaded components

export const CoreApp = () => {
  const api = useApiClient();
  return (
    <>
      {/* <ChangeLogModal /> */}
      {/* <PlaywrightUtilLoader> */}
      <AiContextProvider
        backendApi={
          "AgentSession" in api ? (api.AgentSession as AiBackendApi) : undefined
        }
        // enabled={import.meta.env.AI_ENABLED === "true"}
        enabled={false}
      >
        <RouterProvider
          router={createBrowserRouter(
            createRoutesFromElements(
              <Route
                element={
                  <EapErrorBoundary key="outlet">
                    <UnsavedChangesHandler>
                      <Layout style={{ minHeight: "100vh" }}>
                        <Header />
                        <Outlet />
                      </Layout>
                    </UnsavedChangesHandler>
                  </EapErrorBoundary>
                }
                errorElement={<RouterErrorView key="outlet" />}
              >
                <Route
                  path="/"
                  loader={() => {
                    const defaultProject =
                      useAppStore.getState().defaultProject;
                    if (defaultProject) {
                      return redirect(
                        buildProjectRoutePath(
                          defaultProject.projectId,
                          defaultProject.orgId,
                          ProjectRoutePaths.Home,
                        ),
                      );
                    } else {
                      return redirect(
                        buildCoreRoutePath(CoreRouteElements.Projects),
                      );
                    }
                  }}
                  element={null} // this should never be rendered
                />
                <Route
                  path="/-/"
                  element={
                    <EapErrorBoundary key="core-routes">
                      <Suspense fallback={<SplashScreen />}>
                        <Outlet />
                      </Suspense>
                    </EapErrorBoundary>
                  }
                >
                  <Route
                    path={CoreRouteElements.Projects}
                    element={<MyProjects />}
                  />
                  <Route
                    path={CoreRouteElements.Organizations}
                    element={<MyOrganizations />}
                  />
                  <Route
                    path={CoreRouteElements.Templates}
                    element={<TemplateEditor />}
                  />
                  <Route path="*" element={<NotFoundView />} />
                </Route>
                {OrganizationRoutes}
                {ProjectRoutes}
                <Route
                  path="*"
                  element={
                    <EapErrorBoundary key="404">
                      <NotFoundView />
                    </EapErrorBoundary>
                  }
                />
              </Route>,
            ),
          )}
        />
        {/* </PlaywrightUtilLoader> */}
      </AiContextProvider>
    </>
  );
};

export enum CoreRouteElements {
  Projects = "projects",
  Organizations = "organizations",
  Templates = "templates",
}

export const buildCoreRoutePath = (element: CoreRouteElements) =>
  `/-/${element}`;
