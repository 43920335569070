import type { IToDo } from "../interfaces/IToDo";

export const createTodoDraft = (): IToDo => {
  return {
    uuid: crypto.randomUUID(),
    description: "",
    status: "",
    plannedFinish: null,
    label: "",
    sortIndex: null,
    deleted: false,
    done: false,
  };
};
