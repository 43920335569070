import type { AtomDescriptor } from "../functions/makeAtomDescriptor";

export enum DefaultRelation {
  AgentSession = "AgentSession",
  User = "User",
  Global = "Global",
}

// values must be <...>
export enum DefaultIdentifiers {
  AgentSessionId = "<AgentSessionId>",
  UserId = "<UserId>",
  Version = "<Version>",
}

// key must equal value in this enum
export enum DefaultAtoms {
  AgentSessionInfo = "AgentSessionInfo",
  PersonalUserData = "PersonalUserData",
}

export interface IToolCall {
  id: string;
  type: "function";
  function: {
    name: string;
    arguments: string;
  };
}

export interface IChatMessageBase<
  RoleType extends "user" | "assistant" | "system" | "tool",
> {
  role: RoleType;
}

export interface IUserMessage extends IChatMessageBase<"user"> {
  content: string;
}

export interface IAgentMessage extends IChatMessageBase<"assistant"> {
  content: string | null;
  tool_calls?: IToolCall[];
}

export interface ISystemMessage extends IChatMessageBase<"system"> {
  content: string;
}

export interface IToolMessage extends IChatMessageBase<"tool"> {
  content: string;
  tool_call_id: string;
}

export type IChatMessage =
  | IUserMessage
  | IAgentMessage
  | ISystemMessage
  | IToolMessage;

export interface IAgentSession {
  templateName: string;
  messages: IChatMessage[];
  awareness: Record<AtomDescriptor, number>;
}
