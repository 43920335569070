import type { AtomData } from "@atoms/atom-types";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
export interface Drafting<AtomType extends AtomData = AtomData> {
  draft: Partial<AtomType> | null;
  currentValue: AtomType;
  conflictingValues?: Partial<AtomType>;
  updateDraft: (draft: Partial<AtomType>) => void;
  resetDraft: () => void;
}

export const useDrafting = <AtomType extends AtomData = AtomData>(
  dataFromBackend: AtomType | null | undefined,
): Drafting<AtomType> => {
  const [startValueAtFirstEdit, setStartValueAtFirstEdit] =
    useState<AtomType | null>(null);
  const [draft, setDraft] = useState<Partial<AtomType> | null>(null);
  const [conflictingValues, setConflictingValues] = useState<
    Partial<AtomType> | undefined
  >(undefined);
  const currentValue = { ...startValueAtFirstEdit, ...draft } as AtomType;
  const updateDraft = useCallback(
    (draft: Partial<AtomType>) => {
      setDraft({ ...currentValue, ...draft });
    },
    [currentValue],
  );

  useEffect(() => {
    (globalThis as any).suggest = () => {
      setConflictingValues({
        description: "test",
      } as any);
    };
  }, []);
  /**
   * update the startValueAtFirstEdit when the dataFromBackend changes while the draft is null
   */
  useEffect(() => {
    if (draft === null && dataFromBackend) {
      setStartValueAtFirstEdit(dataFromBackend);
    }
  }, [dataFromBackend, draft]);

  /**
   * propose conflicting values when the dataFromBackend changes while the draft is not null
   */
  useEffect(() => {
    if (!startValueAtFirstEdit || !dataFromBackend) {
      return;
    }
    const diff: Partial<AtomType> = {};
    for (const key in startValueAtFirstEdit) {
      if (!isEqual(startValueAtFirstEdit[key], dataFromBackend[key])) {
        diff[key] = dataFromBackend[key];
      }
    }
    if (Object.keys(diff).length > 0) {
      setConflictingValues(diff);
    } else {
      setConflictingValues(undefined);
    }
  }, [startValueAtFirstEdit, dataFromBackend]);

  useEffect(() => {
    // FIXME: what happens if the atom is deleted while the user has the draft open?
    if (dataFromBackend === null || dataFromBackend?.deleted === true) {
      setDraft(null);
      return;
    }

    if (!dataFromBackend || !draft) {
      return;
    }

    // if backendversion changes to our draft, we need to reset the draft
    const newPackage = { ...dataFromBackend, ...draft };

    if (isEqual(newPackage, dataFromBackend)) {
      setDraft(null);
    }
  }, [dataFromBackend, draft]);

  return {
    draft,
    currentValue,
    conflictingValues,
    resetDraft: () => setDraft(null),
    updateDraft,
  };
};
