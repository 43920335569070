import { type AtomData, GenericPermission } from "@atoms/atom-types";
import { Select, type SelectProps } from "antd";
import SkeletonInput from "antd/es/skeleton/Input";
import { type ReactNode, useContext } from "react";
import { FloatLabel } from "./FloatLabel";
import { MagicFormContext, useLabel } from "./MagicForm";

export const MagicAutoComplete = <AtomType extends AtomData = AtomData>({
  className,
  field,
  options,
  tagRender,
}: {
  field: keyof AtomType & string;
  options: { key: string; value: string; label?: ReactNode }[];
  className?: string;
  tagRender?: SelectProps["tagRender"];
}) => {
  const { updateDraft, currentValue, loading, checkFieldPermission } =
    useContext(MagicFormContext)!;
  const mayUpdate = checkFieldPermission(field, GenericPermission.Update);
  const label = useLabel(field);

  return loading ? (
    <SkeletonInput active style={{ height: 48 }} block />
  ) : (
    <FloatLabel
      key={`${field}_label`}
      label={label}
      value={
        currentValue[field] && currentValue[field]?.length > 0
          ? currentValue[field]?.join()
          : undefined
      }
      className="unprintable-display-none"
    >
      <Select
        className={className}
        key={`${field}_input`}
        mode="multiple"
        style={{ width: "100%", overflow: "hidden" }}
        allowClear={mayUpdate}
        value={currentValue[field] ?? []}
        disabled={!mayUpdate}
        onSelect={(value) => {
          if (value !== undefined && value !== null) {
            const newValues = currentValue[field]
              ? [...currentValue[field], value.toString()]
              : [value.toString()];

            updateDraft({
              [field]: newValues,
            });
          }
        }}
        tagRender={tagRender}
        onDeselect={(value) => {
          if (value !== undefined && value !== null) {
            const newValues =
              currentValue[field]?.filter((code: any) => code !== value) ?? [];

            updateDraft({
              [field]: newValues,
            });
          }
        }}
        onClear={() =>
          updateDraft({
            [field]: [],
          })
        }
      >
        {options?.map(({ key, value }) => (
          <Select.Option key={`${key}_${key}`} value={key}>
            <strong>{key}</strong> - {value}
          </Select.Option>
        ))}
      </Select>
    </FloatLabel>
  );
};
