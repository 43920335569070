import { useApiClient } from "@project/api";
import { Identifiers } from "@project/shared";
import Form from "antd/es/form";
import Col from "antd/es/grid/col";
import Row from "antd/es/row";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import EmptyTemplate from "../../resources/templates/empty.json";
import { FloatLabel } from "../reusable/FloatLabel";
import { ReduxModal } from "./ReduxModal";

export const CreateTemplateModalId = "createTemplate";

export const CreateTemplateModal = () => {
  const [form] = Form.useForm();
  const api = useApiClient();

  const [name, setName] = useState("");

  return (
    <ReduxModal
      id={CreateTemplateModalId}
      title="Neue Berichtsvorlage erstellen"
      onOk={async () => {
        await api.Template.create(
          {
            name,
            templateData: EmptyTemplate as unknown as JSON,
            draft: false,
            deleted: false,
          },
          {
            [Identifiers.TemplateName]: name,
          },
        );
      }}
      form={form}
      afterClose={() => {
        setName("");
        form.resetFields();
      }}
    >
      <Form form={form}>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Form.Item
              noStyle
              rules={[{ required: true, message: "Bitte Name eintragen" }]}
            >
              <FloatLabel label="Name" value={name}>
                <TextArea
                  value={name}
                  onChange={(evt) => setName(evt.target.value)}
                  autoSize
                  autoFocus
                />
              </FloatLabel>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ReduxModal>
  );
};
