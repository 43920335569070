import {
  type GetUserNameWithTransaction,
  useCombinedContext,
} from "@atoms/atom-client";
import {
  DefaultAtoms,
  DefaultIdentifiers,
  type ITransaction,
  type UserPublicID,
} from "@atoms/atom-types";
import { useAtom } from "@project/api";
import { Atoms, Identifiers } from "@project/shared";

export const useEapUserName = (
  publicId: UserPublicID,
  fallback = "Unbekannter Nutzer",
): string | undefined => {
  const {
    [Identifiers.ProjectId]: projectId,
    [Identifiers.OrganizationId]: organizationId,
  } = useCombinedContext();
  const userNameFromProjectOrOrganization = useAtom(
    // a project can only exist in an organization
    // but the project takes precedence over the organization
    projectId ? Atoms.ProjectMembers : Atoms.OrganizationMembers,
    ({ entries }) => entries[publicId]?.name,
    undefined,
    !organizationId && !projectId,
  );

  const userNameFromPersonalUserData = useAtom(
    DefaultAtoms.PersonalUserData,
    ({ displayName }) => displayName ?? fallback,
    { [DefaultIdentifiers.UserId]: publicId },
  );

  if (organizationId || projectId) {
    if (!userNameFromProjectOrOrganization) {
      return fallback;
    }

    return userNameFromProjectOrOrganization;
  } else {
    if (!userNameFromPersonalUserData) {
      return fallback;
    }

    return userNameFromPersonalUserData;
  }
};

export const useEapGetUsernameWithTransaction: GetUserNameWithTransaction =
  async (
    transaction: ITransaction,
    userId: UserPublicID,
    fallback = "Unbekannter Nutzer",
  ): Promise<string | undefined> => {
    const projectId = transaction.getEntry(Identifiers.ProjectId);
    const organizationId = transaction.getEntry(Identifiers.OrganizationId);
    if (projectId) {
      const context = await transaction.aquireAtomValue<{
        entries: Record<string, { name: string }>;
      }>(Atoms.ProjectMembers);

      if (context.entries[userId]?.name) {
        return context.entries[userId].name;
      }
    }

    if (organizationId) {
      const orgContext = await transaction.aquireAtomValue<{
        entries: Record<string, { name: string }>;
      }>(Atoms.OrganizationMembers);

      if (orgContext.entries[userId]?.name) {
        return orgContext.entries[userId].name;
      }
    }

    const personalData = await transaction.aquireAtomValue<{
      displayName: string | null;
    }>(DefaultAtoms.PersonalUserData, {
      [DefaultIdentifiers.UserId]: userId,
    });

    return personalData.displayName ?? fallback;
  };
