import type {
  ITransaction,
  PublicIdMap,
  UserPublicID,
} from "@atoms/atom-types";
import type { IProjectTreeElement, IWorkpackage } from "@project/shared";
import {
  Atoms,
  type IActivity,
  type IWorkpackageCompact,
  Identifiers,
  ProjectTreeElementType,
} from "@project/shared";
import moment from "moment";
import { utils, writeFile } from "xlsx";
import {
  computeActivityStats,
  computeTimedProgress,
} from "../hooks/useWorkPackage";
import type { Activity } from "../structure/activity";
import type { WorkPackage } from "../structure/workPackage";
import { sortByWBSCode } from "../util/sortByWBS";

export interface ColumnProps<T> {
  title: string;
  isUser?: true;
  render?: (v: T) => string;
}

const BoolRenderer = (v: boolean) => (v ? "Ja" : "Nein");
const StringArrayRenderer = (v?: string[]) => v?.join(", ") ?? "";
const DateRenderer = (v?: Date | null) =>
  v ? moment(v).format("DD.MM.YYYY") : "";
const PercentRenderer = (v: number) => `${Math.floor(v)}%`;

const wpColumns: {
  [key in keyof WorkPackage]?: ColumnProps<WorkPackage[key]>;
} = {
  label: { title: "Titel" },
  code: { title: "PSP-Code" },
  description: { title: "Beschreibung" },
  expectedFinish: { title: "Erwartetes Ende", render: DateRenderer },
  plannedFinish: { title: "Enddatum", render: DateRenderer },
  plannedStart: { title: "Startdatum", render: DateRenderer },
  status: { title: "Bericht zum Status" },
  decisionRequired: { title: "Entscheidung?", render: BoolRenderer },
  responsibleUserId: { title: "Verantwortlicher", isUser: true },
  commissionerUserId: { title: "Auftraggeber", isUser: true },
  objective: { title: "Zielstellung" },
  goals: { title: "Lieferergebnisse" },
  dependentWPs: { title: "abhängige AP", render: StringArrayRenderer },
  done: { title: "Abgeschlossen", render: BoolRenderer },
  signal: { title: "Signal" },
  progress: { title: "geschätzter Fortschritt", render: PercentRenderer },
};

const actColums: { [key in keyof Activity]?: ColumnProps<Activity[key]> } = {
  description: { title: "Beschreibung" },
  expectedFinish: { title: "Erwartetes Ende", render: DateRenderer },
  plannedFinish: { title: "Enddatum", render: DateRenderer },
  currentFinish: { title: "Ist Ende", render: DateRenderer },
  status: { title: "Status" },
};

export async function downloadXlsx(
  transaction: ITransaction,
  tree: IProjectTreeElement[],
  userNameById: PublicIdMap<string>,
  onProgress: (percent: number) => void,
  signal?: AbortSignal,
) {
  if (signal && signal.aborted) return;

  const worksheetWithActvitites: string[][] = [
    [
      ...Object.values(wpColumns).map(({ title }) => title),
      "Fortschritt Aktivitäten",
      "Zeitlicher Fortschritt",
      "Gemittelter Fortschritt",
    ],
  ];
  const worksheetWithoutActivities = [
    [...Object.values(wpColumns).map(({ title }) => title), "Fortschritt"],
  ];

  const latestWorkPackages = tree.filter(
    (wp): wp is IProjectTreeElement<IWorkpackageCompact> =>
      wp.elementType === ProjectTreeElementType.Workpackage && !wp.deleted,
  );

  for (const p of latestWorkPackages.sort(sortByWBSCode)) {
    if (signal && signal.aborted) return;

    const wpInfo = await transaction.aquireAtomValue<IWorkpackage>(
      Atoms.WorkpackageLatest,
      {
        [Identifiers.Code]: p.code,
      },
    );
    const latestActivities = Object.values(wpInfo.activities ?? {});

    if (signal && signal.aborted) return;

    const fields = Object.entries(wpColumns).map(renderColumn(p));
    const { combinedProgress, finishedActivities, allActivities } =
      computeActivityStats(p, latestActivities);
    const timeProgress = computeTimedProgress(p);
    if (allActivities > 0) {
      fields.push(`${Math.floor((100 * finishedActivities) / allActivities)}%`);
    } else {
      fields.push("0%");
    }
    fields.push(`${timeProgress}%`);
    fields.push(`${combinedProgress}%`);
    worksheetWithActvitites.push(fields);
    worksheetWithoutActivities.push(fields);
    if (latestActivities.length > 0) {
      worksheetWithoutActivities.push([
        "",
        "PSP-Code",
        ...Object.values(actColums).map(({ title }) => title),
      ]);
      for (const a of latestActivities) {
        if (signal && signal.aborted) return;

        worksheetWithoutActivities.push([
          "",
          p.code,
          ...Object.entries(actColums).map(renderColumn(a)),
        ]);
      }
      worksheetWithoutActivities.push([]);
    }

    onProgress(
      (worksheetWithActvitites.length / latestWorkPackages.length) * 100,
    );
  }
  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet(worksheetWithActvitites);
  utils.book_append_sheet(wb, ws, "Arbeitspakete");
  utils.book_append_sheet(
    wb,
    utils.aoa_to_sheet(worksheetWithoutActivities),
    "Arbeitspakete mit Aktivitäten",
  );
  writeFile(wb, `ap51_report_${moment().format("YYYYMMDD_HHmm")}.xlsx`);

  function renderColumn(
    p: IWorkpackageCompact | IActivity,
  ): (value: any) => string {
    return ([key, { render, isUser }]) => {
      const value = p[key as keyof typeof p];
      if (isUser) {
        if (value) {
          return userNameById[value as UserPublicID] ?? "Unbekannter Nutzer";
        } else {
          return "";
        }
      }
      if (render) {
        return (render as any)(value) ?? "";
      } else return value ?? "";
    };
  }
}
