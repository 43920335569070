import { UserName } from "@atoms/atom-components";
import type { ChangeLog, Paths, UserPublicID } from "@atoms/atom-types";
import { useApiClient, useAtomPatches, useMyUserId } from "@project/api";
import { Atoms, type ITodoList, type ITodoListLog } from "@project/shared";
import { Alert } from "antd";
import Form from "antd/es/form";
import Col from "antd/es/grid/col";
import Row from "antd/es/row";
import Paragraph from "antd/es/typography/Paragraph";
import type { AddOperation } from "fast-json-patch";
import { last, sortBy } from "lodash";
import {
  type ForwardRefRenderFunction,
  type MutableRefObject,
  type ReactNode,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { flushSync } from "react-dom";
import { StringDiff } from "react-string-diff";
import { ModalsApi } from "../../state/modals";
import { useThunkDispatch } from "../../useThunkDispatch";
import { ReduxModal } from "./ReduxModal";

type LabelType = Partial<
  Record<
    keyof ITodoList,
    { label: string; renderer?: (value: any) => ReactNode }
  >
>;

const RevisionChangesModalId = "revisionChanges";

export type ToDoRevisionConflictRef = {
  handleSave: (draft: Partial<ITodoList>) => Promise<void>;
};

interface ToDoRevisionConflictModalProps {
  draft: Partial<ITodoList> | null;
}

const ToDoRevisionConflictModal: ForwardRefRenderFunction<
  ToDoRevisionConflictRef,
  ToDoRevisionConflictModalProps
> = ({ draft }, forwardedRef) => {
  const dispatch = useThunkDispatch();

  // const [keptKeys, setKeptKeys] = useState<string[]>([]);
  const myUserId = useMyUserId();

  const api = useApiClient();

  const [valuesChangedByOthers, setValuesChangedByOthers] =
    useState<Record<`/${Paths<ITodoList>}`, string>>();
  const historyDateCutoff: MutableRefObject<Date | null> = useRef<Date>(null);
  const onAtomPatch = useCallback(
    (p: ITodoListLog | null) => {
      if (!p) {
        // if the atom does not exist, we don't need to compute any changes
        return;
      }

      if (!historyDateCutoff.current) {
        // initial state, save date of last change for further usage
        historyDateCutoff.current =
          last(sortBy((p as ITodoListLog).__history, "date"))?.date ?? null;
      }

      if (!draft) {
        // if we don't have a draft, we don't need to compute any changes
        return;
      }

      const changesByOtherUsers = new Array<ChangeLog<ITodoList>>();
      const changesSinceCutoff = (p as ITodoListLog).__history.filter(
        (e) => e.date >= historyDateCutoff.current!,
      );

      // find the last index of the element in 'changesSinceMounted' that was created by myself
      const indexOfLastChangeByMyself = changesSinceCutoff
        .map((e) => e.author)
        .lastIndexOf(myUserId as UserPublicID);

      changesByOtherUsers.push(
        ...changesSinceCutoff.slice(
          indexOfLastChangeByMyself + 1,
          changesSinceCutoff.length,
        ),
      );

      if (changesByOtherUsers.length > 0) {
        setValuesChangedByOthers(
          // create an object with the 'path' of the change as key and the 'value' as value
          changesByOtherUsers.reduce(
            (acc, e) => {
              e.changes.forEach((c) => {
                acc[c.path] = (c as AddOperation<any>).value;
              });
              return acc;
            },
            {} as Record<`/${Paths<ITodoList>}`, string>,
          ),
        );
      }
    },
    [myUserId, Boolean(draft)],
  );
  useAtomPatches(Atoms.TodoListInfo, onAtomPatch);

  useLayoutEffect(() => {
    if (!draft) {
      historyDateCutoff.current = null;
      setValuesChangedByOthers(undefined);
    }
  }, [Boolean(draft)]);

  const confirmSave: MutableRefObject<(() => void) | undefined> = useRef();
  const cancelSave: MutableRefObject<(() => void) | undefined> = useRef();
  const save = useCallback(
    async (draft?: Partial<ITodoList>) => {
      if (!draft) {
        return;
      } else if (!valuesChangedByOthers) {
        // if there are no changes by others, we can just save the draft
        await api.TodoList.update(draft!);
        return;
      }

      flushSync(() => {
        dispatch(ModalsApi.show(RevisionChangesModalId));
      });
      await new Promise((resolve, reject) => {
        cancelSave.current = () => {
          reject(new Error("saving canceled"));
        };
        confirmSave.current = async () => {
          await api.TodoList.update(draft!);
          resolve(true);
        };
      });
    },
    [Boolean(valuesChangedByOthers)],
  );

  useImperativeHandle(
    forwardedRef,
    () => ({
      handleSave: save,
    }),
    [save],
  );

  const [form] = Form.useForm();

  const labels: LabelType = {
    label: { label: "Titel" },
    responsibleUserId: {
      label: "Verantwortliche/r",
      renderer: (value) => <UserName publicId={value} />,
    },
    involvedUserIds: {
      label: "Beteiligte",
      renderer: (value: UserPublicID[]) => (
        <>
          {value.map((id, idx) => (
            <UserName key={idx} publicId={id} />
          ))}
        </>
      ),
    },
    description: { label: "Beschreibung" },
    dependentWbsElements: { label: "abhängige WBS-Elemente" },
    todos: { label: "ToDos" },
    deleted: { label: "gelöscht" },
    done: { label: "abgeschlossen" },
  };

  if (!valuesChangedByOthers) {
    return null;
  }

  return (
    <ReduxModal
      width={"800px"}
      id={RevisionChangesModalId}
      form={form}
      title="Änderungen überprüfen"
      onOk={() => {
        // calling this without a lambda does not work somehow..
        confirmSave.current?.();
      }}
      onCancel={() => {
        // calling this without a lambda does not work somehow..
        cancelSave.current?.();
      }}
      okButtonProps={{ danger: true }}
      okText="Speichern"
    >
      <Alert
        message="Während das Formular bearbeitet wurde hat ein anderer Benutzer eine neue Version gespeichert. Falls Sie das gleiche Feld bearbeitet haben, werden die Änderungen des anderen Benutzers überschrieben!"
        type="warning"
      />

      <Form form={form} className="mt-4">
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <h3 className="mb-2">Neue Version</h3>
            {Object.entries(valuesChangedByOthers).map(([key, value]) => {
              const splitKey = key.split("/");
              const labelKey = splitKey[1] as keyof ITodoList;
              const label = labels[labelKey];
              if (!label) {
                return null;
              }
              return (
                <div key={key}>
                  <strong>{label.label}</strong>:
                  <Paragraph
                    ellipsis={{ expandable: true, rows: 2, symbol: "mehr" }}
                  >
                    {value !== null
                      ? label.renderer
                        ? label.renderer(value)
                        : value.toString()
                      : null}
                  </Paragraph>
                </div>
              );
            })}
          </Col>
          {Object.keys(draft ?? {}).length > 0 ? (
            <Col span={12}>
              <h3 className="mb-2">Lokale Änderung</h3>
              {Object.entries(draft ?? {}).map(([key, value]) => {
                const label = labels[key as keyof ITodoList];
                if (!label) {
                  return null;
                }
                return (
                  <div key={key}>
                    <strong>{label.label}</strong>:
                    <Paragraph
                      ellipsis={{ expandable: true, rows: 2, symbol: "mehr" }}
                    >
                      {value !== null ? (
                        label.renderer ? (
                          label.renderer(value)
                        ) : typeof value === "string" &&
                          valuesChangedByOthers[
                            `/${key as keyof ITodoList}`
                          ] !== undefined ? (
                          <StringDiff
                            oldValue={
                              valuesChangedByOthers[
                                `/${key as keyof ITodoList}`
                              ] ?? ""
                            }
                            newValue={value}
                          />
                        ) : (
                          value.toString()
                        )
                      ) : null}
                    </Paragraph>
                  </div>
                );
              })}
            </Col>
          ) : null}
        </Row>
      </Form>
    </ReduxModal>
  );
};

export default forwardRef(ToDoRevisionConflictModal);
