export enum GenericPermission {
  None = 0b0000,
  Update = 0b1000,
  Read = 0b0100,
  Delete = 0b0010,
  Create = 0b0001,
  CreateChildren = GenericPermission.Create << 4,
  DeleteChildren = GenericPermission.Delete << 4,
  UpdateChildren = GenericPermission.Update << 4,
}

export enum GenericPermissionsString {
  Create = "create",
  Update = "update",
  Delete = "delete",
  CreateChildren = "createChildren",
  DeleteChildren = "deleteChildren",
  UpdateChildren = "updateChildren",
}

export const GenericPermissionsStringMap: Record<
  GenericPermissionsString,
  GenericPermission
> = {
  [GenericPermissionsString.Create]: GenericPermission.Create,
  [GenericPermissionsString.Update]: GenericPermission.Update,
  [GenericPermissionsString.Delete]: GenericPermission.Delete,
  [GenericPermissionsString.CreateChildren]: GenericPermission.CreateChildren,
  [GenericPermissionsString.DeleteChildren]: GenericPermission.DeleteChildren,
  [GenericPermissionsString.UpdateChildren]: GenericPermission.UpdateChildren,
};

export type FieldPermissions =
  | GenericPermission[]
  | Partial<Record<string, GenericPermission[]>>;

export type AtomPermissions =
  | GenericPermission[]
  | Partial<Record<string, FieldPermissions>>;

export type EntityPermissions<AtomType extends string> = GenericPermission[];

export type RolePermissions<
  AtomType extends string,
  EntityType extends string,
> = Partial<
  | Record<EntityType, EntityPermissions<AtomType>>
  | Record<AtomType, AtomPermissions>
>;

export type PermissionsByRole<
  RoleType extends string,
  EntityType extends string,
  AtomType extends string,
> = Partial<Record<RoleType, RolePermissions<AtomType, EntityType>>>;
