import { DefaultIdentifiers } from "@atoms/atom-types";
import { useAtom } from "@project/api";
import { Atoms } from "@project/shared";
import { isWorkpackage } from "./isWorkpackage";

export const useCategories = (version?: string) => {
  if (!version) {
    return useAtom(Atoms.ProjectTree, (tree) =>
      tree?.entries.filter((e) => !isWorkpackage(e) && !e.deleted),
    );
  }
  return useAtom(
    Atoms.ProjectTreeSnapshot,
    (tree) => {
      return tree?.entries.filter((e) => !isWorkpackage(e) && !e.deleted);
    },
    {
      [DefaultIdentifiers.Version]: version,
    },
  );
};
