import { Button, Tag, Typography } from "antd";
import { useEffect, useState } from "react";

const { Text } = Typography;

interface CodeSchemaInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const CodeSchemaInput: React.FC<CodeSchemaInputProps> = ({
  value,
  onChange,
}) => {
  const [segments, setSegments] = useState<string[]>(
    value ? value.split(".") : [],
  );

  useEffect(() => {
    setSegments(value ? value.split(".") : []);
  }, [value]);

  const segmentOptions = ["A-Z", "1-9", "10-90"];

  const handleSegmentClick = (index: number) => {
    const currentIdx = segmentOptions.indexOf(segments[index] ?? "");
    const nextIdx = (currentIdx + 1) % segmentOptions.length;
    const newSegments = [...segments];
    newSegments[index] = segmentOptions[nextIdx] ?? "";
    setSegments(newSegments);
    onChange(newSegments.join("."));
  };

  const addSegment = () => {
    setSegments([...segments, "A-Z"]);
    onChange([...segments, "A-Z"].join("."));
  };

  const removeSegment = (index: number) => {
    const newSegments = [...segments];
    newSegments.splice(index, 1);
    setSegments(newSegments);
    onChange(newSegments.join("."));
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        flexWrap: "wrap",
        alignItems: "center",
        minHeight: "32px", // Match antd input height
        padding: "4px 11px", // Match antd input padding
        border: "1px solid #d9d9d9", // Match antd input border
        borderRadius: "6px", // Match antd input border radius
        backgroundColor: "#fff",
        width: "100%",
      }}
    >
      {segments.map((segment, index) => (
        <span
          key={Math.random()}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Tag
            style={{ cursor: "pointer", margin: 0 }}
            onClick={() => handleSegmentClick(index)}
            closable
            onClose={() => removeSegment(index)}
          >
            {segment}
          </Tag>
          {index < segments.length - 1 && (
            <Text strong className="ml-2">
              .
            </Text>
          )}
        </span>
      ))}
      <Button size="small" onClick={addSegment} type="dashed">
        + Segment hinzufügen
      </Button>
    </div>
  );
};
