export enum Entities {
  Organization = "Organization",
  Project = "Project",
  Workpackage = "Workpackage",
  Category = "Category",
  Version = "Version",
  Template = "Template",
  TodoList = "TodoList",
  EmailAddress = "EmailAddress",
}
