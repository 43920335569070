import { usePresentAtoms } from "@atoms/atom-client";
import { parseAtomDescriptor } from "@atoms/atom-types";
import type React from "react";
import { useEffect, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";

interface AutocompleteTextareaProps {
  value: string;
  onChange: (value: string) => void;
  onPressEnter: () => void;
  placeholder?: string;
  disabled?: boolean;
}

export const AutocompleteTextarea: React.FC<AutocompleteTextareaProps> = ({
  value,
  onChange,
  onPressEnter,
  placeholder,
  disabled,
}) => {
  const [internalValue, setInternalValue] = useState(value);
  const presentAtoms = usePresentAtoms().filter(
    (atom) => !atom.includes("AgentSession"),
  );

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleChange = (
    _event: any,
    newValue: string,
    _newPlainTextValue: string,
    _mentions: any,
  ) => {
    setInternalValue(newValue);
    onChange(newValue);
  };

  const handleKeyDown = (
    e:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onPressEnter();
    }
  };

  const atomMentions = presentAtoms.map((atom) => {
    const { type, context } = parseAtomDescriptor(atom);
    return {
      id: atom,
      display: "@" + type,
    };
  });

  return (
    <MentionsInput
      value={internalValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      disabled={disabled}
      style={{
        control: {
          minHeight: "50px",
          maxHeight: "150px",
          overflow: "auto",
        },
        input: {
          margin: 0,
          padding: "5px",
          border: "1px solid #d9d9d9",
          borderRadius: "4px",
          fontSize: "14px",
          lineHeight: "1.5",
        },
        suggestions: {
          list: {
            backgroundColor: "white",
            border: "1px solid rgba(0,0,0,0.15)",
            fontSize: "14px",
          },
          item: {
            padding: "5px 15px",
            borderBottom: "1px solid rgba(0,0,0,0.15)",
            "&focused": {
              backgroundColor: "#f0f0f0",
            },
          },
        },
      }}
    >
      <Mention
        trigger="@"
        data={atomMentions}
        renderSuggestion={(_suggestion, search, highlightedDisplay) => (
          <div>{highlightedDisplay}</div>
        )}
        markup="[@__display__](/atom/__id__)"
      />
    </MentionsInput>
  );
};
