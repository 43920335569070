import { ArrowLeftOutlined } from "@ant-design/icons";
import { SetExtraContext, useAtom } from "@project/api";
import { Atoms, EapPermission, Identifiers } from "@project/shared";
import { ErrorBoundary } from "@sentry/react";
import { Button, Card, Col, Empty, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { useEapContext } from "../../hooks/useEapContext";
import { useHasPermission } from "../../hooks/useHasPermission";
import { useSetTitle } from "../../hooks/useSetTitle";
import { ProjectPreview } from "../ProjectPreview";
import { CreateProjectCard } from "./CreateProjectCard";

const { Title, Paragraph } = Typography;

export const OrganizationProjects = () => {
  useSetTitle("Projekte");

  const [searchTerm, setSearchTerm] = useState("");
  const { [Identifiers.OrganizationId]: organizationId } = useEapContext();

  const projects =
    useAtom(Atoms.ProjectList, (projectList) =>
      Object.entries(projectList.entries).map(([projectId]) => ({
        projectId,
        organizationId: organizationId,
      })),
    ) ?? [];
  const organization = useAtom(Atoms.OrganizationInfo);
  const hasPermission = useHasPermission();

  return (
    <Content>
      <Title level={3}>
        {organization?.name ? `${organization.name} - ` : ""}Projektübersicht
        <Button type="link" onClick={() => window.history.back()}>
          <ArrowLeftOutlined /> Zurück zur Übersicht
        </Button>
      </Title>
      <Paragraph>
        Hier können Sie alle Projekte der Organisation einsehen und verwalten.
      </Paragraph>
      <Row gutter={[16, 16]}>
        {projects.length === 0 &&
        !hasPermission(EapPermission.CreateProject) ? (
          <Col span={24}>
            <Card>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    {searchTerm
                      ? "Keine Projekte gefunden."
                      : "Sie haben derzeit keine Projekte oder Einladungen."}
                  </span>
                }
              />
            </Card>
          </Col>
        ) : (
          <>
            {projects?.map(({ projectId, organizationId }) => (
              <Col
                key={projectId}
                xs={24}
                sm={16}
                md={12}
                lg={8}
                xl={6}
                xxl={4}
              >
                <ErrorBoundary key={projectId}>
                  <SetExtraContext
                    context={{
                      [Identifiers.OrganizationId]: organizationId,
                      [Identifiers.ProjectId]: projectId,
                    }}
                  >
                    <ProjectPreview
                      organizationId={organizationId}
                      projectId={projectId}
                    />
                  </SetExtraContext>
                </ErrorBoundary>
              </Col>
            ))}
            <CreateProjectCard />
          </>
        )}
      </Row>
    </Content>
  );
};
