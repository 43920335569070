import { calculateLatestVersionedAtomValue } from "@atoms/atom-types";
import { Atoms, VersionedHistory, useApiClient, useAtom } from "@project/api";
import { Button, InputNumber, Typography } from "antd";
import { useMemo, useState } from "react";
import { ConditionalTooltip } from "../reusable/ConditionalTooltip";

const { Title, Paragraph } = Typography;

export const LicenseManager = () => {
  const consumedLicenses = useAtom(Atoms.Licenses);
  const licenses = useAtom(Atoms.BookedLicenses);
  const api = useApiClient();
  const [pendingLicenses, setPendingLicenses] = useState<number>();

  const value = useMemo(
    () => calculateLatestVersionedAtomValue(licenses?.__history ?? []),
    [licenses?.__history],
  );

  return (
    <>
      <Title level={5}>Aktuell gebuchte Lizenzen</Title>
      <Paragraph>
        Die für Ihre Organisation aktuell gebuchten Lizenzen.
      </Paragraph>
      <InputNumber
        value={pendingLicenses ?? value.licenses}
        min={consumedLicenses?.count ?? 0}
        onChange={(val) => setPendingLicenses(val ?? value.licenses)}
      />
      <ConditionalTooltip
        disabled={
          Boolean(pendingLicenses) && pendingLicenses !== value.licenses
        }
        title="Tragen Sie die gewünschte Anzahl der Lizenzen ein."
      >
        <Button
          className="ml-2"
          disabled={!pendingLicenses || pendingLicenses === value.licenses}
          onClick={() => {
            if (pendingLicenses != null) {
              api.Organization.updateBookedLicenses({
                licenses: pendingLicenses,
              }).then(() => setPendingLicenses(undefined));
            }
          }}
        >
          Lizenzen aktualisieren
        </Button>
      </ConditionalTooltip>

      <Title level={5} className="mt-4">
        Historie
      </Title>
      <Paragraph>
        Hier können Sie den Verlauf der gebuchten Lizenzen einsehen.
      </Paragraph>
      <VersionedHistory
        atomType={Atoms.BookedLicenses}
        name="Lizenzen"
        subItem={{
          key: "licenses",
          render: (changes) => {
            if (
              changes[0]?.path === "/licenses" &&
              changes[0]?.op === "replace"
            ) {
              return `hat die gebuchten Lizenzen auf ${changes[0]?.value} `;
            }
            return null;
          },
        }}
        labels={{ licenses: "Anzahl", deleted: "Gelöscht" }}
        userNameFallback="Admin"
      />
    </>
  );
};
