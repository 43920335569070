import {
  AtomStoreContext,
  useCombinedContext,
  useFrontendProvision,
} from "@atoms/atom-client";
import type { ContextValues } from "@atoms/atom-types";
import { useContext } from "react";

export function makeUseApiClient<API extends object>(APIClient: API) {
  return function useApiClient(
    extraContext: ContextValues = {},
  ): MapResolvers<API> {
    const atomStore = useContext(AtomStoreContext);
    if (!atomStore) {
      throw new Error("atomStore not initialized");
    }
    const { onError } = atomStore;
    const provision = useFrontendProvision();
    const { getPermissionsInContext: getPermissionsHook } = provision;
    const apolloClient = atomStore.getApolloClient();
    const context = useCombinedContext(extraContext);
    const permissions = getPermissionsHook?.(extraContext) ?? [];

    return new Proxy(APIClient, {
      get: (generatedClient, relationName): MapResolvers<API> => {
        if (!(relationName in generatedClient)) {
          return new Proxy(
            {},
            {
              get: (_nonExistentRelation, reducerName) => {
                throw new Error(
                  `${String(relationName)}#${String(reducerName)} not found in API`,
                );
              },
            },
          ) as any;
        }
        return new Proxy((generatedClient as any)[relationName], {
          get: (relationObject, reducerName): MapResolvers<API> => {
            return new Proxy(relationObject[reducerName].handler, {
              get: (handlerFunction, prop2) => {
                if (prop2 === "isAccessible") {
                  return () =>
                    permissions?.includes(
                      relationObject[reducerName].permission,
                    );
                }
                return handlerFunction[prop2];
              },
              apply: (handlerFunction, thisArg, args): MapResolvers<API> => {
                return handlerFunction.apply(thisArg, [
                  apolloClient,
                  onError,
                  { ...context, ...extraContext },
                  ...args,
                ]);
              },
            });
          },
        });
      },
    }) as unknown as MapResolvers<API>;
  };
}

export type MapFunctions<T> = {
  [K in keyof T]: FunctionWithoutFirstThreeArgs<T[K]>;
};
export type FunctionWithoutFirstThreeArgs<T> = T extends {
  handler: (a: any, b: any, c: any, ...args: infer U) => infer R;
}
  ? ((...args: U) => R) & { isAccessible(): boolean }
  : never;
export type MapResolvers<T> = { [P in keyof T]: MapFunctions<T[P]> };
