/*
 * This file has been generated by permissions
 * Hash: 3b41ab79c0f743b19a64fd0b263cf5018f723442c0b4be1534edc9c0eb3d2bd0
 */

export enum UserPermissions {
  Create = "CreateUser",
  Delete = "DeleteUser",
  Read = "ReadUser",
  Update = "UpdateUser",
  ReadPersonalData = "ReadPersonalData",
  UpdatePersonalData = "UpdatePersonalData",
}
