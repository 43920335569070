import { __useDefaultUsername, __useUsername } from "@atoms/atom-client";
import type { UserPublicID } from "@atoms/atom-types";
import { Avatar, Skeleton } from "antd";
import type { AvatarSize } from "antd/es/avatar/AvatarContext";
import { merge } from "lodash";
import type { CSSProperties } from "react";

interface ProjectUserAvatarProps {
  userId: UserPublicID;
  style?: CSSProperties;
  size?: AvatarSize & (number | "large" | "small" | "default");
  fallback?: string;
}

export const UserAvatar = ({
  userId,
  style,
  size,
  fallback,
}: ProjectUserAvatarProps) => {
  let user: string | null | undefined;

  try {
    // __useUsername may throw if called with an invalid projectId or organizationId
    // thus the internal fallback to __useDefaultUsername does not work
    user = __useUsername(userId, fallback);
  } catch (error) {
    user = __useDefaultUsername(userId);
  }

  if (user === undefined) {
    return <Skeleton.Avatar className="avatar noWrap" active size={size} />;
  }

  const initials =
    user
      ?.split(" ")
      .map((p) => p[0])
      .join("") ?? "NA";

  return (
    <Avatar
      className="avatar noWrap"
      style={merge(style, {
        backgroundColor: consistentStringToColor(user ?? "NA"),
      })}
      size={size}
    >
      {initials}
    </Avatar>
  );
};

const consistentStringToColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};
