import {
  type AtomData,
  GenericPermission,
  type UserPublicID,
} from "@atoms/atom-types";
import { Select } from "antd";
import type { DefaultOptionType } from "antd/es/cascader";
import SkeletonInput from "antd/es/skeleton/Input";
import { useContext, useState } from "react";
import { FloatLabel } from "./FloatLabel";
import { MagicFormContext, useLabel } from "./MagicForm";
import { UserName } from "./UserName";

export interface MultiUserSelectorProps<AtomType extends AtomData = AtomData> {
  readonly?: boolean;
  field: keyof AtomType & string;
  options: DefaultOptionType[];
  className?: string;
}

export const MagicMultiUserSelector = <AtomType extends AtomData = AtomData>({
  className,
  readonly,
  field,
  options,
}: MultiUserSelectorProps<AtomType>) => {
  const { updateDraft, loading, currentValue, checkFieldPermission } =
    useContext(MagicFormContext)!;
  const mayUpdate = checkFieldPermission(field, GenericPermission.Update);
  const label = useLabel(field);
  const [filterValue, setFilterValue] = useState<string | undefined>(undefined);
  return loading ? (
    <SkeletonInput active style={{ height: 48, marginBottom: "10px" }} block />
  ) : (
    <FloatLabel
      key={`${field}-label`}
      label={
        !mayUpdate && (!currentValue[field] || currentValue[field].length === 0)
          ? "kein(e) Beteiligte vorhanden"
          : "Beteiligte"
      }
      value={
        currentValue[field] && currentValue[field].length > 0
          ? currentValue[field].join(", ")
          : undefined
      }
      style={{ marginBottom: "10px" }}
      className="print-bordered"
    >
      <Select
        className={`${className} mmus-label-${label.replace(/\s/g, "-")}`}
        optionFilterProp="label"
        optionLabelProp="label"
        allowClear
        mode="multiple"
        key={`${field}_input_involved`}
        value={currentValue[field] ? currentValue[field] : []}
        disabled={!mayUpdate}
        options={options}
        labelRender={
          options
            ? ({ value }) => <UserName publicId={value as UserPublicID} />
            : undefined
        }
        onSelect={(value) => {
          updateDraft({
            [field]: [...(currentValue[field] ?? []), value as UserPublicID],
          });
        }}
        onDeselect={(value) => {
          setFilterValue(undefined);
          updateDraft({
            [field]: currentValue[field]?.filter(
              (id: UserPublicID) => id !== (value as UserPublicID),
            ),
          });
        }}
        onClear={() => {
          setFilterValue(undefined);
          updateDraft({
            [field]: null,
          });
        }}
      />
    </FloatLabel>
  );
};
