import { type CSSProperties, type ReactElement, useState } from "react";

interface OwnProps {
  children: ReactElement;
  label: string;
  value?: string;
  className?: string;
  style?: CSSProperties;
}
export const FloatLabel = ({
  children,
  label,
  value,
  className,
  style,
}: OwnProps) => {
  const [focus, setFocus] = useState(false);

  const labelClass =
    focus || (value && value.length !== 0) ? "label label-float" : "label";

  return (
    <div
      className={"float-label" + (className ? " " + className : "")}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      style={style}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  );
};
