import { EapPermission, type IUserRoles } from "@project/shared";
import {
  AdminPermissions,
  type AllPermissions,
  OrganizationPermissionsByRole,
  ProjectPermissionsByRole,
} from "../permissions/PermissionsByRole";

/**
 * Derives permissions based on the identifiers in the context.
 * @param userRoles - The userroles atom.
 * @param organizationId - The current organization ID (if any).
 * @param projectId - The current project ID (if any).
 * @returns The permissions in this context.
 */
export const derivePermissionsByContext = (
  userRoles: IUserRoles | null | undefined,
  organizationId: string | undefined,
  projectId: string | undefined,
): AllPermissions[] => {
  if (!userRoles) {
    return [];
  }

  const permissions: AllPermissions[] = [];

  if (userRoles.isAdmin) {
    permissions.push(
      ...AdminPermissions,
      ...(organizationId ? [EapPermission.ReadOrganization] : []),
      ...(projectId ? [EapPermission.ReadProject] : []),
    );
  }
  if (organizationId) {
    const org = userRoles.orgs[organizationId];
    if (org) {
      permissions.push(
        ...org.roles.flatMap((role) => OrganizationPermissionsByRole[role]),
      );
    }
    if (projectId) {
      if (org?.projects[projectId]) {
        const projectRoles = org.projects[projectId] ?? [];
        permissions.push(
          ...projectRoles.flatMap((role) => ProjectPermissionsByRole[role]),
        );
      }
    }
  }
  return [...new Set(permissions)];
};
