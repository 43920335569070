/**
 * Validates that a given code matches the provided schema.
 *
 * @param code - The code string to validate.
 * @param codeSchema - The schema string defining the code structure.
 * @returns `true` if the code matches the schema, otherwise `false`.
 */
export const validateCode = (code: string, codeSchema: string): boolean => {
  const codeParts = code.split(".");
  const schemaParts = codeSchema.split(".");
  const lastSchemaType = schemaParts[schemaParts.length - 1];

  for (let i = 0; i < codeParts.length; i++) {
    const schemaType = schemaParts[i] || lastSchemaType;
    const codePart = codeParts[i] as string;

    switch (schemaType) {
      case "A-Z":
        if (!/^[A-Z]$/.test(codePart)) {
          return false;
        }
        break;
      case "a-z":
        if (!/^[a-z]$/.test(codePart)) {
          return false;
        }
        break;
      case "1-9":
        if (!/^[1-9]$/.test(codePart)) {
          return false;
        }
        break;
      case "0-9":
        if (!/^\d$/.test(codePart)) {
          return false;
        }
        break;
      case "10-90":
        if (!/^(?:[1-8]\d|90)$/.test(codePart)) {
          return false;
        }
        break;
      default:
        throw new Error(`Unsupported schema type: ${schemaType}`);
    }
  }

  // Ensure the code does not have extra parts beyond the schema
  // If schema allows repeating the last type, extra parts are allowed
  // Otherwise, the number of parts should match the schema
  if (codeParts.length > schemaParts.length) {
    for (let i = schemaParts.length; i < codeParts.length; i++) {
      const schemaType = lastSchemaType;
      const codePart = codeParts[i] as string;

      switch (schemaType) {
        case "A-Z":
          if (!/^[A-Z]$/.test(codePart)) {
            return false;
          }
          break;
        case "a-z":
          if (!/^[a-z]$/.test(codePart)) {
            return false;
          }
          break;
        case "1-9":
          if (!/^[1-9]$/.test(codePart)) {
            return false;
          }
          break;
        case "0-9":
          if (!/^\d$/.test(codePart)) {
            return false;
          }
          break;
        case "10-90":
          if (!/^(?:[1-8]\d|90)$/.test(codePart)) {
            return false;
          }
          break;
        default:
          throw new Error(`Unsupported schema type: ${schemaType}`);
      }
    }
  }

  return true;
};
