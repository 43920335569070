import { AuthenticatedApp } from "@atoms/atom-client";
import { App as AntApp, ConfigProvider } from "antd";
import deDE from "antd/locale/de_DE";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import moment from "moment";
import type { ReactNode } from "react";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import * as timeago from "timeago.js";
import de from "timeago.js/lib/lang/de";
import { CoreApp } from "../CoreApp";
import { rootReducer } from "../state";
import "../style/app.scss";
import { SplashScreen } from "./reusable/SplashScreen";

dayjs.locale("de");
dayjs.extend(relativeTime);
moment.locale("de");
timeago.register("de", de);
const store = createStore(rootReducer, applyMiddleware(thunk));
store.subscribe(() => {
  (window as any).state = store.getState();
});

const persistor = persistStore(store);

export const MainComponent: ReactNode = (
  <>
    <Provider store={store}>
      <ConfigProvider
        locale={deDE}
        theme={{
          token: {
            fontFamily: "PT Sans",
            colorPrimary: "#0097cc",
            colorLink: "#0097cc",
          },
        }}
      >
        <AuthenticatedApp fallback={<SplashScreen />}>
          <AntApp>
            <CoreApp />
          </AntApp>
        </AuthenticatedApp>
      </ConfigProvider>
    </Provider>
  </>
);
