import { type AtomData, GenericPermission } from "@atoms/atom-types";
import SkeletonInput from "antd/es/skeleton/Input";
import { useContext } from "react";
import { FloatLabel } from "./FloatLabel";
import { MagicFormContext, useLabel } from "./MagicForm";
import { TextAreaOrDiv } from "./TextAreaOrDiv";

export const MagicTextareaOrDiv = <AtomType extends AtomData = AtomData>({
  field,
  className,
}: {
  field: keyof AtomType & string;
  className?: string;
}) => {
  const { updateDraft, currentValue, loading, checkFieldPermission } =
    useContext(MagicFormContext)!;
  const mayUpdate = checkFieldPermission(field, GenericPermission.Update);
  const label = useLabel(field);

  return loading ? (
    <SkeletonInput active style={{ height: 48 }} />
  ) : (
    <FloatLabel
      key={`${field}-label`}
      label={
        !mayUpdate &&
        (!currentValue[field] || currentValue[field]?.length === 0)
          ? "keine Beschreibung vorhanden"
          : label
      }
      value={currentValue[field]}
      className="print-bordered"
    >
      <TextAreaOrDiv
        key={`${field}-input`}
        value={currentValue[field]}
        className={`${className} mtod-label-${label.replace(/\s/g, "-")}`}
        // bordered={userRole === UserRole.ADMIN}
        disabled={!mayUpdate}
        onChange={(evt) => {
          if (currentValue[field] === evt.target.value) {
            return;
          }
          updateDraft({
            [field]: evt.target.value,
          });
        }}
        autoSize
      />
    </FloatLabel>
  );
};
