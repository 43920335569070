import { AtomNotFoundBoundary } from "@atoms/atom-client";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Atoms, EapProjectRole, workpackageLabels } from "@project/shared";
import { Drawer, FloatButton, Tag } from "antd";
import Col from "antd/es/col";
import { Content } from "antd/es/layout/layout";
import Row from "antd/es/row";
import type { DefaultOptionType } from "antd/es/select";
import { memo, useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetTitle } from "../hooks/useSetTitle";
import { useWorkPackages } from "../hooks/useWorkPackage";
import { getFilteredProjectUserOptions } from "../util/getFilteredProjectUserOptions";
import { ActivityTable } from "./ActivityTable";
import { ControlInformations } from "./ControlInformations";
import { renderActivityChanges } from "./RenderSubItemChanges";
import { WorkpackageHeader } from "./WorkpackageHeader";
import { useApiClient, useAtom, VersionedHistory } from "@project/api";
import { WorkpackageInfoForm } from "../generated/forms/WorkpackageInfo.form";
import { ScopeAwareLink } from "./reusable/ScopeAwareLink";

export interface WorkPackageViewProps {
  code?: string;
  readonly?: boolean;
}

const WorkpackageView = memo(({ code, readonly }: WorkPackageViewProps) => {
  const params = useParams<{ code: string }>();
  if (!code) {
    code = params.code;
  }

  const projectUsersById = useAtom(
    Atoms.ProjectMembers,
    ({ entries }) => entries,
  );

  const allLatestWorkPackages = useWorkPackages();
  const [showHistory, setShowHistory] = useState(false);

  const [responsibleUserOptions, setResponsibleUserOptions] = useState<
    DefaultOptionType[]
  >(
    projectUsersById
      ? getFilteredProjectUserOptions(
          projectUsersById,
          EapProjectRole.Responsible,
        )
      : [],
  );

  const [commissionerOptions, setCommissionerOptions] = useState<
    DefaultOptionType[]
  >(
    projectUsersById
      ? getFilteredProjectUserOptions(
          projectUsersById,
          EapProjectRole.Commissioner,
        )
      : [],
  );

  const [involvedUserOptions, setInvolvedUserOptions] = useState<
    DefaultOptionType[]
  >(projectUsersById ? getFilteredProjectUserOptions(projectUsersById) : []);

  // we have to handle the value of the project user input explicitely, because antd internal handling breaks is not properly resettable
  const [projectUserFilterValue, setProjectUserFilterValue] = useState<
    string | undefined
  >();
  const [commissionerFilterValue, setCommissionerFilterValue] = useState<
    string | undefined
  >();
  useEffect(() => {
    if (!projectUsersById) {
      return;
    }
    setResponsibleUserOptions(
      getFilteredProjectUserOptions(
        projectUsersById,
        EapProjectRole.Responsible,
        projectUserFilterValue,
      ),
    );
  }, [projectUsersById, projectUserFilterValue]);

  useEffect(() => {
    if (!projectUsersById) {
      return;
    }
    const options = getFilteredProjectUserOptions(
      projectUsersById,
      EapProjectRole.Commissioner,
      commissionerFilterValue,
    );
    setCommissionerOptions(options);
  }, [projectUsersById, commissionerFilterValue]);
  useEffect(() => {
    if (!projectUsersById) {
      return;
    }
    const involved = getFilteredProjectUserOptions(projectUsersById);
    setInvolvedUserOptions(involved);
  }, [projectUsersById]);

  useSetTitle(code);

  // reset project user filters
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // @todo
  // if (
  //   workPackageFromBackend === null ||
  //   workPackageFromBackend?.deleted === true
  // ) {
  //   return (
  //     <Content>
  //       <Result
  //         status="404"
  //         title={
  //           workPackageFromBackend === null
  //             ? "Unbekanntes Arbeitspaket"
  //             : "Gelöschtes Arbeitspaket"
  //         }
  //         subTitle="Dieses Arbeitspaket existiert nicht."
  //         extra={
  //           <Button type="primary" onClick={() => navigate("/")}>
  //             Zurück
  //           </Button>
  //         }
  //       />
  //     </Content>
  //   );
  // }
  const api = useApiClient();
  return (
    <>
      <AtomNotFoundBoundary
        title="Unbekanntes Arbeitspaket"
        subTitle="Dieses Arbeitspaket existiert nicht."
      >
        <WorkpackageInfoForm
          onSubmit={async (changed) => {
            await api.Workpackage.update(changed);
          }}
          labels={workpackageLabels}
        >
          <Drawer
            title="Änderungsverlauf"
            placement="right"
            open={showHistory}
            onClose={() => setShowHistory(false)}
            size="large"
            destroyOnClose
          >
            <VersionedHistory
              name="Arbeitspaket"
              atomType={Atoms.WorkpackageInfo}
              subItem={{ key: "activities", render: renderActivityChanges }}
              labels={workpackageLabels}
            />
          </Drawer>
          <FloatButton.Group shape="square" style={{ right: 24 }}>
            <FloatButton.BackTop icon={<FontAwesomeIcon icon={faArrowUp} />} />
          </FloatButton.Group>
          <Content className="mb-10">
            <div className="workPackageEditor printable">
              <WorkpackageHeader
                readonly={readonly}
                showHistory={showHistory}
                setShowHistory={setShowHistory}
              />
              <Row gutter={[10, 10]} className="pt-4">
                <Col span={24}>
                  <WorkpackageInfoForm.TextareaOrDiv field="description" />
                </Col>
                <Col span={24}>
                  <WorkpackageInfoForm.TextareaOrDiv field="objective" />
                </Col>
                <Col span={24}>
                  <WorkpackageInfoForm.TextareaOrDiv field="goals" />
                </Col>

                <Col flex={1}>
                  <Row gutter={[10, 10]}>
                    <Col xs={24} md={6}>
                      <WorkpackageInfoForm.DatePicker field="plannedStart" />
                      <WorkpackageInfoForm.DatePicker field="plannedFinish" />
                    </Col>
                    <Col xs={24} md={6}>
                      <WorkpackageInfoForm.UserSelector
                        field="commissionerUserId"
                        options={commissionerOptions}
                      />
                      <WorkpackageInfoForm.UserSelector
                        field="responsibleUserId"
                        options={responsibleUserOptions}
                      />
                    </Col>
                    <Col xs={24} md={6}>
                      <WorkpackageInfoForm.MultiUserSelector
                        field="involvedUserIds"
                        options={involvedUserOptions}
                      />
                    </Col>
                    <Col xs={24} md={6}>
                      <WorkpackageInfoForm.AutoComplete
                        field="dependentWPs"
                        options={
                          allLatestWorkPackages?.map((wp) => ({
                            key: wp.code,
                            value: wp.code,
                            label: wp.label,
                          })) ?? []
                        }
                        tagRender={({ label, value, closable, onClose }) => (
                          <Tag
                            key={`${value}_${label}`}
                            onMouseDown={(evt) => {
                              evt.preventDefault();
                              evt.stopPropagation();
                            }}
                            closable={closable}
                            onClose={onClose}
                            style={{
                              marginRight: 3,
                              display: "inline-flex",
                              maxWidth: "100%",
                            }}
                          >
                            <span
                              style={{
                                maxWidth: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <ScopeAwareLink to={`/-/p/${value}`}>
                                {label}
                              </ScopeAwareLink>
                            </span>
                          </Tag>
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <ControlInformations />
              <ActivityTable readonly={readonly} className="mt-6" />
            </div>
          </Content>
        </WorkpackageInfoForm>
      </AtomNotFoundBoundary>
    </>
  );
});

export default () => {
  const params = useParams<{ code: string }>();
  return <WorkpackageView code={params.code} key={params.code} />;
};
