import { Button, Progress } from "antd";
import useModal from "antd/es/modal/useModal";
import {
  type ReactElement,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

export const useProgressModal = () => {
  const [modal, modalRender] = useModal();
  const [modalInstance, setModalInstance] = useState<ReturnType<
    typeof modal.info
  > | null>(null);

  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState<ReactElement>();
  const [open, setOpen] = useState(false);
  const abortController = useRef(new AbortController());

  const renderModalContent = useCallback(
    (progress: number, step?: ReactElement) => (
      <div>
        {step}
        <Progress percent={Math.round(progress)} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          <Button
            onClick={() => {
              setOpen(false);
              abortController.current.abort();
            }}
          >
            Abbrechen
          </Button>
        </div>
      </div>
    ),
    [],
  );

  useLayoutEffect(() => {
    if (!open) {
      setProgress(0);
      setStep(undefined);
      abortController.current = new AbortController();

      return;
    }

    const instance = modal.info({
      title: "Fortschritt",
      content: renderModalContent(0, step),
      footer: null,
      closable: false,
      maskClosable: false,
      keyboard: false,
      width: 500,
    });

    setModalInstance(instance);

    return () => instance.destroy();
  }, [modal, open]);

  useLayoutEffect(() => {
    if (!modalInstance || !open) return;

    modalInstance.update({
      content: renderModalContent(progress, step),
    });
  }, [step, progress, modalInstance]);

  return [modalRender, setProgress, setStep, setOpen, abortController] as const;
};
