import { DraftItemType } from "@project/shared";

export const DraftColors: Record<DraftItemType, string> = {
  [DraftItemType.AddCategory]: "green",
  [DraftItemType.AddWorkpackage]: "green",
  [DraftItemType.DeleteCategory]: "red",
  [DraftItemType.DeleteWorkpackage]: "red",
  [DraftItemType.UpdateWorkpackageTitle]: "blue",
  [DraftItemType.UpdateCategoryTitle]: "blue",
  [DraftItemType.RestoreWorkpackage]: "turquoise",
  [DraftItemType.RestoreCategory]: "turquoise",
  [DraftItemType.MoveCategory]: "lightBlue",
  [DraftItemType.MoveWorkpackage]: "lightBlue",
};
export const DraftFillPatterns: Record<DraftItemType, string> = {
  [DraftItemType.AddCategory]: "url(#addPattern) lightGreen",
  [DraftItemType.AddWorkpackage]: "url(#addPattern) lightGreen",
  [DraftItemType.DeleteCategory]: "url(#deletePattern) salmon",
  [DraftItemType.DeleteWorkpackage]: "url(#deletePattern) salmon",
  [DraftItemType.UpdateWorkpackageTitle]: "url(#updatePattern) lightBlue",
  [DraftItemType.UpdateCategoryTitle]: "url(#updatePattern) lightBlue",
  [DraftItemType.RestoreWorkpackage]: "url(#restorePattern) paleTurquoise",
  [DraftItemType.RestoreCategory]: "url(#restorePattern) paleTurquoise",
  [DraftItemType.MoveCategory]: "url(#movePattern) lightBlue",
  [DraftItemType.MoveWorkpackage]: "url(#movePattern) lightBlue",
};
export const DraftFillHoverColors: Record<DraftItemType, string> = {
  [DraftItemType.AddCategory]: "lightGreen",
  [DraftItemType.AddWorkpackage]: "lightGreen",
  [DraftItemType.DeleteCategory]: "salmon",
  [DraftItemType.DeleteWorkpackage]: "salmon",
  [DraftItemType.UpdateWorkpackageTitle]: "lightBlue",
  [DraftItemType.UpdateCategoryTitle]: "lightBlue",
  [DraftItemType.RestoreWorkpackage]: "paleTurquoise",
  [DraftItemType.RestoreCategory]: "paleTurquoise",
  [DraftItemType.MoveCategory]: "lightBlue",
  [DraftItemType.MoveWorkpackage]: "lightBlue",
};
