import { __useAtom } from "@atoms/atom-client";
import { type AtomDescriptor, parseAtomDescriptor } from "@atoms/atom-types";

export const ReferencedAtom = ({ path }: { path: AtomDescriptor }) => {
  const { type, context } = parseAtomDescriptor(path);
  if (!type) {
    return <div>Invalid atom path</div>;
  }
  const atom = __useAtom(type, context);
  return <div title={JSON.stringify(atom)}>{path}</div>;
};

export const ReferencedAtoms = ({ pathes }: { pathes: AtomDescriptor[] }) => {
  return (
    <>
      {pathes.map((path) => (
        <ReferencedAtom key={path} path={path} />
      ))}
    </>
  );
};
