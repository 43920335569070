/*
 * This file has been generated by apiClient
 * Hash: 7a41806d63788047c1a11215f8a766266978da86434767a54ec10470cdfc9c98
 */

import type { ApolloClient } from "@atoms/atom-client";
import { makeUseApiClient } from "@atoms/atom-client";
import type {
  ContextValues,
  UserPublicID,
  VersionedAtomData,
  WithDefaultValues,
} from "@atoms/atom-types";
import gql from "graphql-tag";
import type {
  EapOrganizationRole,
  EapProjectRole,
  IActivity,
  IBookedLicenses,
  ICategory,
  IDraft,
  IOrganizationInfo,
  IOrganizationMembership,
  IProjectInfo,
  IProjectMembership,
  ITemplateInfo,
  ITodoList,
  IVersion,
  IWorkpackage,
} from "..";

export const APIClient = {
  Organization: {
    addUserToByEmail: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        email: string,
        name: string,
        roles: Array<EapOrganizationRole>,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            addUserToOrganizationByEmail: Promise<boolean>;
          }>({
            mutation: gql`
mutation addUserToOrganizationByEmail($context: JSON!, $email: String!, $name: String!, $roles: [EapOrganizationRole!]!) {
  addUserToOrganizationByEmail(context: $context, email: $email, name: $name, roles: $roles)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              email,
              name,
              roles,
            },
          });
          return res!.addUserToOrganizationByEmail;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    createProject: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        projectInfo: IProjectInfo,
        extraContext?: ContextValues,
      ): Promise<string> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            createProject: Promise<string>;
          }>({
            mutation: gql`
mutation createProject($context: JSON!, $projectInfo: JSON!) {
  createProject(context: $context, projectInfo: $projectInfo)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              projectInfo,
            },
          });
          return res!.createProject;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    updateBookedLicenses: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        update: Partial<IBookedLicenses>,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            updateBookedLicenses: Promise<boolean>;
          }>({
            mutation: gql`
mutation updateBookedLicenses($context: JSON!, $update: JSON!) {
  updateBookedLicenses(context: $context, update: $update)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              update,
            },
          });
          return res!.updateBookedLicenses;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    update: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        update: Partial<IOrganizationInfo>,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            updateOrganizationInfo: Promise<boolean>;
          }>({
            mutation: gql`
mutation updateOrganizationInfo($context: JSON!, $update: JSON!) {
  updateOrganizationInfo(context: $context, update: $update)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              update,
            },
          });
          return res!.updateOrganizationInfo;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    updateMembersEntry: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        key: UserPublicID,
        newValue: IOrganizationMembership,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            updateOrganizationMembersEntry: Promise<boolean>;
          }>({
            mutation: gql`
mutation updateOrganizationMembersEntry($context: JSON!, $key: JSON!, $newValue: JSON!) {
  updateOrganizationMembersEntry(context: $context, key: $key, newValue: $newValue)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              key,
              newValue,
            },
          });
          return res!.updateOrganizationMembersEntry;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },
  },

  Project: {
    addCategoryDraft: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        parentCode: string,
        extraContext?: ContextValues,
      ): Promise<string> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            addCategoryDraft: Promise<string>;
          }>({
            mutation: gql`
mutation addCategoryDraft($context: JSON!, $parentCode: String!) {
  addCategoryDraft(context: $context, parentCode: $parentCode)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              parentCode,
            },
          });
          return res!.addCategoryDraft;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    addToMembers: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        key: UserPublicID,
        newValue: IProjectMembership,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            addToProjectMembers: Promise<boolean>;
          }>({
            mutation: gql`
mutation addToProjectMembers($context: JSON!, $key: JSON!, $newValue: JSON!) {
  addToProjectMembers(context: $context, key: $key, newValue: $newValue)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              key,
              newValue,
            },
          });
          return res!.addToProjectMembers;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    addUserToByEmail: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        email: string,
        name: string,
        roles: Array<EapProjectRole>,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            addUserToProjectByEmail: Promise<boolean>;
          }>({
            mutation: gql`
mutation addUserToProjectByEmail($context: JSON!, $email: String!, $name: String!, $roles: [EapProjectRole!]!) {
  addUserToProjectByEmail(context: $context, email: $email, name: $name, roles: $roles)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              email,
              name,
              roles,
            },
          });
          return res!.addUserToProjectByEmail;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    addWorkpackageDraft: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        parentCode: string,
        extraContext?: ContextValues,
      ): Promise<string> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            addWorkpackageDraft: Promise<string>;
          }>({
            mutation: gql`
mutation addWorkpackageDraft($context: JSON!, $parentCode: String!) {
  addWorkpackageDraft(context: $context, parentCode: $parentCode)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              parentCode,
            },
          });
          return res!.addWorkpackageDraft;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    changeDraftCode: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        code: string,
        newCode: string,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            changeDraftCode: Promise<boolean>;
          }>({
            mutation: gql`
mutation changeDraftCode($context: JSON!, $code: String!, $newCode: String!) {
  changeDraftCode(context: $context, code: $code, newCode: $newCode)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              code,
              newCode,
            },
          });
          return res!.changeDraftCode;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    changeDraftDescription: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        code: string,
        newDescription: string,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            changeDraftDescription: Promise<boolean>;
          }>({
            mutation: gql`
mutation changeDraftDescription($context: JSON!, $code: String!, $newDescription: String!) {
  changeDraftDescription(context: $context, code: $code, newDescription: $newDescription)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              code,
              newDescription,
            },
          });
          return res!.changeDraftDescription;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    changeDraftLabel: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        code: string,
        newLabel: string,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            changeDraftLabel: Promise<boolean>;
          }>({
            mutation: gql`
mutation changeDraftLabel($context: JSON!, $code: String!, $newLabel: String!) {
  changeDraftLabel(context: $context, code: $code, newLabel: $newLabel)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              code,
              newLabel,
            },
          });
          return res!.changeDraftLabel;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    deleteAllDrafts: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            deleteAllDrafts: Promise<boolean>;
          }>({
            mutation: gql`
mutation deleteAllDrafts($context: JSON!) {
  deleteAllDrafts(context: $context)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
            },
          });
          return res!.deleteAllDrafts;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    pushDraft: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        data: IDraft,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            pushDraft: Promise<boolean>;
          }>({
            mutation: gql`
mutation pushDraft($context: JSON!, $data: JSON!) {
  pushDraft(context: $context, data: $data)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              data,
            },
          });
          return res!.pushDraft;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    removeDraft: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        data: Partial<IDraft>,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            removeDraft: Promise<boolean>;
          }>({
            mutation: gql`
mutation removeDraft($context: JSON!, $data: JSON!) {
  removeDraft(context: $context, data: $data)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              data,
            },
          });
          return res!.removeDraft;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    removeFromMembers: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        key: UserPublicID,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            removeFromProjectMembers: Promise<boolean>;
          }>({
            mutation: gql`
mutation removeFromProjectMembers($context: JSON!, $key: JSON!) {
  removeFromProjectMembers(context: $context, key: $key)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              key,
            },
          });
          return res!.removeFromProjectMembers;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    restoreBackup: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        backup: string,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            restoreBackup: Promise<boolean>;
          }>({
            mutation: gql`
mutation restoreBackup($context: JSON!, $backup: String!) {
  restoreBackup(context: $context, backup: $backup)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              backup,
            },
          });
          return res!.restoreBackup;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    setDraft: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        draft: Array<IDraft>,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            setDraft: Promise<boolean>;
          }>({
            mutation: gql`
mutation setDraft($context: JSON!, $draft: [JSON!]!) {
  setDraft(context: $context, draft: $draft)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              draft,
            },
          });
          return res!.setDraft;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    update: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        update: Partial<IProjectInfo>,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            updateProjectInfo: Promise<boolean>;
          }>({
            mutation: gql`
mutation updateProjectInfo($context: JSON!, $update: JSON!) {
  updateProjectInfo(context: $context, update: $update)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              update,
            },
          });
          return res!.updateProjectInfo;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    updateMembersEntry: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        key: UserPublicID,
        newValue: IProjectMembership,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            updateProjectMembersEntry: Promise<boolean>;
          }>({
            mutation: gql`
mutation updateProjectMembersEntry($context: JSON!, $key: JSON!, $newValue: JSON!) {
  updateProjectMembersEntry(context: $context, key: $key, newValue: $newValue)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              key,
              newValue,
            },
          });
          return res!.updateProjectMembersEntry;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },
  },

  Global: {
    createOrganization: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        bookedLicenses: WithDefaultValues<
          IBookedLicenses,
          "deleted" | "licenses"
        >,
        organizationInfo: IOrganizationInfo,
        extraContext?: ContextValues,
      ): Promise<string> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            createOrganization: Promise<string>;
          }>({
            mutation: gql`
mutation createOrganization($context: JSON!, $bookedLicenses: JSON!, $organizationInfo: JSON!) {
  createOrganization(context: $context, bookedLicenses: $bookedLicenses, organizationInfo: $organizationInfo)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              bookedLicenses,
              organizationInfo,
            },
          });
          return res!.createOrganization;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    nukeAllData: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            nukeAllData: Promise<boolean>;
          }>({
            mutation: gql`
mutation nukeAllData($context: JSON!) {
  nukeAllData(context: $context)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
            },
          });
          return res!.nukeAllData;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },
  },

  Workpackage: {
    freeze: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            freezeWorkpackage: Promise<boolean>;
          }>({
            mutation: gql`
mutation freezeWorkpackage($context: JSON!) {
  freezeWorkpackage(context: $context)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
            },
          });
          return res!.freezeWorkpackage;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    readActivities: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        extraContext?: ContextValues,
      ): Promise<Record<string, IActivity>> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            readActivities: Promise<Record<string, IActivity>>;
          }>({
            mutation: gql`
mutation readActivities($context: JSON!) {
  readActivities(context: $context)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
            },
          });
          return res!.readActivities;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    read: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        extraContext?: ContextValues,
      ): Promise<VersionedAtomData<IWorkpackage> | null> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            readWorkpackage2: Promise<VersionedAtomData<IWorkpackage> | null>;
          }>({
            mutation: gql`
mutation readWorkpackage2($context: JSON!) {
  readWorkpackage2(context: $context)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
            },
          });
          return res!.readWorkpackage2;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    unfreeze: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            unfreezeWorkpackage: Promise<boolean>;
          }>({
            mutation: gql`
mutation unfreezeWorkpackage($context: JSON!) {
  unfreezeWorkpackage(context: $context)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
            },
          });
          return res!.unfreezeWorkpackage;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    update: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        update: Partial<IWorkpackage>,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            updateWorkpackageInfo: Promise<boolean>;
          }>({
            mutation: gql`
mutation updateWorkpackageInfo($context: JSON!, $update: JSON!) {
  updateWorkpackageInfo(context: $context, update: $update)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              update,
            },
          });
          return res!.updateWorkpackageInfo;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },
  },

  Category: {
    create: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        categoryInfo: WithDefaultValues<ICategory, "description">,
        extraContext?: ContextValues,
      ): Promise<string> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            createCategory: Promise<string>;
          }>({
            mutation: gql`
mutation createCategory($context: JSON!, $categoryInfo: JSON!) {
  createCategory(context: $context, categoryInfo: $categoryInfo)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              categoryInfo,
            },
          });
          return res!.createCategory;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    update: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        update: Partial<ICategory>,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            updateCategoryInfo: Promise<boolean>;
          }>({
            mutation: gql`
mutation updateCategoryInfo($context: JSON!, $update: JSON!) {
  updateCategoryInfo(context: $context, update: $update)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              update,
            },
          });
          return res!.updateCategoryInfo;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },
  },

  Version: {
    createFromDrafts: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        info: Omit<IVersion, "author" | "date">,
        omitDrafts: Array<string>,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            createVersionFromDrafts: Promise<boolean>;
          }>({
            mutation: gql`
mutation createVersionFromDrafts($context: JSON!, $info: JSON!, $omitDrafts: [String!]!) {
  createVersionFromDrafts(context: $context, info: $info, omitDrafts: $omitDrafts)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              info,
              omitDrafts,
            },
          });
          return res!.createVersionFromDrafts;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },
  },

  Template: {
    create: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        templateInfo: ITemplateInfo,
        extraContext?: ContextValues,
      ): Promise<string> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            createTemplate: Promise<string>;
          }>({
            mutation: gql`
mutation createTemplate($context: JSON!, $templateInfo: JSON!) {
  createTemplate(context: $context, templateInfo: $templateInfo)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              templateInfo,
            },
          });
          return res!.createTemplate;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    read: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        extraContext?: ContextValues,
      ): Promise<ITemplateInfo | null> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            readTemplateInfo2: Promise<ITemplateInfo | null>;
          }>({
            mutation: gql`
mutation readTemplateInfo2($context: JSON!) {
  readTemplateInfo2(context: $context)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
            },
          });
          return res!.readTemplateInfo2;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    update: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        update: Partial<ITemplateInfo>,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            updateTemplateInfo: Promise<boolean>;
          }>({
            mutation: gql`
mutation updateTemplateInfo($context: JSON!, $update: JSON!) {
  updateTemplateInfo(context: $context, update: $update)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              update,
            },
          });
          return res!.updateTemplateInfo;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },
  },

  TodoList: {
    create: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        todoListInfo: WithDefaultValues<
          ITodoList,
          | "deleted"
          | "dependentWbsElements"
          | "description"
          | "done"
          | "involvedUserIds"
          | "label"
          | "responsibleUserId"
          | "todos"
        >,
        extraContext?: ContextValues,
      ): Promise<string> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            createTodoList: Promise<string>;
          }>({
            mutation: gql`
mutation createTodoList($context: JSON!, $todoListInfo: JSON!) {
  createTodoList(context: $context, todoListInfo: $todoListInfo)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              todoListInfo,
            },
          });
          return res!.createTodoList;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    delete: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            deleteTodoList: Promise<boolean>;
          }>({
            mutation: gql`
mutation deleteTodoList($context: JSON!) {
  deleteTodoList(context: $context)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
            },
          });
          return res!.deleteTodoList;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    update: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        update: Partial<ITodoList>,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            updateTodoListInfo: Promise<boolean>;
          }>({
            mutation: gql`
mutation updateTodoListInfo($context: JSON!, $update: JSON!) {
  updateTodoListInfo(context: $context, update: $update)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              update,
            },
          });
          return res!.updateTodoListInfo;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },
  },

  AgentSession: {
    changeTemplate: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        templateName: string,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            changeTemplate: Promise<boolean>;
          }>({
            mutation: gql`
mutation changeTemplate($context: JSON!, $templateName: String!) {
  changeTemplate(context: $context, templateName: $templateName)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              templateName,
            },
          });
          return res!.changeTemplate;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    confirmToolCall: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        toolCallId: string,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            confirmToolCall: Promise<boolean>;
          }>({
            mutation: gql`
mutation confirmToolCall($context: JSON!, $toolCallId: String!) {
  confirmToolCall(context: $context, toolCallId: $toolCallId)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              toolCallId,
            },
          });
          return res!.confirmToolCall;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    createSession: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        agentName: string,
        extraContext?: ContextValues,
      ): Promise<string> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            createSession: Promise<string>;
          }>({
            mutation: gql`
mutation createSession($context: JSON!, $agentName: String!) {
  createSession(context: $context, agentName: $agentName)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              agentName,
            },
          });
          return res!.createSession;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    promptSession: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            promptSession: Promise<boolean>;
          }>({
            mutation: gql`
mutation promptSession($context: JSON!) {
  promptSession(context: $context)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
            },
          });
          return res!.promptSession;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },

    sendMessage: {
      handler: async (
        apolloClient: ApolloClient<any>,
        onError: (error: any) => void,
        context: ContextValues,
        content: string,
        extraContext?: ContextValues,
      ): Promise<boolean> => {
        try {
          const { data: res } = await apolloClient.mutate<{
            sendMessage: Promise<boolean>;
          }>({
            mutation: gql`
mutation sendMessage($context: JSON!, $content: String!) {
  sendMessage(context: $context, content: $content)
}`,
            fetchPolicy: "no-cache",
            variables: {
              context: { ...context, ...extraContext },
              content,
            },
          });
          return res!.sendMessage;
        } catch (error) {
          onError(error);
          throw error;
        }
      },
    },
  },
};

export const useApiClient = makeUseApiClient(APIClient);
