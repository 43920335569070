import {
  AtomFrameworkProvider,
  FrontendProvisionProvider,
} from "@atoms/atom-client";
import { ProjectProvision } from "@project/frontend-provision";
import { atomInfos } from "@project/shared";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ErrorBoundary key="root">
      <FrontendProvisionProvider provision={ProjectProvision}>
        <AtomFrameworkProvider
          splashScreen={ProjectProvision.splashScreen}
          atomInfos={atomInfos}
        >
          {ProjectProvision.appComponent}
        </AtomFrameworkProvider>
      </FrontendProvisionProvider>
    </ErrorBoundary>
  </StrictMode>,
);
