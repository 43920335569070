import { DefaultIdentifiers } from "@atoms/atom-types";
import { SetExtraContext, useAtom } from "@project/api";
import { useMyUserId } from "@project/api";
import { Atoms, Identifiers } from "@project/shared";
import { Card, Col, Empty, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useSetTitle } from "../hooks/useSetTitle";
import { EapErrorBoundary } from "./EapErrorBoundary";
import { ProjectPreview } from "./ProjectPreview";
import { useActiveProject } from "../hooks/useScopeAwareNavigate";
import { useLocation, useNavigate } from "react-router-dom";
import { buildProjectRoutePath, ProjectRoutePaths } from "./ProjectRoutes";

const { Title, Paragraph } = Typography;

export const MyProjects = () => {
  useSetTitle("Projekte");

  const [searchTerm, setSearchTerm] = useState("");
  const myUserId = useMyUserId();
  const { isDefaultProject, projectId, organizationId } = useActiveProject();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (isDefaultProject && projectId && organizationId && pathname === "/") {
      navigate(
        buildProjectRoutePath(
          projectId,
          organizationId,
          ProjectRoutePaths.Home,
        ),
        { replace: true },
      );
    }
  }, [isDefaultProject, projectId, organizationId, pathname, navigate]);

  const projects =
    useAtom(
      Atoms.UserRoles,
      (userRoles) =>
        Object.entries(userRoles.orgs).flatMap(([organizationId, org]) =>
          Object.keys(org.projects).map((projectId) => ({
            projectId,
            organizationId,
          })),
        ),
      { [DefaultIdentifiers.UserId]: myUserId },
    ) ?? [];

  return (
    <Content>
      <Title level={2} className="flex-grow">
        Projektübersicht
      </Title>
      <Paragraph>
        Hier können Sie alle Ihre Projekte einsehen und verwalten.
      </Paragraph>
      <Row gutter={[16, 16]} className="mt-4">
        {projects.length === 0 ? (
          <Col span={24}>
            <Card>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    {searchTerm
                      ? "Keine Projekte gefunden."
                      : "Sie haben derzeit keine Projekte."}
                  </span>
                }
              />
            </Card>
          </Col>
        ) : (
          <>
            {projects?.map(({ projectId, organizationId }) => (
              <Col
                key={organizationId + projectId}
                xs={24}
                sm={16}
                md={12}
                lg={8}
                xl={6}
                xxl={4}
              >
                <EapErrorBoundary key={projectId}>
                  <SetExtraContext
                    context={{
                      [Identifiers.OrganizationId]: organizationId,
                      [Identifiers.ProjectId]: projectId,
                    }}
                  >
                    <ProjectPreview
                      organizationId={organizationId}
                      projectId={projectId}
                    />
                  </SetExtraContext>
                </EapErrorBoundary>
              </Col>
            ))}
          </>
        )}
      </Row>
    </Content>
  );
};
