import { __getUsernameResolver } from "@atoms/atom-client";
import {
  type AtomData,
  GenericPermission,
  type UserPublicID,
} from "@atoms/atom-types";
import { AutoComplete } from "antd";
import type { DefaultOptionType } from "antd/es/cascader";
import SkeletonInput from "antd/es/skeleton/Input";
import { useContext, useEffect, useState } from "react";
import { FloatLabel } from "./FloatLabel";
import { MagicFormContext, useLabel } from "./MagicForm";

export interface UserSelectorProps<AtomType extends AtomData = AtomData> {
  readonly?: boolean;
  field: keyof AtomType & string;
  options: DefaultOptionType[];
  className?: string;
}

export const MagicUserSelector = <AtomType extends AtomData = AtomData>({
  readonly,
  field,
  options,
  className,
}: UserSelectorProps<AtomType>) => {
  const { updateDraft, loading, currentValue, checkFieldPermission } =
    useContext(MagicFormContext)!;
  const getUsername = __getUsernameResolver();
  const mayUpdate = checkFieldPermission(field, GenericPermission.Update);
  const label = useLabel(field);
  const [filterValue, setFilterValue] = useState<string | undefined>(undefined);
  const [username, setUsername] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (currentValue[field]) {
      getUsername(currentValue[field], "Unbekannter Nutzer").then(setUsername);
    }
  }, [currentValue[field], getUsername]);
  return loading ? (
    <SkeletonInput active style={{ height: 48, marginBottom: "10px" }} block />
  ) : (
    <FloatLabel
      key={`${field}-selector`}
      label={label}
      value={username}
      style={{ marginBottom: "10px" }}
      className="print-bordered"
    >
      <AutoComplete
        className={`${className} mus-label-${label.replace(/\s/g, "-")}`}
        allowClear
        key={`${field}_input_commissioner`}
        value={username}
        disabled={readonly || !mayUpdate}
        options={options}
        onChange={(value) => {
          setFilterValue(value);
          if (value === "" && currentValue[field] !== null) {
            updateDraft({
              [field]: null,
            });
          }
        }}
        onSelect={(value) => {
          setFilterValue(undefined);
          updateDraft({
            [field]: value as UserPublicID,
          });
        }}
        onClear={() => {
          setFilterValue(undefined);
          updateDraft({
            [field]: null,
          });
        }}
      />
    </FloatLabel>
  );
};
