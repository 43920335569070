import {
  DefaultAtoms,
  DefaultIdentifiers,
  type IPrivateUserData,
  type UserPublicID,
} from "@atoms/atom-types";
import { __useAtom } from "../makeUseAtom";

/**
 * A hook that returns the default username.
 * This is used to set the username in the Apollo cache.
 * By default, the username is fetched from personaluserdataatom.
 * @returns The default username.
 */
export const __useDefaultUsername = (userId: UserPublicID): string => {
  const personalUserData = __useAtom<IPrivateUserData>(
    DefaultAtoms.PersonalUserData,
    {
      [DefaultIdentifiers.UserId]: userId,
    },
  );
  return personalUserData?.displayName ?? "unknown user";
};
