import { ApolloLink } from "@apollo/client";
import { isArray, isPlainObject, mapValues } from "lodash";

export const mapValuesDeep = <ObjectT extends Record<string, any>>(
  obj: ObjectT,
  fn: (o: ObjectT) => ObjectT,
): any[] | object => {
  if (isArray(obj)) {
    return obj.map((e): any => mapValuesDeep(e, fn));
  }
  return isPlainObject(obj)
    ? mapValues(obj as unknown as object, (val): any => {
        return mapValuesDeep(val, fn);
      })
    : fn(obj);
};

export function isDateString(value: any): boolean {
  return (
    typeof value === "string" &&
    value.length >= 24 &&
    value.length <= 29 &&
    value.match(
      /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?([+-][0-2]\d:[0-5]\d|Z)/,
    ) !== null
  );
}
export const RehydrateDatesLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response.data) {
      const r = mapValuesDeep(response.data, (value: any) =>
        isDateString(value) ? new Date(value) : value,
      );
      response.data = r;
    }
    return response;
  });
});
