/*
 * This file has been generated by permissions
 * Hash: 66e0e2a0aae353da429b39871ca9a415a0b167e0131981c2b6df61ef77aa373b
 */

export enum WorkpackagePermissions {
  Create = "CreateWorkpackage",
  Delete = "DeleteWorkpackage",
  Read = "ReadWorkpackage",
  Update = "UpdateWorkpackage",
  ReadInfo = "ReadInfo",
  UpdateInfo = "UpdateInfo",
}
