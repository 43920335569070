import {
  ApolloClient,
  type ApolloClientOptions,
  ApolloLink,
  type FetchResult,
  HttpLink,
  type HttpOptions,
  InMemoryCache,
  type NormalizedCacheObject,
  Observable,
  type Operation,
  type RequestHandler,
  type ServerParseError,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ErrorLink } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { getMainDefinition } from "@apollo/client/utilities";
import { default as ApolloLinkTimeout } from "apollo-link-timeout";
import EventEmitter from "eventemitter3";
import { print } from "graphql";
import { type Client, type ClientOptions, createClient } from "graphql-ws";
import { isArray, isPlainObject, mapValues, merge } from "lodash";
import { LoggerWrapper } from "./logger";
export type { ApolloClient };
const logger = new LoggerWrapper("ApolloConnection");

const mapValuesDeep = (obj: object, fn: any): any => {
  if (isArray(obj)) {
    return obj.map((e) => mapValuesDeep(e, fn));
  }
  return isPlainObject(obj)
    ? mapValues(obj, (val) => {
        return mapValuesDeep(val, fn);
      })
    : fn(obj);
};

function isDateString(value: any): boolean {
  return (
    typeof value === "string" &&
    value.length >= 24 &&
    value.length <= 29 &&
    value.match(
      /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?([+-][0-2]\d:[0-5]\d|Z)/,
    ) !== null
  );
}

const rehydrateDatesLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response.data) {
      const r = mapValuesDeep(response.data, (value: any) =>
        isDateString(value) ? new Date(value) : value,
      );
      response.data = r;
    }
    return response;
  });
});

export type ApolloOptions = {
  client: Omit<ApolloClientOptions<NormalizedCacheObject>, "cache" | "link">;
  link: {
    http: HttpOptions;
    ws: ClientOptions;
    timeout: {
      timeout: number;
      statusCode?: number;
    };
    retry: RetryLink.Options;
  };
};
export type ApolloOptionsOverride = Partial<{
  client: Partial<
    Omit<ApolloClientOptions<NormalizedCacheObject>, "cache" | "link">
  >;
  link: Partial<{
    http: Partial<HttpOptions>;
    ws: Partial<ClientOptions>;
    timeout: {
      timeout: number;
      statusCode?: number;
    };
    retry: Partial<RetryLink.Options>;
  }>;
}>;

export enum ApolloEvent {
  GRAPHQL_ERROR = "graphql_error",
  NETWORK_ERROR = "network_error",
  WS_INTERNAL_ERROR = "ws_internal_error",
  WS_CONNECTING = "ws_connecting",
  WS_CONNECTED = "ws_connected",
  WS_CLOSED = "ws_closed",
  WS_TERMINATED = "ws_terminated",
  WS_GENERAL_ERROR = "ws_general_error",
  WS_NETWORK_ERROR = "ws_network_error",
  WS_GRAPHQL_ERROR = "ws_graphql_error",
}

export enum WebsocketCloseCode {
  InternalServerError = 4500,
  InternalClientError = 4005,
  BadRequest = 4400,
  BadResponse = 4004,
  /** Tried subscribing before connect ack */
  Unauthorized = 4401,
  Forbidden = 4403,
  SubprotocolNotAcceptable = 4406,
  ConnectionInitialisationTimeout = 4408,
  ConnectionAcknowledgementTimeout = 4504,
  /** Subscriber distinction is very important */
  SubscriberAlreadyExists = 4409,
  TooManyInitialisationRequests = 4429,
}

export class ApolloConnection extends EventEmitter {
  static instance?: ApolloConnection;

  private apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

  private socket: WebSocket | undefined;

  constructor() {
    if (ApolloConnection.instance) {
      // biome-ignore lint/correctness/noUnreachableSuper: <explanation>
      // biome-ignore lint/correctness/noConstructorReturn: <explanation>
      return ApolloConnection.instance;
    }

    super();

    ApolloConnection.instance = this;
  }

  public init(
    options: ApolloOptions,
    getAuthToken: () => Promise<string | null>,
  ): ApolloClient<NormalizedCacheObject> {
    if (this.getApolloClient()) {
      return this.getApolloClient()!;
    }

    const cache = new InMemoryCache();

    const authLink = setContext(async (_, { headers }) => {
      try {
        // get the authentication token from local storage if it exists
        const token = await getAuthToken();
        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : undefined,
          },
        };
      } catch (e) {
        return {};
      }
    });

    const httpLink = new HttpLink(options.link.http);

    const wsLink = new WebSocketLink({
      ...options.link.ws,
      onNonLazyError: (errorOrCloseEvent): void => {
        if (errorOrCloseEvent instanceof CloseEvent) {
          const err = errorOrCloseEvent as CloseEvent;
          this.emit(ApolloEvent.WS_NETWORK_ERROR, err);
          logger.error(
            `network error (${err.code}): ${err.reason}, clean: ${err.wasClean}`,
          );
        } else {
          const err = errorOrCloseEvent as Error;
          this.emit(ApolloEvent.WS_INTERNAL_ERROR, err);
          logger.error(`internal error: ${err.message}`);
        }
      },
      on: {
        connecting: (): void => {
          logger.debug("connecting");
          this.emit(ApolloEvent.WS_CONNECTING);
        },
        connected: (socket): void => {
          this.socket = socket as WebSocket;
          logger.debug("connected");
          this.emit(ApolloEvent.WS_CONNECTED);
        },
        closed: (e): void => {
          logger.debug("closed");
          this.emit(ApolloEvent.WS_CLOSED, e as CloseEvent);
        },
        error: (e): void => {
          if (e instanceof ErrorEvent) {
            const err = e as ErrorEvent;
            this.emit(ApolloEvent.WS_GENERAL_ERROR, err);
            logger.error(`error: ${err.message}`);
          } else if (e instanceof Error) {
            const err = e as Error;
            this.emit(ApolloEvent.WS_GENERAL_ERROR, err);
            logger.error(`error: ${e}`);
          }
        },
      },
      connectionParams: async (): Promise<{ token?: string | null }> => {
        try {
          const token = await getAuthToken();

          if (token) {
            return {
              token,
            };
          } else {
            return {};
          }
        } catch (e) {
          return {};
        }
      },
      shouldRetry: () => true,
    });

    const timeoutLink = new ApolloLinkTimeout(
      options.link.timeout.timeout,
      options.link.timeout.statusCode,
    );
    const retryLink = new RetryLink(options.link.retry);
    const httpErrorLink = new ErrorLink(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          const locs = locations
            ?.map((l) => `line: ${l.line} col: ${l.column}`)
            .join(",");
          logger.error(
            `graphql error: Message: ${message}, Location: ${locs ?? "unknown"}, Path: ${path}`,
          );
          this.emit(ApolloEvent.GRAPHQL_ERROR, message);
        });
      }
      if (networkError) {
        if ((networkError as ServerParseError).bodyText) {
          // Check if error response is JSON
          // fix for https://github.com/apollographql/apollo-feature-requests/issues/153
          try {
            JSON.parse((networkError as ServerParseError).bodyText);
          } catch (e) {
            // If not replace parsing error message with real one
            networkError.message = (networkError as ServerParseError).bodyText;
          }
        }
        logger.error(`network error: ${networkError}`);
        this.emit(ApolloEvent.NETWORK_ERROR, networkError);
      }
    });

    const subscriptionErrorLink = new ErrorLink(
      ({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            const locs = locations
              ?.map((l) => `line: ${l.line} col: ${l.column}`)
              .join(",");

            if (message === "Unauthorized") {
              return;
            }

            logger.error(
              `subscription graphql error: Message: ${message}, Location: ${locs ?? "unknown"}, Path: ${path}`,
            );
            this.emit(ApolloEvent.WS_GRAPHQL_ERROR, message);
          });
        }
        if (networkError) {
          if ((networkError as ServerParseError).bodyText) {
            // Check if error response is JSON
            // fix for https://github.com/apollographql/apollo-feature-requests/issues/153
            try {
              JSON.parse((networkError as ServerParseError).bodyText);
            } catch (e) {
              // If not replace parsing error message with real one
              networkError.message = (
                networkError as ServerParseError
              ).bodyText;
            }
          }
          if (networkError instanceof Error) {
            logger.error(
              `subscription network error: ${(networkError as Error).message}`,
            );
          }

          if (networkError instanceof CloseEvent) {
            const err = networkError as CloseEvent;
            logger.error(
              `subscription network error: socket closed with event ${err.code} ${err.reason || ""}`,
            );
          }

          this.emit(ApolloEvent.WS_NETWORK_ERROR, networkError);
        }
      },
    );

    const namedLink = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers }: any) => ({
        uri: localStorage.getItem("graphqlNames")
          ? `${options.link.http.uri}?${operation.operationName}`
          : options.link.http.uri,
        headers: {
          ...headers,
          "x-apollo-operation-name": operation.operationName,
        },
      }));
      return forward(operation);
    });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },

      ApolloLink.from([subscriptionErrorLink, rehydrateDatesLink, wsLink]),
      ApolloLink.from(
        [
          timeoutLink,
          retryLink,
          httpErrorLink,
          rehydrateDatesLink,
          namedLink,
          authLink,
          httpLink,
        ].filter(Boolean) as (ApolloLink | RequestHandler)[],
      ),
    );

    this.apolloClient = new ApolloClient({
      ...options,
      cache,
      link: splitLink,
    });

    return this.apolloClient;
  }

  public dispose(): void {
    this.apolloClient?.stop();
    // delete this.apolloClient;
  }

  public getApolloClient(): ApolloClient<NormalizedCacheObject> | undefined {
    return this.apolloClient;
  }

  public closeWebsocket(): boolean {
    if (this.socket) {
      this.socket.close(4205, "Client Restart");
      return true;
    } else {
      return false;
    }
  }
}

class WebSocketLink extends ApolloLink {
  private client: Client;

  private timedOut: ReturnType<typeof setTimeout> | undefined;

  constructor(options: ClientOptions) {
    super();

    this.client = createClient(
      merge<ClientOptions, Partial<ClientOptions>>(options, {
        on: {
          ping: (received) => {
            if (!received /* sent */) {
              this.timedOut = setTimeout(() => {
                // a close event `4499: Terminated` is issued to the current WebSocket and an
                // artificial `{ code: 4499, reason: 'Terminated', wasClean: false }` close-event-like
                // object is immediately emitted without waiting for the one coming from `WebSocket.onclose`
                //
                // calling terminate is not considered fatal and a connection retry will occur as expected
                //
                // see: https://github.com/enisdenjo/graphql-ws/discussions/290
                this.client.terminate();
              }, 5_000);
            }
          },
          pong: (received) => {
            if (received && this.timedOut) {
              clearTimeout(this.timedOut);
            }
          },
        },
      }),
    );
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink) as any,
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      );
    });
  }
}

export const apolloConnection = new ApolloConnection();

(globalThis as any).ac = apolloConnection;
