import { HttpLink } from "@apollo/client";
import { ApolloLink } from "@apollo/client/link/core/ApolloLink";

export const makeHttpLink = (uri: string) => {
  const httpLink = new HttpLink({});
  const contextLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: any) => ({
      uri: localStorage.getItem("graphqlDebug")
        ? `${uri}?${operation.operationName}`
        : uri,
      headers: {
        ...headers,
        "x-apollo-operation-name": operation.operationName,
      },
    }));
    return forward(operation);
  });

  return ApolloLink.from([contextLink, httpLink]);
};
