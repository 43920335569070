import { create } from "zustand";
import { persist, type PersistOptions } from "zustand/middleware";
import type { StateCreator } from "zustand";

export interface GenericActions<State> {
  set: <K extends keyof State>(key: K, value: State[K]) => void;
}

export const getStoreKey = (
  storeName: string,
  identifiers?: Array<string | number | undefined>,
) => {
  if (!identifiers) {
    return storeName;
  }
  return `${storeName}#${identifiers.map((id) => id?.toString()).join("/")}`;
};

export function createPersistedStore<State>(
  storeInitializer: StateCreator<State>,
  storeName: string,
  identifiers?: Array<string | number | undefined>,
  options?: Omit<PersistOptions<Partial<State>>, "name">,
) {
  const storeKey = getStoreKey(storeName, identifiers);

  return create(
    persist<State & GenericActions<State>>(
      (set, ...args) => ({
        ...storeInitializer(set, ...args),
        set: <K extends keyof State>(key: K, value: State[K]) =>
          set((state) => ({ ...state, [key]: value })),
      }),
      {
        name: storeKey,
        ...(options as Omit<
          PersistOptions<State & GenericActions<State>>,
          "name"
        >),
      },
    ),
  );
}
