import { ArrowLeftOutlined } from "@ant-design/icons";
import { AsyncButton, UserName, makeAtomTable } from "@atoms/atom-components";
import type { atomDataIndex } from "@project/api";
import { useApiClient, useAtom, useMyUserId } from "@project/api";
import { Atoms, EapProjectRole, EapProjectRoleLabel } from "@project/shared";
import { Button, Input, Select, Typography, message } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { useSetTitle } from "../hooks/useSetTitle";
import { ProjectRoleEditor } from "./ProjectRoleEditor";

const { Title } = Typography;

const EapTable = makeAtomTable<atomDataIndex>();

export const ProjectUserManager = () => {
  const api = useApiClient();
  useSetTitle("Nutzerverwaltung");

  const myUserId = useMyUserId();

  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserName, setNewUserName] = useState("");
  const [newUserRoles, setNewUserRoles] = useState<EapProjectRole[]>([]);

  const project = useAtom(Atoms.ProjectInfo);

  const handleAddUser = async () => {
    if (!newUserEmail || !newUserName) {
      message.error(
        "Bitte geben Sie eine gültige E-Mail-Adresse und einen Namen ein",
      );
      return;
    }
    try {
      await api.Project.addUserToByEmail(
        newUserEmail,
        newUserName,
        newUserRoles,
      );
      message.success("Benutzer erfolgreich eingeladen");
      setNewUserEmail("");
      setNewUserName("");
      setNewUserRoles([]);
    } catch (error) {
      message.error("Fehler beim Einladen des Benutzers");
    }
  };

  return (
    <Content>
      <Title level={3}>
        {project?.name ? `${project.name} - ` : ""}Nutzerverwaltung
        <Button
          type="link"
          onClick={() => window.history.back()}
          style={{ marginBottom: 16 }}
        >
          <ArrowLeftOutlined /> Zurück zur Übersicht
        </Button>
      </Title>

      <div className="flex flex-row mb-5">
        <Input
          placeholder="Vorname Nachname"
          value={newUserName}
          onChange={(e) => setNewUserName(e.target.value)}
          style={{ width: "300px", marginRight: "10px" }}
        />
        <Input
          placeholder="E-Mail"
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}
          style={{
            width: "300px",
            marginRight: "10px",
            verticalAlign: "middle",
          }}
        />
        <Select
          mode="multiple"
          placeholder="Rolle(n)"
          value={newUserRoles}
          onChange={setNewUserRoles}
          style={{
            width: "300px",
            marginRight: "10px",
            verticalAlign: "middle",
          }}
          options={Object.values(EapProjectRole).map((role) => ({
            label: EapProjectRoleLabel[role],
            value: role,
          }))}
        />
        <AsyncButton onClick={handleAddUser}>Benutzer einladen</AsyncButton>
      </div>

      <h3>Projektmitglieder</h3>
      <EapTable
        atomType={Atoms.ProjectMembers}
        rowKey="key"
        actions={(key, record) => (
          <>
            <AsyncButton
              onClick={async () => {
                return await api.Project.updateMembersEntry(key, {
                  ...record,
                  disabled: !record.disabled,
                });
              }}
              disabled={key === myUserId}
              title={
                key === myUserId
                  ? "Sie können sich selbst nicht deaktivieren"
                  : ""
              }
            >
              {record.disabled ? "Aktivieren" : "Deaktivieren"}
            </AsyncButton>
          </>
        )}
        actionColProps={{ width: "200px" }}
        columns={[
          {
            title: "Benutzer",
            dataIndex: "key",
            render: (userId) => <UserName publicId={userId} />,
            key: "user",
          },
          {
            title: "Rollen",
            dataIndex: "roles",
            key: "roles",
            render: (roles, record) => (
              <ProjectRoleEditor
                key={record.key} // Add key to prevent re-renders
                roles={roles as EapProjectRole[]}
                onChange={(newRoles) =>
                  api.Project.updateMembersEntry(record.key, {
                    ...record,
                    roles: newRoles,
                  })
                }
              />
            ),
          },
          {
            title: "Status",
            width: 100,
            dataIndex: "disabled",
            key: "disabled",
            render: (isDisabled) => (
              <span>{(isDisabled as boolean) ? "Deaktiviert" : "Aktiv"}</span>
            ),
          },
        ]}
      />
    </Content>
  );
};
