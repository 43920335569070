import type { ContextValues, UserPublicID } from "@atoms/atom-types";
import { DefaultIdentifiers } from "@atoms/atom-types";
import type { ReactNode } from "react";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface UserState {
  userId: UserPublicID | null;
  setUserId: (id: UserPublicID | null) => void;
}

export const useUserStore = create<UserState>()(
  persist(
    (set) => ({
      userId: null,
      setUserId: (id) => set({ userId: id }),
    }),
    {
      name: "user-store",
    },
  ),
);

export const useMyUserId = (throwIfNotFound = true): UserPublicID => {
  const userId = useUserStore((state) => state.userId);
  if (!userId && throwIfNotFound) throw new Error("No user ID found");
  return userId as UserPublicID;
};

export const usePersonalContext = (): ContextValues => {
  const userId = useMyUserId();
  if (!userId) return {};
  return {
    [DefaultIdentifiers.UserId]: userId,
  };
};

/**
 * Wraps the children in a component that will ensure that the user ID is present.
 */
export const AuthenticatedApp = ({
  children,
  fallback = null,
}: {
  children: ReactNode;
  fallback?: ReactNode;
}) => {
  const userId = useMyUserId(false);
  if (!userId) return fallback;
  return children;
};
