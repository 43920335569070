import { setAutoFreeze } from "immer";
import { combineReducers } from "redux";
import { ApolloWsReduxReducer, type ApolloWsState } from "./apollo";
import { ModalsReduxReducer, type ModalsState } from "./modals";
import {
  type TableSettings,
  TableSettingsReduxReducer,
  type TableState,
  TableStateReduxReducer,
} from "./table";

setAutoFreeze(false);

export const rootReducer = combineReducers({
  table: TableStateReduxReducer,
  tableSettings: TableSettingsReduxReducer,
  modal: ModalsReduxReducer,
  apollo: ApolloWsReduxReducer,
});

// tslint:disable-next-line: no-empty-interface
export interface State {
  table: TableState;
  tableSettings: TableSettings;
  modal: ModalsState;
  apollo: ApolloWsState;
}

export type GetState = () => State;
