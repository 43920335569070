import type { UserPublicID } from "@atoms/atom-types";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import type { NoUndefinedRangeValueType } from "rc-picker/lib/PickerInput/RangePicker";
import { useAtom } from "@project/api";
import { Atoms, EapProjectRole } from "@project/shared";
import Badge from "antd/es/badge";
import Button from "antd/es/button";
import Col from "antd/es/col";
import DatePicker from "antd/es/date-picker";
import Divider from "antd/es/divider";
import Search from "antd/es/input/Search";
import Popover from "antd/es/popover";
import Row from "antd/es/row";
import Select from "antd/es/select";
import Switch from "antd/es/switch";
import dayjs from "dayjs";
import { debounce } from "lodash";
import {
  type CSSProperties,
  type ChangeEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { classNames2 } from "../classNames2";
import { useWorkpackageFiltersStore } from "../state/project/workpackageFilters";
import {
  getFilteredProjectUserOptions,
  nobodyOption,
} from "../util/getFilteredProjectUserOptions";
import { FloatLabel } from "./reusable/FloatLabel";

export const WorkPackageSearchWithFilter = ({
  style,
}: { style?: CSSProperties }) => {
  const resetAll = useWorkpackageFiltersStore((state) => state.resetAll);
  const setWorkpackageFiltersState = useWorkpackageFiltersStore(
    (state) => state.set,
  );
  const label = useWorkpackageFiltersStore((state) => state.label);
  // const label2 = useProjectStore((state) => state.label);
  // console.log("label", label);
  // console.log("label2", label2);
  const projectUsersById = useAtom(
    Atoms.ProjectMembers,
    ({ entries }) => entries,
  );

  // available filters
  const attentionRequiredOnly = useWorkpackageFiltersStore(
    (state) => state.attentionRequiredOnly,
  );
  const dueWithin40DaysOnly = useWorkpackageFiltersStore(
    (state) => state.dueWithin40DaysOnly,
  );
  const favoritesOnly = useWorkpackageFiltersStore(
    (state) => state.favoritesOnly,
  );
  const responsibles = useWorkpackageFiltersStore(
    (state) => state.responsibleUserIds,
  );
  const commissioners = useWorkpackageFiltersStore(
    (state) => state.commissionerUserIds,
  );
  const involvedUsers = useWorkpackageFiltersStore(
    (state) => state.involvedUserIds,
  );
  const plannedStart = useWorkpackageFiltersStore((state) => state.startDate);
  const plannedEnd = useWorkpackageFiltersStore((state) => state.endDate);
  const active = useWorkpackageFiltersStore((state) => state.active);
  const activeFilters = [
    attentionRequiredOnly,
    dueWithin40DaysOnly,
    involvedUsers,
    favoritesOnly,
    responsibles,
    commissioners,
    plannedStart,
    plannedEnd,
    active,
  ].filter(Boolean).length;

  const [responsibleOptions, setResponsibleOptions] = useState(
    nobodyOption.concat(
      projectUsersById
        ? getFilteredProjectUserOptions(
            projectUsersById,
            EapProjectRole.Responsible,
          )
        : [],
    ),
  );
  const [commissionerOptions, setCommissionerOptions] = useState(
    nobodyOption.concat(
      projectUsersById
        ? getFilteredProjectUserOptions(
            projectUsersById,
            EapProjectRole.Commissioner,
          )
        : [],
    ),
  );
  const [involvedUserOptions, setInvolvedUserOptions] = useState(
    nobodyOption.concat(
      projectUsersById ? getFilteredProjectUserOptions(projectUsersById) : [],
    ),
  );

  // we have to handle this explicitely, because antd internal handling breaks with "reset" button
  const [projectUserFilterValue, setProjectUserFilterValue] = useState<
    string | undefined
  >();
  const [commissionerFilterValue, setCommissionerFilterValue] = useState<
    string | undefined
  >();
  const [involvedUserFilterValue, setInvolvedUserFilterValue] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (!projectUsersById) {
      return;
    }
    setResponsibleOptions(
      nobodyOption.concat(
        getFilteredProjectUserOptions(
          projectUsersById,
          EapProjectRole.Responsible,
          projectUserFilterValue,
        ),
      ),
    );
  }, [projectUsersById, projectUserFilterValue]);
  useEffect(() => {
    if (!projectUsersById) {
      return;
    }
    setCommissionerOptions(
      nobodyOption.concat(
        getFilteredProjectUserOptions(
          projectUsersById,
          EapProjectRole.Commissioner,
          commissionerFilterValue,
        ),
      ),
    );
  }, [projectUsersById, commissionerFilterValue]);
  useEffect(() => {
    if (!projectUsersById) {
      return;
    }
    setInvolvedUserOptions(
      getFilteredProjectUserOptions(
        projectUsersById,
        undefined,
        involvedUserFilterValue,
      ),
    );
  }, [projectUsersById, involvedUserFilterValue]);

  const [searchValue, setSearchValue] = useState<string | undefined>(label);
  const debouncedOnSearch = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      setWorkpackageFiltersState("label", e.target.value);
    }, 500),
    [setWorkpackageFiltersState],
  );
  useEffect(() => {
    // reset search value when switching projects
    setSearchValue(label);
  }, [label]);

  return (
    <Search
      className="workPackageSearch"
      style={style}
      placeholder="Suche in Titel, Beschreibung, Zielen, Lieferergebnissen und Codes"
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value);
        debouncedOnSearch(e);
      }}
      onBlur={debouncedOnSearch}
      allowClear
      enterButton={false}
      type="search"
      addonAfter={
        <>
          <Popover
            mouseLeaveDelay={0.3}
            placement="bottomRight"
            title={
              <Row
                align="middle"
                gutter={[10, 10]}
                className="filtersPopoverTitle"
              >
                <Col flex={1}>
                  <span id="header">Filter</span>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setProjectUserFilterValue(undefined);
                      setCommissionerFilterValue(undefined);
                      setInvolvedUserFilterValue(undefined);
                      setSearchValue("");
                      resetAll();
                    }}
                  >
                    Zurücksetzen
                  </Button>
                </Col>
              </Row>
            }
            content={
              <div className="filtersPopoverContent">
                <Row gutter={[10, 10]}>
                  <Col span={24}>
                    <span>
                      <Switch
                        checked={attentionRequiredOnly}
                        onChange={(newValue) => {
                          setWorkpackageFiltersState(
                            "attentionRequiredOnly",
                            newValue,
                          );
                        }}
                      />
                      Nur AP mit Entscheidungsbedarf
                    </span>
                  </Col>
                  <Col span={24}>
                    <span>
                      <Switch
                        checked={active}
                        onChange={(newValue) => {
                          setWorkpackageFiltersState("active", newValue);
                        }}
                      />
                      Nur Aktive
                    </span>
                  </Col>
                  <Col span={24}>
                    <span>
                      <Switch
                        checked={dueWithin40DaysOnly}
                        onChange={(newValue) => {
                          setWorkpackageFiltersState(
                            "dueWithin40DaysOnly",
                            newValue,
                          );
                        }}
                      />
                      Fällig innerhalb der nächsten 40 Tage
                    </span>
                  </Col>
                  <Col span={24}>
                    <span>
                      <Switch
                        checked={favoritesOnly}
                        onChange={(newValue) => {
                          setWorkpackageFiltersState("favoritesOnly", newValue);
                        }}
                      />
                      Nur Favoriten
                    </span>
                  </Col>
                </Row>
                <Divider className="border-gray-200" />
                <Row gutter={[10, 10]}>
                  <Col span={24}>
                    <FloatLabel
                      label="Verantwortliche/r"
                      value={
                        responsibles && responsibles.length > 0
                          ? responsibles.join(", ")
                          : undefined
                      }
                    >
                      <Select
                        allowClear
                        mode="multiple"
                        value={responsibles ?? []}
                        onSelect={(value) => {
                          if (value !== undefined && value !== null) {
                            const newProjectUserFilter = responsibles
                              ? [...responsibles, value]
                              : [value];

                            setWorkpackageFiltersState(
                              "responsibleUserIds",
                              newProjectUserFilter,
                            );
                          }
                        }}
                        onDeselect={(value) => {
                          if (value !== undefined && value !== null) {
                            const newProjectUserFilter =
                              responsibles?.filter(
                                (r) => r !== value.toString(),
                              ) ?? null;
                            setWorkpackageFiltersState(
                              "responsibleUserIds",
                              newProjectUserFilter &&
                                newProjectUserFilter.length > 0
                                ? newProjectUserFilter
                                : null,
                            );
                          }
                        }}
                        onClear={() => {
                          setWorkpackageFiltersState(
                            "responsibleUserIds",
                            null,
                          );
                        }}
                        optionFilterProp="label"
                        optionLabelProp="label"
                        options={responsibleOptions}
                      />
                    </FloatLabel>
                  </Col>
                  <Col span={24}>
                    <div
                      className="text-gray-300 font-light text-xs text-center flex justify-center"
                      style={{ marginTop: -8, marginBottom: -10 }}
                    >
                      ↑ oder ↓
                    </div>
                  </Col>
                  <Col span={24}>
                    <FloatLabel
                      label="Auftraggeber/in"
                      value={
                        commissioners && commissioners.length > 0
                          ? commissioners.join(", ")
                          : undefined
                      }
                    >
                      <Select
                        allowClear
                        mode="multiple"
                        value={commissioners ?? []}
                        onSelect={(value) => {
                          if (value !== undefined && value !== null) {
                            const newCommissionerFilter = commissioners
                              ? [...commissioners, value]
                              : [value];

                            setWorkpackageFiltersState(
                              "commissionerUserIds",
                              newCommissionerFilter,
                            );
                          }
                        }}
                        onDeselect={(value) => {
                          if (value !== undefined && value !== null) {
                            const newCommissionerFilter =
                              commissioners?.filter(
                                (r) => r !== value.toString(),
                              ) ?? null;
                            setWorkpackageFiltersState(
                              "commissionerUserIds",
                              newCommissionerFilter &&
                                newCommissionerFilter.length > 0
                                ? newCommissionerFilter
                                : null,
                            );
                          }
                        }}
                        onClear={() => {
                          setWorkpackageFiltersState(
                            "commissionerUserIds",
                            null,
                          );
                        }}
                        optionFilterProp="label"
                        optionLabelProp="label"
                        options={commissionerOptions}
                      />
                    </FloatLabel>
                  </Col>
                </Row>
                <Divider className="border-gray-200" />
                <Row gutter={[10, 10]}>
                  <Col span={24}>
                    <FloatLabel
                      label="Beteiligte"
                      value={
                        involvedUsers && involvedUsers.length > 0
                          ? involvedUsers.join(", ")
                          : undefined
                      }
                    >
                      <Select
                        allowClear
                        mode="multiple"
                        value={involvedUsers ?? []}
                        onSelect={(value) => {
                          if (value !== undefined && value !== null) {
                            const newInvolvedUsersFilter = involvedUsers
                              ? [...involvedUsers, value.toString()]
                              : [value.toString()];

                            setWorkpackageFiltersState(
                              "involvedUserIds",
                              newInvolvedUsersFilter as UserPublicID[],
                            );
                          }
                        }}
                        onDeselect={(value) => {
                          if (value !== undefined && value !== null) {
                            const newInvolvedUsersFilter =
                              involvedUsers?.filter(
                                (r) => r !== value.toString(),
                              ) ?? null;
                            setWorkpackageFiltersState(
                              "involvedUserIds",
                              newInvolvedUsersFilter &&
                                newInvolvedUsersFilter.length > 0
                                ? newInvolvedUsersFilter
                                : null,
                            );
                          }
                        }}
                        onClear={() => {
                          setWorkpackageFiltersState("involvedUserIds", null);
                        }}
                        optionFilterProp="label"
                        optionLabelProp="label"
                        options={involvedUserOptions}
                      />
                    </FloatLabel>
                  </Col>
                  <Col span={24}>
                    <FloatLabel
                      label="Startdatum zwischen"
                      value={
                        plannedStart ? Object.values(plannedStart).join("") : ""
                      }
                    >
                      <DatePicker.RangePicker
                        placeholder={["", ""]}
                        format="DD.MM.YYYY"
                        value={
                          plannedStart
                            ? [
                                plannedStart.beginning
                                  ? dayjs(plannedStart.beginning)
                                  : null,
                                plannedStart.end
                                  ? dayjs(plannedStart.end)
                                  : null,
                              ]
                            : null
                        }
                        onChange={(
                          value:
                            | any //NoUndefinedRangeValueType<dayjs.Dayjs>
                            | undefined,
                        ) => {
                          if (!value) {
                            setWorkpackageFiltersState("startDate", null);
                            return;
                          }

                          setWorkpackageFiltersState("startDate", {
                            beginning: value?.[0]?.toDate() ?? null,
                            end: value?.[1]?.toDate() ?? null,
                          });
                        }}
                        allowClear={true}
                        allowEmpty={[true, true]}
                        picker="date"
                        inputReadOnly
                      />
                    </FloatLabel>
                  </Col>
                </Row>
              </div>
            }
          >
            <Badge count={activeFilters} size="small">
              <Button
                icon={<FontAwesomeIcon icon={faFilter} />}
                className={classNames2(() => ({
                  active: activeFilters > 0,
                  filterBtn: true,
                }))}
              />
            </Badge>
          </Popover>
        </>
      }
    />
  );
};
