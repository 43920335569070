import type { SortOrder } from "antd/es/table/interface";

/**
 * Compares two dates, which can be null, based on the provided sort order.
 *
 * @param a - The first date to be compared, can be null.
 * @param b - The second date to be compared, can be null.
 * @param sortOrder - (Optional) Specifies the sort order. If "ascend", null dates will come before non-null dates; otherwise, null dates will come after non-null dates.
 * @returns A number representing the sort order. If both dates are null, returns 0; if one date is null, returns -1 or 1 based on the sortOrder; otherwise, returns the difference between the two dates' time values.
 */
export const compareDates = (
  a: Date | null,
  b: Date | null,
  sortOrder: SortOrder = "ascend",
) => {
  if (a === null && b === null) {
    return 0;
  } else if (a === null || b === null) {
    return sortOrder === "ascend" ? -1 : 1;
  } else {
    return a.getTime() - b.getTime();
  }
};
