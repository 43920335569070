import type { UserPublicID } from "@atoms/atom-types";
import { notification } from "antd";
import { useEffect, useState } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { useUserStore } from "./useMyUserId";

export const useHandleOidcEvents = () => {
  const setUserId = useUserStore((state) => state.setUserId);
  const {
    isAuthenticated,
    activeNavigator,
    isLoading,
    signinRedirect,
    user,
    events,
    signinSilent,
    error,
  } = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  // Update user ID when authenticated
  useEffect(() => {
    if (isAuthenticated && user) {
      setUserId(user.profile?.sub as UserPublicID | null);
    } else {
      setUserId(null);
    }
  }, [isAuthenticated, user, setUserId]);

  // automatically sign-in
  useEffect(() => {
    const signIn = async () => {
      if (
        !hasAuthParams() &&
        !isAuthenticated &&
        !activeNavigator &&
        !isLoading &&
        !hasTriedSignin &&
        !error
      ) {
        try {
          await signinRedirect();
        } catch (e) {
          console.error("Auto Sign-in failed", e);
        } finally {
          setHasTriedSignin(true);
        }
      }
    };

    signIn();
  }, [
    hasTriedSignin,
    isAuthenticated,
    activeNavigator,
    isLoading,
    signinRedirect,
  ]);

  useEffect(() => {
    const removeAccessTokenExpiring = events.addAccessTokenExpiring(
      async () => {
        try {
          await signinSilent();
        } catch (e) {
          console.error("access token renew failed", e);
        }
      },
    );
    const removeAccessTokenExpired = events.addAccessTokenExpired(() => {
      console.debug("access token expired");
    });

    return () => {
      removeAccessTokenExpiring();
      removeAccessTokenExpired();
    };
  }, [events, signinSilent]);

  useEffect(() => {
    if (error) {
      notification.error({
        placement: "top",
        key: "keycloak-error",
        message: "Authentifizierungsfehler",
        description: <i>{error.message}</i>,
        duration: 0,
      });
      console.error("oidc error", error);
    }
  }, [error]);
};
