import type { GenericActions } from "@atoms/atom-client";
import { useIdentifier } from "@atoms/atom-client";
import { useScopedStore } from "@atoms/atom-client";
import type { UserPublicID } from "@atoms/atom-types";
import { Identifiers } from "@project/shared";
import { type StateCreator, useStore } from "zustand";

interface WorkpackageFiltersState {
  attentionRequiredOnly: boolean;
  checked: string[];
  responsibleUserIds: UserPublicID[] | null;
  commissionerUserIds: UserPublicID[] | null;
  involvedUserIds: UserPublicID[] | null;
  active: boolean;
  label: string;
  dueWithin40DaysOnly: boolean;
  favoritesOnly: boolean;
  endDate: Date | null;
  startDate: { beginning: Date | null; end: Date | null } | null;
}

interface WorkpackageFiltersActions {
  resetAllExceptLabel: () => void;
  resetAll: () => void;
}

type WorkpackageFilters = WorkpackageFiltersState & WorkpackageFiltersActions;

const defaultWorkpackageFiltersState: WorkpackageFiltersState = {
  attentionRequiredOnly: false,
  checked: [],
  active: false,
  favoritesOnly: false,
  label: "",
  responsibleUserIds: null,
  commissionerUserIds: null,
  involvedUserIds: null,
  dueWithin40DaysOnly: false,
  endDate: null,
  startDate: null,
};

const workpackageFiltersStoreInitializer: StateCreator<WorkpackageFilters> = (
  set,
) => ({
  ...defaultWorkpackageFiltersState,
  resetAllExceptLabel: () =>
    set((state) => ({
      ...state,
      workpackageFilters: {
        ...defaultWorkpackageFiltersState,
        label: state.label,
      },
    })),
  resetAll: () =>
    set(() => ({
      ...defaultWorkpackageFiltersState,
    })),
});

export const useWorkpackageFiltersStore = <Selected>(
  selector: (
    state: WorkpackageFilters & GenericActions<WorkpackageFiltersState>,
  ) => Selected,
) => {
  const projectId = useIdentifier(Identifiers.ProjectId);
  const organizationId = useIdentifier(Identifiers.OrganizationId);

  const store = useScopedStore<WorkpackageFilters>(
    "workpackageFilters",
    [organizationId, projectId],
    workpackageFiltersStoreInitializer,
  );
  if (!store) {
    throw new Error("Project store not found");
  }
  (globalThis as any).store = store;

  return useStore(store, selector);
};
