import { __useAtom } from "@atoms/atom-client";
import {
  type AtomDescriptor,
  DefaultAtoms,
  DefaultIdentifiers,
  type IAgentSession,
} from "@atoms/atom-types";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, List, Spin, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { useAiApi } from "./AiContext";
import { AutocompleteTextarea } from "./AutocompleteTextarea";
import { ReferencedAtoms } from "./ReferencedAtoms";
import { ToolCallBubble } from "./ToolCallBubble";
const { Title, Paragraph, Text } = Typography;
export const AiChat: React.FC<{
  sessionId: string;
}> = ({ sessionId }) => {
  const api = useAiApi();
  const session = __useAtom<IAgentSession>(DefaultAtoms.AgentSessionInfo, {
    [DefaultIdentifiers.AgentSessionId]: sessionId,
  });
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  if (!session) {
    return <div>No session found</div>;
  }

  const lastMessageIsByUser = session.messages.at(-1)?.role === "user";
  const handleSendMessage = async () => {
    if (!inputText.trim()) return;

    setInputText("");
    setIsLoading(true);
    await api?.sendMessage(inputText);
    setIsLoading(false);
  };
  return (
    <Content style={{ maxHeight: "100vh", overflowY: "auto" }}>
      <div>
        <ReferencedAtoms
          pathes={Object.keys(session.awareness) as AtomDescriptor[]}
        />
        <List
          dataSource={[...session.messages].filter(
            (message) => message.role !== "system",
          )}
          renderItem={(message, index) => (
            <List.Item key={index}>
              <div style={{ display: "inline-block" }}>
                {message.content ? (
                  <ReactMarkdown
                    components={{
                      h1: ({ children }) => <Title level={1}>{children}</Title>,
                      h2: ({ children }) => <Title level={2}>{children}</Title>,
                      h3: ({ children }) => <Title level={3}>{children}</Title>,
                      p: ({ children }) => <Paragraph>{children}</Paragraph>,
                      strong: ({ children }) => <Text strong>{children}</Text>,
                      em: ({ children }) => <Text italic>{children}</Text>,
                      code: ({ children }) => (
                        <pre>
                          <code>{children}</code>
                        </pre>
                      ),
                    }}
                  >
                    {message.content as string}
                  </ReactMarkdown>
                ) : null}
                {"tool_calls" in message &&
                  message.tool_calls?.map((toolCall: any) => (
                    <ToolCallBubble key={toolCall.id} toolCall={toolCall} />
                  ))}
              </div>
            </List.Item>
          )}
        />
      </div>
      {!lastMessageIsByUser ? (
        <>
          <AutocompleteTextarea
            value={inputText}
            onChange={setInputText}
            onPressEnter={handleSendMessage}
            placeholder="Schreibe deine Nachricht... Verwende @, um Atome zu erwähnen"
            disabled={isLoading || lastMessageIsByUser}
          />
          <Button
            type="primary"
            icon={<FontAwesomeIcon icon={faPaperPlane} />}
            onClick={handleSendMessage}
            loading={isLoading}
            disabled={lastMessageIsByUser}
          >
            Send
          </Button>
        </>
      ) : (
        <Spin />
      )}
    </Content>
  );
};
