import type { IWorkpackage } from "../interfaces/IWorkpackage";
import type { FieldPropertiesMap } from "@atoms/atom-types";

export const workpackageLabels: FieldPropertiesMap<IWorkpackage> = {
  activities: { label: "Aktivitäten" },
  commissionerUserId: { label: "Auftraggeber/in" },
  decisionRequired: { label: "Entscheidungsbedarf" },
  done: { label: "abgeschlossen" },
  decisionRequiredReason: { label: "Erläuterung zum Entscheidungsbedarf" },
  involvedUserIds: { label: "Beteiligte" },
  dependentWPs: { label: "abhängige AP" },
  description: { label: "Beschreibung" },
  frozen: { label: "frozen" },
  deleted: { label: "gelöscht" },
  decision: { label: "Entscheidung" },
  expectedFinish: { label: "erwartetes Enddatum" },
  goals: { label: "Lieferergebnisse" },
  label: { label: "Titel" },
  objective: { label: "Zielstellung" },
  plannedFinish: { label: "Enddatum" },
  plannedStart: { label: "Startdatum" },
  progress: { label: "Fortschritt" },
  progressReason: { label: "Grund für Fortschritt" },
  responsibleUserId: { label: "Verantwortliche/r" },
  signal: { label: "Signal" },
  status: { label: "Status" },
};
