type Credentials = "include" | "same-origin";

export function getBackendUrl(): {
  backendBaseUrl: string;
  graphQlUrl: string;
  graphQlSubscriptionUrl: string;
  credentials: Credentials;
} {
  const customHost = localStorage.getItem("CUSTOM_HOST");

  let backendBaseUrl: string;
  let secure = true;
  let credentials: Credentials = "include";

  if (
    typeof import.meta.env.BACKEND_URI === "string" &&
    import.meta.env.BACKEND_URI.length > 0
  ) {
    backendBaseUrl = import.meta.env.BACKEND_URI;
  } else {
    // local dev setup
    secure = location.protocol === "https:";
    backendBaseUrl = `${location.host}/backend`;
    credentials = "same-origin";
  }

  return {
    backendBaseUrl: `${secure ? "https" : "http"}://${customHost ?? backendBaseUrl}`,
    graphQlUrl: `${secure ? "https" : "http"}://${customHost ?? backendBaseUrl}/graphql`,
    graphQlSubscriptionUrl: `${secure ? "wss" : "ws"}://${customHost ?? backendBaseUrl}/graphql`,
    credentials: credentials,
  };
}
