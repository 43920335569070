import type { InputRef } from "antd";
import type { FormInstance } from "antd/es/form/Form";
import type { ModalProps } from "antd/es/modal";
import {
  type MouseEvent,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import Draggable, {
  type DraggableData,
  type DraggableEvent,
} from "react-draggable";
import { useSelector } from "react-redux";
import { UIElement } from "../../UIElements";
import { classNames2 } from "../../classNames2";
import type { State } from "../../state";
import { ModalsApi } from "../../state/modals";
import { useThunkDispatch } from "../../useThunkDispatch";
import { ModalWithShortcuts } from "./ModalWithShortcuts";

export interface ReduxModalProps
  extends Omit<ModalProps, "onOk" | "onCancel" | "modalRender"> {
  id: string;
  dispatcher?: () => any;
  onOk?: (e: MouseEvent<HTMLElement>) => void | Promise<void>;
  children: ReactNode;
  form?: FormInstance;
  forceVisible?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  draggable?: boolean;
  firstInputRef?: React.MutableRefObject<InputRef | null>;
}

export const ReduxModal = ({
  id,
  dispatcher,
  onOk,
  children,
  form,
  forceVisible,
  onCancel,
  className,
  onSubmit,
  okButtonProps,
  title,
  draggable = false,
  firstInputRef,
  maskClosable = false,
  ...props
}: ReduxModalProps) => {
  const dispatch = useThunkDispatch();
  const visible = useSelector((state: State) => state.modal[id]);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef<HTMLDivElement>(null);

  const [isHandlingClick, setHandlingClick] = useState<boolean>(false);
  const [errorAnimation, setErrorAnimation] = useState(false);
  // focus first input field on open
  useEffect(() => {
    if (visible && firstInputRef?.current) {
      setTimeout(() => {
        firstInputRef.current!.focus({ cursor: "all" });
      }, 0);
    }
  }, [visible]);

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <ModalWithShortcuts
      open={visible || forceVisible}
      cancelText="Abbrechen"
      onCancel={() => {
        onCancel?.();
        return dispatch(ModalsApi.hide(id));
      }}
      className={className ? `pw_modal_${id} ${className}` : `pw_modal_${id}`}
      okButtonProps={{
        ...okButtonProps,
        loading: isHandlingClick,
        className: classNames2(
          () => ({ "async-button-animated": errorAnimation }),
          "async-button",
          UIElement.Modal_Confirm,
        ),

        onAnimationEnd: () => setErrorAnimation(false),
      }}
      cancelButtonProps={{
        className: UIElement.Modal_Cancel,
      }}
      onOk={async (e) => {
        onSubmit?.();
        form?.submit();

        if (form === undefined || form.getFieldsError().length === 0) {
          try {
            setHandlingClick(true);
            if (dispatcher) {
              await dispatch(dispatcher());
            } else if (onOk) {
              await onOk(e);
            }
            setHandlingClick(false);
            dispatch(ModalsApi.hide(id));
          } catch (e) {
            setHandlingClick(false);
            setErrorAnimation(true);
            throw e;
          }
        }
      }}
      title={
        draggable ? (
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            {title}
          </div>
        ) : (
          title
        )
      }
      modalRender={
        draggable
          ? (modal) => (
              <Draggable
                nodeRef={draggleRef}
                disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>
            )
          : undefined
      }
      maskClosable={maskClosable}
      {...props}
    >
      {children}
    </ModalWithShortcuts>
  );
};
