import { MagicFormContext } from "@atoms/atom-components";
import type { IWorkpackage } from "@project/shared";
import { InputNumber, Progress, Row, Tooltip } from "antd";
import SkeletonInput from "antd/es/skeleton/Input";
import { useContext } from "react";
import { computeActivityStats } from "../hooks/useWorkPackage";

export const AverageProgress = () => {
  const { currentValue, loading, updateDraft } = useContext(MagicFormContext)!;
  const activityStats = computeActivityStats(currentValue as IWorkpackage);

  return loading ? (
    <SkeletonInput active style={{ height: 56, width: 300 }} />
  ) : (
    <div className="float-label">
      <div className="label label-float">gemittelter Fortschritt</div>
      <Row
        wrap={false}
        align="middle"
        className="progress"
        style={{ width: "300px" }}
      >
        <Tooltip title={<>{`Gemittelt: ${activityStats.combinedProgress}%`}</>}>
          <Progress
            key={`average_progress_mean`}
            percent={activityStats?.combinedProgress}
            showInfo={false}
          />
        </Tooltip>
        <InputNumber
          key={`average_input_progress_mean`}
          style={{ marginLeft: "10px" }}
          value={activityStats?.combinedProgress}
          disabled
          formatter={(value) => `${value}%`}
          parser={(value) =>
            Number.parseInt(value?.replace("%", "") ?? "") || 0
          }
          onChange={(value) => {
            updateDraft({
              progress: value!,
            });
          }}
        />
      </Row>
    </div>
  );
};
