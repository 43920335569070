export type ClassNameGenerator = { [className: string]: () => boolean };
export type ClassNameGenerator2 = () => {
  [className: string]: boolean | undefined | null;
};

export function classNames2(
  generator?: ClassNameGenerator2,
  ...defaultClasses: Array<string | undefined>
): string {
  const active: string[] = [];
  const generated = generator ? generator() : [];
  for (const [className, condition] of Object.entries(generated)) {
    if (condition) {
      active.push(className);
    }
  }
  defaultClasses.forEach((cls) => {
    if (cls) {
      active.push(cls);
    }
  });
  return active.join(" ");
}
