import { DraftItemType } from "../enums/DraftItemType";
import type { DraftOperationType } from "../types/DraftOperationType";
import type { DraftScopeType } from "../types/DraftScopeType";

export function generateDraftOperationAndScopeMap(): Map<
  DraftItemType,
  { operation: DraftOperationType; scope: DraftScopeType }
> {
  const map = new Map();

  for (const key in DraftItemType) {
    const itemType = DraftItemType[key as keyof typeof DraftItemType];
    const [operation, scope] = itemType
      .split(/(?=[A-Z])/)
      .map((s) => s.replace(/[^A-Za-z]/g, ""));

    map.set(itemType, {
      operation: operation as DraftOperationType,
      scope: scope as DraftScopeType,
    });
  }

  return map;
}
