import { calculateLatestVersionedAtomValue } from "@atoms/atom-types";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "@project/api";
import { Atoms } from "@project/shared";
import { Typography } from "antd";
import { useMemo } from "react";

export const LicenseIndicator = () => {
  const consumedLicenses = useAtom(Atoms.Licenses);
  const bookedLicenses = useAtom(Atoms.BookedLicenses);
  const value = useMemo(
    () =>
      calculateLatestVersionedAtomValue(bookedLicenses?.__history ?? [])
        .licenses,
    [bookedLicenses?.__history],
  );
  return (
    <Typography.Text>
      <FontAwesomeIcon icon={faKey} className="mr-1 text-gray-600" />
      Lizenzen: {consumedLicenses?.count} / {value}
    </Typography.Text>
  );
};
