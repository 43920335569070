import type { FallbackRender } from "@sentry/react";
import * as Sentry from "@sentry/react";
import { Button, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Result from "antd/es/result";
import type { ReactNode } from "react";

const FallbackComponent: FallbackRender = (errorData) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Result
          status="500"
          title="Ups, das ist schief gegangen."
          subTitle={
            <>
              Bitte versuchen Sie die Seite neu zu laden.
              <br />
              Falls der Fehler weiterhin auftritt, kontaktieren Sie bitte den{" "}
              <a href="mailto:info@program51.de">Support</a>.
              <br />
              <i>Event-ID</i>:{" "}
              <code className="text-xs">{errorData.eventId}</code>
              <br />
              {errorData.error.message !== undefined ? (
                <>
                  <div style={{ maxWidth: "50vw" }}>
                    <i>Fehler:</i>{" "}
                    <code className="text-xs">{errorData.error.message}</code>
                  </div>
                </>
              ) : null}
            </>
          }
          extra={
            <>
              <Button type="primary" onClick={() => location.reload()}>
                Neu laden
              </Button>
              {/* <Button
                                type="link"
                                onClick={() =>
                                    showSentryReportDialog({
                                        eventId: errorData.eventId,
                                    })
                                }
                            >
                                Weitere Informationen hinzufügen
                            </Button> */}
            </>
          }
        />
      </Content>
    </Layout>
  );
};

export const EapErrorBoundary = ({ children }: { children: ReactNode }) => {
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent}>
      {children}
    </Sentry.ErrorBoundary>
  );
};
