import {
  type ANY_FIELD,
  type AtomAccessPermissions,
  type AtomData,
  GenericPermission,
  type UserPublicID,
} from "@atoms/atom-types";
import { Input } from "antd";
import { createContext, useContext } from "react";
import type { LabelType } from "./RevisionConflictModal";

export const MagicFormContext = createContext<FormContextType | undefined>(
  undefined,
);
export const MagicFormProvider = MagicFormContext.Provider;

export type ChangeSet<AtomDataType extends AtomData = AtomData> =
  Partial<AtomDataType>;

export interface SuggestedChange<AtomDataType extends AtomData = AtomData> {
  source?: string;
  explanation?: string;
  changeSet: ChangeSet<AtomDataType>;
}

export type FormContextType<
  AtomDataType extends AtomData = AtomData,
  PermissionsType extends string = string,
> = {
  currentValue: AtomDataType;
  permissions?: AtomAccessPermissions<AtomDataType, PermissionsType>;
  updateDraft: (draft: Partial<AtomDataType>) => void;
  resetDraft: () => void;
  submit: (ignoreSuggestedChanges?: boolean) => Promise<void>;
  onCancel: () => void;
  myUserId: UserPublicID;
  /**
   * @param field The field to check permission for. Use ANY_FIELD Symbol to check if the user has the permission for any field.
   * @param permission The permission to check for.
   * @returns Whether the user has the permission for the field.
   */
  checkFieldPermission: (
    field: (keyof AtomDataType & string) | typeof ANY_FIELD,
    permission: GenericPermission,
  ) => boolean;
  discardSuggestedChanges: () => void;
  resolveSuggestedChange: (
    field: keyof AtomDataType & string,
    value: AtomDataType[keyof AtomDataType],
  ) => void;
  atomType: string;
  suggestedChanges?: SuggestedChange<AtomDataType>;
  labels?: LabelType<AtomDataType>;
  draft: Readonly<Partial<AtomDataType>> | null;
  valueFromBackend: Readonly<AtomDataType> | null | undefined;
  loading?: boolean;
};

export interface MagicInputProps<AtomType extends AtomData = AtomData> {
  field: keyof AtomType & string;
  label?: string;
  validate?: (state: AtomType) => string | undefined;
}

export const useLabel = <AtomType extends AtomData = AtomData>(
  field: keyof AtomType & string,
): string => {
  const { labels } = useContext(
    MagicFormContext,
  ) as unknown as FormContextType<AtomType>;
  return (labels?.[field]?.label ?? field) as string;
};

export const MagicInput = <AtomType extends AtomData = AtomData>({
  field,
  validate,
  label,
}: MagicInputProps<AtomType>) => {
  const { currentValue, checkFieldPermission } = useMagicForm();
  const mayUpdate = checkFieldPermission(field, GenericPermission.Update);
  return (
    <Input name={field} disabled={!mayUpdate} value={currentValue[field]} />
  );
};

export const useMagicForm = <
  AtomType extends AtomData = AtomData,
  PermissionsType extends string = string,
>() => {
  return useContext(MagicFormContext) as unknown as FormContextType<
    AtomType,
    PermissionsType
  >;
};
