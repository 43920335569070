import type { ContextValues } from "@atoms/atom-types";
import { cloneDeep, isEqual } from "lodash";
import { useContext, useLayoutEffect, useReducer, useRef } from "react";
import { AtomStoreContext } from "./AtomStoreProvider";
import { useCombinedContext } from "./useCombinedContext";

export type SelectorFunction<TState, TSelected = any> = (
  state: TState,
) => TSelected;

export class AtomNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "AtomNotFoundError";
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function makeUseAtom<AtomType extends string, AtomDataFromAtomType>() {
  /**
   * use atom data only by type
   * @param type which atom to use
   * @param skip optional parameter to skip subscription */
  function useAtom<
    TType extends keyof AtomDataFromAtomType,
    IType extends AtomDataFromAtomType[TType],
  >(type: TType): IType | undefined | null;
  /**
   * use atom data and supply additional context
   * @param type which atom to use
   * @param extraContext additional context
   * @param skip optional parameter to skip subscription
   */
  function useAtom<
    TType extends keyof AtomDataFromAtomType,
    IType extends AtomDataFromAtomType[TType],
  >(
    type: TType,
    extraContext?: ContextValues,
    skip?: boolean,
  ): IType | undefined | null;
  /**
   * use atom data with selector function and supply additional context
   * @param type which atom to use
   * @param selector function to select data from atom (like in useSelector)
   * @param extraContext additional context
   * @param skip optional parameter to skip subscription
   */
  function useAtom<
    TType extends keyof AtomDataFromAtomType,
    IType extends AtomDataFromAtomType[TType],
    TSelected,
  >(
    type: TType,
    selector?: SelectorFunction<IType, TSelected>,
    extraContext?: ContextValues,
    skip?: boolean,
  ): TSelected | undefined | null;
  /**
   *  use atom data with selector function and supply additional context
   *  this variant allows specifying RType via the selector function
   * @param type which atom to use
   * @param selector function to select data from atom (like in useSelector)
   * @param extraContext additional context
   * @param skip optional parameter to skip subscription
   */
  function useAtom<RType extends object, TSelected = RType>(
    type: AtomType,
    selector: SelectorFunction<RType, TSelected>,
    extraContext?: ContextValues,
    skip?: boolean,
  ): TSelected | undefined | null;
  /**
   *  use atom data and supply additional context with identity function as selector
   * @param type which atom to use
   * @param extraContext additional context
   * @param skip optional parameter to skip subscription
   */
  function useAtom<RType extends object, TSelected = RType>(
    type: AtomType,
    extraContext?: ContextValues,
    skip?: boolean,
  ): TSelected | undefined | null;
  function useAtom<RType extends object, TSelected = RType>(
    type: AtomType,
    selectorOrExtraContext?: ContextValues | SelectorFunction<RType, TSelected>,
    extraContextOrSkip?: ContextValues | boolean,
    skip?: boolean,
  ): TSelected | undefined | null {
    const equalityFn = isEqual;
    const selector =
      typeof selectorOrExtraContext === "function"
        ? selectorOrExtraContext
        : (t: RType): TSelected => t as unknown as TSelected;

    let finalExtraContext: ContextValues | undefined;
    let shouldSkip = false;

    if (typeof selectorOrExtraContext === "object") {
      finalExtraContext = selectorOrExtraContext;
      shouldSkip =
        typeof extraContextOrSkip === "boolean" ? extraContextOrSkip : false;
    } else {
      finalExtraContext =
        typeof extraContextOrSkip === "object" ? extraContextOrSkip : undefined;
      shouldSkip = typeof skip === "boolean" ? skip : false;
    }

    const atomStore = useContext(AtomStoreContext)!;
    const combinedContext = useCombinedContext();
    const context: ContextValues<string> = {
      ...combinedContext,
      ...finalExtraContext,
    };

    // if (type === AtomType.UserMapping && context["<TableId>"] === undefined) {
    //     console.log(context);
    //     console.trace();
    // }

    const latestSelector = useRef<(arg0: RType) => TSelected>();
    const latestSelectedState = useRef<TSelected | null>();
    const [, forceRender] = useReducer((s) => s + 1, 0);
    let selectedState: TSelected | null | undefined = undefined;

    const latestSubscriptionCallbackError = useRef<Error>();
    // checkParams: {
    //     /* this scope will not be changed during re-generation */
    //     const apolloWsState = useSelector((state: State) => state.apollo.state);

    //     useLayoutEffect(() => {
    //         if (apolloWsState === "terminated") {
    //             dispatch(ApolloWsApi.removeSubscription(atomId));
    //             subscriptions[atomId]!.current?.unsubscribe();
    //         }
    //     }, [apolloWsState]);
    // }

    const prevSelectedState = useRef<TSelected | null>();

    try {
      if (
        selector !== latestSelector.current ||
        latestSubscriptionCallbackError.current
      ) {
        const currentStoreValue = shouldSkip
          ? undefined
          : atomStore.getAtom(type, context);
        const newSelectedState =
          currentStoreValue && cloneDeep(selector(currentStoreValue));
        if (!equalityFn(newSelectedState, prevSelectedState.current)) {
          selectedState = newSelectedState;
        } else {
          selectedState = prevSelectedState.current;
        }
      } else {
        selectedState = latestSelectedState.current;
      }
    } catch (err: any) {
      if (
        latestSubscriptionCallbackError.current &&
        latestSubscriptionCallbackError.current.stack
      ) {
        err.message += `\nThe error may be correlated with this previous error:\n${latestSubscriptionCallbackError.current.stack}\n\n`;
      }

      throw err;
    }

    useLayoutEffect(() => {
      latestSelector.current = selector;
      latestSelectedState.current = cloneDeep(selectedState);
      prevSelectedState.current = selectedState;
      latestSubscriptionCallbackError.current = undefined;
    });

    useLayoutEffect(() => {
      if (shouldSkip) {
        return;
      }

      function checkForUpdates(): void {
        try {
          const currentStoreValue = atomStore.getAtom(type, context);
          const newSelectedState =
            currentStoreValue && latestSelector.current!(currentStoreValue);

          if (equalityFn(newSelectedState, latestSelectedState.current)) {
            return;
          }
          latestSelectedState.current = cloneDeep(newSelectedState);
        } catch (err: any) {
          latestSubscriptionCallbackError.current = err;
          forceRender();
          throw err;
        }

        forceRender();
      }

      atomStore.subscribe(type, context, checkForUpdates, selector);
      checkForUpdates();

      return () => {
        void atomStore.unsubscribe(type, context, checkForUpdates);
      };
    }, [type, JSON.stringify(context), shouldSkip]);

    if (selectedState === null) {
      throw new AtomNotFoundError(`Atom ${type} not found`);
    }

    return selectedState;
  }

  return useAtom;
}

export const __useAtom = makeUseAtom<string, unknown>();
