import { useScopedStore } from "@atoms/atom-client";
import type { GenericActions } from "@atoms/atom-client";
import { useIdentifier } from "@atoms/atom-client";
import { Identifiers } from "@project/shared";
import { type StateCreator, useStore } from "zustand";

interface WorkpackageListState {
  expanded: string[];
  favorites: { [code: string]: true };
}

interface WorkpackageListActions {
  toggleFavorite: (code: string) => void;
}

type WorkpackageList = WorkpackageListState & WorkpackageListActions;

const defaultWorkpackageListState: WorkpackageListState = {
  expanded: ["all"],
  favorites: {},
};

const workpackageListStoreInitializer: StateCreator<WorkpackageList> = (
  set,
) => ({
  ...defaultWorkpackageListState,
  toggleFavorite: (code) =>
    set((state) => {
      const newFavorites = { ...state.favorites };
      if (newFavorites[code]) {
        delete newFavorites[code];
      } else {
        newFavorites[code] = true;
      }
      return { ...state, favorites: newFavorites };
    }),
});

export const useWorkpackageListStore = <Selected>(
  selector: (
    state: WorkpackageList & GenericActions<WorkpackageListState>,
  ) => Selected,
) => {
  const projectId = useIdentifier(Identifiers.ProjectId);
  const organizationId = useIdentifier(Identifiers.OrganizationId);

  const store = useScopedStore<WorkpackageList>(
    "workpackageList",
    [organizationId, projectId],
    workpackageListStoreInitializer,
  );
  if (!store) {
    throw new Error("Project store not found");
  }
  (globalThis as any).store = store;

  return useStore(store, selector);
};
