import {
  faChevronDown,
  faClockRotateLeft,
  faDiagramProject,
  faHome,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tag } from "antd";
import { Header as AntHeader } from "antd/es/layout/layout";
import Menu, { type MenuProps } from "antd/es/menu/menu";
import SkeletonAvatar from "antd/es/skeleton/Avatar";
import { type ReactNode, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UIElement } from "../UIElements";
import { useActiveProject } from "../hooks/useScopeAwareNavigate";
import { ENVIRONMENTS } from "../util/constants";
import "./header.scss";
import { buildProjectRoutePath, ProjectRoutePaths } from "./ProjectRoutes";
import { DataProtectionDeclarationModal } from "./modals/DataProtectionDeclarationModal";
import { ImprintModal } from "./modals/ImprintModal";
import { UserButton } from "./UserButton";
import { ProjectSelector } from "./Header/ProjectSelector";

interface HeaderProps {
  mock?: boolean;
  leftChildren?: ReactNode;
  rightChildren?: ReactNode;
}
export const Header = ({ leftChildren, rightChildren, mock }: HeaderProps) => {
  const { projectId, organizationId } = useActiveProject();
  const navigate = !mock ? useNavigate() : () => {};
  const location = !mock ? useLocation() : { pathname: "" };
  const environment = import.meta.env.HOSTING_ENVIRONMENT;
  const [current, setCurrent] = useState(location.pathname);

  useLayoutEffect(() => {
    setCurrent(location.pathname);
  }, [location.pathname]);

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "link") {
      return;
    }

    navigate(key);
  };

  const itemsLeft: MenuProps["items"] = [
    ...(projectId && organizationId
      ? [
          {
            label: "Arbeitspakete",
            key: buildProjectRoutePath(
              projectId,
              organizationId,
              ProjectRoutePaths.Home,
            ),
            className: UIElement.Header_Home,
            icon: <FontAwesomeIcon icon={faHome} />,
          },
          {
            label: "ToDos",
            key: buildProjectRoutePath(
              projectId,
              organizationId,
              ProjectRoutePaths.Todos,
            ),
            className: UIElement.Header_ToDos,
            icon: <FontAwesomeIcon icon={faListCheck} />,
          },
          {
            label: "WBS",
            key: "wbs", // this is intentionally not the same then the "Baum" key, otherwise there would be redundant keys
            className: UIElement.Header_WBS,
            icon: <FontAwesomeIcon icon={faDiagramProject} />,
            onTitleClick: () =>
              navigate(
                buildProjectRoutePath(
                  projectId,
                  organizationId,
                  ProjectRoutePaths.WBS,
                ),
              ),
            children: [
              {
                label: "Baum",
                key: buildProjectRoutePath(
                  projectId,
                  organizationId,
                  ProjectRoutePaths.WBS,
                ),
              },
              {
                label: "Dokumentation",
                key: buildProjectRoutePath(
                  projectId,
                  organizationId,
                  ProjectRoutePaths.WBS_Table,
                ),
              },
            ],
          },
          {
            label: "Aktivität",
            className: UIElement.Header_Activity,
            key: buildProjectRoutePath(
              projectId,
              organizationId,
              ProjectRoutePaths.Activity,
            ),
            icon: <FontAwesomeIcon icon={faClockRotateLeft} />,
          },
        ]
      : []),
  ];

  // const itemsRight: MenuProps["items"] = [
  //   ...(projectId && organizationId
  //     ? [
  //         {
  //           label: "Externe Links",
  //           key: "links",
  //           icon: <FontAwesomeIcon icon={faExternalLinkAlt} />,
  //           children: [
  //             {
  //               label: (
  //                 <a
  //                   href="https://eap.flexopus.com/"
  //                   target="_blank"
  //                   rel="noopener noreferrer"
  //                 >
  //                   Flexopus
  //                 </a>
  //               ),
  //               key: "flexopus",
  //               className: UIElement.Header_Flexopus,
  //             },
  //           ],
  //         },
  //       ]
  //     : []),
  // ].filter(Boolean) as MenuProps["items"];

  return (
    <>
      {!mock ? (
        <>
          <DataProtectionDeclarationModal />
          <ImprintModal />
        </>
      ) : null}
      <AntHeader className="header">
        <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          {environment !== ENVIRONMENTS.prd ? (
            <Tag
              color={environment === ENVIRONMENTS.dev ? "green" : "orange"}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
              }}
              closable
            >
              <b>{environment.toUpperCase()} environment</b>
            </Tag>
          ) : null}
          {mock ? (
            <img src="/program51_icon.png" alt="Home" key="logo" />
          ) : (
            <>
              <Link
                to="/"
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <img src="/program51_icon.png" alt="Home" key="logo" />
              </Link>
            </>
          )}
          {!mock ? <ProjectSelector /> : null}
          <Menu
            mode="horizontal"
            onClick={onClick}
            selectedKeys={[
              itemsLeft
                .find((item) => current.endsWith(item?.key?.toString() ?? ""))
                ?.key?.toString() ?? "",
            ]}
            theme="dark"
            items={itemsLeft}
            className="w-full"
          />
          {/* <Menu
            mode="horizontal"
            theme="dark"
            selectable={false}
            items={itemsRight}
            className="pr-7"
          /> */}
        </div>
        {rightChildren}
        {mock ? (
          <Button type="text" style={{ padding: "0px" }} id="user-button">
            <div
              style={{
                height: "inherit",
                display: "flex",
                alignItems: "center",
                marginRight: "0px",
                marginLeft: "10px",
              }}
            >
              <SkeletonAvatar
                active
                size="default"
                className="avatar noWrap bg-white rounded-full !w-[32px] h-[32px]"
                style={{ border: "1px solid white" }}
              />
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  fontSize: ".65rem",
                  transition: "transform .1s",
                  color: "white",
                }}
              />
            </div>
          </Button>
        ) : (
          <UserButton />
        )}
      </AntHeader>
    </>
  );
};
