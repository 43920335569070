import { Collapse } from "antd";
import { ModalsApi } from "../../state/modals";
import { ReduxModal } from "./ReduxModal";

export const TemplateHelpModalId = "templateHelp";

export const TemplateHelpModal = () => {
  return (
    <ReduxModal
      id={TemplateHelpModalId}
      maskClosable
      title="Hilfe zur Erstellung von Berichtsvorlagen"
      dispatcher={() => ModalsApi.hide(TemplateHelpModalId)}
    >
      <h3>Verfügbare Felder</h3>
      <Collapse>
        <Collapse.Panel header="Generisch" key="1">
          <ul>
            <li>
              <code>header_title</code>
            </li>
            <li>
              <code>footer_date</code>
            </li>
            <li>
              <code>footer_pages</code>
            </li>
          </ul>
        </Collapse.Panel>
        <Collapse.Panel header="Arbeitspaket" key="2">
          <ul>
            <li>
              <code>wp_title</code>
            </li>
            <li>
              <code>wp_code</code>
            </li>
            <li>
              <code>wp_state</code>
            </li>
            <li>
              <code>wp_description</code>
            </li>
            <li>
              <code>wp_objective</code>
            </li>
            <li>
              <code>wp_goals</code>
            </li>
            <li>
              <code>wp_planned</code>
            </li>
            <li>
              <code>wp_planned</code>
            </li>
            <li>
              <code>wp_responsible</code>
            </li>
            <li>
              <code>wp_commissioner</code>
            </li>
            <li>
              <code>wp_involved</code>
            </li>
            <li>
              <code>img_disruption</code>
            </li>
            <li>
              <code>img_disruption</code>
            </li>
            <li>
              <code>img_scheduled</code>
            </li>
            <li>
              <code>wp_finished</code>
            </li>
            <li>
              <code>wp_estimated</code>
            </li>
            <li>
              <code>wp_progress</code>
            </li>
            <li>
              <code>progress_time</code>
            </li>
            <li>
              <code>progress_mean</code>
            </li>
            <li>
              <code>wp_progress</code>
            </li>
            <li>
              <code>wp_status</code>
            </li>
          </ul>
        </Collapse.Panel>
        <Collapse.Panel header="Aktivitäten" key="3">
          {"{aktnr}"} ist die laufende Nummer der Aktivität (beginnend bei null,
          sortiert wie im Webinterface)
          <ul>
            <li>
              <code>{"row{aktnr}_id"}</code>
            </li>
            <li>
              <code>{"row{aktnr}_description"}</code>
            </li>
            <li>
              <code>{"row{aktnr}_planned-finish"}</code>
            </li>
            <li>
              <code>{"row{aktnr}_expected-finish"}</code>
            </li>
            <li>
              <code>{"row{aktnr}_current-finish"}</code>
            </li>
            <li>
              <code>{"row{aktnr}_status"}</code>
            </li>
          </ul>
        </Collapse.Panel>
      </Collapse>
      <h3>Tastenkombinationen</h3>
      Für den aktuell ausgewählten Eintrag (hellblauer Rahmen):
      <ul>
        <li>
          Um 1mm bewegen (<kbd>shift</kbd> gedrückt halten um 0.1 mm zu bewegen)
          = <kbd>↑</kbd>, <kbd>→</kbd>, <kbd>↓</kbd>, <kbd>←</kbd>
        </li>
        <li>
          Kopieren ＝ ( <kbd>ctrl</kbd> oder <kbd>⌘</kbd>) +<kbd>c</kbd>
        </li>
        <li>
          Einfügen ＝( <kbd>ctrl</kbd> oder <kbd>⌘</kbd>) +<kbd>v</kbd>
        </li>
        <li>
          Löschen ＝ <kbd>backspace</kbd> oder <kbd>entfernen</kbd>
        </li>
        <li>
          Rückgängig machen ＝( <kbd>ctrl</kbd> oder <kbd>⌘</kbd>) +<kbd>z</kbd>
        </li>
        <li>
          Wiederherstellen ＝ <kbd>ctrl</kbd> +<kbd>y</kbd> oder <kbd>⌘</kbd> +{" "}
          <kbd>shift</kbd>+<kbd>z</kbd>
        </li>
      </ul>
      <h3>Sonstiges</h3>
      <ul>
        <li>
          durch Gedrückthalten von <kbd>shift</kbd> können mehrere Elemente
          gleichzeitig ausgewählt werden
        </li>
        <li>
          es können Hilfslinien aus den Linealen gezogen werden (Achtung, diese
          werden nicht mit gespeichert!)
        </li>
      </ul>
    </ReduxModal>
  );
};
