import { Button } from "antd";
import { useMagicForm } from "./MagicForm";

export const MagicResetButton = () => {
  const { draft, loading, resetDraft } = useMagicForm();

  return (
    <Button
      key={`btn_revert`}
      type="primary"
      danger
      disabled={draft === null || loading}
      onClick={() => resetDraft()}
    >
      Änderungen verwerfen
    </Button>
  );
};
