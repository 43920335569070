import Popconfirm, { type PopconfirmProps } from "antd/es/popconfirm";

export const ConditionalPopconfirm = ({
  disabled,
  children,
  ...props
}: PopconfirmProps) => {
  return disabled ? (
    <>{children}</>
  ) : (
    <Popconfirm {...props}>{children}</Popconfirm>
  );
};
