import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "antd";
import { useRef } from "react";
import {
  CreateOrganizationModal,
  type CreateOrganizationModalRef,
} from "./CreateOrganizationModal";

export const CreateOrganizationCard = () => {
  const createOrganizationModalRef = useRef<CreateOrganizationModalRef>(null);

  return (
    <>
      <CreateOrganizationModal ref={createOrganizationModalRef} />
      <Card
        title="Neue Organisation erstellen"
        style={{ marginBottom: 16 }}
        hoverable
        onClick={() => createOrganizationModalRef.current?.show()}
        classNames={{
          title: "font-normal",
        }}
        className="h-36 border-dashed border-2"
      >
        <div style={{ textAlign: "center" }}>
          <FontAwesomeIcon icon={faPlus} size="lg" className="text-gray-400" />
        </div>
      </Card>
    </>
  );
};
