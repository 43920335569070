import type { FrontendProvision } from "@atoms/atom-client";
import { MainComponent } from "./components/MainComponent";
import { SplashScreenNode } from "./components/SplashScreenNode";
import { useEapContext } from "./hooks/useEapContext";
import { useEapPermissions } from "./hooks/useEapPermissions";
import {
  useEapGetUsernameWithTransaction,
  useEapUserName,
} from "./hooks/useEapUserName";

export const ProjectProvision: FrontendProvision = {
  appComponent: MainComponent,
  splashScreen: SplashScreenNode,
  getContextHook: useEapContext,
  getPermissionsInContext: useEapPermissions,
  getUsernameFromIdHook: useEapUserName,
  getUsernameWithTransaction: useEapGetUsernameWithTransaction,
};
