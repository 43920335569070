import { debounce } from "lodash";

import {
  type ForwardRefRenderFunction,
  type MouseEvent,
  type PropsWithChildren,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import type { Renderer } from "./Renderer";
import { RendererContext } from "./RendererContext";
import type { IRect } from "./SizeAndPos";

export interface ZoomableLayerProps {
  // scaleX: number;
  visibleAreaChanged?: (visibleArea: IRect) => void;
  scaleChanged?: (scale: number) => void;
  height: number;
  autoFitHorizontal?: boolean;
  width: number;
  contentBox: IRect;
  maxPossibleScale?: number;
}

export const LayerContext = createContext<{
  scaleX: number;
  visibleArea: IRect;
} | null>({ scaleX: 1, visibleArea: { x: 0, y: 0, width: 0, height: 0 } });

export enum LOD {
  NOT_VISIBLE = 0,
  OVERVIEW = 1,
  DEFAULT = 2,
  DETAIL = 3,
}

export function useLod(
  elementArea: IRect,
  borders: [number, number] = [1.5, 1],
) {
  const { visibleArea, scaleX } = useContext(LayerContext)!;
  const [lod, setLod] = useState(LOD.DEFAULT);
  useEffect(() => {
    const invisible =
      elementArea.x + elementArea.width < visibleArea.x ||
      elementArea.x > visibleArea.x + visibleArea.width;
    if (invisible) {
      setLod(LOD.NOT_VISIBLE);
    } else {
      if (scaleX > borders[0] && lod !== LOD.DETAIL) {
        setLod(LOD.DETAIL);
      } else if (scaleX > borders[1] && lod !== LOD.DEFAULT) {
        setLod(LOD.DEFAULT);
      } else if (lod !== LOD.OVERVIEW) {
        setLod(LOD.OVERVIEW);
      }
    }
  }, [elementArea, scaleX, visibleArea]);
  return lod;
}

function boundBetween(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export type ZoomableLayerUtils = {
  resetZoomAndPan: () => void;
  zoomIn: () => void;
  zoomOut: () => void;
  makeVisible(area: IRect): void;
  print: (title: string) => Promise<void>;
};

/**
 * @npm: zoomablelayer
 * @param props
 * @param forwardedRef
 * @returns
 */
const ZoomableLayer: ForwardRefRenderFunction<
  ZoomableLayerUtils,
  PropsWithChildren<ZoomableLayerProps>
> = (props, forwardedRef) => {
  const boundingBoxCSP = props.contentBox;
  const { children, autoFitHorizontal } = props;

  const [scaleX, setScaleX] = useState(0.1);
  const [animateTransition, setAnimateTransition] = useState(false);

  const { Rect, Group } = useContext<Renderer>(RendererContext);

  const [visibleAreaCSP, setVisibleArea] = useState<IRect>({
    x: 0,
    y: 0,
    width: 10,
    height: 10,
  });

  const setVisibleAreaWithinBounds = useCallback(
    (newAreaCSP: IRect) => {
      setAnimateTransition(true);
      const boundedWidth = Math.min(newAreaCSP.width, boundingBoxCSP.width);
      const boundedHeight = Math.min(newAreaCSP.height, boundingBoxCSP.height);
      const areaBounded = {
        x: boundBetween(newAreaCSP.x, -20, boundingBoxCSP.width - boundedWidth), // -20 in order to add some padding
        y: boundBetween(
          newAreaCSP.y,
          -20,
          boundingBoxCSP.height - boundedHeight,
        ), // -20 in order to add some padding
        width: boundedWidth,
        height: boundedHeight,
      };

      // if max zoom is reached, center the visible area
      // if (areaBounded.width === boundingBoxCSP.width) {
      //     areaBounded.y = Math.max(
      //         Math.min(areaBounded.y, boundingBoxCSP.y + boundingBoxCSP.height - newAreaCSP.height),
      //         boundingBoxCSP.y,
      //     );
      // }

      // if (areaBounded.height === boundingBoxCSP.height) {
      //     areaBounded.x = Math.max(
      //         Math.min(areaBounded.x, boundingBoxCSP.x + boundingBoxCSP.width - newAreaCSP.width),
      //         boundingBoxCSP.x,
      //     );
      // }

      setVisibleArea(areaBounded);
      props.visibleAreaChanged?.(areaBounded);
    },
    [setVisibleArea, JSON.stringify(boundingBoxCSP)],
  );

  const resetZoomAndPan = () => {
    const maxScale = Math.min(
      autoFitHorizontal
        ? props.width / boundingBoxCSP.width
        : Math.min(
            props.width / boundingBoxCSP.width,
            props.height / boundingBoxCSP.height,
          ) * 0.98,
      props.maxPossibleScale ?? 1,
    );
    setScaleX(maxScale);
    props.scaleChanged?.(maxScale);
    setVisibleAreaWithinBounds({
      x: -20, // -20 in order to add some padding
      y: -20, // -20 in order to add some padding
      width: props.width / maxScale,
      height: props.height / maxScale,
    });
  };

  const debouncedSetVisibleAreaWithinBounds = useCallback(
    debounce(setVisibleAreaWithinBounds, 200, {
      trailing: true,
      leading: false,
    }),
    [],
  );

  const zoom = useCallback(
    (steps: number, pointerX: number, pointerY: number) => {
      const zoomFactor = 1 + Math.min(Math.abs(steps), 30) / 80;
      const minScale =
        Math.min(
          props.width / boundingBoxCSP.width,
          props.height / boundingBoxCSP.height,
        ) * 0.98;
      const newScale = Math.min(
        Math.max(scaleX * (steps < 0 ? zoomFactor : 1 / zoomFactor), minScale),
        props.maxPossibleScale ?? 1,
      );
      props.scaleChanged?.(newScale);
      setScaleX(newScale);
      // props.onWeel?.(e);
      const mouseOffsetCSP = {
        x: pointerX / scaleX,
        y: pointerY / scaleX,
      };
      const offsetXCSP = mouseOffsetCSP.x * (scaleX / newScale - 1);
      const offsetYCSP = mouseOffsetCSP.y * (scaleX / newScale - 1);
      const newVisibleAreaCSP = {
        x: visibleAreaCSP.x - offsetXCSP,
        y: visibleAreaCSP.y - offsetYCSP,
        width: props.width / newScale,
        height: props.height / newScale,
      };
      debouncedSetVisibleAreaWithinBounds(newVisibleAreaCSP);
      setVisibleArea(newVisibleAreaCSP);
    },
    [
      debouncedSetVisibleAreaWithinBounds,
      scaleX,
      props.width,
      props.height,
      JSON.stringify(visibleAreaCSP),
      boundingBoxCSP?.width,
      boundingBoxCSP?.height,
    ],
  );

  useImperativeHandle(
    forwardedRef,
    () => ({
      resetZoomAndPan,
      zoomIn: () => zoom(-40, props.width / 2, props.height / 2),
      zoomOut: () => zoom(40, props.width / 2, props.height / 2),
      makeVisible: (rect: IRect) => {
        // Todo - make this work
        // expand visible area to include the given rect
        // const overlapX =
        //     rect.x < visibleAreaCSP.x
        //         ? rect.x - visibleAreaCSP.x
        //         : rect.x + rect.width > visibleAreaCSP.x + visibleAreaCSP.width
        //         ? rect.x + rect.width - (visibleAreaCSP.x + visibleAreaCSP.width)
        //         : 0;
        // const overlapY =
        //     rect.y < visibleAreaCSP.y
        //         ? rect.y - visibleAreaCSP.y
        //         : rect.y + rect.height > visibleAreaCSP.y + visibleAreaCSP.height
        //         ? rect.y + rect.height - (visibleAreaCSP.y + visibleAreaCSP.height)
        //         : 0;
        // const newVisibleAreaCSP = {
        //     x: visibleAreaCSP.x + overlapX,
        //     y: visibleAreaCSP.y + overlapY,
        //     width: visibleAreaCSP.width,
        //     height: visibleAreaCSP.height,
        // };
        // setVisibleAreaWithinBounds(newVisibleAreaCSP);
      },
      print,
    }),
    [JSON.stringify(visibleAreaCSP), props.width, props.height],
  );

  // reset zoom and pan on initial render
  useLayoutEffect(resetZoomAndPan, []);

  const onWheel = useCallback(
    (e: WheelEvent) => {
      setAnimateTransition(false);
      zoom(e.deltaY, e.offsetX, e.offsetY);
      e.stopPropagation();
      e.preventDefault();
    },
    [zoom],
  );
  // drag and drop:
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [dragStartVisibleArea, setDragStartVisibleArea] = useState<IRect>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const svgGroupRef = useRef<SVGGElement>(null);
  const childSvgRef = useRef<SVGSVGElement>(null);

  const onDragStart = useCallback(
    (e: MouseEvent<SVGGElement>) => {
      if (e.button === 2) {
        // do nothing on right click
        return;
      }
      setAnimateTransition(false);
      setDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
      setDragStartVisibleArea(visibleAreaCSP);
    },
    [JSON.stringify(visibleAreaCSP)],
  );
  const onDragEnd = useCallback(() => {
    setDragging(false);
  }, []);
  const onDrag = useCallback(
    (e: MouseEvent<SVGGElement>) => {
      if (dragging) {
        const offsetCSP = {
          x: (e.clientX - dragStart.x) / scaleX,
          y: (e.clientY - dragStart.y) / scaleX,
        };
        setVisibleAreaWithinBounds({
          x: dragStartVisibleArea.x - offsetCSP.x,
          y: dragStartVisibleArea.y - offsetCSP.y,
          width: dragStartVisibleArea.width,
          height: dragStartVisibleArea.height,
        });
      }
    },
    [dragging, dragStart, dragStartVisibleArea, scaleX],
  );

  useLayoutEffect(() => {
    const dragHandler = (e: any) => onDrag(e);

    document.addEventListener("mousemove", dragHandler);

    return () => document.removeEventListener("mousemove", dragHandler);
  }, [onDrag]);

  const print = useCallback(
    async (title: string) =>
      new Promise<void>((resolve, reject) => {
        if (!childSvgRef.current) {
          reject(new Error("unable to print"));
          return;
        }

        const svgBlob = new Blob(
          [
            `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="-10 -10 ${
              boundingBoxCSP.width + 20
            } ${boundingBoxCSP.height + 20}">${new XMLSerializer().serializeToString(
              childSvgRef.current,
            )}</svg>`,
          ],
          { type: "image/svg+xml" },
        );
        const url = URL.createObjectURL(svgBlob);
        const win = window.open(url, "wbs");
        win?.addEventListener("load", () => {
          win!.document.title = title;
          win!.print();
          URL.revokeObjectURL(url);
          resolve();
        });
        win?.addEventListener("afterprint", () => {
          win!.close();
        });
      }),
    [childSvgRef, boundingBoxCSP.height, boundingBoxCSP.width],
  );

  useEffect(() => {
    if (!svgGroupRef.current) {
      return;
    }

    const wheelCb = (e: any) => {
      e.preventDefault();
      onWheel(e);
    };
    svgGroupRef.current.addEventListener("wheel", wheelCb, false);

    return () => svgGroupRef.current?.removeEventListener("wheel", wheelCb);
  }, [svgGroupRef.current, onWheel]);

  return (
    <LayerContext.Provider value={{ scaleX, visibleArea: visibleAreaCSP }}>
      <Group
        transform={`scale(${scaleX}) translate(${-visibleAreaCSP.x}, ${-visibleAreaCSP.y})`}
        style={{
          transition:
            animateTransition && !dragging ? "transform 0.2s" : undefined,
          touchAction: "none",
          cursor: dragging ? "grabbing" : "grab",
        }}
        onMouseDown={onDragStart}
        onMouseUp={onDragEnd}
        onMouseLeave={onDragEnd}
        ref={svgGroupRef}
      >
        <Rect
          x={boundingBoxCSP.x}
          y={boundingBoxCSP.y}
          style={{ touchAction: "none" }}
          width={99999999}
          height={99999999}
          fill="transparent"
          stroke="none"
          strokeWidth={1}
          onMouseDown={(e) => svgGroupRef.current?.onmousedown?.(e as any)}
          onMouseUp={(e) => svgGroupRef.current?.onmouseup?.(e as any)}
          onMouseLeave={(e) => svgGroupRef.current?.onmouseleave?.(e as any)}
        />
        <g ref={childSvgRef}>{children}</g>
      </Group>
    </LayerContext.Provider>
  );
};

export default forwardRef(ZoomableLayer);
