import { PageHeader } from "@ant-design/pro-layout";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faQuestion, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons/faFileDownload";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons/faFileUpload";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faUndo } from "@fortawesome/free-solid-svg-icons/faUndo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Designer } from "@pdfme/ui";
import { useApiClient } from "@project/api";
import { Atoms, type ITemplateInfo, Identifiers } from "@project/shared";
import { Button, Input, type InputRef, Select } from "antd";
import ButtonGroup from "antd/es/button/button-group";
import { Content } from "antd/es/layout/layout";
import saveAs from "file-saver";
import { isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useAsyncEffect, useAsyncLayoutEffect } from "../hooks/useAsyncEffect";
import { ModalsApi } from "../state/modals";
import { useThunkDispatch } from "../useThunkDispatch";
import { fetchFonts, fileToBase64 } from "../util/renderReport";
import "./TemplateEditor.scss";
import { useAtom } from "@project/api";
import {
  CreateTemplateModal,
  CreateTemplateModalId,
} from "./modals/CreateTemplateModal";
import {
  TemplateHelpModal,
  TemplateHelpModalId,
} from "./modals/TemplateHelpModal";
import { AsyncButton } from "./reusable/AsyncButton";

export default function TemplateEditor() {
  const ref = useRef<HTMLDivElement>(null);
  const basePdfUploadRef = useRef<InputRef>(null);
  const templateUploadRef = useRef<InputRef>(null);
  const [designer, setDesigner] = useState<Designer>();
  const dispatch = useThunkDispatch();

  const templates = useAtom(Atoms.TemplateList, (l) => l.entries);
  const [selectedTemplateName, setSelectedTemplateName] = useState<
    string | undefined
  >(templates?.[0]?.name);
  const [basePDF, setBasePDF] = useState<string | undefined>(undefined);

  const [templateDraft, setTemplateDraft] = useState<
    ITemplateInfo | null | undefined
  >(undefined);

  const api = useApiClient({
    [Identifiers.TemplateName]: selectedTemplateName,
  });

  useAsyncLayoutEffect(async () => {
    if (!selectedTemplateName) {
      setTemplateDraft(null);
    } else {
      setTemplateDraft(await api.Template.read());
    }
  }, [selectedTemplateName]);

  useAsyncEffect(async () => {
    if (!ref.current) {
      return;
    }

    if (!templateDraft) {
      setDesigner(undefined);
      return;
    }

    const font = await fetchFonts();

    if (selectedTemplateName) {
      const d = new Designer({
        domContainer: ref.current,
        template: templateDraft.templateData as any,
        options: {
          font,
        },
      });

      setDesigner(d);
    }
  }, [ref, templateDraft]);

  useEffect(() => {
    if (templateDraft && basePDF) {
      setTemplateDraft({
        ...templateDraft,
        templateData: {
          ...templateDraft.templateData,
          basePdf: basePDF,
        },
      });
    }
  }, [basePDF, templateDraft]);

  return (
    <Content>
      <CreateTemplateModal />
      <TemplateHelpModal />
      <PageHeader
        title="Template Editor"
        extra={[
          <Select
            key="template"
            onChange={(v) =>
              setSelectedTemplateName(
                templates?.find((t) => t.name === v)?.name,
              )
            }
            value={selectedTemplateName}
            style={{ width: "400px" }}
          >
            {templates?.map((t) => (
              <Select.Option key={t.name}>{t.name}</Select.Option>
            ))}
          </Select>,
          <ButtonGroup key="save-buttons">
            <AsyncButton
              onClick={async () => {
                if (!designer) return;
                await api.Template.update({
                  templateData: (designer as any).template,
                });
              }}
              type="primary"
              icon={<FontAwesomeIcon icon={faSave} />}
              disabled={
                !templateDraft ||
                isEqual(templateDraft.templateData, (designer as any)?.template)
              }
            >
              Speichern
            </AsyncButton>
            <AsyncButton
              onClick={async () => {
                if (!designer || !selectedTemplateName) return;
                await api.Template.delete(selectedTemplateName);
                setSelectedTemplateName(undefined);
              }}
              icon={<FontAwesomeIcon icon={faTrash} />}
              disabled={!selectedTemplateName}
              title="Löschen"
            />
            <AsyncButton
              onClick={async () => {
                if (!designer || !templateDraft) return;
                setTemplateDraft(await api.Template.read());
              }}
              icon={<FontAwesomeIcon icon={faUndo} />}
              disabled={
                !templateDraft ||
                isEqual(templateDraft.templateData, (designer as any)?.template)
              }
              title="Änderungen zurücksetzen"
            />
          </ButtonGroup>,
          <Input
            key="restore"
            accept=".json"
            type="file"
            onChange={async () => {
              const file = templateUploadRef.current?.input?.files?.[0];
              if (!file) return;

              await api.Template.update({
                templateData: JSON.parse(await file.text()),
              });
            }}
            style={{ display: "none" }}
            ref={templateUploadRef}
          />,
          <ButtonGroup key="download-buttons">
            <Button
              disabled={!selectedTemplateName}
              onClick={() => {
                const str = JSON.stringify(templateDraft!.templateData);
                const bytes = new TextEncoder().encode(str);
                const blob = new Blob([bytes], {
                  type: "application/json;charset=utf-8",
                });

                saveAs(blob, `${templateDraft!.name}.json`);
              }}
              icon={<FontAwesomeIcon icon={faFileDownload} />}
              title="Vorlage herunterladen"
            />
            <Button
              icon={<FontAwesomeIcon icon={faFileUpload} />}
              onClick={() => templateUploadRef.current?.input?.click()}
              disabled={!selectedTemplateName}
              title="Vorlage hochladen"
            />
          </ButtonGroup>,
          <Input
            key="base-pdf"
            accept=".pdf"
            type="file"
            onChange={async () => {
              // setBasePDF(await fileToBase64(file.target.value));
              const file = basePdfUploadRef.current?.input?.files?.[0];

              if (!file) return;

              setBasePDF(await fileToBase64(file));
            }}
            style={{ display: "none" }}
            ref={basePdfUploadRef}
          />,
          <Button
            key="base-pdf-upload"
            icon={<FontAwesomeIcon icon={faFileUpload} />}
            onClick={() => basePdfUploadRef.current?.input?.click()}
            disabled={!selectedTemplateName}
          >
            Hintergrund hochladen
          </Button>,
          <AsyncButton
            key="new"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => dispatch(ModalsApi.show(CreateTemplateModalId))}
          >
            Neu
          </AsyncButton>,
          <Button
            key="help"
            icon={<FontAwesomeIcon icon={faQuestion} />}
            onClick={() => dispatch(ModalsApi.show(TemplateHelpModalId))}
          />,
        ]}
      />
      {selectedTemplateName ? (
        <div
          ref={ref}
          style={{ height: "calc(100vh-198px)" }}
          className="templateEditor"
        />
      ) : null}
    </Content>
  );
}
