import { type AtomData, GenericPermission } from "@atoms/atom-types";
import { Switch } from "antd";
import SkeletonInput from "antd/es/skeleton/Input";
import { useContext } from "react";
import { MagicFormContext, useLabel } from "./MagicForm";

export const MagicSwitch = <AtomType extends AtomData = AtomData>({
  field,
  className,
}: {
  field: keyof AtomType & string;
  className?: string;
}) => {
  const { updateDraft, currentValue, loading, checkFieldPermission } =
    useContext(MagicFormContext)!;
  const mayUpdate = checkFieldPermission(field, GenericPermission.Update);
  const label = useLabel(field);

  return loading ? (
    <SkeletonInput active style={{ height: 48, width: 120 }} />
  ) : (
    <div className="float-label" style={{ width: "120px" }}>
      <div className="label label-float">{label}</div>
      <div className="switch">
        <Switch
          className={`${className} msw-label-${label.replace(/\s/g, "-")}`}
          checked={currentValue[field]}
          key={`${field}_input_decision-required`}
          disabled={!mayUpdate}
          checkedChildren="ja"
          unCheckedChildren="nein"
          defaultChecked={currentValue[field]}
          onChange={(value) => {
            updateDraft({
              decisionRequired: value,
            });
          }}
        />
      </div>
    </div>
  );
};
