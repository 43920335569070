import {
  faBarsProgress,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom, usePersonalContext } from "@project/api";
import { Atoms, Identifiers, type IProjectInfo } from "@project/shared";
import { Button, Dropdown, type MenuProps } from "antd";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useEapContext } from "../../hooks/useEapContext";
import { UIElement } from "../../UIElements";
import { buildCoreRoutePath, CoreRouteElements } from "../../CoreApp";
import type { MenuItemGroupType, MenuItemType } from "antd/es/menu/interface";
import { uniqBy } from "lodash";
import { buildProjectRoutePath, ProjectRoutePaths } from "../ProjectRoutes";

export const ProjectSelector = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const personalContext = usePersonalContext();
  const {
    [Identifiers.OrganizationId]: currentOrganizationId,
    [Identifiers.ProjectId]: currentProjectId,
  } = useEapContext();

  const projects = useAtom(
    Atoms.UserRoles,
    (userRoles) =>
      Object.entries(userRoles.orgs).flatMap(([organizationId, org]) =>
        Object.keys(org.projects).map((projectId) => ({
          projectId,
          organizationId,
        })),
      ),
    personalContext,
  );

  let currentProject: IProjectInfo | null | undefined;
  try {
    currentProject = useAtom(
      Atoms.ProjectInfo,
      (project) => project,
      {
        [Identifiers.OrganizationId]: currentOrganizationId,
        [Identifiers.ProjectId]: currentProjectId,
      },
      !currentOrganizationId || !currentProjectId,
    );
  } catch {
    // this can be safely ignored, as it only happens when the project is not found
  }

  const items: MenuProps["items"] = useMemo(() => {
    const baseItems = [
      projects?.length === 0
        ? {
            label: "Keine Projekte",
            key: "no-projects",
            type: "group" as const,
          }
        : null,
      ...uniqBy(projects, "organizationId").map(({ organizationId }) => ({
        type: "group" as const,
        key: organizationId,
        label: <OrganizationHeader organizationId={organizationId} />,
        children: [],
      })),
      {
        type: "divider" as const,
      },
      {
        label: (
          <Link to={buildCoreRoutePath(CoreRouteElements.Projects)}>
            Zur Projektübersicht
          </Link>
        ),
        key: "overview",
        icon: <FontAwesomeIcon icon={faBarsProgress} />,
      },
    ];

    (projects ?? []).forEach(({ projectId, organizationId }) => {
      const existingGroup = baseItems?.find(
        (item) => item?.type === "group" && item?.key === organizationId,
      );
      const projectItem = {
        label: (
          <ProjectSelectorItem
            projectId={projectId}
            organizationId={organizationId}
            active={
              currentOrganizationId === organizationId &&
              currentProjectId === projectId
            }
          />
        ),
        key: `${organizationId}-${projectId}`,
      };
      if (existingGroup) {
        (existingGroup as MenuItemGroupType<MenuItemType>).children?.push(
          projectItem,
        );
      }
    });

    return baseItems;
  }, [projects, currentOrganizationId, currentProjectId]);

  return (
    <>
      <Dropdown
        placement="bottomLeft"
        onOpenChange={(open) => setDropdownVisible(open)}
        menu={{ items }}
        mouseEnterDelay={0.2} //seconds
      >
        <Button
          type="text"
          className={clsx("ml-8 mr-4 p-2", UIElement.Header_ProjectSelector)}
          id="user-button"
        >
          <div className="h-full flex items-center text-white">
            <span>{currentProject ? currentProject.name : "Projekte"}</span>
            <FontAwesomeIcon
              rotation={dropdownVisible ? 180 : undefined}
              icon={faChevronDown}
              className="ml-2.5 text-[0.65rem] transition-transform"
            />
          </div>
        </Button>
      </Dropdown>
    </>
  );
};

const ProjectSelectorItem = ({
  projectId,
  organizationId,
  active,
}: { projectId: string; organizationId: string; active: boolean }) => {
  const project = useAtom(Atoms.ProjectInfo, (project) => project, {
    [Identifiers.OrganizationId]: organizationId,
    [Identifiers.ProjectId]: projectId,
  });

  return (
    <Link
      to={buildProjectRoutePath(
        projectId,
        organizationId,
        ProjectRoutePaths.Home,
      )}
      className={active ? "font-bold" : ""}
    >
      {project?.name}
    </Link>
  );
};

const OrganizationHeader = ({ organizationId }: { organizationId: string }) => {
  const organizationName = useAtom(
    Atoms.OrganizationInfo,
    (organization) => organization.name,
    {
      [Identifiers.OrganizationId]: organizationId,
    },
  );

  return <div>Organisation "{organizationName}"</div>;
};
