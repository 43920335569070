import type { FieldPropertiesMap } from "@atoms/atom-types";
import type { ITodoList } from "../interfaces/ITodoList";

export const todoListLabels: FieldPropertiesMap<ITodoList> = {
  label: { label: "Titel" },
  responsibleUserId: { label: "Verantwortliche/r" },
  involvedUserIds: { label: "Beteiligte" },
  description: { label: "Beschreibung" },
  dependentWbsElements: { label: "abhängige WBS-Elemente" },
  todos: { label: "ToDos" },
  deleted: { label: "gelöscht" },
  done: { label: "abgeschlossen" },
};
