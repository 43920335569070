import { OpenAI } from "openai";

if (!localStorage.getItem("openai-api-key")) {
  localStorage.setItem("openai-api-key", "sk-proj-1234567890");
}

export const openaiClient = new OpenAI({
  apiKey: localStorage.getItem("openai-api-key")!,
  dangerouslyAllowBrowser: true,
});
