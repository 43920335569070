import { DefaultIdentifiers } from "@atoms/atom-types";
import { Identifiers } from "@project/shared";
import { useParams } from "react-router-dom";
import { useAppStore } from "../state/app";

export type EapContextValues = {
  [key in Identifiers | DefaultIdentifiers]?: any;
};

export function useEapContext(): EapContextValues {
  const defaultProject = useAppStore((state) => state.defaultProject);
  const { code, templateName, todoListId, organizationId, projectId } =
    useParams<{
      code?: string;
      templateName?: string;
      todoListId?: string;
      organizationId?: string;
      projectId?: string;
    }>();
  return {
    [Identifiers.Code]: code,
    [DefaultIdentifiers.Version]: "latest",
    [Identifiers.TemplateName]: templateName,
    [Identifiers.TodoListId]: todoListId,
    [Identifiers.OrganizationId]:
      organizationId && organizationId !== "-"
        ? organizationId
        : defaultProject?.orgId,
    [Identifiers.ProjectId]:
      organizationId && organizationId !== "-"
        ? projectId
        : defaultProject?.projectId,
  };
}
