import { Layout, Spin } from "antd";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import { Content } from "antd/es/layout/layout";
import type { ReactNode } from "react";

export const SplashScreenNode: ReactNode = (
  <ErrorBoundary key="splash">
    <Layout style={{ minHeight: "100vh" }}>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
      </Content>
    </Layout>
  </ErrorBoundary>
);
