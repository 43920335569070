import { Atoms, useAtom, validateCode } from "@project/api";
import { useCodes } from "./useCodes";

export const useCodeValidation = (code: string, oldCode?: string) => {
  let help: string | undefined = undefined;
  const codes = useCodes();
  const codeSchema = useAtom(Atoms.ProjectInfo, (info) => info.codeSchema);
  const withoutLastPart = code.split(".").slice(0, -1).join(".");
  if (
    withoutLastPart &&
    (!codes[withoutLastPart] || withoutLastPart === oldCode)
  ) {
    help = `Übergeordnetes Element ${withoutLastPart} nicht vorhanden`;
  }
  if (codeSchema && !validateCode(code, codeSchema)) {
    help = "Ungültiges Code Format";
  }

  if (codes[code as string] && code !== oldCode) {
    help = `Code bereits vergeben für ${codes[code as string]}`;
  }
  return help;
};
