import type { ICompactCategory, IProjectTreeElement } from "@project/shared";
import { find } from "lodash";

// returns the category path which are leading to a specific code
export const getCategoryPath = (
  categories: IProjectTreeElement<ICompactCategory>[],
  code: string,
  includeLast = false,
) => {
  if (categories.length === 0 || code === "") return;

  const categoryTree = new Array<IProjectTreeElement<ICompactCategory>>();

  const categoryCodeTokens = code.split(".");
  for (
    let index = categoryCodeTokens.length - (includeLast ? 0 : 1);
    index > 0;
    index--
  ) {
    const categoryCode = [...categoryCodeTokens].splice(0, index).join(".");
    const category = find(categories, { code: categoryCode });

    if (category) {
      categoryTree.push(category);
    }
  }

  return categoryTree.reverse();
};
