/*
 * This file has been generated by Add Atom
 * Hash: a8d47e47a01d53c89e1208bcdf2f3f0d5939d584fb40dead6d5eb091ddda240a
 */

export enum Atoms {
  CategoryInfo = "CategoryInfo",
  CategoryLatest = "CategoryLatest",
  WorkpackageInfo = "WorkpackageInfo",
  WorkpackageLatest = "WorkpackageLatest",
  ProjectTree = "ProjectTree",
  Licenses = "Licenses",
  ProjectTreeSnapshot = "ProjectTreeSnapshot",
  VersionList = "VersionList",
  BookedLicenses = "BookedLicenses",
  Draft = "Draft",
  ProjectMembers = "ProjectMembers",
  ProjectActivity = "ProjectActivity",
  TemplateInfo = "TemplateInfo",
  TemplateLatest = "TemplateLatest",
  TemplateList = "TemplateList",
  TodoListList = "TodoListList",
  TodoListInfo = "TodoListInfo",
  TodoListLatest = "TodoListLatest",
  ProjectInfo = "ProjectInfo",
  OrganizationInfo = "OrganizationInfo",
  OrganizationMembers = "OrganizationMembers",
  UserRoles = "UserRoles",
  OrganizationList = "OrganizationList",
  ProjectList = "ProjectList",
}
