import { MagicSaveButton } from "@atoms/atom-components";
import { organizationInfoLabels, useApiClient } from "@project/api";
import { Col, Row } from "antd";
import { memo } from "react";
import { OrganizationInfoForm } from "../../generated/forms/OrganizationInfo.form";
import { useScopeAwareNavigate } from "../../hooks/useScopeAwareNavigate";

export interface OrganizationInfoEditorProps {
  readonly?: boolean;
}

export const OrganizationInfoEditor = memo(
  ({ readonly }: OrganizationInfoEditorProps) => {
    const api = useApiClient();
    const navigate = useScopeAwareNavigate();

    return (
      <OrganizationInfoForm
        onSubmit={async (draft) => {
          await api.Organization.update(draft);
        }}
        labels={organizationInfoLabels}
      >
        <div className="organizationInfoEditor printable">
          <Row gutter={[16, 16]} className="py-4">
            <Col span={24}>
              <OrganizationInfoForm.TextareaOrDiv field="name" />
            </Col>
            <Col span={24}>
              <OrganizationInfoForm.TextareaOrDiv field="description" />
            </Col>
            <Col span={24}>
              <MagicSaveButton />
            </Col>
          </Row>
        </div>
      </OrganizationInfoForm>
    );
  },
);
