import { useContext } from "react";
import { AtomStoreContext } from "../AtomStoreProvider";

export function useAtomStore() {
  const store = useContext(AtomStoreContext);
  if (!store) {
    throw new Error("AtomStoreContext not found");
  }
  return store;
}
