import type { EapProjectRole, IProjectMembers } from "@project/shared";
import type { DefaultOptionType } from "antd/es/select";

export const getFilteredProjectUserOptions = (
  projectUsersById: IProjectMembers["entries"],
  role?: EapProjectRole,
  filter?: string,
): DefaultOptionType[] => {
  const filteredUsers = Object.entries(projectUsersById).filter(([_, user]) => {
    if (!user.roles) {
      console.trace("user has no roles", user);
    }
    return (
      user.roles.length > 0 &&
      user.name !== "undefined undefined" && // some users without name are not correctly saved in the database
      (role !== undefined ? user.roles.includes(role) : true) &&
      (filter !== undefined
        ? new RegExp(filter, "i").test(user.name ?? "Unbekannt")
        : true)
    );
  });

  return filteredUsers.map<DefaultOptionType>(([userId, user]) => ({
    value: userId,
    label: user.name,
  }));
};

export const nobodyOption: DefaultOptionType[] = [
  { value: "null", label: "Niemand" },
];
