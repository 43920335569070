import type { ITransaction, PublicIdMap } from "@atoms/atom-types";
import type { IProjectInfo } from "@project/api";
import {
  Atoms,
  type ITemplateInfo,
  type IWorkpackage,
  Identifiers,
} from "@project/shared";
import moment from "moment";
import type { ReactElement } from "react";
import type { FilteredPackages } from "../hooks/useFilteredPackages";
import { generatePdf, renderReportTemplate } from "../util/renderReport";

export const renderReports = async (
  transaction: ITransaction,
  filteredPackages: FilteredPackages | undefined,
  userNameById: PublicIdMap<string>,
  onProgress: (percent: number, step: ReactElement) => void,
  signal?: AbortSignal,
) => {
  if (signal && signal.aborted) return;

  const wpTemplate = await transaction.aquireAtomValue<ITemplateInfo>(
    Atoms.TemplateLatest,
    {
      [Identifiers.TemplateName]: "report_workpackage",
    },
  );
  const actTemplate = await transaction.aquireAtomValue<ITemplateInfo>(
    Atoms.TemplateLatest,
    {
      [Identifiers.TemplateName]: "report_activities",
    },
  );

  const projectInfo = await transaction.aquireAtomValue<IProjectInfo>(
    Atoms.ProjectInfo,
  );

  if ((filteredPackages?.length ?? 0) === 0 || !wpTemplate || !actTemplate) {
    throw new Error("Unable to render report, no packages or templates found");
  }

  const reports: Blob[] = [];
  for (const [i, p] of filteredPackages!.entries()) {
    if (signal && signal.aborted) return;

    const wpInfo = await transaction.aquireAtomValue<IWorkpackage>(
      Atoms.WorkpackageLatest,
      {
        [Identifiers.Code]: p.code,
      },
    );
    reports.push(
      ...(await renderReportTemplate(
        { ...(p as unknown as IWorkpackage), activities: wpInfo.activities },
        p.code,
        userNameById,
        wpTemplate,
        actTemplate,
      )),
    );
    onProgress(
      Math.round(((i + 1) / filteredPackages!.length) * 100),
      <span key={p.code}>
        Erstelle Bericht für Arbeitspaket <code>{p.code}</code>
      </span>,
    );
  }

  await generatePdf(reports, `report_${moment().format("YYYYMMDD-HHmm")}.pdf`);
};
