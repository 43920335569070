import { ErrorBoundary } from "react-error-boundary";
import { AtomNotFoundError } from "./makeUseAtom";
import { Button, Result } from "antd";
import { Content } from "antd/es/layout/layout";
import { useNavigate, useInRouterContext, useLocation } from "react-router-dom";
import { useLayoutEffect, useRef } from "react";

export const AtomNotFoundBoundary = ({
  children,
  title,
  subTitle,
}: {
  children: React.ReactNode;
  title: string;
  subTitle: string;
}) => {
  const errorBoundaryRef = useRef<ErrorBoundary>(null);

  // make the error boundary failsafe for use outside of router context
  const inRouterContext = useInRouterContext();
  const navigate = inRouterContext ? useNavigate() : null;
  const location = inRouterContext ? useLocation() : null;

  useLayoutEffect(() => {
    const handleLocationChange = () => {
      errorBoundaryRef.current?.resetErrorBoundary();
    };
    if (!location) {
      // if not in router context location is null and we need to listen to popstate events
      // FIXME: this is not triggered when clicking on links inside the app
      window.addEventListener("popstate", handleLocationChange);
      return () => window.removeEventListener("popstate", handleLocationChange);
    } else {
      window.removeEventListener("popstate", handleLocationChange);
      handleLocationChange();
    }
  }, [location]);

  const handleNavigateHome = () => {
    if (navigate) {
      navigate("/");
    } else {
      window.location.href = "/";
    }
  };

  return (
    <ErrorBoundary
      ref={errorBoundaryRef}
      fallback={
        <Content>
          <Result
            status="404"
            title={title}
            subTitle={subTitle}
            extra={
              <Button type="primary" onClick={handleNavigateHome}>
                Zur Startseite
              </Button>
            }
          />
        </Content>
      }
      onError={(error) => {
        console.error(error);
        if (!(error instanceof AtomNotFoundError)) {
          throw error;
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
