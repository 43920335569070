import { Col, Collapse, Row } from "antd";
import { WorkpackageInfoForm } from "../generated/forms/WorkpackageInfo.form";
import { AverageProgress } from "./AverageProgress";
import { EstimatedProgress } from "./EstimatedProgress";
import { ActivityProgress } from "./MagicProgress";
import { MagicSignal } from "./MagicSignal";
import { TimeProgress } from "./TimeProgress";

export const ControlInformations = () => {
  return (
    <>
      <Collapse
        style={{ width: "100%" }}
        defaultActiveKey={["1"]}
        className="mt-3"
        items={[
          {
            key: "1",
            label: (
              <span className="font-semibold text-sm">
                Steuerungsinformationen
              </span>
            ),
            children: (
              <Row
                gutter={[10, 10]}
                justify="center"
                style={{ pageBreakBefore: "auto" }}
              >
                <Col>
                  <MagicSignal />
                </Col>
                <Col>
                  <Row wrap={false} align="middle">
                    <ActivityProgress />
                  </Row>
                  <Row wrap={false} align="middle">
                    <EstimatedProgress />
                  </Row>
                </Col>
                <Col>
                  <Row wrap={false} align="middle">
                    <TimeProgress />
                  </Row>
                  <Row wrap={false} align="middle">
                    <AverageProgress />
                  </Row>
                </Col>
                <Col
                  flex={1}
                  // without this the col would always expand to 100%
                  style={{ flexBasis: "40%" }}
                >
                  <WorkpackageInfoForm.TextareaOrDiv field="status" />
                </Col>
              </Row>
            ),
          },
        ]}
      />
      <Collapse
        style={{ width: "100%" }}
        defaultActiveKey={["1"]}
        className="mt-3"
        items={[
          {
            key: "1",
            label: (
              <span className="font-semibold text-sm">Änderungsmanagement</span>
            ),
            className: "unprintable-display-none",
            children: (
              <Row
                gutter={[10, 10]}
                justify="center"
                style={{ pageBreakBefore: "auto" }}
              >
                <Col>
                  <WorkpackageInfoForm.DatePicker field="expectedFinish" />
                </Col>
                <Col>
                  <WorkpackageInfoForm.Switch field="decisionRequired" />
                </Col>
                <Col
                  flex={1}
                  // without this the col would always expand to 100%
                  style={{ flexBasis: "35%" }}
                >
                  <WorkpackageInfoForm.TextareaOrDiv field="decisionRequiredReason" />
                </Col>
                <Col
                  flex={1}
                  // without this the col would always expand to 100%
                  style={{ flexBasis: "40%" }}
                >
                  <WorkpackageInfoForm.TextareaOrDiv field="decision" />
                </Col>
              </Row>
            ),
          },
        ]}
      />
    </>
  );
};
