import type { ContextValues } from "@atoms/atom-types";
import { useContext } from "react";
import { ExtraContext } from "./ExtraContext";

import { useFrontendProvision } from "./FrontendProvisionProvider";

export const useCombinedContext = (evenMoreExtraContext?: ContextValues) => {
  const provision = useFrontendProvision();
  const atomContext = provision.getContextHook?.() ?? {};
  const extraContext = useContext(ExtraContext) ?? {};

  return {
    ...atomContext,
    ...extraContext,
    ...evenMoreExtraContext,
  };
};
