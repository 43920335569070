import { makeForm } from "@atoms/atom-components";
import { Atoms, type IWorkpackage, type WorkpackagePermissions } from "@project/shared";
import { useHasPermission } from "../../hooks/useHasPermission";

export const WorkpackageInfoForm = makeForm<
  Atoms.WorkpackageLatest,
  IWorkpackage
>({
  atom: Atoms.WorkpackageLatest,
  // permissions: workpackageInfoPermissions,
});
