import { makeForm } from "@atoms/atom-components";
import {
  Atoms,
  type IOrganizationInfo,
  type OrganizationPermissions,
} from "@project/shared";
import { useHasPermission } from "../../hooks/useHasPermission";

export const OrganizationInfoForm = makeForm<
  Atoms.OrganizationInfo,
  OrganizationPermissions,
  IOrganizationInfo
>({
  atom: Atoms.OrganizationInfo,
  useHasPermission: useHasPermission,
  // permissions: organizationInfoPermissions,
  labels: {
    name: { label: "missing label" },
    description: { label: "missing label" },
  },
});
