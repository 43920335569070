import { Link } from "react-router-dom";

import type { LinkProps } from "react-router-dom";
import { useScopedPath } from "../../hooks/useScopeAwareNavigate";

export const ScopeAwareLink = ({
  to,
  ...props
}: Omit<LinkProps, "to"> & { to: string }) => {
  const path = useScopedPath()(to);

  return <Link to={path} {...props} />;
};
