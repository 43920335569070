import type { PublicIdMap } from "@atoms/atom-types";
import { generate } from "@nightvisi0n/pdfme-generator";
import { type ITemplateInfo, type IWorkpackage, Signal } from "@project/shared";
import FileSaver from "file-saver";
import { orderBy } from "lodash";
import moment from "moment";
import PDFMerger from "pdf-merger-js";
import {
  computeActivityStats,
  computeTimedProgress,
} from "../hooks/useWorkPackage";

export const fetchTemplate = async () => {
  return fetch(`${location.origin}/report-template.json`)
    .then((res) => res.json())
    .catch((err) => console.error(err));
};

export const fetchFonts = async () => {
  const ptSansRegular = await fetch(
    `${location.origin}/fonts/pt-sans-latin-400-normal.woff`,
  ).then((res) => res.arrayBuffer());
  const ptSansBold = await fetch(
    `${location.origin}/fonts/pt-sans-latin-700-normal.woff`,
  ).then((res) => res.arrayBuffer());

  return {
    pt_sans_regular: {
      data: ptSansRegular,
      fallback: true,
    },
    pt_sans_bold: {
      data: ptSansBold,
    },
  };
};

const imageUrlToBase64 = async (url: string): Promise<string> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(this.result as string);
      };
      reader.readAsDataURL(blob);
    } catch (e) {
      reject(e);
    }
  });
};

export const fileToBase64 = async (file: File): Promise<string> => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = function () {
      resolve(this.result as string);
    };
    reader.readAsDataURL(file);
  });
};

const getWpState = (wp: IWorkpackage) => {
  if (wp.progress === 0) {
    return "geplant";
  } else if (wp.done) {
    return "abgeschlossen";
  } else {
    return "in Bearbeitung";
  }
};

export const renderReportTemplate = async (
  wp: IWorkpackage,
  code: string,
  userNameById: PublicIdMap<string>,
  wpTemplate: ITemplateInfo,
  actTemplate: ITemplateInfo,
) => {
  const activities = orderBy(wp.activities ?? {}, ["sortIndex"], ["asc"]);
  const activityCount = Object.keys(activities).length;

  const fonts = await fetchFonts().catch((err) => {
    console.error(err);
    throw new Error("Failed to fetch fonts");
  });
  const progressBar = await imageUrlToBase64(
    `${location.origin}/images/report-progress-bar.png`,
  );

  const activityStats = computeActivityStats(wp);
  const timeProgress = computeTimedProgress(wp);

  (wpTemplate.templateData as any).schemas[0].progress_mean.width =
    (activityStats.combinedProgress / 100) *
    (wpTemplate.templateData as any).schemas[0].progress_mean.width;
  (wpTemplate.templateData as any).schemas[0].progress_time.width =
    (timeProgress / 100) *
    (wpTemplate.templateData as any).schemas[0].progress_time.width;

  const pageCount = Math.ceil(activityCount / 11) + 1;

  for (const key in wp) {
    const x = wp[key as keyof IWorkpackage];
    if (typeof x === "string") {
      (wp[key as keyof IWorkpackage] as string) = x.normalize();
    }
  }

  const wpPdf = await generate({
    template: wpTemplate.templateData as any,
    inputs: [
      {
        header_title: `${wp.label}, ${code}`,
        header_description: "",
        wp_title: wp.label,
        wp_code: code,
        wp_state: getWpState(wp),
        wp_description: wp.description,
        wp_objective: wp.objective,
        wp_goals: wp.goals,
        "wp_planned-start": moment(wp.plannedStart).format("DD.MM.YYYY"),
        "wp_planned-finish": moment(wp.plannedFinish).format("DD.MM.YYYY"),
        wp_responsible: wp.responsibleUserId
          ? userNameById[wp.responsibleUserId] ?? "Unbekannter Nutzer"
          : "",
        wp_commissioner: wp.commissionerUserId
          ? userNameById[wp.commissionerUserId] ?? "Unbekannter Nutzer"
          : "",
        wp_involved:
          wp.involvedUserIds
            ?.map((id) => userNameById[id] ?? "Unbekannter Nutzer")
            .join(", ") ?? "",
        img_disruption:
          wp.signal === Signal.RED
            ? await imageUrlToBase64(
                `${location.origin}/icons/disruption-active.png`,
              )
            : await imageUrlToBase64(`${location.origin}/icons/disruption.png`),
        "img_disruption-likely":
          wp.signal === Signal.YELLOW
            ? await imageUrlToBase64(
                `${location.origin}/icons/disruption-likely-active.png`,
              )
            : await imageUrlToBase64(
                `${location.origin}/icons/disruption-likely.png`,
              ),
        img_scheduled:
          wp.signal === Signal.GREEN
            ? await imageUrlToBase64(
                `${location.origin}/icons/scheduled-active.png`,
              )
            : await imageUrlToBase64(`${location.origin}/icons/scheduled.png`),
        "wp_finished-activities": `${activityStats.finishedActivities}/${activityStats.allActivities}`,
        "wp_estimated-progress": `${wp.progress} %`,
        "wp_progress-time": `${timeProgress} %`,
        progress_time: progressBar,
        progress_mean: progressBar,
        "wp_progress-mean": `${activityStats.combinedProgress} %`,
        wp_status: wp.status ?? "",
        footer_date: moment().format("DD.MM.YYYY HH:mm:ss"),
        footer_pages: `1 / ${pageCount}`,
      },
    ],
    options: {
      font: fonts,
    },
  });

  let acPdf: Uint8Array | undefined;

  if (activityCount > 0) {
    const activityInputs: Record<string, string>[] = [];

    for (let i = 0; i < Math.ceil(activityCount / 11); i++) {
      const pageInputs: Record<string, string> = {
        header_title: `${wp.label}, ${code}`,
        footer_date: moment().format("DD.MM.YYYY HH:mm:ss"),
        footer_pages: `${i + 2} / ${pageCount}`,
      };

      for (let j = i * 11; j < Math.min((i + 1) * 11, activityCount); j++) {
        const activity = Object.values(activities)[j];
        pageInputs[`row${(j % 11) + 1}_id`] = `${j + 1}`.padStart(2, "0");
        pageInputs[`row${(j % 11) + 1}_description`] = activity!.description;
        pageInputs[`row${(j % 11) + 1}_planned-finish`] = activity!
          .plannedFinish
          ? moment(activity!.plannedFinish).format("DD.MM.YYYY")
          : "";
        pageInputs[`row${(j % 11) + 1}_expected-finish`] = activity!
          .expectedFinish
          ? moment(activity!.expectedFinish).format("DD.MM.YYYY")
          : "";
        pageInputs[`row${(j % 11) + 1}_current-finish`] = activity!
          .currentFinish
          ? moment(activity!.currentFinish).format("DD.MM.YYYY")
          : "";
        pageInputs[`row${(j % 11) + 1}_status`] = activity!.status;
      }

      activityInputs.push(pageInputs);
    }
    acPdf = await generate({
      template: actTemplate.templateData as any as any,
      inputs: activityInputs,
      options: {
        font: fonts,
      },
    });
  }

  const blobs: Blob[] = [];

  blobs.push(new Blob([wpPdf.buffer], { type: "application/pdf" }));

  if (acPdf) {
    blobs.push(new Blob([acPdf], { type: "application/pdf" }));
  }

  return blobs;
};

export const generatePdf = async (
  blobs: Blob[],
  filename: string,
): Promise<void> => {
  const merger = new PDFMerger();

  for (const blob of blobs) {
    await merger.add(URL.createObjectURL(blob));
  }

  FileSaver.saveAs(await (merger as any).saveAsBlob(), filename);
};
