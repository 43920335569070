import type { IWorkpackage } from "@project/shared";
import type dayjs from "dayjs";

export const getActivityDateRange = (
  dateOption: dayjs.Dayjs,
  workpackage?: IWorkpackage,
) => {
  if (!workpackage) return false;

  if (
    workpackage.expectedFinish &&
    dateOption.isBefore(
      new Date(workpackage.expectedFinish.getTime() + 86400000),
      "day",
    )
  ) {
    return false;
  } else if (
    workpackage.plannedFinish !== null &&
    dateOption.isAfter(workpackage.plannedFinish, "day")
  ) {
    return true;
  }

  return false;
};
