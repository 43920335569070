import {
  type IActivity,
  type IWorkpackage,
  createActivityDraft,
} from "@project/shared";
import type { InputRef } from "antd";
import DatePicker from "antd/es/date-picker";
import Form from "antd/es/form";
import Col from "antd/es/grid/col";
import TextArea from "antd/es/input/TextArea";
import Row from "antd/es/row";
import dayjs from "dayjs";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { UIElement } from "../../UIElements";
import { ModalsApi } from "../../state/modals";
import { useThunkDispatch } from "../../useThunkDispatch";
import { getActivityDateRange } from "../../util/getActivityDateRange";
import { FloatLabel } from "../reusable/FloatLabel";
import { TagPicker } from "../reusable/TagPicker";
import { ReduxModal } from "./ReduxModal";

export const CreateActivityModalId = "createActivity";

export interface CreateActivityModalRef {
  create: (workpackage: IWorkpackage) => Promise<IActivity>;
}

export const CreateActivityModal = forwardRef<CreateActivityModalRef>(
  (_, ref) => {
    const dispatch = useThunkDispatch();

    const firstInputRef = useRef<InputRef>(null);
    const [form] = Form.useForm();
    const [draft, setDraft] = useState<Partial<IActivity> | null>(null);
    const [resolve, setResolve] = useState<
      null | ((value: IActivity | PromiseLike<IActivity>) => void) | null
    >(null);
    const [reject, setReject] = useState<
      null | ((reason?: any) => void) | null
    >(null);
    const [workpackage, setWorkpackage] = useState<IWorkpackage | null>(null);

    useImperativeHandle(ref, () => ({
      create: (workpackage: IWorkpackage) => {
        setDraft(createActivityDraft());
        dispatch(ModalsApi.show(CreateActivityModalId));
        setWorkpackage(workpackage);
        return new Promise<IActivity>((_resolve, _reject) => {
          setResolve(() => _resolve);
          setReject(() => _reject);
        });
      },
    }));

    const updateDraft = (updatedFields: Partial<IActivity>) => {
      setDraft((prevDraft) => ({ ...prevDraft, ...updatedFields }));
    };

    const handleOk = () => {
      if (draft && resolve) {
        resolve(draft as IActivity);
        setDraft(null);
        setResolve(() => null);
        setReject(() => null);
      }
    };

    const handleCancel = () => {
      if (reject) {
        reject();
        setDraft(null);
        setResolve(() => null);
        setReject(() => null);
      }
    };

    return (
      <ReduxModal
        id={CreateActivityModalId}
        form={form}
        title="Neue Aktivität erstellen"
        firstInputRef={firstInputRef}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {!draft || !workpackage ? null : (
          <Form form={form}>
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Form.Item
                  noStyle
                  rules={[
                    { required: true, message: "Bitte Beschreibung eintragen" },
                  ]}
                >
                  <FloatLabel label="Beschreibung" value={draft.description}>
                    <TextArea
                      className={UIElement.Activity_DescriptionInput}
                      ref={firstInputRef}
                      value={draft.description}
                      onChange={(evt) =>
                        updateDraft({ description: evt.target.value })
                      }
                      autoSize
                    />
                  </FloatLabel>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item noStyle>
                  <FloatLabel
                    label="Enddatum"
                    value={draft.plannedFinish?.toString()}
                  >
                    <DatePicker
                      className={UIElement.Activity_PlannedFinishInput}
                      placeholder={""}
                      popupClassName={UIElement.Activity_PlannedFinishDropdown}
                      format="DD.MM.YYYY"
                      value={
                        draft.plannedFinish
                          ? dayjs(draft.plannedFinish)
                          : undefined
                      }
                      onChange={(moment) =>
                        updateDraft({ plannedFinish: moment?.toDate() })
                      }
                      disabledDate={(option) =>
                        getActivityDateRange(option, workpackage)
                      }
                      inputReadOnly
                    />
                  </FloatLabel>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  noStyle
                  rules={[{ required: false, message: "Label" }]}
                >
                  <TagPicker
                    value={draft.label ?? null}
                    onPick={(id) =>
                      updateDraft({
                        label: id,
                      })
                    }
                    onReset={() =>
                      updateDraft({
                        label: "",
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </ReduxModal>
    );
  },
);
