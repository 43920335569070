import type { IActivity } from "../interfaces/IActivity";

export const createActivityDraft = (): IActivity => ({
  uuid: crypto.randomUUID(),
  description: "",
  status: "",
  plannedFinish: null,
  expectedFinish: null,
  currentFinish: null,
  label: "",
  sortIndex: null,
  deleted: false,
});
