import type { ContextValues } from "@atoms/atom-types";
import { type ReactNode, createContext, useContext } from "react";

export const ExtraContext = createContext<ContextValues>({});

export const SetIdentifier = ({
  identifier,
  value,
  children,
}: {
  identifier: string;
  value: string | undefined;
  children: ReactNode;
}) => {
  const parentContext = useContext(ExtraContext);
  return (
    <ExtraContext.Provider value={{ ...parentContext, [identifier]: value }}>
      {children}
    </ExtraContext.Provider>
  );
};

export const SetExtraContext = ({
  context,
  children,
}: {
  context: ContextValues;
  children: ReactNode;
}) => {
  const parentContext = useContext(ExtraContext);
  return (
    <ExtraContext.Provider value={{ ...parentContext, ...context }}>
      {children}
    </ExtraContext.Provider>
  );
};
