import { PageHeader } from "@ant-design/pro-layout";
import { useAiTemplate } from "@atoms/ai-module/frontend";
import { isDateString, mapValuesDeep, useMyUserId } from "@atoms/atom-client";
import { TextAreaOrDiv } from "@atoms/atom-components";
import type { UserPublicID } from "@atoms/atom-types";
import {
  faCheckCircle as faCheckCircleRegular,
  faClock,
  faSave,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowUp,
  faCheckCircle,
  faClockRotateLeft,
  faExclamationTriangle,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VersionedHistory, useApiClient, useAtom } from "@project/api";
import {
  Atoms,
  EapPermission,
  EapProjectRole,
  type ITodoList,
  type IWorkpackage,
  Identifiers,
  todoListLabels,
} from "@project/shared";
import { Affix, Drawer, FloatButton, Result } from "antd";
import AutoComplete from "antd/es/auto-complete";
import Button from "antd/es/button";
import Col from "antd/es/col";
import Input from "antd/es/input";
import { Content } from "antd/es/layout/layout";
import Popconfirm from "antd/es/popconfirm";
import Row from "antd/es/row";
import Select, { type DefaultOptionType } from "antd/es/select";
import SkeletonInput from "antd/es/skeleton/Input";
import Tag from "antd/es/tag";
import { isEqual } from "lodash";
import {
  type ReactNode,
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Link, useParams } from "react-router-dom";
import { UIElement } from "../UIElements";
import { useCategories } from "../hooks/useCategories";
import { useEapContext } from "../hooks/useEapContext";
import { useHasPermission } from "../hooks/useHasPermission";
import { useReactTransition } from "../hooks/useReactTransition";
import { useScopeAwareNavigate } from "../hooks/useScopeAwareNavigate";
import { useSetTitle } from "../hooks/useSetTitle";
import { computeTodoStats, useWorkPackages } from "../hooks/useWorkPackage";
import { getFilteredProjectUserOptions } from "../util/getFilteredProjectUserOptions";
import { sortByCode } from "../util/sortByWBS";
import { ProjectRoutePaths } from "./ProjectRoutes";
import { renderTodoChanges } from "./RenderSubItemChanges";
import { TodoTable } from "./TodoTable";
import {
  UnsavedChangesContext,
  todoListDraftSessionStoragePrefix,
} from "./UnsavedChangesWarning";
import ToDoRevisionConflictModal, {
  type ToDoRevisionConflictRef,
} from "./modals/ToDoRevisionConflictModal";
import { AsyncButton } from "./reusable/AsyncButton";
import { FloatLabel } from "./reusable/FloatLabel";

export interface TodoListViewProps {
  todoListId?: string;
  readonly?: boolean;
}

const TodoListView = memo(({ todoListId, readonly }: TodoListViewProps) => {
  const api = useApiClient();
  const navigate = useScopeAwareNavigate();
  const params = useParams<{ todoListId: string }>();
  if (!todoListId) {
    todoListId = params.todoListId;
  }
  const toDoRevisionConflict = useRef<ToDoRevisionConflictRef>(null);

  const projectUsersById = useAtom(
    Atoms.ProjectMembers,
    ({ entries }) => entries,
  );
  const todoListFromBackend = useAtom<ITodoList>(Atoms.TodoListLatest);

  const loading = useReactTransition() || todoListFromBackend === undefined;

  const [showHistory, setShowHistory] = useState(false);

  const allLatestWorkPackages = useWorkPackages();
  const categories = useCategories();
  const hasPermission = useHasPermission();
  const myUserId = useMyUserId();
  const responsibleUserId = todoListFromBackend?.responsibleUserId;
  const isResponsible = responsibleUserId === myUserId;
  const involvedUserIds = todoListFromBackend?.involvedUserIds ?? [];
  const isInvolved = involvedUserIds.includes(myUserId as UserPublicID);

  const sessionStorageKey = todoListDraftSessionStoragePrefix + todoListId;
  const [todoListDraft, setTodoListDraft] = useState<Partial<ITodoList> | null>(
    () => {
      const storedData = sessionStorage.getItem(sessionStorageKey);
      if (!storedData) return null;

      const parsedData = JSON.parse(storedData);
      return mapValuesDeep<Partial<IWorkpackage>>(parsedData, (value: any) =>
        isDateString(value) ? new Date(value) : value,
      );
    },
  );
  const unsavedChangesWarning = useContext(UnsavedChangesContext);
  const updateDraft = useCallback(
    (patch: Partial<ITodoList> | null) => {
      if (patch === null) {
        setTodoListDraft(null);
        sessionStorage.removeItem(sessionStorageKey);
        unsavedChangesWarning?.removeUnsavedChange(todoListId!);
        return;
      }

      const newDraft = { ...todoListDraft, ...patch };
      setTodoListDraft(newDraft);
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(newDraft));
      unsavedChangesWarning?.addUnsavedChange(
        "todoList",
        todoListId!,
        newDraft.label ?? "",
      );
    },
    [setTodoListDraft, todoListDraft],
  );

  const mayChangeResposible =
    !readonly && (hasPermission(EapPermission.UpdateTodoList) || isResponsible);

  const mayEditMetaInfo =
    !readonly &&
    (hasPermission([EapPermission.UpdateTodoList]) ||
      isInvolved ||
      isResponsible);

  const [responsibleUserOptions, setResponsibleUserOptions] = useState<
    DefaultOptionType[]
  >(
    projectUsersById
      ? getFilteredProjectUserOptions(
          projectUsersById,
          EapProjectRole.Responsible,
        )
      : [],
  );
  const [involvedUserOptions, setInvolvedUserOptions] = useState<
    DefaultOptionType[]
  >(projectUsersById ? getFilteredProjectUserOptions(projectUsersById) : []);

  // we have to handle the value of the project user input explicitely, because antd internal handling breaks is not properly resettable
  const [projectUserFilterValue, setProjectUserFilterValue] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (!projectUsersById) {
      return;
    }
    setResponsibleUserOptions(
      getFilteredProjectUserOptions(
        projectUsersById,
        EapProjectRole.Responsible,
        projectUserFilterValue,
      ),
    );
  }, [projectUsersById, projectUserFilterValue]);
  useEffect(() => {
    if (!projectUsersById) {
      return;
    }
    setInvolvedUserOptions(getFilteredProjectUserOptions(projectUsersById));
  }, [projectUsersById]);

  // reset project user filters
  useLayoutEffect(() => {
    if (todoListDraft === null) {
      setProjectUserFilterValue(undefined);
    }
  }, [todoListDraft]);

  const todoList = { ...todoListFromBackend!, ...todoListDraft };

  useEffect(() => {
    if (todoListFromBackend === null || todoListFromBackend?.deleted === true) {
      updateDraft(null);
      return;
    }

    if (!todoListFromBackend || !todoListDraft) {
      return;
    }

    const newTodoList = { ...todoListFromBackend, ...todoListDraft };

    if (isEqual(newTodoList, todoListFromBackend)) {
      updateDraft(null);
    }
  }, [todoListDraft, todoListFromBackend]);

  useSetTitle(`ToDos - ${todoList.label ?? "Unbekannt"}`);
  const id = useEapContext()["<TodoListId>"];
  useAiTemplate("help-with-todolist", {
    [Identifiers.TodoListId]: id,
  });
  const todoStats = computeTodoStats(todoList);
  const description =
    todoList.description === "" ? undefined : todoList.description;

  const labels: ReactNode[] = [];
  if (todoStats.combinedProgress === 0) {
    labels.push(
      <Tag key="progress_not-started" icon={<FontAwesomeIcon icon={faClock} />}>
        geplant
      </Tag>,
    );
  } else if (todoList.done) {
    labels.push(
      <Tag
        color="success"
        key="progress_finished"
        icon={<FontAwesomeIcon icon={faCheckCircleRegular} />}
      >
        abgeschlossen
      </Tag>,
    );
  } else {
    labels.push(
      <Tag
        color="processing"
        key="progress"
        icon={<FontAwesomeIcon icon={faSync} />}
      >
        in Bearbeitung
      </Tag>,
    );
  }

  if (todoListFromBackend === null) {
    return (
      <Content>
        <Result
          status="404"
          title="Unbekannte ToDo-Liste"
          subTitle="Diese ToDo-Liste existiert nicht."
          extra={
            <Button
              type="primary"
              onClick={() => navigate(`/-/${ProjectRoutePaths.Todos}`)}
            >
              Zurück
            </Button>
          }
        />
      </Content>
    );
  }

  return (
    <>
      <ToDoRevisionConflictModal
        ref={toDoRevisionConflict}
        draft={todoListDraft}
      />
      <Drawer
        title="Änderungsverlauf"
        placement="right"
        open={showHistory}
        onClose={() => setShowHistory(false)}
        size="large"
        destroyOnClose
      >
        <VersionedHistory
          name="ToDo-Liste"
          atomType={Atoms.TodoListInfo}
          subItem={{ key: "todos", render: renderTodoChanges }}
          labels={todoListLabels}
        />
      </Drawer>
      <FloatButton.Group shape="square" style={{ right: 24 }}>
        <FloatButton.BackTop icon={<FontAwesomeIcon icon={faArrowUp} />} />
      </FloatButton.Group>
      <Content className="mb-10">
        <div className="workPackageEditor printable" style={{ paddingTop: 0 }}>
          <Affix offsetTop={64}>
            <div
              style={{
                backgroundColor: "white",
                marginLeft: "-10px",
                marginRight: "-10px",
                borderBottom: "1px solid rgb(216, 216, 216)",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                height: "63px", // fixed height so that the "offsetHeader" of the tables works
              }}
            >
              <PageHeader
                className="pt-2 pb-2 text-white"
                style={{ marginLeft: "10px", marginRight: "10px" }}
                onBack={() => navigate(`/-/${ProjectRoutePaths.Todos}`)}
                title={
                  loading ? (
                    <SkeletonInput size="small" active />
                  ) : (
                    <Input
                      key={`${todoListId}_input_label`}
                      className="ghost"
                      style={{
                        width:
                          !todoList.label || todoList.label.length < 8
                            ? "10ch"
                            : todoList.label.length + 2 + "ch",
                      }}
                      value={todoList.label ?? ""}
                      disabled={!mayEditMetaInfo}
                      variant={mayEditMetaInfo ? "outlined" : undefined}
                      onChange={(evt) => {
                        updateDraft({ label: evt.target.value });
                      }}
                      classNames={{ input: "font-bold text-lg" }}
                    />
                  )
                }
                subTitle={
                  <Row align="middle" className="headerLabels">
                    {loading ? (
                      <SkeletonInput size="small" />
                    ) : (
                      labels.map((l, idx) => {
                        return <Col key={`${todoListId}_${idx}`}>{l}</Col>;
                      })
                    )}
                  </Row>
                }
                extra={[
                  <Button
                    key="btn_history"
                    onClick={() => setShowHistory(true)}
                  >
                    <FontAwesomeIcon
                      icon={faClockRotateLeft}
                      style={{ margin: 0 }}
                    />
                  </Button>,
                  !readonly && hasPermission(EapPermission.DeleteTodoList) ? (
                    <Popconfirm
                      key="popconfirm_wp_delete"
                      placement="topLeft"
                      title="ToDo-Liste wirklich löschen?"
                      onConfirm={async () => {
                        await api.TodoList.delete();
                        navigate(`/-/${ProjectRoutePaths.Todos}`);
                      }}
                      okText="Löschen"
                      okButtonProps={{
                        type: "primary",
                        danger: true,
                        className: UIElement.Todo_ListConfirmDeleteButton,
                      }}
                      cancelText="Abbrechen"
                      icon={
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          style={{ color: "red" }}
                          className="anticon"
                        />
                      }
                    >
                      <Button
                        className={UIElement.Todo_ListDeleteButton}
                        danger
                        disabled={loading}
                      >
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          title="Löschen"
                          style={{ marginRight: 0 }}
                        />
                      </Button>
                    </Popconfirm>
                  ) : null,
                  !readonly && hasPermission([EapPermission.UpdateTodoList]) ? (
                    todoListFromBackend?.done ? (
                      <AsyncButton
                        key="btn_wp_reopen"
                        disabled={todoListDraft !== null}
                        onClick={async () =>
                          await api.TodoList.update({ done: false })
                        }
                      >
                        ToDo-Liste wieder öffnen
                      </AsyncButton>
                    ) : (
                      <AsyncButton
                        key="btn_wp_close"
                        disabled={
                          todoListDraft !== null ||
                          todoStats?.combinedProgress !== 100
                        }
                        title={
                          todoStats?.combinedProgress !== 100
                            ? "Gemittelter Fortschritt < 100%"
                            : "AP abschließen"
                        }
                        onClick={async () => {
                          await api.TodoList.update({ done: true });
                        }}
                        icon={<FontAwesomeIcon icon={faCheckCircle} />}
                      >
                        ToDo-Liste abschließen
                      </AsyncButton>
                    )
                  ) : null,
                  !readonly && mayEditMetaInfo ? (
                    <Button
                      className={UIElement.Todo_ListRevertButton}
                      key={`${todoListId}_btn_revert`}
                      type="primary"
                      danger
                      disabled={
                        todoListDraft === null ||
                        loading ||
                        isEqual(todoList, todoListFromBackend)
                      }
                      onClick={() => updateDraft(null)}
                    >
                      Änderungen verwerfen
                    </Button>
                  ) : null,
                  !readonly && mayEditMetaInfo ? (
                    <AsyncButton
                      className={UIElement.Todo_ListSaveButton}
                      key={`btn_save`}
                      type="primary"
                      disabled={
                        todoListDraft === null ||
                        isEqual(todoList, todoListFromBackend)
                      }
                      onClick={async () => {
                        await toDoRevisionConflict.current?.handleSave(
                          todoListDraft!,
                        );
                        updateDraft(null);
                      }}
                      icon={<FontAwesomeIcon icon={faSave} />}
                    >
                      Speichern
                    </AsyncButton>
                  ) : null,
                ]}
              />
            </div>
          </Affix>
          <Row gutter={[10, 10]} className="pt-4">
            <Col span={24}>
              {loading ? (
                <SkeletonInput active style={{ height: 48 }} />
              ) : (
                <FloatLabel
                  key={`${todoListId}_label_description`}
                  label={
                    !mayEditMetaInfo &&
                    (!description || description?.length === 0)
                      ? "keine Beschreibung vorhanden"
                      : "Beschreibung"
                  }
                  value={description}
                  className="print-bordered"
                >
                  <TextAreaOrDiv
                    className={UIElement.Todo_ListDescriptionInput}
                    key={`${todoListId}_input_description`}
                    value={description}
                    // bordered={userRole === UserRole.ADMIN}
                    disabled={!mayEditMetaInfo}
                    onChange={(evt) => {
                      updateDraft({
                        description: evt.target.value,
                      });
                    }}
                    autoSize
                  />
                </FloatLabel>
              )}
            </Col>

            <Col flex={1}>
              <Row gutter={[10, 10]}>
                <Col xs={24} md={8}>
                  {loading ? (
                    <SkeletonInput active style={{ height: 48 }} block />
                  ) : (
                    <FloatLabel
                      key={`${todoListId}_label_responsible`}
                      label={
                        !mayChangeResposible &&
                        (!todoList.responsibleUserId ||
                          todoList.responsibleUserId.length === 0)
                          ? "kein(e) Verantwortliche/r vorhanden"
                          : "Verantwortliche/r"
                      }
                      value={
                        projectUserFilterValue ??
                        (projectUsersById && todoList.responsibleUserId
                          ? projectUsersById[todoList.responsibleUserId]?.name
                          : "")
                      }
                      className="print-bordered"
                    >
                      <AutoComplete
                        className={UIElement.Todo_ListResponsibleInput}
                        allowClear
                        key={`${todoListId}_input_responsible`}
                        value={
                          projectUserFilterValue ??
                          (projectUsersById && todoList.responsibleUserId
                            ? projectUsersById[todoList.responsibleUserId]?.name
                            : "")
                        }
                        disabled={!mayChangeResposible}
                        options={responsibleUserOptions}
                        onChange={(value) => {
                          setProjectUserFilterValue(value);
                          if (
                            value === "" &&
                            todoListFromBackend?.responsibleUserId !== null
                          ) {
                            updateDraft({
                              responsibleUserId: null,
                            });
                          }
                        }}
                        onSelect={(value) => {
                          setProjectUserFilterValue(undefined);
                          updateDraft({
                            responsibleUserId: value as UserPublicID,
                          });
                        }}
                        onClear={() => {
                          setProjectUserFilterValue(undefined);
                          updateDraft({
                            responsibleUserId: null,
                          });
                        }}
                      />
                    </FloatLabel>
                  )}
                </Col>
                <Col xs={24} md={8}>
                  {loading ? (
                    <SkeletonInput
                      active
                      style={{ height: 48, marginBottom: "10px" }}
                      block
                    />
                  ) : (
                    <FloatLabel
                      key={`${todoListId}_label_involved`}
                      label={
                        !mayChangeResposible &&
                        (!todoList.involvedUserIds ||
                          todoList.involvedUserIds.length === 0)
                          ? "kein(e) Beteiligte vorhanden"
                          : "Beteiligte"
                      }
                      value={
                        todoList.involvedUserIds &&
                        todoList.involvedUserIds.length > 0
                          ? todoList.involvedUserIds.join(", ")
                          : undefined
                      }
                      style={{ marginBottom: "10px" }}
                      className="print-bordered"
                    >
                      <Select
                        className={UIElement.Todo_ListInvolvedInput}
                        allowClear
                        mode="multiple"
                        key={`${todoListId}_input_involved`}
                        value={
                          todoList.involvedUserIds
                            ? todoList.involvedUserIds
                            : []
                        }
                        disabled={
                          !isResponsible &&
                          !hasPermission(EapPermission.UpdateTodoList)
                        }
                        options={involvedUserOptions}
                        labelRender={
                          projectUsersById
                            ? ({ value }) =>
                                projectUsersById[value as UserPublicID]?.name
                            : undefined
                        }
                        optionFilterProp="label"
                        optionLabelProp="label"
                        onSelect={(value) => {
                          updateDraft({
                            involvedUserIds: [
                              ...(todoList.involvedUserIds ?? []),
                              value as UserPublicID,
                            ],
                          });
                        }}
                        onDeselect={(value) => {
                          setProjectUserFilterValue(undefined);
                          if (projectUsersById) {
                            updateDraft({
                              involvedUserIds: todoList.involvedUserIds?.filter(
                                (id) => id !== value,
                              ),
                            });
                          }
                        }}
                        onClear={() => {
                          setProjectUserFilterValue(undefined);
                          updateDraft({
                            involvedUserIds: [],
                          });
                        }}
                      />
                    </FloatLabel>
                  )}
                </Col>
                <Col xs={24} md={8}>
                  {loading ? (
                    <SkeletonInput active style={{ height: 48 }} block />
                  ) : (
                    <FloatLabel
                      key={`${todoListId}_label_dependent-wbs-elements`}
                      label={
                        !mayEditMetaInfo &&
                        (!todoList.dependentWbsElements ||
                          todoList.dependentWbsElements.length === 0)
                          ? "keine zugehörigen WBS-Elemente vorhanden"
                          : "zugehöriges WBS-Element"
                      }
                      value={
                        todoList.dependentWbsElements &&
                        todoList.dependentWbsElements.length > 0
                          ? todoList.dependentWbsElements?.join()
                          : undefined
                      }
                      className="unprintable-display-none"
                    >
                      <Select
                        className={UIElement.Todo_ListDependentWBSElementsInput}
                        key={`${todoListId}_input_dependent-wbs-elements`}
                        mode="multiple"
                        optionFilterProp="label"
                        allowClear={mayEditMetaInfo}
                        value={todoList.dependentWbsElements ?? []}
                        options={[
                          ...(allLatestWorkPackages ?? []),
                          ...(categories ?? []),
                        ]
                          ?.map(({ code, label }) => ({
                            label: `${code} - ${label}`,
                            value: code,
                          }))
                          .sort((a, b) => sortByCode(a.value, b.value))}
                        disabled={!mayEditMetaInfo}
                        onSelect={(value) => {
                          if (value !== undefined && value !== null) {
                            const newDependentWbsElements =
                              todoList.dependentWbsElements
                                ? [
                                    ...todoList.dependentWbsElements,
                                    value.toString(),
                                  ]
                                : [value.toString()];

                            updateDraft({
                              dependentWbsElements: newDependentWbsElements,
                            });
                          }
                        }}
                        onDeselect={(value) => {
                          if (value !== undefined && value !== null) {
                            const newDependentWbsElements =
                              todoList.dependentWbsElements?.filter(
                                (code) => code !== value,
                              ) ?? [];

                            updateDraft({
                              dependentWbsElements: newDependentWbsElements,
                            });
                          }
                        }}
                        onClear={() => {
                          updateDraft({
                            dependentWbsElements: [],
                          });
                        }}
                        tagRender={({ label, value, closable, onClose }) => (
                          <Tag
                            key={`${todoListId}_${label}`}
                            onMouseDown={(evt) => {
                              evt.preventDefault();
                              evt.stopPropagation();
                            }}
                            closable={closable}
                            onClose={onClose}
                            style={{ marginRight: 3 }}
                          >
                            <Link
                              to={`/-/${ProjectRoutePaths.Workpackage}/${value}`}
                            >
                              {label}
                            </Link>
                          </Tag>
                        )}
                      />
                    </FloatLabel>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <TodoTable
            todoList={loading ? undefined : todoList}
            responsibleUserId={todoListFromBackend?.responsibleUserId}
            involvedUserIds={todoListFromBackend?.involvedUserIds}
            readonly={readonly}
            className="mt-6"
            updateDraft={updateDraft}
          />
        </div>
      </Content>
    </>
  );
});

export default () => {
  const params = useParams<{ todoListId: string }>();
  return <TodoListView key={params.todoListId} />;
};
