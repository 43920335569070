import { __useAtom } from "@atoms/atom-client";
import {
  type AtomData,
  type ContextValues,
  DefaultIdentifiers,
  type TypedOperation,
  type UserPublicID,
  type VersionedAtomData,
} from "@atoms/atom-types";
import { Empty, List, Skeleton, Tooltip } from "antd";
import dayjs from "dayjs";
import { applyPatch } from "fast-json-patch";
import { groupBy, isDate } from "lodash";
import { DiffMethod, StringDiff } from "react-string-diff";
import TimeAgo from "timeago-react";
import { UserName } from "./UserName";
import type { FieldPropertiesMap } from "../../../atom-types/src/FieldProperties";
import { UserAvatar } from "./ProjectUserAvatar";

export type InfoTypeFromHistory<AtomType> = AtomType extends VersionedAtomData<
  infer InfoType
>
  ? InfoType
  : never;
export interface VersionedHistoryProps<
  AtomDataIndex,
  AtomType extends keyof AtomDataIndex,
> {
  name: string;
  atomType: AtomType;
  subItem?: {
    key: keyof InfoTypeFromHistory<AtomDataIndex[AtomType]>;
    render: (
      changes: TypedOperation<AtomData>[],
      old: AtomData,
    ) => React.ReactNode;
  };
  labels: FieldPropertiesMap<InfoTypeFromHistory<AtomDataIndex[AtomType]>>;
  overrides?: ContextValues<string>; // you can replace string with ContextType if you have it defined elsewhere
  userNameFallback?: string;
}

export function makeVersionedHistory<AtomDataIndex>() {
  const VersionedHistory = <AtomType extends keyof AtomDataIndex>({
    name,
    atomType,
    overrides,
    subItem,
    labels,
    userNameFallback,
  }: VersionedHistoryProps<AtomDataIndex, AtomType>) => {
    const history = __useAtom(
      atomType as string,
      ({ __history }: VersionedAtomData<AtomData>) => __history,
      {
        ...overrides,
        [DefaultIdentifiers.Version]: undefined,
      },
    );
    if (history === undefined) {
      return (
        <List>
          <List.Item key="1">
            <Skeleton active />
          </List.Item>
          <List.Item key="2">
            <Skeleton active />
          </List.Item>
          <List.Item key="3">
            <Skeleton active />
          </List.Item>
          <List.Item key="4">
            <Skeleton active />
          </List.Item>
        </List>
      );
    } else if (history === null) {
      return (
        <List
          locale={{
            emptyText: <Empty description="Kein Änderungsverlauf vorhanden" />,
          }}
        />
      );
    }

    function getAtVersion(version: number) {
      const atVersion = history!
        .slice(0, version)
        .reduce(
          (acc, patch) => applyPatch(acc, patch.changes).newDocument,
          {} as any,
        );
      return atVersion;
    }

    return (
      <List style={{ width: "100%" }}>
        {history
          .map((atomData, oldIndex) => ({
            ...atomData,
            changes: atomData.changes.filter(
              (change) => change.path.split("/")[1]! in labels,
            ),
            oldIndex,
          }))
          .filter((atomData) => atomData.changes.length > 0)
          .map((atomData, index) => (
            <List.Item key={atomData.date.getTime()}>
              <List.Item.Meta
                avatar={
                  <UserAvatar
                    userId={atomData.author}
                    fallback={userNameFallback}
                  />
                }
                title={
                  <div className="flex justify-between">
                    <span>
                      <UserName
                        publicId={atomData.author}
                        fallback={userNameFallback}
                      />
                    </span>
                    <span className="text-gray-400">
                      <Tooltip
                        title={dayjs(atomData.date).format(
                          "DD.MM.YY HH:mm [Uhr]",
                        )}
                      >
                        <TimeAgo
                          datetime={atomData.date}
                          locale="de"
                          opts={{ minInterval: 60 }}
                        />
                      </Tooltip>
                    </span>
                  </div>
                }
                description={
                  index > 0 ? (
                    <div>
                      {Object.entries(groupBy(atomData.changes, "op")).map(
                        ([type, changesOfType], m) => (
                          <div key={JSON.stringify(changesOfType)}>
                            {m > 0 && ", "}
                            {Object.entries(
                              groupBy(
                                changesOfType,
                                (change) => change.path.split("/")[1!],
                              ),
                            )
                              .filter(
                                ([field, [change]]) =>
                                  field in labels && change,
                              )
                              .map(([field, changes], n) => {
                                if (subItem && field === subItem.key) {
                                  const old = getAtVersion(atomData.oldIndex);
                                  return subItem.render(changes, old);
                                }
                                const change = changes[0]!;
                                switch (change.op) {
                                  case "add":
                                    if (
                                      typeof change.value === "object" ||
                                      isDate(change.value)
                                    ) {
                                      return (
                                        <div
                                          key={JSON.stringify(change)}
                                          className="inline"
                                        >
                                          {n > 0 && ", "}
                                          <strong>
                                            {labels[field]?.label}
                                          </strong>
                                        </div>
                                      );
                                    }
                                    return (
                                      <Tooltip
                                        key={JSON.stringify(change)}
                                        title={
                                          change.value.label ||
                                          change.value.description
                                        }
                                        overlayStyle={{ maxWidth: "500px" }}
                                      >
                                        <>
                                          {n > 0 && ", "}
                                          <strong>
                                            {labels[field]?.label}
                                          </strong>
                                        </>
                                      </Tooltip>
                                    );
                                  case "replace":
                                    if (changes.length > 1) {
                                      return (
                                        <div key={JSON.stringify(change)}>
                                          {n > 0 && ", "}
                                          <strong>
                                            {labels[field]?.label}
                                          </strong>
                                        </div>
                                      );
                                    } else if (
                                      typeof change.value === "string" &&
                                      change.value.length === 36
                                    ) {
                                      return (
                                        <Tooltip
                                          overlayStyle={{ maxWidth: "500px" }}
                                          key={JSON.stringify(change)}
                                          title={() => {
                                            const old = getAtVersion(
                                              atomData.oldIndex,
                                            );
                                            if (
                                              !old[field] ||
                                              (typeof old[field] !== "string" &&
                                                typeof old[field] !== "number")
                                            ) {
                                              return (
                                                <UserName
                                                  publicId={change.value}
                                                />
                                              );
                                            }
                                            return (
                                              <span key={n}>
                                                <UserName
                                                  publicId={
                                                    old[field] as UserPublicID
                                                  }
                                                />{" "}
                                                -&gt;{" "}
                                                <UserName
                                                  publicId={change.value}
                                                />
                                              </span>
                                            );
                                          }}
                                        >
                                          <>
                                            {n > 0 && ", "}
                                            <strong>
                                              {labels[field]?.label}
                                            </strong>
                                          </>
                                        </Tooltip>
                                      );
                                    } else if (
                                      typeof change.value === "string"
                                    ) {
                                      return (
                                        <Tooltip
                                          overlayStyle={{ maxWidth: "500px" }}
                                          key={JSON.stringify(change)}
                                          title={() => {
                                            const old = getAtVersion(
                                              atomData.oldIndex,
                                            );
                                            if (
                                              !old[field] ||
                                              (typeof old[field] !== "string" &&
                                                typeof old[field] !== "number")
                                            ) {
                                              return change.value;
                                            }
                                            return (
                                              <StringDiff
                                                oldValue={
                                                  // should be redundant but somehow it's possible that old[field] is undefined
                                                  old[field]
                                                    ? old[field].toString()
                                                    : ""
                                                }
                                                newValue={
                                                  // should be redundant but somehow it's possible that change.value is undefined
                                                  change.value
                                                    ? change.value.toString()
                                                    : ""
                                                }
                                                component="strong"
                                                method={
                                                  DiffMethod.WordsWithSpace
                                                }
                                                styles={{
                                                  added: {
                                                    color: "green",
                                                  },
                                                  removed: {
                                                    color: "red",
                                                  },
                                                  default: {
                                                    color: "white",
                                                  },
                                                }}
                                              />
                                            );
                                          }}
                                        >
                                          <>
                                            {n > 0 && ", "}
                                            <strong>
                                              {labels[field]?.label}
                                            </strong>
                                          </>
                                        </Tooltip>
                                      );
                                    } else if (
                                      typeof change.value === "number"
                                    ) {
                                      return (
                                        <Tooltip
                                          overlayStyle={{ maxWidth: "500px" }}
                                          key={JSON.stringify(change)}
                                          title={() => {
                                            const old = getAtVersion(
                                              atomData.oldIndex,
                                            );
                                            if (
                                              !old[field] ||
                                              (typeof old[field] !== "string" &&
                                                typeof old[field] !== "number")
                                            ) {
                                              return change.value;
                                            }
                                            return (
                                              <span key={n}>
                                                {old[field]} -&gt;{" "}
                                                {change.value}
                                              </span>
                                            );
                                          }}
                                        >
                                          <>
                                            {n > 0 && ", "}
                                            <strong>
                                              {labels[field]?.label}
                                            </strong>
                                          </>
                                        </Tooltip>
                                      );
                                    } else if (isDate(change.value)) {
                                      return (
                                        <Tooltip
                                          overlayStyle={{ maxWidth: "500px" }}
                                          key={JSON.stringify(change)}
                                          title={() => {
                                            const old = getAtVersion(
                                              atomData.oldIndex,
                                            );
                                            return (
                                              <span key={n}>
                                                {new Date(
                                                  old[field],
                                                ).toDateString()}{" "}
                                                -&gt;{" "}
                                                {new Date(
                                                  change.value,
                                                ).toDateString()}
                                              </span>
                                            );
                                          }}
                                        >
                                          <>
                                            {n > 0 && ", "}
                                            <strong>
                                              {labels[field]?.label}
                                            </strong>
                                          </>
                                        </Tooltip>
                                      );
                                    } else {
                                      return (
                                        <div
                                          key={JSON.stringify(change)}
                                          className="inline"
                                        >
                                          {n > 0 && ", "}
                                          <strong>
                                            {labels[field]?.label}
                                          </strong>
                                        </div>
                                      );
                                    }
                                  case "remove":
                                    return (
                                      <div
                                        key={JSON.stringify(change)}
                                        className="inline"
                                      >
                                        {n > 0 && ", "}
                                        <strong>{labels[field]?.label}</strong>
                                      </div>
                                    );
                                }
                                return null;
                              })}
                            {type === "remove"
                              ? " entfernt"
                              : type === "add"
                                ? " hinzugefügt"
                                : " geändert"}
                          </div>
                        ),
                      )}
                    </div>
                  ) : (
                    <div>{name} erstellt</div>
                  )
                }
              />
            </List.Item>
          ))
          .reverse()}
      </List>
    );
  };
  return VersionedHistory;
}
