import type { IToDo, ITodoList } from "@project/shared";
import type { InputRef } from "antd";
import DatePicker from "antd/es/date-picker";
import Form from "antd/es/form";
import Col from "antd/es/grid/col";
import TextArea from "antd/es/input/TextArea";
import Row from "antd/es/row";
import dayjs from "dayjs";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { ModalsApi } from "../../state/modals";
import { useThunkDispatch } from "../../useThunkDispatch";
import { FloatLabel } from "../reusable/FloatLabel";
import { TagPicker } from "../reusable/TagPicker";
import { ReduxModal } from "./ReduxModal";

export const UpdateTodoModalId = "updateTodo";

export interface UpdateTodoModalRef {
  update: (todo: IToDo, todoList: ITodoList) => Promise<IToDo>;
}

export const UpdateTodoModal = forwardRef<UpdateTodoModalRef>((_, ref) => {
  const dispatch = useThunkDispatch();

  const firstInputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const [draft, setDraft] = useState<Partial<IToDo> | null>(null);
  const [resolve, setResolve] = useState<
    null | ((value: IToDo | PromiseLike<IToDo>) => void) | null
  >(null);
  const [reject, setReject] = useState<null | ((reason?: any) => void) | null>(
    null,
  );
  const [todoList, setTodoList] = useState<ITodoList | null>(null);

  useImperativeHandle(ref, () => ({
    update: (todo: IToDo, todoList: ITodoList) => {
      setDraft(todo);
      dispatch(ModalsApi.show(UpdateTodoModalId));
      setTodoList(todoList);
      return new Promise<IToDo>((_resolve, _reject) => {
        setResolve(() => _resolve);
        setReject(() => _reject);
      });
    },
  }));

  const updateDraft = (updatedFields: Partial<IToDo>) => {
    setDraft((prevDraft) => ({ ...prevDraft, ...updatedFields }));
  };

  const handleOk = () => {
    if (draft && resolve) {
      resolve(draft as IToDo);
      setDraft(null);
      setResolve(() => null);
      setReject(() => null);
    }
  };

  const handleCancel = () => {
    if (reject) {
      reject();
      setDraft(null);
      setResolve(() => null);
      setReject(() => null);
    }
  };

  return (
    <ReduxModal
      id={UpdateTodoModalId}
      form={form}
      title="ToDo bearbeiten"
      firstInputRef={firstInputRef}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {!draft || !todoList ? null : (
        <Form form={form}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Form.Item
                noStyle
                rules={[
                  { required: true, message: "Bitte Beschreibung eintragen" },
                ]}
              >
                <FloatLabel label="Beschreibung" value={draft.description}>
                  <TextArea
                    ref={firstInputRef}
                    value={draft.description}
                    onChange={(evt) =>
                      updateDraft({
                        description: evt.target.value,
                      })
                    }
                    autoSize
                  />
                </FloatLabel>
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item noStyle>
                <FloatLabel
                  label="Fällig am"
                  value={draft.plannedFinish?.toString()}
                >
                  <DatePicker
                    placeholder={""}
                    format="DD.MM.YYYY"
                    value={
                      draft.plannedFinish
                        ? dayjs(draft.plannedFinish)
                        : undefined
                    }
                    onChange={(moment) =>
                      updateDraft({
                        plannedFinish: moment?.toDate() ?? null,
                      })
                    }
                    disabledDate={(option) =>
                      dayjs(option).isBefore(dayjs(), "day")
                    }
                    inputReadOnly
                  />
                </FloatLabel>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                noStyle
                rules={[{ required: false, message: "Label" }]}
              >
                <TagPicker
                  value={draft.label ?? null}
                  onPick={(id) =>
                    updateDraft({
                      label: id,
                    })
                  }
                  onReset={() =>
                    updateDraft({
                      label: "",
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                noStyle
                rules={[{ required: false, message: "Bericht zum ToDo-Item" }]}
              >
                <FloatLabel label="Bericht zum ToDo-Item" value={draft.status}>
                  <TextArea
                    value={draft.status}
                    onChange={(evt) =>
                      updateDraft({
                        status: evt.target.value,
                      })
                    }
                    autoSize
                  />
                </FloatLabel>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </ReduxModal>
  );
});
