import { last } from "lodash";
import type { IDraft } from "../interfaces/IDraft";
import type { IProjectTreeElement } from "../types/IProjectTreeElement";

export const getNewSubCode = (
  parentCode: string,
  drafts: IDraft[],
  treeElements: IProjectTreeElement[],
  codeSchema: string,
): string => {
  let newSubCode = "";
  const level = parentCode === "" ? 0 : parentCode.split(".").length;

  const isCodeAvailable = (possibleCode: string) => {
    if (
      !drafts.find(
        (entry) => entry.code.toLocaleUpperCase() === possibleCode,
      ) &&
      !treeElements.find(
        (entry) => entry.code.toLocaleUpperCase() === possibleCode,
      )
    ) {
      return true;
    }
    return false;
  };
  const possibleCode = "";

  const codeSchemaJson = codeSchema.split(".");
  const schemaType = codeSchemaJson[level] || last(codeSchemaJson);

  let range, offset;
  if (schemaType === "A-Z") {
    range = 26;
    offset = 65;
  } else if (schemaType === "1-9" || schemaType === "10-90") {
    range = 9;
    offset = 49;
  } else {
    throw new Error("invalid CODE_SCHEMA: " + schemaType);
  }

  if (schemaType) {
    for (let i = 0; i < range; i++) {
      if (schemaType === "A-Z" || schemaType === "1-9") {
        newSubCode = String.fromCharCode(offset + i).toLocaleUpperCase();
      } else if (schemaType === "10-90") {
        newSubCode = String.fromCharCode(offset + i).padEnd(2, "0");
      }

      const possibleCode =
        level === 0 ? newSubCode : parentCode + "." + newSubCode;
      if (isCodeAvailable(possibleCode)) {
        return possibleCode;
      }
    }
  }
  throw new Error("no code available");
};

export const getNewSubCodeOld = (
  parentCode: string,
  drafts: IDraft[],
  treeElements: IProjectTreeElement[],
  codeSchema?: string, // todo
): string | undefined => {
  let newSubCode: string | undefined;
  const level = parentCode.split(".").length;
  const isCodeAvailable = (newSubCode: string, topLevel = false) => {
    const possibleCode = topLevel ? newSubCode : parentCode + "." + newSubCode;

    if (
      !drafts.find((entry) => entry.code === possibleCode) &&
      !treeElements.find((entry) => entry.code === possibleCode)
    ) {
      return true;
    }
    return false;
  };
  let possibleCode: string | undefined;
  if (parentCode === "") {
    for (let i = 0; i < 26; i++) {
      newSubCode = String.fromCharCode(65 + i);
      if (isCodeAvailable(newSubCode, true)) {
        possibleCode = newSubCode;
        return possibleCode;
      }
    }
  }
  switch (level) {
    case 1:
      for (let i = 0; i < 26; i++) {
        newSubCode = String.fromCharCode(65 + i);
        if (isCodeAvailable(newSubCode)) {
          possibleCode = parentCode + "." + newSubCode;
          break;
        }
      }
      break;
    case 2:
    case 3:
      for (let i = 1; i < 10; i++) {
        newSubCode = i.toString();
        if (isCodeAvailable(newSubCode)) {
          possibleCode = parentCode + "." + newSubCode;
          break;
        }
      }
      break;
    default:
      for (let i = 1; i < 10; i++) {
        newSubCode = (i * 10).toString();
        if (isCodeAvailable(newSubCode)) {
          possibleCode = parentCode + "." + newSubCode;
          break;
        }
      }
      break;
  }
  if (!possibleCode) return parentCode;

  return possibleCode;
};
