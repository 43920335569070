import { type LogLevelNumbers, type Logger, default as log } from "loglevel";

export class LoggerWrapper {
  private logger: Logger;

  private namespace: string;

  constructor(namespace = "default") {
    this.namespace = namespace;
    this.logger = log.getLogger(this.namespace);

    // look if we have a custom loglevel stored in localStorage
    const persistedSettingsJson = localStorage.getItem(
      "persist:SettingsReducer",
    );
    if (persistedSettingsJson) {
      try {
        const level = Number.parseInt(
          JSON.parse(persistedSettingsJson).loglevel,
          10,
        ) as LogLevelNumbers;
        if (level < 0 || level > 5) {
          throw Error(`loglevel outside range: ${level}`);
        }
        this.logger.setLevel(level, false);
      } catch (err) {
        this.logger.debug(`unable to parse settings from localstorage: ${err}`);
      }
    } else {
      // set default level based on NODE_ENV environment variable
      // if (import.meta.env.NODE_ENV === "production") {
      //     this.logger.setDefaultLevel(log.levels.ERROR);
      // } else {
      this.logger.setDefaultLevel(log.levels.WARN);
      // }
    }
  }

  public getLogLevel(): LogLevelNumbers {
    return this.logger.getLevel();
  }

  public trace(...msg: any[]): void {
    this.logger.trace(`[${this.namespace}] ` + msg);
  }

  public debug(...msg: any[]): void {
    this.logger.debug(`[${this.namespace}] ` + msg);
  }

  public log(...msg: any[]): void {
    this.logger.log(`[${this.namespace}] ` + msg);
  }

  public info(...msg: any[]): void {
    this.logger.info(`[${this.namespace}] ` + msg);
  }

  public warn(...msg: any[]): void {
    this.logger.warn(`[${this.namespace}] ` + msg);
  }

  public error(...msg: any[]): void {
    this.logger.error(`[${this.namespace}] ` + msg);
  }
}

export default new LoggerWrapper();
