import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "antd";
import { useRef } from "react";
import {
  CreateProjectModal,
  type CreateProjectModalRef,
} from "./CreateProjectModal";

export const CreateProjectCard = () => {
  const createProjectModalRef = useRef<CreateProjectModalRef>(null);

  return (
    <>
      <CreateProjectModal ref={createProjectModalRef} />
      <Card
        title="Neues Projekt erstellen"
        hoverable
        onClick={() => createProjectModalRef.current?.show()}
        classNames={{
          title: "font-normal",
        }}
        className="h-36 border-dashed border-2"
      >
        <div className="flex h-full items-center justify-center">
          <FontAwesomeIcon icon={faPlus} size="lg" className="text-gray-400" />
        </div>
      </Card>
    </>
  );
};
