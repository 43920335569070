import type { OidcSettings } from "@atoms/atom-types";
import { WebStorageStateStore } from "oidc-client-ts";
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthProvider as OidcAuthProvider, useAuth } from "react-oidc-context";
import { getBackendUrl } from "../getBackendUrl";
import { useHandleOidcEvents } from "../hooks/useHandleOidcEvents";
import { makeGetAuthToken } from "../makeGetAuthToken";

type GetAuthTokenFn = () => Promise<string | null>;

const AuthTokenContext = createContext<GetAuthTokenFn | null>(null);

export const useAuthToken = () => {
  const context = useContext(AuthTokenContext);
  if (!context) {
    throw new Error("useAuthToken must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
  splashScreen: ReactNode;
}

export function AuthProvider({ children, splashScreen }: AuthProviderProps) {
  const [oidcSettings, setOidcSettings] = useState<OidcSettings | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [getAuthToken, setGetAuthToken] = useState<GetAuthTokenFn | null>(null);
  const { backendBaseUrl } = getBackendUrl();

  useEffect(() => {
    fetch(`${backendBaseUrl}/auth/oidc`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch OIDC settings");
        }
        return response.json();
      })
      .then((data) => {
        const getAuthToken =
          data.type === "dummy"
            ? () => Promise.resolve("dummy-user-token")
            : makeGetAuthToken(data.authority, data.client_id);
        setGetAuthToken(() => getAuthToken);
        if (data.type !== "dummy") {
          setOidcSettings(data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  }, []);

  if (isLoading || !getAuthToken) {
    return splashScreen;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <OidcAuthProvider
      {...oidcSettings}
      userStore={new WebStorageStateStore({ store: window.localStorage })}
      onSigninCallback={() => {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname,
        );
      }}
      automaticSilentRenew={true}
    >
      <AuthTokenContext.Provider value={getAuthToken}>
        <AuthGate splashScreen={splashScreen}>{children}</AuthGate>
      </AuthTokenContext.Provider>
    </OidcAuthProvider>
  );
}

function AuthGate({
  children,
  splashScreen,
}: {
  children: ReactNode;
  splashScreen: ReactNode;
}) {
  const { isLoading, isAuthenticated } = useAuth();
  useHandleOidcEvents();

  if (isLoading && !isAuthenticated) {
    return splashScreen;
  }

  return children;
}
