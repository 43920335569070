import type { Size } from "@pdfme/ui";
import type { DraftItemType } from "@project/shared";
import type { Position } from "../components/WBS/SizeAndPos";

export enum ChildAlignment {
  Horizontal = 0,
  Vertical = 1,
}

export enum ElementType {
  Category = 0,
  Package = 1,
}

export interface WBSTreeElement {
  code: string;
  label?: string;
  children: WBSTreeElement[];
  parentCode: string | null;
  type: ElementType;
  childAlignment: ChildAlignment;
  draft?: DraftItemType;
  computedDraft?: boolean;
  size?: Size;
  labelSize?: Size;
  labelPosition?: Position;
  position?: Position;
}

export interface DisplaySettings {
  padding: number;
  spacingBetweenVerticalChildren: number;
  spacingBetweenHorizontalChildren: number;
  indentForVerticalChildren: number;
  indentForHorizontalChildren: number;
  fontSize: number;
  showCode: boolean;
  codeVertical: boolean;
  showDiffOnly: boolean;
  boxColor: string;
  textColor: string;
  expertMode: boolean;
}
