import type { Operation } from "fast-json-patch";

export const UserId = "<UserId>";

export type IAtomPatch<
  AtomDataFromAtomType,
  TType extends keyof AtomDataFromAtomType,
  IType extends AtomDataFromAtomType[TType],
> = Operation & {
  path: `/${Paths<IType>}`;
};
type Paths<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? {
        [K in keyof T]-?: K extends string | number
          ? `${K}` | Join<K, Paths<T[K], Prev[D]>>
          : never;
      }[keyof T]
    : "";
type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${"" extends P ? "" : "/"}${P}`
    : never
  : never;
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, ...0[]];
