import { useApiClient } from "@project/api";
import { useAtom } from "@project/api";
import { Atoms, DraftItemType, getDraftOperation } from "@project/shared";
import { AnimatePresence, motion } from "framer-motion";
import {
  type KeyboardEvent,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import { getNodeCodeUIElement, getNodeUIElement } from "../../UIElements";
import { useWbsStore } from "../../state/project/wbs";
import { ElementType, type WBSTreeElement } from "../../types/WBSTreeTypes";
import {
  DraftColors,
  DraftFillHoverColors,
  DraftFillPatterns,
} from "./DraftColors";
import type { Renderer } from "./Renderer";
import { RendererContext } from "./RendererContext";
import { WBSCodeInput } from "./WBSCodeInput";
import { WBSContextMenu } from "./WBSContextMenu";
import { WBSHoverMenu } from "./WBSHoverMenu";
import { WBSInput } from "./WBSInput";

interface OverviewNodeProps {
  element: WBSTreeElement;
  onTab?: (e: KeyboardEvent<HTMLInputElement>) => void;
}
function shadeHexColor(color: string, percent: number): string {
  const f = Number.parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    "#" +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
}

type DefaultNodeUtils = {
  activateEditor: () => void;
};

export const EditNode = ({ element, onTab }: OverviewNodeProps) => {
  const { Text } = useContext<Renderer>(RendererContext);
  const displaySettings = useWbsStore((state) => state.displaySettings);
  const collapsedIndex = useWbsStore((state) =>
    state.collapsedCodes.indexOf(element.code),
  );
  const isCategory = element.type === ElementType.Category;
  const level = element.code.split(".").length - 1;
  const api = useApiClient();
  const expertMode = useWbsStore((state) => state.displaySettings.expertMode);
  const setEdit = useWbsStore((state) => state.setEdit);
  const stopEdit = useWbsStore((state) => state.stopEdit);

  const bgColorsPerLevel = [
    "0,0%,80%",
    "211,58%,86%",
    "83,56%,84%",
    "32,68%,85%",
    "0,0%,88%",
    "0,0%,88%",
    "0,0%,88%",
    "0,0%,88%",
    "0,0%,88%",
    "0,0%,88%",
    "0,0%,88%",
    "0,0%,88%",
    "0,0%,88%",
  ];

  function darkenHSLColor(color: string, percent: number) {
    const [h, s, l] = color.split(",").map((x) => Number.parseInt(x));
    return `${h},${s}%,${l! - percent}%`;
  }

  const [hovered, setHovered] = useState(false);

  const editCode = useWbsStore((state) => state.edit!.editWhat === "code");
  const editLabel = useWbsStore((state) => state.edit!.editWhat === "label");
  const [code, setCode] = useState(element.code);
  const [validCode, setValidCode] = useState(true);
  const drafts = useAtom(Atoms.Draft, (d) => d.entries) ?? [];
  const [label, setLabel] = useState(element.label);
  const [codeRef, setCodeRef] = useState<SVGTSpanElement | null>(null);
  const [labelRef, setLabelRef] = useState<SVGTSpanElement | null>(null);
  const [totalWidth, setTotalWidth] = useState(element.labelSize?.width ?? 100);

  const nodeBox = (
    <motion.rect
      key={element.code}
      width={totalWidth}
      height={element.labelSize?.height ?? 100}
      // initial={{ opacity: 0 }}
      animate={{
        fill: hovered
          ? element.draft !== undefined
            ? DraftFillHoverColors[element.draft]
            : `hsl(${darkenHSLColor(bgColorsPerLevel[level]!, 10)})`
          : element.draft !== undefined
            ? DraftFillPatterns[element.draft]
            : `hsl(${bgColorsPerLevel[level]})` ?? displaySettings.boxColor,
        stroke:
          element.draft !== undefined
            ? DraftColors[element.draft]
            : `hsl(${darkenHSLColor(bgColorsPerLevel[level]!, 30)})`,
        opacity: 1,
      }}
      strokeWidth="2"
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      rx="5"
    />
  );

  const [codeWidth, setCodeWidth] = useState(0);
  useLayoutEffect(() => {
    if (codeRef) {
      setCodeWidth(codeRef.getComputedTextLength());
    }
  }, [codeRef, code]);

  useLayoutEffect(() => {
    if (labelRef) {
      setTotalWidth(
        codeWidth +
          labelRef.getComputedTextLength() +
          3 * displaySettings.padding,
      );
    }
  }, [labelRef, label, codeWidth, displaySettings.padding]);

  const separatorPadding = 0.75 * displaySettings.padding;

  const commitCode = useCallback(async () => {
    if (!validCode) {
      setCode(element.code);
      return;
    }
    if (element.code === code) {
      return;
    }

    await api.Project.changeDraftCode(element.code, code);
  }, [code, element.code, validCode]);

  const commitLabel = useCallback(async () => {
    if (element.label === label) {
      return;
    }
    if (!drafts.find((d) => d.code === element.code)) {
      await api.Project.pushDraft({
        type:
          element.type === ElementType.Category
            ? DraftItemType.UpdateCategoryTitle
            : DraftItemType.UpdateWorkpackageTitle,
        code: element.code,
        label: label!,
      });
    } else {
      await api.Project.changeDraftLabel(element.code, label!);
    }
  }, [label, element.code]);

  return (
    <motion.g
      initial={{
        translateX: element.labelPosition!.x,
        translateY: element.labelPosition!.y,
        // opacity: 0,
      }}
      className={getNodeUIElement(element.code)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      animate={{
        translateX: element.labelPosition!.x,
        translateY: element.labelPosition!.y,
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
      transition={{ ease: "linear", duration: 0.2 }}
    >
      <defs>
        <pattern
          id="deletePattern"
          patternUnits="userSpaceOnUse"
          width="4"
          height="4"
          patternTransform="rotate(-45 2 2)"
        >
          <rect width={4} height={4} fill="white" />
          <path d="M -1,2 l 6,0" style={{ stroke: "salmon", strokeWidth: 1 }} />
        </pattern>
        <pattern
          id="addPattern"
          patternUnits="userSpaceOnUse"
          width="4"
          height="4"
          patternTransform="rotate(-45 2 2)"
        >
          <rect width={4} height={4} fill="white" />
          <path
            d="M -1,2 l 6,0"
            style={{ stroke: "lightGreen", strokeWidth: 1 }}
          />
        </pattern>
      </defs>
      <AnimatePresence>
        {collapsedIndex !== -1 ? (
          <>
            <motion.rect
              // initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              x={2}
              y={2}
              width={totalWidth}
              height={element.labelSize?.height ?? 100}
              stroke={
                element.draft !== undefined
                  ? DraftColors[element.draft]
                  : shadeHexColor(displaySettings.boxColor, -0.2)
              }
              fill="transparent"
              rx="5"
            />
            <motion.rect
              // initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              x={4}
              y={4}
              width={totalWidth}
              height={element.labelSize?.height ?? 100}
              stroke={
                element.draft != null
                  ? DraftColors[element.draft]
                  : shadeHexColor(displaySettings.boxColor, -0.2)
              }
              fill="transparent"
              rx="5"
            />
          </>
        ) : null}
      </AnimatePresence>
      {expertMode ? (
        <WBSHoverMenu element={element}>{nodeBox}</WBSHoverMenu>
      ) : (
        <WBSContextMenu element={element}>{nodeBox}</WBSContextMenu>
      )}
      <line
        x1={(codeWidth ?? 0) + 2 * separatorPadding}
        y1="0"
        x2={(codeWidth ?? 0) + 2 * separatorPadding}
        y2={element.labelSize?.height ?? 100}
        stroke={`hsl(${darkenHSLColor(bgColorsPerLevel[level]!, 20)})`}
        strokeDasharray="1 2"
      />
      <Text
        pointerEvents={"none"}
        x={displaySettings.padding}
        y={displaySettings.padding + displaySettings.fontSize}
        width={totalWidth}
        fill={displaySettings.textColor}
        fontSize={displaySettings.fontSize}
        textDecoration={
          element.draft !== undefined &&
          getDraftOperation(element.draft) === "Delete"
            ? "line-through"
            : undefined
        }
      >
        {displaySettings.showCode && !displaySettings.codeVertical ? (
          <tspan
            className={getNodeCodeUIElement(element.code)}
            fontWeight={isCategory ? 700 : 400}
            ref={setCodeRef}
            onDoubleClick={() => {
              if (
                element.draft !== undefined &&
                getDraftOperation(element.draft) === "Add"
              ) {
                setEdit(element.code, "code");
              }
            }}
            pointerEvents={"all"}
            style={{ fill: editCode ? "rgba(0,0,0,0)" : undefined }}
          >
            {code}
          </tspan>
        ) : null}
        <tspan
          dx={displaySettings.padding}
          className={getNodeCodeUIElement(element.code)}
          fontStyle={!isCategory ? "italic" : undefined}
          ref={setLabelRef}
          onDoubleClick={() => setEdit(element.code, "label")}
          pointerEvents={"all"}
          style={{ fill: editLabel ? "rgba(0,0,0,0)" : undefined }}
        >
          {label}
        </tspan>
      </Text>
      {editCode ? (
        <WBSCodeInput
          onValidationChange={setValidCode}
          oldCode={element.code}
          style={{
            userSelect: "none",
            height: element.labelSize!.height + 2,
            fontWeight: isCategory ? 700 : 400,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            paddingLeft: displaySettings.padding,
          }}
          wwidth={codeWidth + displaySettings.padding + separatorPadding}
          value={code}
          onTab={async (e) => {
            await commitCode();
          }}
          autoFocus
          onlyLastPart
          onChange={(e) => setCode(e.target.value)}
          onEnter={async (e) => {
            await commitCode();
            stopEdit();
          }}
          onBlur={async () => {
            await commitCode();
            stopEdit();
          }}
        />
      ) : null}
      {editLabel ? (
        <WBSInput
          style={{
            userSelect: "none",
            height: element.labelSize!.height + 2,
            fontStyle: !isCategory ? "italic" : undefined,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            // paddingLeft: separatorPadding,
          }}
          wwidth={
            totalWidth -
            codeWidth -
            displaySettings.padding -
            separatorPadding +
            4
          }
          value={label}
          x={codeWidth + displaySettings.padding + separatorPadding - 2}
          autoFocus
          onTab={async (e) => {
            await commitLabel();
            setEdit(element.code, "code");
            onTab?.(e);
          }}
          onEnter={async () => {
            await commitLabel();
            stopEdit();
          }}
          onChange={(e) => setLabel(e.target.value)}
          onBlur={async () => {
            await commitLabel();
            stopEdit();
          }}
        />
      ) : null}
    </motion.g>
  );
};
