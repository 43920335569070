/*
 * This file has been generated by permissions
 * Hash: ba6c309cd5b298d2576ca9bc6892e8fa7b2ae17609f3c304e8d43c8f8c204aea
 */

export enum CategoryPermissions {
  Create = "CreateCategory",
  Delete = "DeleteCategory",
  Read = "ReadCategory",
  Update = "UpdateCategory",
  ReadInfo = "ReadInfo",
  UpdateInfo = "UpdateInfo",
}
