import { useScopedStore } from "@atoms/atom-client";
import { useIdentifier } from "@atoms/atom-client";
import type { GenericActions } from "@atoms/atom-client";
import { type DraftItemType, Identifiers } from "@project/shared";
import { type StateCreator, useStore } from "zustand";
import type { DisplaySettings, WBSTreeElement } from "../../types/WBSTreeTypes";

const defaultDisplaySettings: DisplaySettings = {
  padding: 10,
  spacingBetweenHorizontalChildren: 20,
  spacingBetweenVerticalChildren: 20,
  indentForVerticalChildren: 20,
  indentForHorizontalChildren: 20,
  fontSize: 14,
  showCode: true,
  showDiffOnly: false,
  codeVertical: false,
  boxColor: "#ffffff",
  textColor: "#000000",
  expertMode: true,
};

interface Carret {
  code: string;
  edit?: boolean;
  editWhat?: "code" | "label";
}

interface WbsState {
  scale: number;
  collapsedCodes: string[];
  flippedCodes: string[];
  edit?: Carret;
  draft: {
    parent: string;
    type: DraftItemType;
  } | null;
  displaySettings: DisplaySettings;
  showComparedTrees: boolean;
  activeVersion?: string;
  versionToCompare?: string;
  deletionDraft?: Pick<WBSTreeElement, "code" | "label" | "type">;
}
interface WbsActions {
  resetDisplaySettings: () => void;
  setEdit: (code: string, editWhat: "code" | "label") => void;
  stopEdit: () => void;
  moveCarret: (code: string) => void;
  swapVersionToCompare: () => void;
  addFlippedCode: (code: string) => void;
  removeFlippedCode: (code: string) => void;
  addCollapsedCode: (code: string) => void;
  removeCollapsedCode: (code: string) => void;
}

export type Wbs = WbsState & WbsActions;

const defaultWbsState: WbsState = {
  scale: 1,
  collapsedCodes: [],
  flippedCodes: [],
  draft: null,
  displaySettings: defaultDisplaySettings,
  showComparedTrees: false,
};

const wbsStoreInitializer: StateCreator<Wbs> = (set) => ({
  ...defaultWbsState,
  resetDisplaySettings: () =>
    set((state) => ({
      ...state,
      displaySettings: defaultDisplaySettings,
    })),
  setEdit: (code: string, editWhat: "code" | "label") =>
    set((state) => ({
      ...state,
      edit: { code, edit: true, editWhat },
    })),
  stopEdit: () =>
    set((state) => ({
      ...state,
      edit: undefined,
    })),
  moveCarret: (code: string) => set((state) => ({ ...state, edit: { code } })),
  swapVersionToCompare: () =>
    set((state) => {
      if (!state.versionToCompare) {
        return state;
      }
      const tmp = state.activeVersion;
      return {
        ...state,
        activeVersion: state.versionToCompare,
        versionToCompare: tmp,
      };
    }),
  addFlippedCode: (code: string) =>
    set((state) => ({ ...state, flippedCodes: [...state.flippedCodes, code] })),
  removeFlippedCode: (code: string) =>
    set((state) => ({
      ...state,
      flippedCodes: state.flippedCodes.filter((c) => c !== code),
    })),
  addCollapsedCode: (code: string) =>
    set((state) => ({
      ...state,
      collapsedCodes: [...state.collapsedCodes, code],
    })),
  removeCollapsedCode: (code: string) =>
    set((state) => ({
      ...state,
      collapsedCodes: state.collapsedCodes.filter((c) => c !== code),
    })),
});

export const useWbsStore = <Selected>(
  selector: (state: Wbs & GenericActions<WbsState>) => Selected,
) => {
  const projectId = useIdentifier(Identifiers.ProjectId);
  const organizationId = useIdentifier(Identifiers.OrganizationId);

  const store = useScopedStore<Wbs>(
    "wbs",
    [organizationId, projectId],
    wbsStoreInitializer,
  );
  if (!store) {
    throw new Error("Project store not found");
  }
  (globalThis as any).store = store;

  return useStore(store, selector);
};
