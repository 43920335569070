import type { ContextValues } from "@atoms/atom-types";
import { useEffect } from "react";
import { useAiApi } from "./AiContext";

function deepEqual(a: ContextValues, b: ContextValues) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export const useAiTemplate = (
  templateName?: string,
  extraContext?: ContextValues,
) => {
  const api = useAiApi();
  useEffect(() => {
    if (!templateName) {
      api?.setCurrentTemplate(undefined);
      return;
    }

    if (api && api.currentTemplate !== templateName) {
      api.setCurrentTemplate(templateName);
    }
    // return () => {
    //   if (api && api.currentTemplate === templateName) {
    //     api.setCurrentTemplate(undefined);
    //   }
    // };
  }, [templateName, api?.currentTemplate]);
  useEffect(() => {
    if (
      api &&
      !deepEqual(
        api.extraContext as ContextValues,
        extraContext as ContextValues,
      )
    ) {
      console.log("setting extraContext", extraContext, api.extraContext);
      api.setExtraContext(extraContext);
    }
  }, [extraContext, api?.extraContext]);
};
