import {
  type ForwardRefExoticComponent,
  type FunctionComponent,
  type RefAttributes,
  type SVGProps,
  forwardRef,
} from "react";

export interface Renderer {
  Stage: FunctionComponent<SVGProps<SVGSVGElement>>;
  Rect: FunctionComponent<SVGProps<SVGRectElement>>;
  Group: ForwardRefExoticComponent<
    Omit<SVGProps<SVGGElement>, "ref"> & RefAttributes<SVGGElement>
  >;
  Text: FunctionComponent<SVGProps<SVGTextElement>>;
}

export const SVGRenderer: Renderer = {
  Stage: (
    { children, ...props }: SVGProps<SVGSVGElement> = {
      width: 0,
      height: "100%",
    },
  ) => {
    return <svg {...props}>{children}</svg>;
  },
  Rect: ({ children, ...props }: SVGProps<SVGRectElement>) => {
    return <rect {...props}>{children}</rect>;
  },
  Group: forwardRef<SVGGElement, SVGProps<SVGGElement>>(
    ({ children, ...props }, ref) => {
      return (
        <g {...props} ref={ref}>
          {children}
        </g>
      );
    },
  ),
  Text: ({ children, ...props }: SVGProps<SVGTextElement>) => {
    return <text {...props}>{children}</text>;
  },
};
