import { DefaultIdentifiers } from "@atoms/atom-types";
import { SetExtraContext, useAtom, useMyUserId } from "@project/api";
import { Atoms, Identifiers } from "@project/shared";
import { Card, Col, Empty, Input, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { useSetTitle } from "../hooks/useSetTitle";
import { EapErrorBoundary } from "./EapErrorBoundary";
import { CreateOrganizationCard } from "./Organization/CreateOrganizationCard";
import { OrganizationPreviewCard } from "./Organization/OrganizationPreviewCard";

const { Title, Paragraph } = Typography;
const { Search } = Input;

// this component displays all organizations the user is a member of
// if the user is an admin, all organizations are displayed
export const MyOrganizations = () => {
  useSetTitle("Organisationen");

  const [searchTerm, setSearchTerm] = useState("");
  const myUserId = useMyUserId();

  const organisationsWithMembership =
    useAtom(
      Atoms.UserRoles,
      (userRoles) =>
        Object.entries(userRoles.orgs)
          .filter(([_, { roles }]) => roles.length > 0)
          .map(([id, _]) => id),
      {
        [DefaultIdentifiers.UserId]: myUserId,
      },
    ) ?? [];
  const isAdmin = useAtom(Atoms.UserRoles, (userRoles) => userRoles.isAdmin, {
    [DefaultIdentifiers.UserId]: myUserId,
  });
  const allOrganizations =
    useAtom(
      Atoms.OrganizationList,
      (organizationList) => Object.keys(organizationList.entries),
      undefined,
      !isAdmin,
    ) ?? [];

  let myOrganisations = [];
  if (isAdmin) {
    // admin users see all organizations, independent of their membership
    myOrganisations = allOrganizations;
  } else {
    // regular users see only the organizations they are a member of
    myOrganisations = organisationsWithMembership;
  }

  // Get organization names for search
  const organizationNames =
    useAtom(Atoms.OrganizationList, (organizationList) =>
      Object.entries(organizationList.entries).reduce(
        (acc, [id, org]) => {
          acc[id] = org?.name?.toLowerCase() ?? "";
          return acc;
        },
        {} as Record<string, string>,
      ),
    ) ?? {};

  // Filter organizations based on search term
  const filteredOrganizations = searchTerm
    ? myOrganisations.filter((id) =>
        organizationNames[id]?.includes(searchTerm.toLowerCase()),
      )
    : myOrganisations;

  return (
    <Content>
      <div className="flex">
        <Title level={2} className="flex-grow">
          Organisationsverwaltung
        </Title>{" "}
        <div className="flex-grow-0">
          <Search
            placeholder="Suchen"
            onChange={(e) => setSearchTerm(e.target.value)}
            allowClear
          />
        </div>
      </div>
      <Paragraph>
        Hier können Sie alle Ihre Organisationen einsehen und verwalten.
        {isAdmin &&
          " Als Administrator sehen Sie alle Organisationen und können neue Organisationen erstellen."}
      </Paragraph>
      <Row gutter={[16, 16]} className="mt-4">
        {filteredOrganizations.length === 0 && !isAdmin ? (
          <Col span={24}>
            <Card>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    {searchTerm
                      ? "Keine Organisationen gefunden."
                      : "Sie haben derzeit keine Organisationen oder Einladungen."}
                  </span>
                }
              ></Empty>
            </Card>
          </Col>
        ) : (
          <>
            {filteredOrganizations.map((organizationId) => (
              <Col
                key={organizationId}
                xs={24}
                sm={16}
                md={12}
                lg={8}
                xl={6}
                xxl={4}
              >
                <EapErrorBoundary key={organizationId}>
                  <SetExtraContext
                    context={{
                      [Identifiers.OrganizationId]: organizationId,
                      // if we have a default project enabled, we would load the users of this project, which is not what we want here
                      [Identifiers.ProjectId]: undefined,
                    }}
                  >
                    <OrganizationPreviewCard />
                  </SetExtraContext>
                </EapErrorBoundary>
              </Col>
            ))}
            {isAdmin && (
              <Col
                key="add-organization"
                xs={24}
                sm={16}
                md={12}
                lg={8}
                xl={6}
                xxl={4}
              >
                <EapErrorBoundary>
                  <CreateOrganizationCard />
                </EapErrorBoundary>
              </Col>
            )}
          </>
        )}
      </Row>
    </Content>
  );
};
