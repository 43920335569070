import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import { isEqual } from "lodash";
import { memo, useState } from "react";
import { useWbsStore } from "../../state/project/wbs";
import { ChildAlignment, type WBSTreeElement } from "../../types/WBSTreeTypes";
import { useThunkDispatch } from "../../useThunkDispatch";
import { DefaultNode } from "./DefaultNode";
import { EditNode } from "./EditNode";

export interface WBSNodeProps {
  element: WBSTreeElement;
  parentAlignment?: ChildAlignment;
  level?: number;
}

export const WBSNode = memo(
  ({
    element,
    level = 0,
    parentAlignment = ChildAlignment.Horizontal,
  }: WBSNodeProps) => {
    const dispatch = useThunkDispatch();
    const displaySettings = useWbsStore((state) => state.displaySettings);
    const collapsedIndex = useWbsStore((state) =>
      state.collapsedCodes.indexOf(element.code),
    );
    const isBeeingEdited = useWbsStore(
      (state) => state.edit?.code === element.code && state.edit.edit,
    );
    const addCollapsedCode = useWbsStore((state) => state.addCollapsedCode);
    const removeCollapsedCode = useWbsStore(
      (state) => state.removeCollapsedCode,
    );
    const [collapseIconHovered, setCollapseIconHovered] = useState(false);
    // const lod = useLod({ ...element.labelPosition!, ...element.labelSize! });

    if (level >= 100) {
      console.error("WBSNode: Maximum recursion depth reached");
      return null;
    }

    return (
      <motion.g
        initial={{
          translateX: element.position!.x,
          translateY: element.position!.y,
          // opacity: 0,
        }}
        animate={{
          translateX: element.position!.x,
          translateY: element.position!.y,
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        transition={{ ease: "linear", duration: 0.2 }}
        onMouseDown={(e) => {
          // if not a middle mouse click
          if (e.button !== 1) {
            e.stopPropagation();
          }
        }}
        style={{ cursor: "auto" }}
      >
        {isBeeingEdited ? (
          <EditNode element={element} />
        ) : (
          <DefaultNode element={element} />
        )}
        {/* {lod === LOD.DETAIL ? <DetailedNode element={element} /> : null}
            {lod === LOD.DEFAULT ? <DefaultNode element={element} /> : null}
            {lod === LOD.OVERVIEW ? <OverviewNode element={element} /> : null} */}
        <AnimatePresence>
          {element.children.map((child, i) => {
            if (element.childAlignment === ChildAlignment.Vertical) {
              const x1 = displaySettings.indentForVerticalChildren / 2;
              const y1 = element.labelSize!.height;
              const y2 = child.position!.y + child.labelSize!.height / 2;
              return (
                <motion.polyline
                  key={`connector${i}`}
                  // initial={{ opacity: 0 }}
                  animate={{
                    points: `${x1},${y1},${x1},${y2},${child.position!.x},${y2}`,
                    opacity: 1,
                  }}
                  exit={{ opacity: 0 }}
                  transition={{ ease: "linear", duration: 0.1 }}
                  stroke={"black"}
                  fill={"none"}
                />
              );
            } else {
              const x1 =
                element.labelPosition!.x + element.labelSize!.width / 2;
              const y1 = element.labelSize!.height;
              const x2 =
                child.position!.x +
                child.labelPosition!.x +
                child.labelSize!.width / 2;
              const y3 = child.position!.y;
              const y2 = (y1 + y3) / 2;
              return (
                <motion.polyline
                  key={`connector${i}`}
                  // initial={{ opacity: 0 }}
                  animate={{
                    points: `${x1},${y1},${x1},${y2},${x2},${y2},${x2},${y3}`,
                    opacity: 1,
                  }}
                  exit={{ opacity: 0 }}
                  transition={{ ease: "linear", duration: 0.1 }}
                  stroke={"black"}
                  fill={"none"}
                />
              );
            }
          })}
        </AnimatePresence>
        <AnimatePresence>
          {element.children.length > 0 || collapsedIndex !== -1 ? (
            <motion.g
              className={`pw_wbs_collapse_button_${element.code.replace(".", "_")}`}
              transform={
                element.childAlignment === ChildAlignment.Vertical
                  ? `translate(${displaySettings.indentForVerticalChildren / 2 - 10}, ${
                      element.labelSize!.height + 2
                    })`
                  : `translate(${element.labelPosition!.x + element.labelSize!.width / 2 - 10}, ${
                      element.labelSize!.height
                    })`
              }
              onClick={() => {
                setCollapseIconHovered(false);
                if (collapsedIndex === -1) {
                  addCollapsedCode(element.code);
                } else {
                  removeCollapsedCode(element.code);
                }
              }}
              onMouseEnter={() => setCollapseIconHovered(true)}
              onMouseLeave={() => setCollapseIconHovered(false)}
              style={{ cursor: "pointer" }}
            >
              <rect
                width={18}
                height={18}
                strokeWidth={1}
                stroke="white"
                fill="white"
                rx={10}
              />
              <motion.path
                d={
                  (collapsedIndex !== -1
                    ? faPlusCircle.icon[4]
                    : faMinusCircle.icon[4]) as string
                }
                transform={"scale(0.03) translate(50, 50)"}
                width={30}
                ref={() => 1}
                height={30}
                animate={{ fill: collapseIconHovered ? "#808080" : "#d3d3d3" }}
              />
            </motion.g>
          ) : null}
          {element.children.map((child, i) => {
            return (
              <WBSNode
                key={child.code}
                element={child}
                level={level + 1}
                parentAlignment={element.childAlignment}
              />
            );
          })}
        </AnimatePresence>
      </motion.g>
    );
  },
  isEqual,
);
