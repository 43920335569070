import type { ContextValues } from "@atoms/atom-types";
import { useContext, useLayoutEffect } from "react";
import { AtomStoreContext } from "./AtomStoreProvider";
import { useCombinedContext } from "./useCombinedContext";

export interface Delta {
  [key: string]: any;
  [key: number]: any;
}
/**
 * Hook to get the patches for an atom.
 */
type OnPatchCallback<
  AtomDataFromAtomType,
  IType extends AtomDataFromAtomType[TType],
  TType extends keyof AtomDataFromAtomType,
> = (
  value: IType | null,
  patches: Delta,
  /**
   * When loading an atom for the first time, this is `true`. (so it did not just happen)
   */
  computed?: boolean,
) => void;

export function makeUseAtomPatches<
  AtomType extends string,
  AtomDataFromAtomType,
>(): <
  TType extends keyof AtomDataFromAtomType,
  IType extends AtomDataFromAtomType[TType],
>(
  type: AtomType,
  callback: OnPatchCallback<AtomDataFromAtomType, IType, TType>,
  extraContext?: ContextValues,
) => void {
  function useAtomPatches<
    TType extends keyof AtomDataFromAtomType,
    IType extends AtomDataFromAtomType[TType],
  >(
    type: AtomType,
    callback: OnPatchCallback<AtomDataFromAtomType, IType, TType>,
    extraContext?: ContextValues,
  ): void {
    const atomStore = useContext(AtomStoreContext);
    const context = useCombinedContext(extraContext);

    useLayoutEffect(() => {
      atomStore?.subscribe(
        type,
        context,
        callback as OnPatchCallback<any, any, any>,
        (v) => v,
      );

      return () => {
        void atomStore?.unsubscribe(type, context, callback);
      };
    }, [atomStore, callback, type, JSON.stringify(context)]);
  }

  return useAtomPatches;
}

export const __useAtomPatches = makeUseAtomPatches();
