import { type ReactNode, useRef } from "react";
import type { StoreRegistry } from "./StoreRegistry";
import { StoreRegistryContext } from "./StoreRegistryContext";

interface StoreRegistryProviderProps {
  children: ReactNode;
}

export const StoreRegistryProvider: React.FC<StoreRegistryProviderProps> = ({
  children,
}) => {
  const storeRegistryRef = useRef<StoreRegistry>(new Map());

  return (
    <StoreRegistryContext.Provider value={storeRegistryRef.current}>
      {children}
    </StoreRegistryContext.Provider>
  );
};
