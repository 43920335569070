import { useContext, useMemo } from "react";
import type { StateCreator, StoreApi } from "zustand";
import type { PersistOptions } from "zustand/middleware";
import { StoreRegistryContext } from "./StoreRegistryContext";
import {
  createPersistedStore,
  getStoreKey,
  type GenericActions,
} from "./storeFactory";

export function useScopedStore<State>(
  storeName: string,
  identifiers: (string | number | undefined)[],
  storeInitializer: StateCreator<State>,
  options?: Omit<PersistOptions<Partial<State>>, "name">,
): StoreApi<State & GenericActions<State>> | null {
  const storeRegistry = useContext(StoreRegistryContext);

  if (!storeRegistry) {
    throw new Error(
      "useScopedStore must be used within a StoreRegistryProvider",
    );
  }

  const hasUndefinedIdentifier = identifiers.some((id) => id === undefined);
  if (hasUndefinedIdentifier) {
    return null;
  }

  return useMemo(() => {
    const storeKey = getStoreKey(storeName, identifiers);
    if (storeRegistry.has(storeKey)) {
      return storeRegistry.get(storeKey) ?? null;
    } else {
      const newStore = createPersistedStore<State>(
        storeInitializer,
        storeName,
        identifiers,
        options,
      );
      storeRegistry.set(storeKey, newStore);
      return newStore;
    }
  }, [storeName, identifiers]);
}
