import { __useAtomPatches, useMyUserId } from "@atoms/atom-client";
import { UserName } from "@atoms/atom-components";
import type { ChangeLog, Paths, UserPublicID } from "@atoms/atom-types";
import { useApiClient } from "@project/api";
import {
  Atoms,
  type IWorkpackage,
  type IWorkpackageLog,
} from "@project/shared";
import { Alert } from "antd";
import Form from "antd/es/form";
import Col from "antd/es/grid/col";
import Row from "antd/es/row";
import Paragraph from "antd/es/typography/Paragraph";
import type { AddOperation } from "fast-json-patch";
import { last, sortBy } from "lodash";
import {
  type ForwardRefRenderFunction,
  type MutableRefObject,
  type ReactNode,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { flushSync } from "react-dom";
import { StringDiff } from "react-string-diff";
import { ModalsApi } from "../../state/modals";
import { useThunkDispatch } from "../../useThunkDispatch";
import { ReduxModal } from "./ReduxModal";

type LabelType = Record<
  keyof IWorkpackage,
  { label: string; renderer?: (value: any) => ReactNode }
>;

const RevisionChangesModalId = "revisionChanges";

export type WpRevisionConflictModalRef = {
  handleSave: (workPackageDraft: Partial<IWorkpackage>) => Promise<void>;
};
interface WpRevisionConflictModalProps {
  draft: Partial<IWorkpackage> | null;
}

const WpRevisionConflictModal: ForwardRefRenderFunction<
  WpRevisionConflictModalRef,
  WpRevisionConflictModalProps
> = ({ draft }, forwardedRef) => {
  const dispatch = useThunkDispatch();

  // const [keptKeys, setKeptKeys] = useState<string[]>([]);
  const myUserId = useMyUserId();

  const api = useApiClient();

  const [valuesChangedByOthers, setValuesChangedByOthers] =
    useState<Record<`/${keyof Partial<IWorkpackage>}`, string>>();
  const historyDateCutoff: MutableRefObject<Date | null> = useRef<Date>(null);
  const onAtomPatch = useCallback(
    (p: IWorkpackageLog | null) => {
      if (!historyDateCutoff.current) {
        // initial state, save date of last change for further usage
        historyDateCutoff.current =
          last(sortBy((p as IWorkpackageLog).__history, "date"))?.date ?? null;
      }

      if (!draft) {
        // if we don't have a draft, we don't need to compute any changes
        return;
      }

      const changesByOtherUsers = new Array<ChangeLog<IWorkpackage>>();
      const changesSinceCutoff = (p as IWorkpackageLog).__history.filter(
        (e) => e.date >= historyDateCutoff.current!,
      );

      // find the last index of the element in 'changesSinceMounted' that was created by myself
      const indexOfLastChangeByMyself = changesSinceCutoff
        .map((e) => e.author)
        .lastIndexOf(myUserId as UserPublicID);

      changesByOtherUsers.push(
        ...changesSinceCutoff.slice(
          indexOfLastChangeByMyself + 1,
          changesSinceCutoff.length,
        ),
      );

      if (changesByOtherUsers.length > 0) {
        setValuesChangedByOthers(
          // create an object with the 'path' of the change as key and the 'value' as value
          changesByOtherUsers.reduce(
            (acc, e) => {
              e.changes.forEach((c) => {
                acc[c.path] = (c as AddOperation<any>).value;
              });
              return acc;
            },
            {} as Record<`/${Paths<IWorkpackage>}`, string>,
          ),
        );
      }
    },
    [myUserId, Boolean(draft)],
  );
  __useAtomPatches(Atoms.WorkpackageInfo, onAtomPatch);

  useLayoutEffect(() => {
    if (!draft) {
      historyDateCutoff.current = null;
      setValuesChangedByOthers(undefined);
    }
  }, [Boolean(draft)]);

  const [confirmSave, setConfirmSave] = useState<
    ((val: any) => void) | undefined
  >(undefined);
  const [rejectSave, setRejectSave] = useState<(() => void) | undefined>(
    undefined,
  );
  const save = useCallback(
    async (draft: Partial<IWorkpackage>) => {
      if (!draft) {
        return;
      } else if (!valuesChangedByOthers) {
        // if there are no changes by others, we can just save the draft
        await api.Workpackage.update(draft!);
        return;
      }

      flushSync(() => {
        dispatch(ModalsApi.show(RevisionChangesModalId));
      });
      await new Promise((resolve, reject) => {
        setRejectSave(() => () => {
          console.log("save was denied");
          reject(new Error("save was denied"));
        });
        setConfirmSave(() => async () => {
          await api.Workpackage.update(draft!);
          resolve(true);
        });
      });
    },
    [Boolean(valuesChangedByOthers)],
  );

  useImperativeHandle(
    forwardedRef,
    () => ({
      handleSave: save,
    }),
    [save],
  );

  const [form] = Form.useForm();

  const labels: LabelType = {
    activities: {
      label: "Aktivitäten",
      renderer: (value: IWorkpackage["activities"]) => (
        <ul>
          {Object.values(value ?? {}).map((activity) => (
            <li key={activity.uuid}>
              {activity.description === "" ? (
                <i>Aktivität ohne Beschreibung</i>
              ) : (
                activity.description
              )}
            </li>
          ))}
        </ul>
      ),
    },
    commissionerUserId: {
      label: "Auftraggeber/in",
      renderer: (value) => <UserName publicId={value} />,
    },
    decisionRequired: { label: "Entscheidungsbedarf" },
    done: { label: "abgeschlossen" },
    decisionRequiredReason: { label: "Erläuterung zum Entscheidungsbedarf" },
    dependentWPs: { label: "abhängige AP" },
    description: { label: "Beschreibung" },
    frozen: { label: "frozen" },
    deleted: { label: "gelöscht" },
    decision: { label: "Entscheidung" },
    expectedFinish: { label: "erwartetes Enddatum" },
    goals: { label: "Lieferziele" },
    objective: { label: "Ziel" },
    plannedFinish: {
      label: "geplantes Enddatum",
      renderer: (value) => (
        <>
          {typeof value.toDateString === "function"
            ? value.toDateString()
            : "Invalid Date"}
        </>
      ),
    },
    plannedStart: {
      label: "geplantes Startdatum",
      renderer: (value) => (
        <>
          {typeof value.toDateString === "function"
            ? value.toDateString()
            : "Invalid Date"}
        </>
      ),
    },
    responsibleUserId: {
      label: "Verantwortliche/r",
      renderer: (value) => <UserName publicId={value} />,
    },
    progress: { label: "Fortschritt" },
    progressReason: { label: "Grund für Fortschritt" },
    signal: { label: "Signal" },
    status: { label: "Status" },
    label: { label: "Label" },
    involvedUserIds: {
      label: "",
      renderer: (value: UserPublicID[]) => (
        <>
          {value?.map((id, idx) => (
            <UserName key={idx} publicId={id} />
          ))}
        </>
      ),
    },
  };

  if (!valuesChangedByOthers) {
    return null;
  }

  return (
    <ReduxModal
      width={"800px"}
      id={RevisionChangesModalId}
      form={form}
      title="Änderungen überprüfen"
      onOk={confirmSave}
      onCancel={rejectSave}
      okButtonProps={{ danger: true }}
      okText="Speichern"
    >
      <Alert
        message="Während das Formular bearbeitet wurde hat ein anderer Benutzer eine neue Version gespeichert. Falls Sie das gleiche Feld bearbeitet haben, werden die Änderungen des anderen Benutzers überschrieben!"
        type="warning"
      />

      <Form form={form} className="mt-4">
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <h3 className="mb-2">Neue Version</h3>
            {Object.entries(valuesChangedByOthers).map(([key, value]) => {
              const labelKey = key.split("/")[1] as keyof IWorkpackage;
              const label = labels[labelKey];
              if (!label) {
                return null;
              }
              return (
                <div key={key}>
                  <strong>{label.label}</strong>:
                  <Paragraph
                    ellipsis={{ expandable: true, rows: 2, symbol: "mehr" }}
                  >
                    {value !== null
                      ? label.renderer
                        ? label.renderer(value)
                        : value.toString()
                      : null}
                  </Paragraph>
                </div>
              );
            })}
          </Col>
          {Object.keys(draft ?? {}).length > 0 ? (
            <Col span={12}>
              <h3 className="mb-2">Lokale Änderung</h3>
              {Object.entries(draft ?? {}).map(([key, value]) => {
                const labelKey = key.split("/")[1] as keyof IWorkpackage;
                const label = labels[labelKey];
                if (!label) {
                  return null;
                }
                return (
                  <div key={key}>
                    <strong>{label.label}</strong>:
                    <Paragraph
                      ellipsis={{ expandable: true, rows: 2, symbol: "mehr" }}
                    >
                      {value !== null ? (
                        label.renderer ? (
                          label.renderer(value)
                        ) : typeof value === "string" &&
                          valuesChangedByOthers[
                            `/${key as keyof IWorkpackage}`
                          ] !== undefined ? (
                          <StringDiff
                            oldValue={
                              valuesChangedByOthers[
                                `/${key as keyof IWorkpackage}`
                              ] ?? ""
                            }
                            newValue={value}
                          />
                        ) : (
                          value.toString()
                        )
                      ) : null}
                    </Paragraph>
                  </div>
                );
              })}
            </Col>
          ) : null}
        </Row>
      </Form>
    </ReduxModal>
  );
};

export default forwardRef(WpRevisionConflictModal);
