import {
  faCheckSquare as faCheckSquareSolid,
  faCompressAlt,
  faExpandAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "@project/api";
import { Atoms } from "@project/shared";
import Button from "antd/es/button";
import ButtonGroup from "antd/es/button/button-group";
import Divider from "antd/es/divider";
import Empty from "antd/es/empty";
import { Col, Row } from "antd/es/grid";
import SkeletonInput from "antd/es/skeleton/Input";
import Tooltip from "antd/es/tooltip";
import Tree, { type DataNode } from "antd/es/tree";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { classNames2 } from "../classNames2";
import { useReactTransition } from "../hooks/useReactTransition";
import { useScopeAwareNavigate } from "../hooks/useScopeAwareNavigate";
import { useWorkpackageFiltersStore } from "../state/project/workpackageFilters";
import { useWorkpackageListStore } from "../state/project/workpackageList";
import { useThunkDispatch } from "../useThunkDispatch";
import { buildCategoryTree } from "../util/buildCategoryTree";

export const WorkPackageTree = memo(() => {
  const dispatch = useThunkDispatch();
  const navigate = useScopeAwareNavigate();

  const treeCategories = buildCategoryTree();
  const loading = useReactTransition() || treeCategories === undefined;

  const containerRef = useRef<HTMLDivElement>(null);
  const [treeHeight, setTreeHeight] = useState(0);

  // observe height of container
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setTreeHeight(entry.contentRect.height);
      }
    });
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [containerRef]);

  const placeholderCategories: DataNode[] = [
    {
      key: "1",
      title: <SkeletonInput size="small" active />,
    },
    {
      key: "2",
      title: <SkeletonInput size="small" active />,
      children: [
        {
          key: "2.1",
          title: <SkeletonInput size="small" active block />,
        },
        {
          key: "2.2",
          title: <SkeletonInput size="small" active block />,
        },
      ],
    },
    {
      key: "3",
      title: <SkeletonInput size="small" active />,
    },
    {
      key: "4",
      title: <SkeletonInput size="small" active />,
      children: [
        {
          key: "4.1",
          title: <SkeletonInput size="small" active block />,
        },
        {
          key: "4.2",
          title: <SkeletonInput size="small" active block />,
        },
        {
          key: "4.3",
          title: <SkeletonInput size="small" active block />,
        },
        {
          key: "4.4",
          title: <SkeletonInput size="small" active block />,
        },
      ],
    },
  ];

  const expanded = useWorkpackageListStore((state) => state.expanded);
  const checked = useWorkpackageFiltersStore((state) => state.checked);
  const setWorkpackageListState = useWorkpackageListStore((state) => state.set);
  const setWorkpackageFiltersState = useWorkpackageFiltersStore(
    (state) => state.set,
  );
  const categories = useAtom(Atoms.ProjectTree, (tree) => tree.entries) ?? [];
  const allCategoryCodes = useMemo(
    () => categories.map((c) => c.code),
    [categories],
  );

  const onSelectCallback = useCallback((selectedKeys: React.Key[]) => {
    if (selectedKeys.length === 1) {
      navigate("/");
      setWorkpackageFiltersState("checked", selectedKeys as string[]);
      setWorkpackageListState(
        "expanded",
        expanded.indexOf(selectedKeys[0] as string) === -1
          ? [...expanded, selectedKeys[0] as string]
          : expanded.filter((f) => f !== selectedKeys[0]),
      );
    } else if (selectedKeys.length === 0) {
      navigate("/");
      setWorkpackageFiltersState("checked", []);
    }
  }, []);

  const onCheckCallback = useCallback(
    (
      checked: React.Key[] | { checked: React.Key[]; halfChecked: React.Key[] },
    ) =>
      setWorkpackageFiltersState(
        "checked",
        (Array.isArray(checked) ? checked : checked.checked) as string[],
      ),
    [setWorkpackageFiltersState],
  );
  const onExpandCallback = useCallback(
    (
      keys: React.Key[] | { expanded: React.Key[]; halfExpanded: React.Key[] },
    ) => setWorkpackageListState("expanded", keys as string[]),
    [setWorkpackageListState],
  );
  return (
    <>
      <div className="categoryTree" ref={containerRef}>
        {treeCategories === null || treeCategories?.length === 0 ? (
          <Empty
            description="WBS enthält keine Elemente"
            className="mt-10 mb-10 text-gray-400"
          />
        ) : (
          <Tree
            checkable
            checkedKeys={loading ? undefined : checked}
            expandedKeys={
              loading
                ? placeholderCategories.map((p) => p.key)
                : expanded[0] === "all"
                  ? allCategoryCodes
                  : expanded
            }
            onExpand={onExpandCallback}
            onCheck={onCheckCallback}
            treeData={loading ? placeholderCategories : treeCategories}
            // treeData={placeholderCategories}
            onSelect={onSelectCallback}
            virtual
            height={treeHeight}
            disabled={loading}
          />
        )}
      </div>
      <Divider style={{ margin: 0 }} />
      <Row gutter={[10, 10]} align="middle">
        <Col className="expandedControlIcons" flex={1}>
          <ButtonGroup>
            <Tooltip title="Auswahl aufheben">
              <Button
                onClick={() => setWorkpackageFiltersState("checked", [])}
                disabled={checked.length === 0}
                className={classNames2(() => ({
                  clearChecked: true,
                  active: checked.length !== 0,
                }))}
              >
                <FontAwesomeIcon icon={faCheckSquareSolid} />
              </Button>
            </Tooltip>
            <Tooltip title="Alles einklappen">
              <Button onClick={() => setWorkpackageListState("expanded", [])}>
                <FontAwesomeIcon icon={faCompressAlt} />
              </Button>
            </Tooltip>
            <Tooltip title="Alles ausklappen">
              <Button
                onClick={() => setWorkpackageListState("expanded", ["all"])}
              >
                <FontAwesomeIcon icon={faExpandAlt} />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Col>
      </Row>
    </>
  );
});
