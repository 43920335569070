import { type DependencyList, useEffect, useLayoutEffect } from "react";

export function useAsyncEffect(cb: () => Promise<void>, deps: DependencyList) {
  return useEffect(() => {
    cb().catch(console.error);
  }, deps);
}

export function useAsyncLayoutEffect(
  cb: () => Promise<void>,
  deps: DependencyList,
) {
  return useLayoutEffect(() => {
    cb().catch(console.error);
  }, deps);
}
