import { gql } from "@apollo/client";
import type { AtomInfos } from "@atoms/atom-types";
import type { Delta } from "jsondiffpatch";
import { type ReactNode, createContext, useMemo } from "react";
import { AtomStore } from "./AtomStore";
import { useAuthToken } from "./auth/AuthProvider";
import { initApolloClient } from "./initApolloClient";
import type { SelectorFunction } from "./makeUseAtom";

export const subscriptionDocument = gql`
  subscription subscribeAtom($type: String!, $context: JSON!) {
    subscribeAtom(type: $type, context: $context)
  }
`;

export type SubscriptionProps = {
  current: Promise<ZenObservable.Subscription>;
  /** after the last subscriber is removed, the websocket subscription remains open for a second to see if something is resubscribed */
  unsubscribeTimeout?: ReturnType<typeof setTimeout>;
  selectors: Array<SelectorFunction<any, any>>;
  callbacks: Array<
    (data: any, changes: Delta, computedChanges?: boolean) => void
  >;
  lastValue: any | null;
  completed?: boolean;
  error?: Error;
};

export type SubscriptionCallbacks = { [path: string]: SubscriptionProps };

export const AtomStoreContext = createContext<AtomStore | null>(null);

interface AtomStoreProviderProps {
  children: ReactNode;
  atomInfos: AtomInfos<string, string, string>;
}

export function AtomStoreProvider({
  children,
  atomInfos,
}: AtomStoreProviderProps) {
  const getAuthToken = useAuthToken();

  const apolloClient = useMemo(() => {
    const apolloClient = initApolloClient(getAuthToken);
    return apolloClient;
  }, [getAuthToken]);

  const atomStore = useMemo(() => {
    const as = new AtomStore(apolloClient, atomInfos);
    (globalThis as any).as = as;
    return as;
  }, [apolloClient]);

  return (
    <AtomStoreContext.Provider value={atomStore}>
      {children}
    </AtomStoreContext.Provider>
  );
}
