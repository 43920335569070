import type { AtomInfos } from "@atoms/atom-types";
import type { ReactNode } from "react";
import { AtomStoreProvider } from "./AtomStoreProvider";
import { AuthProvider } from "./auth/AuthProvider";

interface AtomsProviderProps {
  children: ReactNode;
  splashScreen: ReactNode;
  atomInfos: AtomInfos<string, string, string>;
}

export function AtomFrameworkProvider({
  children,
  splashScreen,
  atomInfos,
}: AtomsProviderProps) {
  return (
    <AuthProvider splashScreen={splashScreen}>
      <AtomStoreProvider atomInfos={atomInfos}>{children}</AtomStoreProvider>
    </AuthProvider>
  );
}
