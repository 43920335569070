import type { ContextValues } from "@atoms/atom-types";
import { useRef } from "react";
import { useCombinedContext } from "../useCombinedContext";
import { useAtomStore } from "./useAtomStore";

export function useTransaction(extraContext: ContextValues = {}) {
  const combinedContext = useCombinedContext();
  const store = useAtomStore();
  const transactionRef = useRef<ReturnType<typeof store.getTransaction>>();

  if (!transactionRef.current) {
    transactionRef.current = store.getTransaction({
      ...combinedContext,
      ...extraContext,
    });
  }

  return transactionRef.current;
}
