export type LabelType = {
  id: string;
  color: string;
  description: string;
};

export const generic_labels: LabelType[] = [
  {
    id: "label_01",
    color: "#9b9b9b",
    description: "Label 01",
  },
  {
    id: "label_02",
    color: "#ca5741",
    description: "Label 02",
  },
  {
    id: "label_03",
    color: "#e3c11a",
    description: "Label 03",
  },
  {
    id: "label_04",
    color: "#45a55e",
    description: "Label 04",
  },
  {
    id: "label_05",
    color: "#3b7fc2",
    description: "Label 05",
  },
  {
    id: "label_06",
    color: "#244490",
    description: "Label 06",
  },
  {
    id: "label_07",
    color: "#6a4394",
    description: "Label 07",
  },
  {
    id: "label_08",
    color: "#af579d",
    description: "Label 08",
  },
];

export const getLabelProperties = (labelId: string) => {
  const label = [...generic_labels].find((l) => l.id === labelId);
  if (!label) {
    return null;
  }

  return {
    color: label.color,
    description: label.description,
  };
};
