import { ApolloError } from "@apollo/client";
import { ErrorLink } from "@apollo/client/link/error";

export const makeErrorLink = (
  _callbacks?: Partial<{ graphQl: () => void; network: () => void }>,
) => {
  return new ErrorLink(({ graphQLErrors, networkError }) => {
    console.error(new ApolloError({ graphQLErrors, networkError }));
  });
};
