import { Layout } from "antd";
import { Suspense } from "react";
import { Route, Outlet } from "react-router-dom";
import {} from "../../CoreApp";
import { OrganizationProjects } from "../Organization/OrganizationProjects";
import { OrganizationUserManager } from "../Organization/OrganizationUserManager";
import { SplashScreen } from "../reusable/SplashScreen";
import { AtomNotFoundBoundary } from "@atoms/atom-client";
import { EapErrorBoundary } from "../EapErrorBoundary";

export enum OrganizationRoutePaths {
  Settings = "settings",
  Projects = "projects",
  Users = "users",
}

export const OrganizationRoutes = (
  <Route
    path="/:organizationId/*"
    element={
      <EapErrorBoundary>
        <AtomNotFoundBoundary
          title="Unbekannte Organisation"
          subTitle="Diese Organisation existiert nicht oder sie haben keinen Zugriff auf diese Organisation."
        >
          <Outlet />
        </AtomNotFoundBoundary>
      </EapErrorBoundary>
    }
  >
    <Route
      path="-/"
      element={
        <Suspense fallback={<SplashScreen />}>
          <Layout>
            <Outlet />
          </Layout>
        </Suspense>
      }
    >
      <Route
        path={OrganizationRoutePaths.Users}
        element={<OrganizationUserManager />}
      />
      <Route
        path={OrganizationRoutePaths.Projects}
        element={<OrganizationProjects />}
      />
    </Route>
  </Route>
);

export const buildOrganizationRoutePath = (
  organizationId: string,
  organizationRoute: OrganizationRoutePaths,
) => `/${organizationId}/-/${organizationRoute}`;
