import { __useAtom } from "@atoms/atom-client";
import type {
  ContextValues,
  ListAtomDataType,
  SetAtomDataType,
} from "@atoms/atom-types";
import { Button, Dropdown, Menu, Table } from "antd";
import type { ColumnType, ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";

type ArrayElement<ArrayType> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : ArrayType extends Record<any, infer ElementType>
    ? Exclude<ElementType, undefined>
    : never;

type KeyOfList<EntryType> = EntryType extends Array<any>
  ? number
  : EntryType extends Record<infer KeyType, any>
    ? KeyType extends string | number | symbol
      ? KeyType
      : never
    : EntryType extends Partial<Record<any, any>>
      ? keyof EntryType
      : never;

export type KeyColumnType<KeyType> = {
  dataIndex: "key";
  render: (value: KeyType) => React.ReactNode;
} & Omit<ColumnType<{ key: KeyType }>, "dataIndex" | "render">;

export type ExtraColumnType<EntryType> = {
  render: (text: string, record: EntryType) => React.ReactNode;
} & Omit<ColumnType<EntryType>, "dataIndex" | "render">;

export type TypedColumnType<
  EntryType,
  IndexType extends keyof EntryType | "key" = keyof EntryType | "key",
  KeyType extends string = string,
> = IndexType extends keyof EntryType
  ? {
      dataIndex: IndexType;
      render?: (
        value: EntryType[IndexType],
        record: EntryType & { key: KeyType },
        index: number,
      ) => React.ReactNode;
    } & Omit<ColumnsType<EntryType>[number], "dataIndex" | "render">
  : KeyColumnType<KeyType>;

export interface AtomTableProps<
  AtomDataFromAtomType extends Record<string, unknown>,
  AtomType extends keyof AtomDataFromAtomType,
  IType extends keyof AtomDataFromAtomType[AtomType] &
    "entries" = keyof AtomDataFromAtomType[AtomType] & "entries",
  EntryType extends ArrayElement<
    AtomDataFromAtomType[AtomType][IType]
  > = ArrayElement<AtomDataFromAtomType[AtomType][IType]>,
  KeyType extends keyof EntryType = keyof EntryType,
  ListKeyType extends KeyOfList<
    AtomDataFromAtomType[AtomType][IType]
  > = KeyOfList<AtomDataFromAtomType[AtomType][IType]>,
> extends Omit<
    React.ComponentProps<
      typeof Table<EntryType & { key: ListKeyType & string }>
    >,
    "columns" | "dataSource"
  > {
  atomType: AtomType;
  columns: Array<
    TypedColumnType<EntryType, KeyType | "key", ListKeyType & string>
  >;
  actions?: (
    key: ListKeyType,
    record: EntryType,
    index: ListKeyType,
  ) => React.ReactNode;
  actionColProps?: Omit<TypedColumnType<any>, "dataIndex" | "render" | "key">;
  overrides?: ContextValues;
  filterOptions?: {
    name: string;
    selector: keyof EntryType & string;
    render?: (value: any) => React.ReactNode;
  }[];
}

export function makeAtomTable<
  AtomDataFromAtomType extends Record<string, unknown>,
>() {
  const AtomTable = <t extends keyof AtomDataFromAtomType & string>({
    atomType,
    overrides,
    className,
    filterOptions,
    actions,
    actionColProps,
    ...props
  }: AtomTableProps<AtomDataFromAtomType, t>) => {
    const data = __useAtom(
      atomType,
      ({
        entries,
      }:
        | SetAtomDataType<string, Record<string, unknown>>
        | ListAtomDataType<Record<string, unknown>>) => Object.entries(entries),
      overrides,
    );
    const [filter, setFilter] = useState<{
      displayName?: string;
      name?: string;
      value?: string;
      render?: (value: any) => React.ReactNode;
    } | null>(null);
    const [filteredData, setFilteredData] = useState<any[] | null>(null);

    useEffect(() => {
      if (filter && data) {
        setFilteredData(
          data
            .filter(([key, elem]) =>
              filter.name && filter.value !== undefined
                ? elem![filter.name] === filter.value
                : true,
            )
            .map(([key, elem]) => ({ ...elem, key })),
        );
      } else {
        setFilteredData(null);
      }
    }, [filter, data]);

    const filterActions: React.ReactNode =
      filterOptions && data ? (
        <>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="noFilter" onClick={() => setFilter(null)}>
                  No Filter
                </Menu.Item>
                {filterOptions.map((option, idx) => (
                  <Menu.Item
                    key={idx}
                    onClick={() => {
                      setFilter({
                        name: option.selector,
                        value: undefined,
                        displayName: option.name,
                        render: option.render,
                      });
                    }}
                  >
                    {option.name}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button>Filter by {filter?.displayName || "Name"}</Button>
          </Dropdown>
          <Dropdown
            disabled={!filter?.name}
            overlay={
              <Menu>
                {filter?.name
                  ? Array.from(
                      new Set(
                        data.map(([key, entry]) => entry![filter!.name!]),
                      ),
                    ).map((value, idx) => (
                      <Menu.Item
                        key={idx}
                        onClick={() => {
                          setFilter(
                            filter !== null
                              ? { ...filter, value: value as string }
                              : { name: undefined, value: value as string },
                          );
                        }}
                      >
                        {filter.render
                          ? filter.render(value as string)
                          : (value as string)}
                      </Menu.Item>
                    ))
                  : null}
              </Menu>
            }
          >
            <Button>
              {filter?.value
                ? filter.render
                  ? filter.render(filter.value)
                  : filter.value
                : "Select"}
            </Button>
          </Dropdown>
        </>
      ) : null;

    return (
      <div className={className}>
        {filterActions}
        <Table
          {...props}
          columns={
            actions
              ? [
                  ...(props.columns as ColumnsType<any>),
                  {
                    title: "Aktionen",
                    dataIndex: "key",
                    render: (value, record, index) =>
                      actions(value, record as any, index as any),
                    ...(actionColProps || {}),
                  },
                ]
              : (props.columns as ColumnsType<any>)
          }
          dataSource={
            filteredData
              ? filteredData
              : !data
                ? []
                : data.map(([key, entry]) => ({ ...entry, key }))
          }
          loading={data === undefined}
        />
      </div>
    );
  };
  return AtomTable;
}
