/*
 * This file has been generated by permissions
 * Hash: 8dd5569902718b7280692ed9269a2663cb122b0f0da29a5d1bf004f2cb7f97b7
 */

export enum TodoListPermissions {
  Create = "CreateTodoList",
  Delete = "DeleteTodoList",
  Read = "ReadTodoList",
  Update = "UpdateTodoList",
  ReadInfo = "ReadInfo",
  UpdateInfo = "UpdateInfo",
}
