import type { AtomData, VersionedMetaInfo } from "../VersionAtom";

import { applyPatch, deepClone } from "fast-json-patch";
import type { ChangeLog } from "../VersionAtom";

export function calculateLatestVersionedAtomValue<
  DataType extends AtomData = AtomData,
>(history: Array<ChangeLog<DataType>>): DataType & VersionedMetaInfo {
  if (history.length === 0) {
    return {} as DataType & VersionedMetaInfo;
  }

  const latest = history.reduce(
    (acc, { changes }) => {
      return applyPatch(acc, deepClone(changes)).newDocument;
    },
    {} as DataType & VersionedMetaInfo,
  );

  const latestHistoryElement = history[history.length - 1]!;
  latest.date = latestHistoryElement.date;
  latest.author = latestHistoryElement.author;

  return latest;
}
