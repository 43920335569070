import { Modal, type ModalProps } from "antd";
import { useEffect, useState } from "react";

export const ModalWithShortcuts = (props: ModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const keyHandler = async (e: KeyboardEvent) => {
    if (e.key === "Enter" && e.shiftKey && !props.okButtonProps?.disabled) {
      props.onOk?.(new MouseEvent("click") as any);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    addEventListener("keydown", keyHandler);

    return () => {
      removeEventListener("keydown", keyHandler);
    };
  }, [isOpen]);
  return (
    <Modal
      afterOpenChange={(open) => {
        props.afterOpenChange?.(open);
        setIsOpen(open);
      }}
      {...props}
    />
  );
};
