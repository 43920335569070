export type AtomDescriptor = `atom:${string}${string}`;

export function makeAtomDescriptor(
  type: string,
  context: Record<string, any> = {},
): AtomDescriptor {
  return `atom:${type}${JSON.stringify(context)}`;
}

export const AtomRef = makeAtomDescriptor;

export function parseAtomDescriptor(descriptor: AtomDescriptor) {
  const match = descriptor.match(/atom:(\w+)(\{.*\})?/);
  if (!match) {
    throw new Error("Invalid atom descriptor");
  }
  return {
    type: match[1],
    context: match[2] ? JSON.parse(match[2]) : {},
  };
}

export function extractAtomDescriptors(
  stringContainingAtomDescriptors: string,
): AtomDescriptor[] {
  const regex = /atom:\w+(?:\{.*?\})?/g;
  return (stringContainingAtomDescriptors.match(regex) ||
    []) as AtomDescriptor[];
}
