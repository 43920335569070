import { MagicFormContext } from "@atoms/atom-components";
import type { IWorkpackage } from "@project/shared";
import { Progress, Row } from "antd";
import SkeletonInput from "antd/es/skeleton/Input";
import { useContext } from "react";
import { computeActivityStats } from "../hooks/useWorkPackage";

export const ActivityProgress = () => {
  const { currentValue, loading } = useContext(MagicFormContext)!;
  const activityStats = computeActivityStats(currentValue as IWorkpackage);

  return loading ? (
    <SkeletonInput active style={{ height: 56, width: 300 }} />
  ) : (
    <div className="float-label">
      <div className="label label-float">Aktivitäten abgeschlossen</div>
      <Row
        wrap={false}
        align="middle"
        className="progress"
        style={{ width: "300px" }}
      >
        <Progress
          percent={
            (activityStats.finishedActivities / activityStats.allActivities) *
            100
          }
          showInfo={false}
        />
        <span
          style={{
            whiteSpace: "nowrap",
            width: 100,
            textAlign: "center",
            display: "inline-block",
          }}
        >
          {activityStats.finishedActivities} / {activityStats.allActivities}
        </span>
      </Row>
    </div>
  );
};
