import { ApolloClient, InMemoryCache } from "@apollo/client";
import { getBackendUrl } from "../index";
// import { Modal, Spin, notification } from "antd";
import { makeAuthLink } from "./apollo/makeAuthLink";
import { makeErrorLink } from "./apollo/makeErrorLink";
import { makeHttpLink } from "./apollo/makeHttpLink";
import { makeRehydrateDatesLink } from "./apollo/makeRehydrateDatesLink";
import { makeRetryLink } from "./apollo/makeRetryLink";
import { makeSplitLink } from "./apollo/makeSplitLink";
import { makeSubscriptionsLink } from "./apollo/makeSubscriptionsLink";
// import { getAuthToken } from "./util/keycloak";

export const initApolloClient = (
  getAuthToken: () => Promise<string | null>,
) => {
  const { graphQlUrl, graphQlSubscriptionUrl } = getBackendUrl();

  return new ApolloClient({
    queryDeduplication: true,
    // defaultOptions: {
    //     mutate: {
    //         errorPolicy: "all",
    //     },
    //     query: {
    //         errorPolicy: "all",
    //     },
    // },
    cache: new InMemoryCache(),
    link: makeSplitLink(
      [
        makeRetryLink(),
        makeRehydrateDatesLink(),
        makeAuthLink(getAuthToken),
        makeHttpLink(graphQlUrl),
      ],
      [
        makeErrorLink(),
        makeRehydrateDatesLink(),
        makeSubscriptionsLink(graphQlSubscriptionUrl, getAuthToken, 10, 5_000, {
          onDelayedConnection: () => {
            // notification.info({
            //   placement: "top",
            //   message: "Verbindungsaufbau",
            //   description: "Verbinde zum Server...",
            //   key: "ws-connection-delayed",
            //   duration: 0,
            //   icon: <Spin />,
            //   closeIcon: false,
            // });
          },
          connected: () => {
            // notification.destroy("ws-connection-delayed");
          },
          onFatalError: () => {
            // notification.destroy("ws-connection-delayed");
            // Modal.error({
            //   title: "Verbindung fehlgeschlagen",
            //   maskClosable: false,
            //   closable: false,
            //   okText: "Erneut versuchen",
            //   onOk: () => {
            //     location.reload();
            //   },
            //   content: "Verbindung zum Server fehlgeschlagen",
            // });
          },
        }),
      ],
    ),
  });
};
